// package
import { Fragment, useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Image,
  Button,
  Form,
  Pagination as BootstrapPagination,
} from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Slider from "react-slick";
// component
import AgStatus from "elements/System/AgStatus";
import Attachment from "elements/System/Attachment";
import Note from "elements/System/Notes";
import { api, common } from "includes";

export default function DataDetail() {
  // const
  const navigate = useNavigate();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // state
  const { moduleId, dataId } = useParams();
  const [status, setStatus] = useState<any>(100);
  const [moduleInfo, setModuleInfo] = useState<any>(null);
  const [dataInfo, setDataInfo] = useState<any>(null);
  const [fieldInfo, setFieldInfo] = useState<any>(null);
  const [selectedStage, setSelectedStage] = useState<any>(null);
  const [expand, setExpand] = useState(false);
  const [readMore, setReadMore] = useState(false);
  const [conversionInfo, setConversionInfo] = useState<any>(null);

  // effect
  useEffect(() => {
    getColumnsData();
  }, []);

  // api
  const getColumnsData = () => {
    let data: any = {
      url: `/module/read?type=sp&fields=id,name,data,key,stage&query=id=${moduleId}&join=&limit=30&offset=0&order=sort ASC`,
      method: "GET",
      auth: "token",
    };
    api.call(data, (res: any) => {
      if (res.status === 200) {
        let result: any = res.data;
        setModuleInfo({
          key: result.key,
          name: result.name,
          stage: result.stage,
        });
        setFieldInfo(result.data);
        getData(result.key);
      } else {
        setStatus(res.status);
      }
    });
  };

  const getData = (moduleKey: string) => {
    let data: any = {
      url: `/data/read/${moduleKey}/${moduleId}?query=id=${dataId}&type=sp&fields=*&limit=1&offset=0&order=id ASC&join=`,
      method: "GET",
    };
    api.call(data, (res: any) => {
      setStatus(res.status);
      if (res.status === 200) {
        let result: any = res.data;
        setSelectedStage(result.stage);
        setDataInfo(result);
        if (result.conversion) {
          getConversionInfo();
        }
      }
    });
  };

  const getConversionInfo = () => {
    let data: any = {
      url: "/conversion/info/",
      query: dataId,
      method: "GET",
    };
    api.call(data, (res: any) => {
      setStatus(res.status);
      if (res.status === 200) {
        let result: any = res.data;
        setConversionInfo(result);
      }
    });
  };

  const onStageChange = (obj: any) => {
    setSelectedStage(obj);
    let fields: any = {};
    fields.stage = obj;

    let data = {
      url: "/data/update/",
      query: moduleInfo.key + "/" + dataId,
      method: "PUT",
      body: fields,
    };

    api.call(data, (res: any) => {
      if (res.status === 200) {
        common.notify("S", "Stage Successfully Updated");
      } else {
        common.notify("E", res.message);
      }
    });
  };

  // support
  const onAgCallBack = (status: any) => {
    if (status == 400) {
      navigate("/data/list/" + moduleId);
    } else {
      getColumnsData();
    }
  };

  // render
  return (
    <Fragment>
      {status !== 200 ? (
        <AgStatus
          process={status}
          message={{
            100: "Loading details... Please wait.",
            400: "You're trying to access invalid records.",
          }}
          button={{ 400: "Back to List" }}
          onAgCallBack={onAgCallBack}
          size="l"
          img={moduleInfo?.key ? `${moduleInfo?.key}.svg` : "modules.svg"}
        />
      ) : dataInfo?.conversion ? (
        // conversion information
        <div>
          <Row>
            <Col xl={12} lg={12}>
              <Card>
                <Card.Body className="py-1 px-1 border-bottom border-light">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="float-start">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="">Back</Tooltip>}
                      >
                        <Link
                          to={"/data/list/" + moduleId}
                          className="text-reset fs-20 p-1 d-inline-block"
                        >
                          <i
                            className="ri-arrow-left-s-line pe-2"
                            data-bs-container="#tooltips-container"
                            data-bs-toggle="tooltip"
                          />
                        </Link>
                      </OverlayTrigger>
                      <label className="form-check-label fs-16">Lead</label>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl={12} lg={12}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col sm={12}>
                      <div>
                        <div>
                          <h5 className="mb-3 text-uppercase bg-light p-2">
                            <i className="ri-survey-line me-1" />
                            Converted Lead Information
                          </h5>
                          <Row>
                            <Col>
                              <p className="mt-2 mb-1 text-muted font-12">
                                Account
                              </p>
                              <div className="d-flex">
                                <i className="ri-suitcase-line fs-24 text-success me-1" />
                                <div>
                                  <Link
                                    to={
                                      "/data/detail/" +
                                      conversionInfo?.accounts?.module_id +
                                      "/" +
                                      conversionInfo?.accounts?.id
                                    }
                                    target="_blank"
                                  >
                                    <h5 className="mt-2 font-14">
                                      {conversionInfo?.accounts?.text_1}
                                    </h5>
                                  </Link>
                                </div>
                              </div>
                            </Col>

                            <Col>
                              <p className="mt-2 mb-1 text-muted font-12">
                                Contact
                              </p>
                              <div className="d-flex">
                                <i className=" ri-team-line fs-24 text-success me-1" />
                                <div>
                                  <Link
                                    to={
                                      "/data/detail/" +
                                      conversionInfo?.contacts?.module_id +
                                      "/" +
                                      conversionInfo?.contacts?.id
                                    }
                                    target="_blank"
                                  >
                                    <h5 className="mt-2 font-14">
                                      {conversionInfo?.contacts?.text_1}
                                    </h5>
                                  </Link>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <p className="mt-2 mb-1 text-muted font-12">
                                Deal
                              </p>
                              <div className="d-flex">
                                {conversionInfo?.deals ? (
                                  <>
                                    <i className="ri-thumb-up-line fs-24 text-success me-1" />
                                    <div>
                                      <Link
                                        to={
                                          "/data/detail/" +
                                          conversionInfo?.deals?.module_id +
                                          "/" +
                                          conversionInfo?.deals?.id
                                        }
                                        target="_blank"
                                      >
                                        <h5 className="mt-2 font-14">
                                          {conversionInfo?.deals?.text_1}
                                        </h5>
                                      </Link>
                                    </div>
                                  </>
                                ) : (
                                  "-"
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      ) : (
        // Detail
        <div>
          <Row>
            <Col xl={12} lg={12}>
              <Card>
                <Card.Body className="py-1 px-1 border-bottom border-light">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="float-start">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="">Back</Tooltip>}
                      >
                        <Link
                          to={"/data/list/" + moduleId}
                          className="text-reset fs-20 p-1 d-inline-block"
                        >
                          <i
                            className="ri-arrow-left-s-line pe-2"
                            data-bs-container="#tooltips-container"
                            data-bs-toggle="tooltip"
                          />
                        </Link>
                      </OverlayTrigger>
                      <label className="form-check-label fs-16">
                        {moduleInfo.name}
                      </label>
                    </div>
                    <div id="tooltips-container d-flex align-items-center">
                      {moduleInfo.key === "leads" && (
                        <Button
                          variant="secondary"
                          onClick={() =>
                            navigate(
                              "/data/conversion/" + moduleId + "/" + dataId
                            )
                          }
                        >
                          Convert
                        </Button>
                      )}
                      <Button
                        variant="primary"
                        className="ms-2 me-1"
                        onClick={() =>
                          navigate("/data/crud/" + moduleId + "/" + dataId)
                        }
                      >
                        Edit
                      </Button>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="">Previous</Tooltip>}
                      >
                        <Link
                          to="#"
                          className="text-reset fs-20 p-1 d-inline-block"
                        >
                          <i
                            className="ri-arrow-left-s-line"
                            data-bs-container="#tooltips-container"
                            data-bs-toggle="tooltip"
                          />
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="">Next</Tooltip>}
                      >
                        <Link
                          to="#"
                          className="text-reset fs-20 p-1 d-inline-block"
                        >
                          <i
                            className="ri-arrow-right-s-line"
                            data-bs-container="#tooltips-container"
                            data-bs-toggle="tooltip"
                          />
                        </Link>
                      </OverlayTrigger>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="">Action</Tooltip>}
                      >
                        <Link
                          to="#"
                          className="text-reset fs-20 p-1 d-inline-block"
                        >
                          <Dropdown>
                            <Dropdown.Toggle
                              as={Link}
                              to="#"
                              className="arrow-none card-drop"
                            >
                              <i className="ri-more-2-line" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu align="end">
                              <Dropdown.Item className="dropdown">
                                <i className="me-1 ri-delete-bin-3-line" />
                                Delete
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown">
                                <i className="me-1 ri-folder-transfer-line" />
                                Conversion
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown">
                                <i className="me-1 ri-file-copy-line" />
                                Clone
                              </Dropdown.Item>
                              <Dropdown.Item className="dropdown">
                                <i className="me-1 ri-mail-send-line" />
                                Send Email
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </Link>
                      </OverlayTrigger>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            {expand && (
              <Col xl={3} lg={3}>
                <Card>
                  <Card.Body>
                    <div id="list-example" className="list-group">
                      <a
                        href="#note"
                        className="list-group-item list-group-item-action"
                      >
                        <i className="ri-draft-line me-1" /> Notes
                      </a>
                      <a
                        href="#attach"
                        className="list-group-item list-group-item-action"
                      >
                        <i className="ri-attachment-line me-1" /> Attachments
                      </a>
                      <a
                        href="#open"
                        className="list-group-item list-group-item-action"
                      >
                        <i className="ri-door-open-line me-1" /> Open Activities
                      </a>
                      <a
                        href="#close"
                        className="list-group-item list-group-item-action"
                      >
                        <i className="ri-door-closed-line me-1" /> Closed
                        Activities
                      </a>
                      <a
                        href="#email"
                        className="list-group-item list-group-item-action"
                      >
                        {" "}
                        <i className="ri-mail-line me-1" /> Emails
                      </a>
                      <a
                        href="#timeline"
                        className="list-group-item list-group-item-action"
                      >
                        {" "}
                        <i className="ri-mail-line me-1" /> Timeline
                      </a>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )}
            <Col
              xl={expand ? "9" : "12"}
              lg={expand ? "9" : "12"}
              className="detail-right-wrap"
            >
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="">{expand ? "Collapse" : "Expand"}</Tooltip>
                }
              >
                <span className="expand-collapse-icon">
                  {expand ? (
                    <i
                      className="ri-skip-left-fill fs-22"
                      onClick={() => setExpand(false)}
                    />
                  ) : (
                    <i
                      className="ri-skip-right-fill fs-22"
                      onClick={() => setExpand(true)}
                    />
                  )}
                </span>
              </OverlayTrigger>
              <Card>
                <Card.Body>
                  <h5 className="mb-2 text-uppercase bg-light p-2">
                    <i className="ri-hotel-line me-1" />
                    Stage
                  </h5>
                  {/* <div className="d-flex align-items-center justify-content-between">
                    <div>
                      <Form.Label className="mb-0">START</Form.Label>
                      <p className="text-muted">24/05/2024</p>
                    </div>
                    <div className="text-end">
                      <Form.Label className="mb-0">CLOSING</Form.Label>
                      <p className="text-muted">24/05/2024</p>
                    </div>
                  </div> */}
                  <div className="slider-container status-slider">
                    <Slider {...settings}>
                      {moduleInfo.stage.map((obj: any, idx: number) => {
                        return (
                          <>
                            <div
                              className={`status-card ${selectedStage?.name === obj.name ? "active" : ""
                                }`}
                              key={idx}
                              onClick={() => onStageChange(obj)}
                            >
                              {obj.category == "Won" ? (
                                <i className="ri-thumb-up-fill text-success mx-1" />
                              ) : (
                                obj.category == "Lost" && (
                                  <i className="ri-thumb-down-fill text-danger mx-1" />
                                )
                              )}
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="">{`${obj.category} : ${obj.probability} %`}</Tooltip>
                                }
                              >
                                <p className="status-card-txt">{obj.name}</p>
                              </OverlayTrigger>
                            </div>
                          </>
                        );
                      })}
                    </Slider>
                  </div>
                  {/* <Row className="border-top">
                    <Col md={6} className="mt-2">
                      <Form.Group>
                        <Form.Label>Amount: </Form.Label>
                        <p className="text-muted fs-14">100</p>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mt-2">
                      <Form.Group>
                        <Form.Label>Probability (%): </Form.Label>
                        <p className="text-muted fs-14">50</p>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Expected Revenue: </Form.Label>
                        <p className="text-muted fs-14">50</p>
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group>
                        <Form.Label>Closing Date: </Form.Label>
                        <p className="text-muted fs-14">26/05/2024</p>
                      </Form.Group>
                    </Col>
                  </Row> */}
                </Card.Body>
              </Card>

              <Card>
                <Card.Body>
                  <Row>
                    <Col sm={12}>
                      <div>
                        {fieldInfo.sections.map((item: any, key: any) => (
                          <div key={key}>
                            <h5 className="mb-3 text-uppercase bg-light p-2">
                              <i className="ri-survey-line me-1" />
                              {item.label}
                            </h5>
                            <Row>
                              {fieldInfo.fields
                                .filter(
                                  (i: any) =>
                                    i.layout === item.id &&
                                    i.status === "use" &&
                                    i.visibility
                                )
                                .map((cItem: any, cKey: any) => (
                                  <Col
                                    sm={item.layout === "double" ? 6 : 12}
                                    key={cKey}
                                  >
                                    <Form.Group className="mb-3">
                                      <Form.Label>{cItem.label}</Form.Label>
                                      <p className="text-muted fs-14">
                                        {cItem.type === "date" ? (
                                          dataInfo[cItem.key] ? (
                                            moment(dataInfo[cItem.key]).format(
                                              "DD-MMM-YYYY"
                                            )
                                          ) : (
                                            "-"
                                          )
                                        ) : cItem.type === "date_time" ? (
                                          dataInfo[cItem.key] ? (
                                            moment(dataInfo[cItem.key]).format(
                                              "DD-MMM-YYYY hh:mm A"
                                            )
                                          ) : (
                                            "-"
                                          )
                                        ) : cItem.type === "email" ? (
                                          dataInfo[cItem.key] ? (
                                            <a
                                              href={`mailto:${dataInfo[cItem.key]
                                                }`}
                                              className="text-muted"
                                            >
                                              {dataInfo[cItem.key]}
                                            </a>
                                          ) : (
                                            "-"
                                          )
                                        ) : cItem.type === "phone" ? (
                                          dataInfo[cItem.key] ? (
                                            <a
                                              href={`tel:+${dataInfo[cItem.key]
                                                }`}
                                              className="text-muted"
                                            >
                                              +{dataInfo[cItem.key]}
                                            </a>
                                          ) : (
                                            "-"
                                          )
                                        ) : cItem.type === "url" ? (
                                          dataInfo[cItem.key] ? (
                                            <Link
                                              to={dataInfo[cItem.key]}
                                              target="_blank"
                                              className="link-primary"
                                            >
                                              {dataInfo[cItem.key]}
                                            </Link>
                                          ) : (
                                            "-"
                                          )
                                        ) : cItem.type === "bool" ? (
                                          dataInfo[cItem.key] === "Y" ? (
                                            "Yes"
                                          ) : (
                                            "No"
                                          )
                                        ) : cItem.type === "number" ? (
                                          dataInfo[cItem.key] ? (
                                            common.getNumWithComma(
                                              dataInfo[cItem.key]
                                            )
                                          ) : (
                                            "-"
                                          )
                                        ) : cItem.type === "long_text" ? (
                                          dataInfo[cItem.key] ? (
                                            <>
                                              <p
                                                className={
                                                  readMore
                                                    ? "text-muted fs-14 full-description mb-0"
                                                    : "text-muted fs-14 short-description mb-0"
                                                }
                                              >
                                                {dataInfo[cItem.key] || "-"}
                                              </p>
                                              {dataInfo[cItem.key] &&
                                                dataInfo[cItem.key].length >
                                                100 && (
                                                  <Link
                                                    to="#"
                                                    className="text-muted fw-bold fs-13"
                                                    onClick={() =>
                                                      setReadMore(!readMore)
                                                    }
                                                  >
                                                    {!readMore
                                                      ? "Read more"
                                                      : "Show Less"}
                                                  </Link>
                                                )}
                                            </>
                                          ) : (
                                            "-"
                                          )
                                        ) : ["pick_list", "lookup"].includes(
                                          cItem.type
                                        ) ? (
                                          dataInfo[cItem.key] ? (
                                            Object.values(
                                              dataInfo[cItem.key]
                                            ).join(", ")
                                          ) : (
                                            "-"
                                          )
                                        ) : (
                                          dataInfo[cItem.key] || "-"
                                        )}
                                      </p>
                                    </Form.Group>
                                  </Col>
                                ))}
                            </Row>
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* Notes */}
              <Card id="note">
                <Card.Body>
                  <h5 className="mb-3 text-uppercase bg-light p-2">
                    <i className="ri-draft-line me-1" /> Notes
                  </h5>
                  <Row>
                    <Col md={8}>
                      <Note data_id={dataId} module_id={moduleId} />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* Attachments */}
              <Card id="attach">
                <Card.Body>
                  <h5 className="mb-3 text-uppercase bg-light p-2">
                    <i className="ri-attachment-line me-1" /> Attachments
                  </h5>
                  <Row>
                    <Col md={8}>
                      <Attachment
                        force={false}
                        data_id={dataId}
                        module_id={moduleId}
                        multiple={true}
                      />
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* Open Activities */}
              <Card id="open">
                <Card.Body>
                  <h5 className="mb-3 text-uppercase bg-light p-2">
                    <i className="ri-door-open-line me-1" /> Open Activities
                  </h5>
                  <Row>
                    <Col md={6}>
                      <p className="text-muted fs-14">Coming Soon</p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* Closed Activities */}
              <Card id="close">
                <Card.Body>
                  <h5 className="mb-3 text-uppercase bg-light p-2">
                    <i className="ri-door-closed-line me-1" /> Closed Activities
                  </h5>
                  <Row>
                    <Col md={6}>
                      <p className="text-muted fs-14">Coming Soon</p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* Emails */}
              <Card id="email">
                <Card.Body>
                  <h5 className="mb-3 text-uppercase bg-light p-2">
                    <i className="ri-mail-line me-1" /> Emails
                  </h5>
                  <Row>
                    <Col md={6}>
                      <p className="text-muted fs-14">Coming Soon</p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* Timeline */}
              <Card id="timeline">
                <Card.Body>
                  <h5 className="mb-3 text-uppercase bg-light p-2">
                    <i className="ri-mail-line me-1" /> Timeline
                  </h5>
                  <Row>
                    <Col md={6}>
                      <ul className="timeline-ul">
                        <li className="timeline-list timeline-list-date">
                          <p className="timeline-date">May 31, 2024</p>
                          <p className="timeline-time">05:00 PM</p>
                          <Button variant="warning" className="timeline-icon">
                            <i className="ri-attachment-2"></i>
                          </Button>
                          <div className="timeline-content">
                            <p className="mb-0">
                              Attachment added - emailverified.svg
                            </p>
                            <p className="text-muted fs-12">
                              by Dharma May 31, 2024
                            </p>
                          </div>
                        </li>
                        <li className="timeline-list">
                          <p className="timeline-time">02:03 PM</p>
                          <Button variant="info" className="timeline-icon">
                            <i className="ri-pencil-line"></i>
                          </Button>
                          <div className="timeline-content">
                            <p className="mb-0">
                              First Name was updated from blank value to fname
                              Full Name was updated from Test1 to Test1 fname
                            </p>
                            <p className="text-muted fs-12">
                              by Dharma May 31, 2024
                            </p>
                          </div>
                        </li>
                        <li className="timeline-list timeline-list-date">
                          <p className="timeline-date">May 30, 2024</p>
                          <p className="timeline-time">12:19 PM</p>
                          <Button variant="success" className="timeline-icon">
                            <i className="ri-focus-3-line"></i>
                          </Button>
                          <div className="timeline-content">
                            <p className="mb-0">Lead Created</p>
                            <p className="text-muted fs-12">
                              by Dharma May 31, 2024
                            </p>
                          </div>
                        </li>
                      </ul>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </Fragment>
  );
}
