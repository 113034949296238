//package
import { Fragment, useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Button,
  OverlayTrigger,
  Tooltip,
  Form,
} from "react-bootstrap";
import AmlHashForm from "aml-hash-form";
import EmailEditor, { EditorRef, UnlayerOptions } from "react-email-editor";
import ReactSearchBox from "react-search-box";

//includes
import FormSelect from "elements/System/FormSelect";
import { api, common } from "includes";
import AgStatus from "elements/System/AgStatus";

export default function EmailHome() {
  //ref
  const emailEditorRef = useRef<EditorRef>(null);
  //const
  const pathname = useLocation();
  const navigate = useNavigate();
  const { tempId }: any = useParams();
  //state
  const [process, setProcess] = useState<any>({ result: 100, edit: 100 });
  const [popup, setPopup] = useState<any>("");
  const [tag, setTag] = useState<any>({ label: null, value: null, key: null });
  const [Done, setDone] = useState<
    ((finalMergeTag: Partial<any>) => void) | null
  >(null);

  const [resultTemplate, setResultTemplate] = useState<any>(null);
  const [data, setData] = useState<any>({
    field: [],
    moduleId: "",
    templatedesignJson: null,
    templateHtml: null,
    MergeTag: {},
  });
  const [utils, setUtils] = useState<any>({
    loadEditor: true,
    saveTemplate: false,
  });

  //form
  const formFields = {
    name: { validate: ["req#Template Title is required"] },
    module: { validate: ["objValReq#Module is required"], obj: "value" },
    subject: { validate: ["req#Subject is required"] },
  };
  const { fields, errors, handleChange, handleSubmit, setMultiValue } =
    AmlHashForm(formFields);

  //effect

  useEffect(() => {
    if (tempId != 0) {
      getData();
    }
  }, [tempId]);

  useEffect(() => {
    if (data.moduleId) {
      getMergeTag(data.moduleId);

      utilsState("loadEditor", true);
    }
  }, [data.moduleId]);

  useEffect(() => {
    if (
      resultTemplate &&
      emailEditorRef.current &&
      emailEditorRef.current.editor
    ) {
      emailEditorRef.current.editor.loadDesign(resultTemplate);
    }
  }, [resultTemplate]);

  //api
  const getData = () => {
    processState("result", 100);
    const encodedTypeValue = encodeURIComponent(`'${tempId}'`);
    let url = `?type=sp&fields=*&query=id=${encodedTypeValue}&join=&limit=2&offset=0&order=id ASC`;
    let data = {
      url: "/template/read",
      query: url,
      method: "GET",
    };
    api.call(data, (res: any) => {
      if (res.status === 200) {
        processState("result", res.status);
        const result = res.data;
        const templateHtml = result.data;

        setResultTemplate(templateHtml);
        const moduleId = Object.keys(result.module)[0];

        dataState("moduleId", moduleId);

        setMultiValue({
          name: result.name,
          module: result.module,
          subject: result.subject,
        });
      } else {
        processState("result", res.status);
      }
    });
  };

  const getMergeTag = (value: any) => {
    const encodedTypeValue = encodeURIComponent(`'${value}'`);
    let url = `?type=mp&fields=id,data&query=id=${encodedTypeValue}&join=&limit=2&offset=0&order=id ASC`;

    let data = {
      url: `/module/read`,
      query: url,
      method: "GET",
    };
    api.call(data, (res: any) => {
      if (res.status === 200) {
        const result = res.data;
        console.log("mergeTag", res.data[0].data);
        const fields = res.data[0].data.fields.map((field: any, i: any) => ({
          index: i,
          label: "$" + "{" + field.label + "}",
          value: "$" + "{" + field.label + "}",
          key: field.key,
        }));

        setTag(fields);
      }
    });
  };

  const onEdit = () => {
    utilsState("saveTemplate", true);
    if (emailEditorRef.current && emailEditorRef.current.editor) {
      emailEditorRef.current?.editor.exportHtml((data) => {
        const { design, html } = data;
        let processedHtml = html;
        let designJson = design;

        dataState("templatedesignJson", design);
        tag.forEach((mergeTag: any) => {
          const regexPattern = `\\$\\{${mergeTag.label.slice(2, -1)}\\}`;
          const regex = new RegExp(regexPattern, "g");
          processedHtml = processedHtml.replace(
            regex,
            "${" + mergeTag.key + "}"
          );
        });

        dataState("templateHtml", processedHtml);

        let payload = {
          name: fields.name,
          subject: fields.subject,
          type: "Email",
          body: processedHtml,
          data: designJson,
          module: fields.module,
        };

        const result = {
          url: `/template/update/${tempId}`,
          method: "PUT",
          auth: "token",
          body: payload,
        };

        api.call(result, (res: any) => {
          if (res.status === 200) {
            common.notify("S", "Successfully Updated");
            navigate("/services/email/list");
          } else {
            common.notify("E", "Something Went wrong ,Try Again");
          }

          utilsState("saveTemplate", false);
        });
      });
    }
  };

  const onSave = () => {
    utilsState("saveTemplate", true);
    if (emailEditorRef.current && emailEditorRef.current.editor) {
      emailEditorRef.current?.editor.exportHtml((data) => {
        const { design, html } = data;
        let processedHtml = html;
        let designJson = design;

        dataState("templatedesignJson", design);
        tag.forEach((mergeTag: any) => {
          const regexPattern = `\\$\\{${mergeTag.label.slice(2, -1)}\\}`;
          const regex = new RegExp(regexPattern, "g");
          processedHtml = processedHtml.replace(
            regex,
            "${" + mergeTag.key + "}"
          );
        });

        dataState("templateHtml", processedHtml);
        let payload = {
          name: fields.name,
          subject: fields.subject,
          type: "Email",
          body: processedHtml,
          data: designJson,
          module: fields.module,
        };

        const datas = {
          url: "/template/create",
          method: "POST",
          auth: "token",
          body: payload,
        };

        api.call(datas, (res: any) => {
          if (res.status === 200) {
            common.notify("S", "Successfully saved");
            navigate("/services/email/list");
          } else if (html == null) {
            common.notify("E", "Template Required");
          } else {
            common.notify("E", "Something went wrong,Try Again");
          }

          utilsState("saveTemplate", false);
        });
      });
    }
  };

  //events

  const onSubmit = () => {
    const isValid = handleSubmit();
    if (isValid) {
      if (tempId != 0) {
        onEdit();
      } else {
        onSave();
      }
    }
  };
  const onLoad = () => {
    console.log("Email editor has loaded");
    if (
      resultTemplate &&
      emailEditorRef.current &&
      emailEditorRef.current.editor
    ) {
      emailEditorRef.current.editor.loadDesign(resultTemplate);
    }
  };

  const onReady = () => {
    utilsState("loadEditor", false);

    if (
      resultTemplate &&
      emailEditorRef.current &&
      emailEditorRef.current.editor
    ) {
      emailEditorRef.current.editor.loadDesign(resultTemplate);
    }
  };

  // callback

  const onAgCallBack = (status: any) => {
    navigate(`/services/email/list`);
  };

  // support
  const processState = (label: any, value: any) => {
    setProcess((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };
  const dataState = (label: any, value: any) => {
    setData((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };
  const utilsState = (label: any, value: any) => {
    setUtils((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };
  return (
    <Fragment>
      {process.result != 200 && tempId != 0 ? (
        <AgStatus
          process={process.result}
          message={{
            100: "Loading data.Please wait...",
            204: `No Templates found!`,
            400: "You're trying to access invalid records.",
          }}
          button={{ 400: "Go to List" }}
          size="l"
          //   img={`${moduleInfo?.key}.svg`}
          onAgCallBack={onAgCallBack}
        />
      ) : (
        <Fragment>
          <Col xl={12} lg={12} className="mt-1">
            <Card>
              <Card.Body className="py-1 px-2 border-bottom border-light">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="float-start">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="">Back</Tooltip>}
                    >
                      <Link
                        to="/settings/home"
                        className="text-reset fs-20 p-1 d-inline-block"
                      >
                        <i
                          className="ri-arrow-left-s-line pe-2"
                          data-bs-container="#tooltips-container"
                          data-bs-toggle="tooltip"
                        />
                      </Link>
                    </OverlayTrigger>
                    <label className="form-check-label fs-16">
                      {pathname.pathname.includes("email") ? "Email" : "SMS"}
                    </label>
                  </div>
                  <div className="d-flex align-items-center">
                    <Button
                      variant="light"
                      className="me-1"
                      onClick={() => navigate("/services/email/list")}
                    >
                      Close
                    </Button>
                    <Button
                      variant="primary"
                      onClick={onSubmit}
                      disabled={utils.loadEditor || utils.saveTemplate}
                    >
                      {tempId != 0 ? "Update" : "Save"}
                    </Button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Row>
            <Col md={12}>
              <Card>
                <Card.Body>
                  <Row>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Select Module</Form.Label>
                        <FormSelect
                          url="/module/read"
                          fields="id,name"
                          query=""
                          order="name ASC"
                          fixed={false}
                          value={fields.module}
                          limit={50}
                          multi={false}
                          render={"yes"}
                          async={false}
                          onSelect={(data: any) => {
                            setMultiValue({
                              module: data,
                            });
                            if (data) {
                              const keys: any = Object.keys(data);
                              const key: any = Number(keys[0]);

                              dataState("moduleId", key);
                            } else {
                              console.log("data.value is undefined");
                            }
                          }}
                          error={errors.module}
                          disabled={tempId != 0 ? true : false}
                        />
                        <p className="error-text">{errors.module}</p>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Template Name</Form.Label>
                        <Form.Control
                          type="text"
                          maxLength={255}
                          data-toggle="maxlength"
                          name="name"
                          value={fields.name}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          placeholder="Enter Template name"
                          isInvalid={errors.name ? true : false}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col lg={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Subject</Form.Label>
                        <Form.Control
                          type="text"
                          maxLength={255}
                          data-toggle="maxlength"
                          name="subject"
                          value={fields.subject}
                          onChange={(e) => {
                            handleChange(e);
                          }}
                          placeholder="Enter the Subject"
                          isInvalid={errors.subject ? true : false}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.subject}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col>
                      <div>
                        {popup == "mergeTag" && (
                          <div className="unlayer-div">
                            <div className="etemp-searchmodal-header">
                              <h4 className="modal-title">Search</h4>
                              <i
                                className="ri-close-line fs-20 crm-cp"
                                onClick={() => setPopup("")}
                              />
                            </div>
                            <div className="etemp-searchmodal-body">
                              <label htmlFor="search-bar">
                                Search for merge tags
                              </label>

                              <ReactSearchBox
                                leftIcon={<i className="ri-search-line"></i>}
                                iconBoxSize="48px"
                                onSelect={(record: any) => {
                                  // setMergeTag(record);
                                  dataState("MergeTag", record);

                                  setPopup("");
                                  if (Done) Done(record);
                                }}
                                data={tag}
                                placeholder={""}
                                onChange={(value) => {
                                  console.log("Search box value:", value);
                                }}
                              />
                            </div>

                            <div className="etemp-searchmodal-footer">
                              <Button
                                variant="light"
                                size="sm"
                                onClick={() => setPopup("")}
                              >
                                <i className="ri-close-line" /> Cancel
                              </Button>
                              <Button
                                variant="success"
                                size="sm"
                                onClick={() => {
                                  if (Done && data.MergeTag) {
                                    Done(data.MergeTag);
                                    console.log(data.MergeTag);

                                    setPopup("");
                                  }
                                }}
                                id="addmergetag"
                              >
                                <i className="ri-add-line" /> Add Merge Tag
                              </Button>
                            </div>
                          </div>
                        )}

                        {popup === "mergeTag" && (
                          <div className="unlayer-overlay"></div>
                        )}
                        <Form.Label>Email Body</Form.Label>
                        <EmailEditor
                          ref={emailEditorRef}
                          onReady={onReady}
                          onLoad={onLoad}
                          minHeight={"1025px"}
                          displayMode={"web"}
                          options={
                            {
                              mergeTags: tag,
                            } as unknown as UnlayerOptions
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
}
