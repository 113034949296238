import { configureStore } from "@reduxjs/toolkit";

// reducers
import Layout from "./layout/reducers";

// mount it on the store
export const store = configureStore({
  reducer: {
    Layout: Layout,
  } as any
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
