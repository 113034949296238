// package
import { Fragment } from "react";
// component
import BreadCrumb from "elements/System/BreadCrumb";
import UserProfile from "./UserProfile";
import { common } from "includes";

export default function MyProfile() {
  // const
  const authData = common.getAuth();

  // render
  return (
    <Fragment>
      <BreadCrumb
        breadCrumbItems={[
          { label: "Settings", path: "/settings/home" },
          { label: "Profile", path: "/user/myprofile", active: true },
        ]}
        title={"My Profile"}
      />
      {authData.id && <UserProfile
        userId={authData.id}
        view="page"
        module="MyProfile"
      />}
    </Fragment>
  );
}