import { Fragment } from "react";
import {
  Card,
  Col,
  Row,
  ListGroup as BootstrapListGroup,
  Badge,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// component
import BreadCrumb from "elements/System/BreadCrumb";

export default function SettingsHome() {
  const navigate = useNavigate();
  return (
    <Fragment>
      <BreadCrumb
        breadCrumbItems={[{ label: "Settings", path: "", active: true }]}
        title={"Settings"}
      />
      <Row>
        <Col xl={4}>
          <Card>
            <Card.Body>
              <h4 className="header-title">General</h4>
              <p className="text-muted fs-14">Manage profile, company etc</p>
              <BootstrapListGroup>
                <BootstrapListGroup.Item
                  onClick={() => navigate("/company/home")}
                  action
                >
                  Company Profile
                </BootstrapListGroup.Item>
                <BootstrapListGroup.Item
                  onClick={() => navigate("/user/list")}
                  action
                >
                  Manage Users
                </BootstrapListGroup.Item>
                <BootstrapListGroup.Item
                  onClick={() => navigate("/target/home")}
                  action>
                  Manage Targets
                </BootstrapListGroup.Item>
              </BootstrapListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4}>
          <Card>
            <Card.Body>
              <h4 className="header-title">Security</h4>

              <p className="text-muted fs-14">
                Manage Roles, Permissions etc...
              </p>

              <BootstrapListGroup>
                <BootstrapListGroup.Item
                  onClick={() => navigate("/roles/home")}
                  action
                >
                  Roles
                </BootstrapListGroup.Item>
                <BootstrapListGroup.Item
                  onClick={() => navigate("/permission/home")}
                  action
                >
                  Permissions
                </BootstrapListGroup.Item>
                <BootstrapListGroup.Item disabled={true} action>
                  Etc
                </BootstrapListGroup.Item>
              </BootstrapListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4}>
          <Card>
            <Card.Body>
              <h4 className="header-title">Customization</h4>
              <p className="text-muted fs-14">Forms, field settings etc...</p>
              <BootstrapListGroup>
                <BootstrapListGroup.Item
                  onClick={() => navigate("/module/list")}
                  action
                >
                  Modules
                </BootstrapListGroup.Item>
                <BootstrapListGroup.Item
                  onClick={() => navigate("/pipeline/home")}
                  action
                >
                  Pipelines
                </BootstrapListGroup.Item>
                <BootstrapListGroup.Item
                  onClick={() => navigate("/mapping/home")}
                  action
                >
                  Mappings
                </BootstrapListGroup.Item>
              </BootstrapListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xl={4}>
          <Card>
            <Card.Body>
              <h4 className="header-title">Automation</h4>
              <p className="text-muted fs-14">Automate your flows</p>
              <BootstrapListGroup>
                <BootstrapListGroup.Item
                  onClick={() => navigate("/automation/home")}
                  action
                >
                  Workflow Rules
                </BootstrapListGroup.Item>
                <BootstrapListGroup.Item action disabled={true}>
                  Etc
                </BootstrapListGroup.Item>
                <BootstrapListGroup.Item action disabled={true}>
                  Etc
                </BootstrapListGroup.Item>
              </BootstrapListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4}>
          <Card>
            <Card.Body>
              <h4 className="header-title">Services</h4>
              <p className="text-muted fs-14">
                Supported services for your business
              </p>
              <BootstrapListGroup>
                <BootstrapListGroup.Item
                  onClick={() => navigate("/services/email/list")}
                  action
                >
                  Email
                </BootstrapListGroup.Item>
                <BootstrapListGroup.Item
                  onClick={() => navigate("/services/sms/list")}
                  action
                >
                  SMS
                </BootstrapListGroup.Item>
                <BootstrapListGroup.Item
                  onClick={() => navigate("/services/whatsApp")}
                  action
                >
                  WhatsApp
                </BootstrapListGroup.Item>
              </BootstrapListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}
