// package
import { useEffect, useState, Fragment } from "react";
import {
  Col,
  Row,
  Button,
  Form,
  Accordion,
  Card,
  ListGroup,
  OverlayTrigger,
  Tooltip,
  Badge,
  Spinner,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useParams, Link, useNavigate } from "react-router-dom";
import BreadCrumb from "elements/System/BreadCrumb";
// component
import { api, common } from "includes";
import AgStatus from "elements/System/AgStatus";
import FormProperty from "elements/System/FormProperty";
import form_fields from "assets/files/form_fields.json";

export default function ModuleSettings() {
  // const
  const navigate = useNavigate();
  const { moduleId } = useParams();
  const formDefault = { sKey: null, fKey: null, type: null };
  // system
  const [mount, setMount] = useState<any>(false);
  const [status, setStatus] = useState<any>(100);
  const [isSubmit, setIsSubmit] = useState<any>(false);
  const [currentTab, setCurrentTab] = useState<any>("create");
  const [data, setData] = useState<any>([]);
  const [lookupData, setLookupData] = useState<any>([]);
  const [isDialog, setDialog] = useState<any>(null);
  const [isMode, setMode] = useState<any>(null);
  const [formData, setFormData] = useState<any>(formDefault);
  const [unUsed, setUnUsed] = useState<any>(null);
  const [deleteData, setDeleteData] = useState<any>([]);
  const [formFields] = useState<any>(form_fields);
  const [moduleName, setModuleName] = useState<string>("Form Settings");

  // effect
  useEffect(() => {
    getLookupData();
  }, []);

  // #Drag & drop#
  let isDrag: any = null;
  const [isDragMode, setDragMode] = useState<any>(null);
  const [dragItem, setDragItem] = useState<any>(null);
  const [dropItem, setDropItem] = useState<any>(null);
  const [dragSection, setDragSection] = useState<any>(null);
  const [dropSection, setDropSection] = useState<any>(null);

  // Drag Item
  const onDragStartItem = (e: any, data: any) => {
    if (isDrag == null) {
      isDrag = "item";
      let dragModeVal: any = "item";
      setDragMode(dragModeVal);
      setDragItem(data);
    }
  };

  const handleDragEndItem = () => {
    if (dropItem != null) {
      let sId = getSectionInfo(dropItem.sKey).id;
      let key = generateAttr(dragItem);
      if (key) {
        setData((oldData: any) => {
          let newData = reParse(oldData);
          let fields = getFieldList(dropItem.sKey, "et");
          let nonFields = getFieldList(dropItem.sKey, "net");
          fields.splice(dropItem.fKey, 0, generateField(sId, dragItem, key));
          newData.fields = [...new Set([...fields, ...nonFields])];
          return newData;
        });
        // set property
        setFormData({
          sKey: dropItem.sKey,
          fKey: dropItem.fKey,
          type: "property",
        });
        setMode("drag");
        setDialog("property");
      }
    }

    setTimeout(() => {
      isDrag = null;
      setDragItem(null);
      setDropItem(null);
      setDragMode(null);
    }, 10);
  };

  // Drag UnUsed
  const onDragStartUnUsed = (e: any, data: any) => {
    if (isDrag == null) {
      isDrag = "unUsed";
      setDragMode("unUsed");
      setDragItem(data);
    }
  };

  const handleDragEndUnUsed = () => {
    if (dropItem != null) {
      let sId = getSectionInfo(dropItem.sKey).id;
      let item = reParse(dragItem);
      item.layout = sId;
      item.status = "use";
      setData((oldData: any) => {
        let newData = reParse(oldData);
        let fields = getFieldList(dropItem.sKey, "et");
        let nonFields = getFieldList(dropItem.sKey, "net");
        nonFields = nonFields.filter((i: any) => i.key !== item.key);
        fields.splice(dropItem.fKey, 0, item);
        newData.fields = [...new Set([...fields, ...nonFields])];
        return newData;
      });
    }

    setTimeout(() => {
      isDrag = null;
      setDragItem(null);
      setDropItem(null);
      setDragMode(null);
    }, 10);
  };

  // Drag field
  const onDragStartField = (e: any, data: any) => {
    if (isDrag == null) {
      isDrag = "field";
      setDragMode("field");
      setDragItem(data);
    }
  };

  const onDragOverField = (item: any) => {
    if (dropItem === null) {
      setDropItem(item);
    }
  };

  const onDragLeaveField = () => {
    if (dropItem !== null) {
      setTimeout(() => {
        setDropItem(null);
      }, 1000);
    }
  };

  const handleDragEndField = () => {
    if (dropItem != null) {
      let fromSid = getSectionInfo(dragItem.sKey).id;
      let toSid = getSectionInfo(dropItem.sKey).id;

      setData((oldData: any) => {
        let newData = reParse(oldData);
        let fromFields = getFieldList(dragItem.sKey, "et");
        let toFields = getFieldList(dropItem.sKey, "et");
        let nonFields = getFieldList([fromSid, toSid], "notIn");

        if (dragItem.sKey === dropItem.sKey) {
          fromFields.splice(dragItem.fKey, 1);
          fromFields.splice(dropItem.fKey, 0, dragItem.field);
          newData.fields = [...new Set([...fromFields, ...nonFields])];
        } else {
          fromFields.splice(dragItem.fKey, 1);
          toFields.splice(dropItem.fKey, 0, {
            ...dragItem.field,
            layout: toSid,
          });
          newData.fields = [
            ...new Set([...fromFields, ...toFields, ...nonFields]),
          ];
        }
        return newData;
      });
    }
    setTimeout(() => {
      isDrag = null;
      setDragItem(null);
      setDropItem(null);
      setDragMode(null);
    }, 10);
  };

  // Drag Section
  const onDragStartSection = (e: any, data: any) => {
    if (isDrag == null) {
      isDrag = "section";
      setDragMode("section");
      setDragSection(data);
    }
  };

  const onDragOverSection = (item: any) => {
    setDropSection(item);
  };

  const onDragLeaveSection = () => {
    setTimeout(() => {
      setDropSection(null);
    }, 1000);
  };

  const handleDragEndSection = () => {
    if (dropSection != null) {
      if (dragSection == "new") {
        setData((oldData: any) => {
          let newData = reParse(oldData);
          newData.sections.splice(dropSection, 0, generateSection());
          return newData;
        });
      } else {
        setData((oldData: any) => {
          let newData = reParse(oldData);
          let section = newData.sections[dragSection];
          newData.sections.splice(dragSection, 1);
          newData.sections.splice(dropSection, 0, section);
          return newData;
        });
      }
    }
    setTimeout(() => {
      setDragMode(null);
    }, 10);
  };

  // style
  const fieldStyles = (item: any) => {
    if (
      isDragMode !== null &&
      isDragMode !== "section" &&
      dropItem !== null &&
      dropItem.sKey == item.sKey &&
      dropItem.fKey == item.fKey &&
      dropItem.type == item.type
    ) {
      return "dragStart itemDD";
    } else if (isDragMode !== null && isDragMode !== "section") {
      return "dragStart";
    } else {
      return "";
    }
  };

  const sectionStyles = (sKey: any) => {
    if (
      isDragMode == "section" &&
      dropSection !== null &&
      dropSection == sKey &&
      dragSection != sKey
    ) {
      return " dragStart sectionDD";
    } else if (isDragMode == "section") {
      return " dragStart";
    } else {
      return "";
    }
  };

  const getItemClass = (item: any) => {
    let result = "";
    if (item.required) {
      result += "fs-required ";
    }
    if (item.variable === "system") {
      result += "fs-system ";
    }
    return result;
  };

  // Section Activity
  const sectionSettings = (sKey: any) => {
    setFormData({ sKey, fKey: null, type: "section" });
    setDialog("section");
  };

  const sectionLayout = (layout: any) => {
    setData((data: any) => {
      let newData = reParse(data);
      newData.sections[formData.sKey].layout = layout;
      return newData;
    });
    closeDialog();
  };

  const sectionTitle = (sKey: any, value: any) => {
    setData((data: any) => {
      let newData = reParse(data);
      newData.sections[sKey].label = value;
      return newData;
    });
    closeDialog();
  };

  const sectionDelete = (type: any) => {
    let fields = getFieldList(formData.sKey, "et");
    let nonFields = getFieldList(formData.sKey, "net");
    if (type === "fully") {
      fields = [];
    } else if (type === "unUse") {
      fields.map((item: any) => {
        item.layout = "";
        item.status = "unUse";
      });
    } else if (type === "permanent") {
      fields = [];
      sectionPermantDelete();
    }
    setData((oldData: any) => {
      let newData = reParse(oldData);
      newData.sections.splice(formData.sKey, 1);
      newData.fields = [...new Set([...fields, ...nonFields])];
      return newData;
    });
    setDialog(null);
  };

  const sectionPermantDelete = () => {
    let sId = data.sections[formData.sKey].id;
    let fields = data.fields.filter(
      (item: any) =>
        item.layout === sId && item.variable !== "system" && !item.latest
    );
    setDeleteData((oldData: any) => {
      let newData = reParse(oldData);
      newData = [...new Set([...fields, ...newData])];
      return newData;
    });
  };

  // Field Activity
  const fieldAction = (sKey: any, fKey: any, type: any) => {
    if (type === "edit") {
      setFormData({ sKey, fKey, type: "property" });
      setMode("edit");
      setDialog("property");
    } else if (type === "delete") {
      setFormData({ sKey, fKey, type: "field" });
      setDialog("delete");
    }
  };

  const fieldDelete = (type: any) => {
    setData((oldData: any) => {
      let newData = reParse(oldData);
      let fields = getFieldList(formData.sKey, "et");
      let nonFields = getFieldList(formData.sKey, "net");
      let field = fields[formData.fKey];
      if (type === "permanent") {
        fields.splice(formData.fKey, 1);
        if (!field.latest) {
          setDeleteData((oldData: any) => {
            let newData = reParse(oldData);
            newData.push(field);
            return newData;
          });
        }
      } else if (type === "unUse") {
        field.layout = "";
        field.status = "unUse";
      }
      newData.fields = [...new Set([...fields, ...nonFields])];
      return newData;
    });
    setDialog(null);
  };

  // Unuse activity
  const unUseAction = (key: any, index: any) => {
    setUnUsed({ key, index });
    setDialog("unUseDelete");
  };

  const unUseDelete = () => {
    let fields = reParse(getUnusedList());
    let field = fields[unUsed.index];
    // removed from data
    setData((oldData: any) => {
      let newData = reParse(oldData);
      newData.fields = newData.fields.filter(
        (item: any) => item.key !== unUsed.key
      );
      return newData;
    });
    // move to delete
    if (!field.latest) {
      setDeleteData((oldData: any) => {
        let newData = reParse(oldData);
        newData.push(field);
        return newData;
      });
    }
    setDialog(null);
  };

  // #Api#
  const getLookupData = () => {
    // api call
    let data: any = {
      url: "/service/lookup/module",
      method: "GET",
    };
    api.call(data, (res: any) => {
      if (res.status === 200) {
        let result: any = res.data;
        setLookupData(result);
        getColumnsData();
      } else {
        setStatus(res.status);
      }
    });
  };

  const getColumnsData = () => {
    // api call
    let data: any = {
      url: `/module/read?type=sp&fields=id,name,data,key&query=id=${moduleId}&join=&limit=30&offset=0&order=sort ASC`,
      method: "GET",
      auth: "token",
    };
    api.call(data, (res: any) => {
      setStatus(res.status);
      if (res.status === 200) {
        let result: any = res.data;
        setData(result.data);
        setModuleName(result.name);
        setMount(true);
      }
    });
  };

  // #Submit Handler#
  const onSubmit = () => {
    setIsSubmit(true);
    let params = {
      data: getData(),
    };
    let data = {
      url: "/module/update/",
      query: moduleId,
      method: "PUT",
      body: params,
    };
    api.call(data, () => {
      setIsSubmit(false);
      common.notify("S", "Form updated successfully");
      window.scrollTo(0, 0);
    });
  };

  const onCancel = () => {
    navigate("/module/list");
  };

  // #Property Props#
  const propertyData = () => {
    const { sKey, fKey } = formData;
    return {
      sKey,
      fKey,
      section: getSectionInfo(sKey),
      field: getFieldInfo(sKey, fKey),
      item: getItemInfo(sKey, fKey),
      label: getLabelInfo(sKey, fKey),
      lookup: lookupData,
    };
  };

  const propertyComplete = (data: any) => {
    setData((oldData: any) => {
      let newData = reParse(oldData);
      let fields = getFieldList(formData.sKey, "et");
      let nonFields = getFieldList(formData.sKey, "net");
      fields[formData.fKey] = data.field;
      newData.fields = [...new Set([...fields, ...nonFields])];
      return newData;
    });
    setFormData(formDefault);
    setDialog(null);
  };

  // #Support#
  const generateSection = () => {
    return {
      id: common.randomString(),
      label: `Section ${data.sections.length + 1}`,
      layout: "double",
      fixed: false,
    };
  };

  const generateField = (sId: any, type: any, key: any) => {
    let item = formFields[type];
    let fields = data.fields.filter((item: any) => item.type === type);
    return {
      index: "",
      key: key,
      layout: sId,
      label: `${item.label} ${fields.length + 1}`,
      type: type,
      format: "numeric",
      variable: "custom",
      required: false,
      quick: false,
      automation: true,
      fieldUpdate: true,
      latest: true,
      status: "new",
      fixed: false,
      visibility: true,
      store: true,
      edit: true,
      delete: true,
      disabled: false,
      props: "",
      value: "",
      error: "",
      touched: false,
      render: false,
      attr: {
        option: ["Option 1"],
        optionDefault: "",
        maxChar: item.maxChar,
        defaultChecked: false,
        lookup: "",
        multi: false,
        entity: "",
        default: "",
        dependent: null,
        fill: null,
        data1: "",
      },
    };
  };

  const generateAttr = (type: any) => {
    let field_type: any = type + "_";
    let attrList: any = {
      text: 50 + 1,
      long_text: 25 + 1,
      number: 25 + 1,
      bool: 25 + 1,
      date: 15 + 1,
      date_time: 15 + 1,
      email: 10 + 1,
      phone: 10 + 1,
      url: 15 + 1,
      pick_list: 30 + 1,
      lookup: 15 + 1,
      file: 5 + 1,
    };
    let attrNo: any = [];
    data.fields.map((item: any) => {
      let attr = item.key.replace(/[0-9]/g, "");
      if (attr === field_type) {
        attrNo.push(Number(item.key.replace(/[^0-9]/g, "")));
      }
    });

    if (attrNo.length === 0 || !attrNo.includes(1)) {
      return field_type + 1;
    } else if (attrNo.length !== attrList[type]) {
      attrNo.push(attrList[type]);
    }
    let sNo =
      attrNo
        .sort((a: any, b: any) => a - b)
        .find((x: any, i: any) => attrNo[i + 1] - x > 1) + 1;
    if (isNaN(sNo)) {
      common.notify("W", `Allowed only ${attrList[type] - 1} fields`);
      return "";
    } else {
      return field_type + sNo;
    }
  };

  const getData = () => {
    let inc = 0;
    let newData = reParse(data);
    let newFields: any = [];
    // section fields
    newData.sections.map((sItem: any, sKey: any) => {
      let fields = getFieldList(sKey, "et");
      fields.map((fItem: any) => {
        fItem.index = inc;
        fItem.latest = false;
        if (fItem.status === "new") {
          fItem.status = "use";
        }
        newFields.push(fItem);
        inc++;
      });
    });
    // non section fields
    newData.fields
      .filter((i: any) => !i.layout)
      .map((fItem: any) => {
        fItem.index = inc;
        newFields.push(fItem);
        inc++;
      });
    newData.fields = newFields;
    return newData;
  };

  const getSectionInfo = (key: any) => {
    return data.sections[key];
  };

  const getFieldInfo = (sKey: any, fKey: any) => {
    let sId = data.sections[sKey].id;
    let fields = data.fields.filter((item: any) => item.layout === sId);
    return fields[fKey];
  };

  const getFieldList = (sKey: any, type: any) => {
    let nData = reParse(data);
    if (type == "notIn") {
      return nData.fields.filter((item: any) => !sKey.includes(item.layout));
    } else {
      let sId = data.sections[sKey].id;
      if (type == "et") {
        return nData.fields.filter((item: any) => item.layout === sId);
      } else if (type == "net") {
        return nData.fields.filter((item: any) => item.layout !== sId);
      }
    }
  };

  const getFormFieldInfo = () => {
    let sId = data.sections[formData.sKey].id;
    let fields = data.fields.filter((item: any) => item.layout === sId);
    return fields[formData.fKey];
  };

  const getFieldTypeList = (type: any, value: any) => {
    let sId = data.sections[formData.sKey].id;
    let fields = data.fields.filter(
      (item: any) => item.layout === sId && item[type] === value
    );
    return fields;
  };

  const getItemInfo = (sKey: any, fKey: any) => {
    let fields = getFieldInfo(sKey, fKey);
    return formFields[fields.type];
  };

  const getLabelInfo = (sKey: any, fKey: any) => {
    let field = getFieldInfo(sKey, fKey);
    return data.fields
      .filter((i: any) => i.key !== field.key)
      .map((i: any) => i.label.toUpperCase());
  };

  const getUnusedCount = () => {
    return data.fields.filter((item: any) => item.status == "unUse").length;
  };

  const getUnusedList = () => {
    return data.fields.filter((item: any) => item.status == "unUse");
  };

  const getLeftCount = () => {
    return 240 - Number(data.fields.length);
  };

  // support
  const closeDialog = () => {
    setFormData(formDefault);
    setDialog(null);
  };

  const reParse = (data: any) => {
    return JSON.parse(JSON.stringify(data));
  };

  const onAgCallBack = () => {
    getLookupData();
  };

  // render
  return (
    <div>
      <BreadCrumb
        breadCrumbItems={[
          { label: "Settings", path: "/settings/home" },
          { label: "Form Settings", path: "/Form Settings", active: true },
        ]}
        title={"Form Settings"}
      />
      {status == 100 ? (
        <AgStatus
          process={status}
          size="l"
          message={{ 100: "Loading form settings.Please wait..." }}
          img="modules.svg"
          onAgCallBack={onAgCallBack}
        />
      ) : (
        <div className="fs-wrap lead-wraper form-settings-content-wrap">
          <Row>
            <Col xl={12} lg={12}>
              <Card>
                <Card.Body className="py-1 px-2 border-bottom border-light">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="float-start">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="">Back</Tooltip>}
                      >
                        <Link
                          to="/module/list"
                          className="text-reset fs-20 p-1 d-inline-block"
                        >
                          <i
                            className="ri-arrow-left-s-line pe-2"
                            data-bs-container="#tooltips-container"
                            data-bs-toggle="tooltip"
                          />
                        </Link>
                      </OverlayTrigger>
                      <label className="form-check-label fs-16">
                        {moduleName}
                      </label>
                    </div>
                    <div className="d-flex align-items-center">
                      <Button
                        variant="light"
                        className="me-1"
                        onClick={() => onCancel()}
                        disabled={isSubmit}
                      >
                        <i className=" ri-arrow-go-back-line me-1" />
                        Cancel
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => onSubmit()}
                        disabled={isSubmit}
                      >
                        {isSubmit ? (
                          <>
                            <Spinner
                              className="spinner-border-sm me-1"
                              color="white"
                            />
                            Processing...
                          </>
                        ) : (
                          <>
                            <i className="ri-save-line me-1" />
                            Save
                          </>
                        )}
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <div className="fs-sidebar-wrap">
                <Card className="p-2">
                  <h4 className="header-title">Form Assets</h4>
                  <p className="text-muted">
                    click and Drag a form asset to add
                  </p>
                  <Accordion defaultActiveKey="0" flush>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header className="pb-0">
                        New Fields
                      </Accordion.Header>
                      <Accordion.Body>
                        <Row>
                          {Object.keys(formFields).map((item, key) => {
                            let itemData = formFields[item];
                            if (itemData.show) {
                              return (
                                <Col
                                  md={6}
                                  key={key}
                                  draggable
                                  onDragStart={(e) => onDragStartItem(e, item)}
                                  onDragEnd={() => handleDragEndItem()}
                                  className={`${itemData.size == "small"
                                    ? "col-md-6"
                                    : "col-md-12"
                                    }`}
                                >
                                  {/* <div className={`field-box ${itemData.icon}`}>
                                    <span>{`${itemData.label}`}</span>
                                  </div> */}
                                  <div className="draggable-formfield-wrap">
                                    <i className={itemData.icon} />
                                    <span>{`${itemData.label}`}</span>
                                  </div>
                                </Col>
                              );
                            }
                          })}
                          <Col md={12}>
                            <div
                              className="draggable-formfield-wrap"
                              draggable
                              onDragStart={(e) => onDragStartSection(e, "new")}
                              onDragEnd={() => handleDragEndSection()}
                            >
                              <i className="ri-layout-top-line" />
                              <span>New Section</span>
                            </div>
                          </Col>
                        </Row>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="mb-0">
                            Unused Items{" "}
                            <Badge bg="danger">{getUnusedCount()}</Badge>
                          </p>
                          {/* <span className="unused-count">
                            {getUnusedCount()}
                          </span> */}
                        </div>
                      </Accordion.Header>
                      {/* <Accordion.Collapse eventKey="1"> */}
                      <Accordion.Body>
                        {getUnusedCount() > 0 ? (
                          <Row>
                            {getUnusedList().map((item: any, key: any) => (
                              <Col
                                md={12}
                                key={key}
                                draggable
                                onDragStart={(e) => onDragStartUnUsed(e, item)}
                                onDragEnd={() => handleDragEndUnUsed()}
                              // className="col-md-12 mb-3"
                              >
                                <div className="draggable-formfield-wrap fs-unsed-field">
                                  <div className="d-flex align-items-cemter">
                                    <i className="ri-mail-line me-2"></i>
                                    <span>{item.label}</span>
                                  </div>
                                  <div>
                                    {item.variable === "system" ? (
                                      // <DeleteIcon
                                      //   fontSize="small"
                                      //   color="disabled"
                                      //   className="fs-disable-icon"
                                      // />
                                      <i className="ri-delete-bin-6-line disabled"></i>
                                    ) : (
                                      // <DeleteIcon
                                      //   fontSize="small"
                                      //   color="action"
                                      //   className="fs-cp fs-active-icon"
                                      //   onClick={(e) =>
                                      //     unUseAction(item.key, key)
                                      //   }
                                      // />
                                      <i
                                        className="ri-delete-bin-6-line crm-cp"
                                        onClick={(e) =>
                                          unUseAction(item.key, key)
                                        }
                                      ></i>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            ))}
                          </Row>
                        ) : (
                          <div className="unused-items-wrap">
                            <p className="mb-0">All fields are in use.</p>
                          </div>
                        )}
                      </Accordion.Body>
                      {/* </Accordion.Collapse> */}
                    </Accordion.Item>
                  </Accordion>
                </Card>
                <div className="field-left-count mb-3">
                  Custom Fields Left: {getLeftCount()}
                </div>
              </div>
            </Col>
            <Col sm={8}>
              <Card className="fs-card-right p-3">
                <div className="formTabSection position-r">
                  {/* <Tabs defaultActiveKey={currentTab ? currentTab : "general"}>
                    <Tab eventKey="create" title="Create"> */}
                  {data.sections.map((sItem: any, sKey: any) => {
                    let fields = getFieldList(sKey, "et");
                    let dropData = {
                      type: "drop",
                      sKey,
                      fKey: fields.length,
                    };
                    return (
                      <div
                        key={sKey}
                        draggable={!sItem.fixed}
                        onDragStart={(e) => onDragStartSection(e, sKey)}
                        onDragOver={
                          isDragMode == "section" && !sItem.fixed
                            ? (e) => {
                              onDragOverSection(sKey);
                            }
                            : (e) => { }
                        }
                        onDragLeave={
                          isDragMode == "section" && !sItem.fixed
                            ? (e) => {
                              onDragLeaveSection();
                            }
                            : (e) => { }
                        }
                        onDragEnd={
                          !sItem.fixed
                            ? () => handleDragEndSection()
                            : (e) => { }
                        }
                        className={`fs-form${sItem.fixed ? "-fixed" : ""
                          }-wrap mb-3${sectionStyles(sKey)}`}
                      >
                        <div className="fs-title-wrap">
                          {/* <Input
                            placeholder="Section Title"
                            value={sItem.label}
                            onChange={(e) => sectionTitle(sKey, e.target.value)}
                            disabled={sItem.fixed}
                          /> */}
                          <Form.Control
                            type="text"
                            placeholder="Section Title"
                            className="fs-title-txt"
                            value={sItem.label}
                            onChange={(e) => sectionTitle(sKey, e.target.value)}
                            disabled={sItem.fixed}
                          />
                          {!sItem.fixed && (
                            <div
                              className="fs-title-icon"
                              onClick={(e) => sectionSettings(sKey)}
                            >
                              {/* <img
                                src={common.loadImg(
                                  "form-settings/setting.png"
                                )}
                                alt=""
                              /> */}
                              <i className="ri-settings-5-line fs-22 me-2 crm-cp" />
                            </div>
                          )}
                        </div>
                        <Row>
                          {fields.map((fItem: any, fKey: any) => {
                            let dragData = {
                              type: "inner",
                              sKey,
                              fKey,
                              field: fItem,
                            };
                            return (
                              <Col
                                key={fKey}
                                draggable={!sItem.fixed}
                                onDragStart={(e) =>
                                  onDragStartField(e, dragData)
                                }
                                onDragOver={
                                  !sItem.fixed &&
                                    isDragMode !== null &&
                                    isDragMode !== "section"
                                    ? (e) => {
                                      onDragOverField(dragData);
                                    }
                                    : (e) => { }
                                }
                                onDragLeave={
                                  !sItem.fixed &&
                                    isDragMode !== null &&
                                    isDragMode !== "section"
                                    ? (e) => {
                                      onDragLeaveField();
                                    }
                                    : (e) => { }
                                }
                                onDragEnd={
                                  !sItem.fixed
                                    ? () => handleDragEndField()
                                    : (e) => { }
                                }
                                className={`${fieldStyles(dragData)}`}
                                sm={sItem.layout == "single" ? 12 : 6}
                              >
                                {/* <div
                                  className={`${getItemClass(
                                    fItem
                                  )}dragged-formfield-wrap`}
                                >
                                  <label className="fs-field-name">
                                    {fItem.label}
                                  </label>
                                  <span className="fs-field-type">{`${
                                    formFields[fItem.type]?.label
                                  }`}</span>
                                  {!fItem.fixed && (
                                    <div>
                                      {fItem.edit && (
                                        // <EditIcon
                                        //   fontSize="small"
                                        //   className={`fs-cp fs-active-icon`}
                                        //   onClick={(e) =>
                                        //     fieldAction(sKey, fKey, "edit")
                                        //   }
                                        // />
                                        <i
                                          className="ri-edit-line fs-16 crm-cp me-2"
                                          onClick={(e) =>
                                            fieldAction(sKey, fKey, "edit")
                                          }
                                        ></i>
                                      )}
                                      {fItem.delete && !fItem.required && (
                                        // <DeleteIcon
                                        //   fontSize="small"
                                        //   className={`fs-cp fs-active-icon`}
                                        //   onClick={(e) =>
                                        //     fieldAction(
                                        //       sKey,
                                        //       fKey,
                                        //       "delete"
                                        //     )
                                        //   }
                                        // />
                                        <i
                                          className="ri-delete-bin-6-line fs-16 crm-cp"
                                          onClick={(e) =>
                                            fieldAction(sKey, fKey, "delete")
                                          }
                                        ></i>
                                      )}
                                    </div>
                                  )}
                                </div> */}

                                <div
                                  className={`${getItemClass(
                                    fItem
                                  )}dragged-formfield-wrap`}
                                >
                                  <div className="w-50">
                                    <label className="fs-field-name">
                                      {fItem.label}
                                    </label>
                                  </div>
                                  {!fItem.fixed ? (
                                    <div className="d-flex align-items-center justify-content-between w-50">
                                      <div>
                                        <span className="fs-field-type">{`${formFields[fItem.type]?.label
                                          }`}</span>
                                      </div>
                                      <div>
                                        {fItem.edit && (
                                          <i
                                            className="ri-edit-line fs-16 crm-cp"
                                            onClick={(e) =>
                                              fieldAction(sKey, fKey, "edit")
                                            }
                                          ></i>
                                        )}
                                        {fItem.delete && !fItem.required && (
                                          <i
                                            className="ri-delete-bin-6-line fs-16 crm-cp ms-2"
                                            onClick={(e) =>
                                              fieldAction(sKey, fKey, "delete")
                                            }
                                          ></i>
                                        )}
                                      </div>
                                    </div>
                                  ) : (
                                    <>
                                      <div className="w-50">
                                        <span className="fs-field-type">{`${formFields[fItem.type]?.label
                                          }`}</span>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </Col>
                            );
                          })}
                          {!sItem.fixed && (
                            <Col
                              sm={
                                sItem.layout == "single" || fields.length === 0
                                  ? 12
                                  : 6
                              }
                              onDragOver={
                                isDragMode !== null && isDragMode !== "section"
                                  ? (e) => {
                                    onDragOverField(dropData);
                                  }
                                  : (e) => { }
                              }
                              onDragLeave={
                                isDragMode !== null && isDragMode !== "section"
                                  ? (e) => {
                                    onDragLeaveField();
                                  }
                                  : (e) => { }
                              }
                              className={`${fieldStyles(dropData)}`}
                            >
                              <div className="dragged-formfield-wrap add-dragged-field-wrap">
                                <i className="me-1 ri-add-line" />
                                <p className="text-muted mb-0">
                                  Drop Field here
                                </p>
                              </div>
                            </Col>
                          )}
                        </Row>
                      </div>
                    );
                  })}

                  <div
                    onDragOver={
                      isDragMode == "section"
                        ? (e) => {
                          onDragOverSection(data.sections.length);
                        }
                        : (e) => { }
                    }
                    onDragLeave={
                      isDragMode == "section"
                        ? (e) => {
                          onDragLeaveSection();
                        }
                        : (e) => { }
                    }
                    className={`fs-drop-section mb-3${sectionStyles(
                      data.sections.length
                    )}`}
                  >
                    {/* <div className="fs-title-wrap d-flex justify-content-center align-items-center">
                      <p className="sectionTitle">Drop section here</p>
                    </div> */}
                    <div className="dragged-formfield-wrap add-dragged-field-wrap drop-section">
                      <i className="me-1 ri-add-line" />
                      <p className="text-muted mb-0">Drop Section here</p>
                    </div>
                  </div>
                  {/* </Tab>
                  </Tabs> */}
                </div>
              </Card>
            </Col>
          </Row>
          {/* <div className="fs-btns-wrap">
            <Row className="botBtn">
              <Col sm={12}>
                <Button
                  className="saveBtn"
                  type="submit"
                  onClick={() => onSubmit()}
                  disabled={isSubmit}
                >
                  {isSubmit ? "Processing..." : "Update"}
                </Button>
                <Button
                  className="cancelBtn"
                  type="button"
                  onClick={() => onCancel()}
                  disabled={isSubmit}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </div> */}
        </div>
      )}
      {/* Dialog */}
      {/* Property Dialog */}
      {isDialog == "property" && (
        <FormProperty
          isMode={isMode}
          data={propertyData()}
          closeDialog={() => setDialog(null)}
          onComplete={propertyComplete}
          onDontSave={() => fieldDelete("permanent")}
        />
      )}
      {/* Section Dialog */}
      {isDialog == "section" && (
        <Modal show={true} onHide={closeDialog}>
          <Modal.Header closeButton>
            <Modal.Title>Section Layout</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {formData.type == "section" &&
              (() => {
                let sData = data.sections[formData.sKey];
                return (
                  <>
                    <ListGroup>
                      <ListGroup.Item
                        action
                        active={sData.layout == "single" ? true : false}
                        onClick={() => sectionLayout("single")}
                      >
                        Single Column
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        active={sData.layout == "double" ? true : false}
                        onClick={() => sectionLayout("double")}
                      >
                        Double Column
                      </ListGroup.Item>
                      <ListGroup.Item
                        action
                        onClick={() => setDialog("sectionDelete")}
                      >
                        Delete Section
                      </ListGroup.Item>
                    </ListGroup>
                  </>
                );
              })()}
          </Modal.Body>
        </Modal>
        // <Dialog
        //   open={true}
        //   onClose={() => closeDialog()}
        //   aria-labelledby="alert-dialog-title"
        //   aria-describedby="alert-dialog-description"
        // >
        //   {formData.type == "section" &&
        //     (() => {
        //       let sData = data.sections[formData.sKey];
        //       return (
        //         <Fragment>
        //           <List component="nav" aria-label="mailbox folders">
        //             <ListSubheader>Section Layout</ListSubheader>
        //             <ListItem
        //               button
        //               selected={sData.layout == "single" ? true : false}
        //               onClick={() => sectionLayout("single")}
        //             >
        //               <ListItemText primary="Single Column" />
        //             </ListItem>
        //             <ListItem
        //               button
        //               selected={sData.layout == "double" ? true : false}
        //               onClick={() => sectionLayout("double")}
        //             >
        //               <ListItemText primary="Double Column" />
        //             </ListItem>
        //             {/* <Divider /> */}
        //             <Dropdown.Divider />
        //             <ListSubheader>Delete</ListSubheader>
        //             <ListItem button onClick={() => setDialog("sectionDelete")}>
        //               <ListItemText primary="Delete Section" />
        //             </ListItem>
        //           </List>
        //         </Fragment>
        //       );
        //     })()}
        // </Dialog>
      )}
      {/* Section Delete */}
      {isDialog == "sectionDelete" &&
        (() => {
          let systemFields = getFieldTypeList("variable", "system");
          let requiredFields = getFieldTypeList("required", true);
          let useFields = getFieldTypeList("status", "use");
          let newFields = getFieldTypeList("status", "new");
          return (
            <Modal show={true} onHide={closeDialog}>
              <Modal.Header closeButton>
                <Modal.Title>Section Delete</Modal.Title>
              </Modal.Header>
              <Modal.Body className="text-center">
                <div>
                  {systemFields.length > 0 ? (
                    <p className="fs-14 text-danger">
                      Below systems field are currently in use so you cannot
                      delete this section.
                    </p>
                  ) : requiredFields.length > 0 ? (
                    <p className="fs-14 text-danger">
                      Below field are mandatory one so you cannot delete this
                      section.
                    </p>
                  ) : useFields.length > 0 ? (
                    <p className="fs-14 text-danger">
                      Below fields are already in use are you sure you want to
                      do this action?
                    </p>
                  ) : (
                    <p className="fs-14 text-danger">
                      Are you sure you want to proceed?
                    </p>
                  )}
                  {systemFields.length > 0 ? (
                    <div className="mb-4">
                      <h5>System Fields</h5>
                      {/* <List component="nav" aria-label="mailbox folders">
                        {systemFields.map((item: any, key: any) => (
                          <Fragment key={key}>
                            <ListItem button>
                              <ListItemText primary={item.label} />
                            </ListItem>
                          </Fragment>
                        ))}
                      </List> */}
                      <ListGroup>
                        {systemFields.map((item: any, key: any) => (
                          <ListGroup.Item key={key} action>{item.label}</ListGroup.Item>
                        ))}
                      </ListGroup>
                    </div>
                  ) : requiredFields.length > 0 ? (
                    <div className="mb-4 text-start">
                      <h5>Required Fields</h5>
                      {/* <List component="nav" aria-label="mailbox folders">
                        {requiredFields.map((item: any, key: any) => (
                          <Fragment key={key}>
                            <ListItem button>
                              <ListItemText primary={item.label} />
                            </ListItem>
                          </Fragment>
                        ))}
                      </List> */}
                      <ListGroup>
                        {requiredFields.map((item: any, key: any) => (
                          <ListGroup.Item key={key} action>{item.label}</ListGroup.Item>
                        ))}
                      </ListGroup>
                    </div>
                  ) : (
                    useFields.length > 0 && (
                      <div className="mb-4">
                        <p className="text-muted fs-16">
                          Fields already in use
                        </p>
                        {/* <List component="nav" aria-label="mailbox folders">
                          {useFields.map((item: any, key: any) => (
                            <Fragment key={key}>
                              <ListItem button>
                                <ListItemText primary={item.label} />
                              </ListItem>
                            </Fragment>
                          ))}
                        </List> */}
                        {useFields.map((item: any, key: any) => (
                          <ListGroup key={key}>
                            <ListGroup.Item action>{item.label}</ListGroup.Item>
                          </ListGroup>
                        ))}
                      </div>
                    )
                  )}

                  {systemFields.length == 0 &&
                    requiredFields.length == 0 &&
                    newFields.length > 0 && (
                      <div className="mb-4">
                        <p className="text-muted fs-16">New fields</p>
                        {/* <List component="nav" aria-label="mailbox folders">
                          {newFields.map((item: any, key: any) => (
                            <Fragment key={key}>
                              <ListItem button>
                                <ListItemText primary={item.label} />
                              </ListItem>
                            </Fragment>
                          ))}
                        </List> */}
                        {newFields.map((item: any, key: any) => (
                          <ListGroup key={key}>
                            <ListGroup.Item action>{item.label}</ListGroup.Item>
                          </ListGroup>
                        ))}
                      </div>
                    )}
                </div>
                {/* <DialogActions className="fs-dialog-margin">
                  {systemFields.length > 0 || requiredFields.length > 0 ? (
                    <Button
                      variant="primary"
                      onClick={() => closeDialog()}
                    >
                      Got it!
                    </Button>
                  ) : useFields.length > 0 || newFields.length > 0 ? (
                    <Fragment>
                      <Button
                        variant="danger"
                        onClick={() => sectionDelete("permanent")}
                      >
                        Delete Permanently
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => sectionDelete("unUse")}
                      >
                        Move to Unused Fields
                      </Button>
                      <Button
                        variant="light"
                        onClick={() => closeDialog()}
                      >
                        Cancel
                      </Button>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <Button
                        variant="light"
                        onClick={() => closeDialog()}
                      >
                        No
                      </Button>
                      <Button
                        variant="primary"
                        onClick={() => sectionDelete("fully")}
                      >
                        Yes
                      </Button>
                    </Fragment>
                  )}
                </DialogActions> */}
              </Modal.Body>
              <Modal.Footer>
                {systemFields.length > 0 || requiredFields.length > 0 ? (
                  <Button variant="primary" onClick={() => closeDialog()}>
                    Got it!
                  </Button>
                ) : useFields.length > 0 || newFields.length > 0 ? (
                  <Fragment>
                    <Button
                      variant="danger"
                      onClick={() => sectionDelete("permanent")}
                    >
                      Delete Permanently
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => sectionDelete("unUse")}
                    >
                      Move to Unused Fields
                    </Button>
                    <Button variant="light" onClick={() => closeDialog()}>
                      Cancel
                    </Button>
                  </Fragment>
                ) : (
                  <Fragment>
                    <Button variant="light" onClick={() => closeDialog()}>
                      No
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => sectionDelete("fully")}
                    >
                      Yes
                    </Button>
                  </Fragment>
                )}
              </Modal.Footer>
            </Modal>

            // <Dialog
            //   open={true}
            //   onClose={() => closeDialog()}
            //   aria-labelledby="alert-dialog-title"
            //   aria-describedby="alert-dialog-description"
            // >
            //   <DialogTitle id="alert-dialog-title">
            //     {"Section Delete"}
            //   </DialogTitle>
            //   <DialogContent>
            //     {systemFields.length > 0 ? (
            //       <DialogContentText
            //         id="alert-dialog-description"
            //         className="mb-4"
            //       >
            //         Below systems field are currently in use so you cannot
            //         delete this section.
            //       </DialogContentText>
            //     ) : requiredFields.length > 0 ? (
            //       <DialogContentText
            //         id="alert-dialog-description"
            //         className="mb-4"
            //       >
            //         Below field are mandatory one so you cannot delete this
            //         section.
            //       </DialogContentText>
            //     ) : useFields.length > 0 ? (
            //       <DialogContentText
            //         id="alert-dialog-description"
            //         className="mb-4"
            //       >
            //         Below fields are already in use are you sure you want to do
            //         this action?
            //       </DialogContentText>
            //     ) : (
            //       <DialogContentText
            //         id="alert-dialog-description"
            //         className="mb-4"
            //       >
            //         Are you sure you want to proceed?
            //       </DialogContentText>
            //     )}
            //     {systemFields.length > 0 ? (
            //       <div className="mb-4">
            //         <p className="fs-dialog-title">System Fields</p>
            //         <List component="nav" aria-label="mailbox folders">
            //           {systemFields.map((item: any, key: any) => (
            //             <Fragment key={key}>
            //               <ListItem button>
            //                 <ListItemText primary={item.label} />
            //               </ListItem>
            //               {/* <Divider /> */}
            //               <Dropdown.Divider />
            //             </Fragment>
            //           ))}
            //         </List>
            //       </div>
            //     ) : requiredFields.length > 0 ? (
            //       <div className="mb-4">
            //         <p className="fs-dialog-title">Required Fields</p>
            //         <List component="nav" aria-label="mailbox folders">
            //           {requiredFields.map((item: any, key: any) => (
            //             <Fragment key={key}>
            //               <ListItem button>
            //                 <ListItemText primary={item.label} />
            //               </ListItem>
            //               {/* <Divider /> */}
            //               <Dropdown.Divider />
            //             </Fragment>
            //           ))}
            //         </List>
            //       </div>
            //     ) : (
            //       useFields.length > 0 && (
            //         <div className="mb-4">
            //           <p className="fs-dialog-title">Fields already in use</p>
            //           <List component="nav" aria-label="mailbox folders">
            //             {useFields.map((item: any, key: any) => (
            //               <Fragment key={key}>
            //                 <ListItem button>
            //                   <ListItemText primary={item.label} />
            //                 </ListItem>
            //                 {/* <Divider /> */}
            //                 <Dropdown.Divider />
            //               </Fragment>
            //             ))}
            //           </List>
            //         </div>
            //       )
            //     )}

            //     {systemFields.length == 0 &&
            //       requiredFields.length == 0 &&
            //       newFields.length > 0 && (
            //         <div className="mb-4">
            //           <p className="fs-dialog-title">New fields</p>
            //           <List component="nav" aria-label="mailbox folders">
            //             {newFields.map((item: any, key: any) => (
            //               <Fragment key={key}>
            //                 <ListItem button>
            //                   <ListItemText primary={item.label} />
            //                 </ListItem>
            //                 {/* <Divider /> */}
            //                 <Dropdown.Divider />
            //               </Fragment>
            //             ))}
            //           </List>
            //         </div>
            //       )}
            //   </DialogContent>
            //   <DialogActions className="fs-dialog-margin">
            //     {systemFields.length > 0 || requiredFields.length > 0 ? (
            //       // <MuiButton
            //       //   variant="outlined"
            //       //   color="primary"
            //       //   size="small"
            //       //   onClick={() => closeDialog()}
            //       // >
            //       //   Got it!
            //       // </MuiButton>
            //       <Button
            //         variant="outlined"
            //         color="primary"
            //         size="sm"
            //         onClick={() => closeDialog()}
            //       >
            //         Got it!
            //       </Button>
            //     ) : useFields.length > 0 || newFields.length > 0 ? (
            //       <Fragment>
            //         {/* <MuiButton
            //           variant="outlined"
            //           color="secondary"
            //           size="small"
            //           onClick={() => sectionDelete("permanent")}
            //         >
            //           Delete Permanently
            //         </MuiButton> */}
            //         <Button
            //           variant="outlined"
            //           color="secondary"
            //           size="sm"
            //           onClick={() => sectionDelete("permanent")}
            //         >
            //           Delete Permanently
            //         </Button>
            //         {/* <MuiButton
            //           variant="outlined"
            //           color="primary"
            //           size="small"
            //           onClick={() => sectionDelete("unUse")}
            //         >
            //           Move to Unused Fields
            //         </MuiButton> */}
            //         <Button
            //           variant="outlined"
            //           color="primary"
            //           size="sm"
            //           onClick={() => sectionDelete("unUse")}
            //         >
            //           Move to Unused Fields
            //         </Button>
            //         {/* <MuiButton
            //           variant="outlined"
            //           size="small"
            //           onClick={() => closeDialog()}
            //         >
            //           Cancel
            //         </MuiButton> */}
            //         <Button
            //           variant="outlined"
            //           size="sm"
            //           onClick={() => closeDialog()}
            //         >
            //           Cancel
            //         </Button>
            //       </Fragment>
            //     ) : (
            //       <Fragment>
            //         {/* <MuiButton
            //           variant="outlined"
            //           color="secondary"
            //           size="small"
            //           onClick={() => closeDialog()}
            //         >
            //           No
            //         </MuiButton>
            //         <MuiButton
            //           variant="outlined"
            //           color="primary"
            //           size="small"
            //           onClick={() => sectionDelete("fully")}
            //         >
            //           Yes
            //         </MuiButton> */}
            //         <Button
            //           variant="outlined"
            //           color="secondary"
            //           size="sm"
            //           onClick={() => closeDialog()}
            //         >
            //           No
            //         </Button>
            //         <Button
            //           variant="outlined"
            //           color="primary"
            //           size="sm"
            //           onClick={() => sectionDelete("fully")}
            //         >
            //           Yes
            //         </Button>
            //       </Fragment>
            //     )}
            //   </DialogActions>
            // </Dialog>
          );
        })()}

      {/* Delete Field Dialog */}
      {isDialog == "delete" && (
        <Modal show={true} onHide={closeDialog}>
          <Modal.Header closeButton>
            <Modal.Title>Delete Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <img src={common.loadImg("svg/delete-confirm.svg")} style={{ width: 120, height: 120 }} alt="logo" />
            <p className="text-muted fs-16 mt-2">
              Are you sure you want to do this action?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={() => fieldDelete("permanent")}
              disabled={getFormFieldInfo().variable === "system"}
            >
              Delete Permanently
            </Button>
            <Button variant="primary" onClick={() => fieldDelete("unUse")}>
              Move to Unused Fields
            </Button>
            <Button variant="light" onClick={() => closeDialog()}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        // <Dialog
        //   open={true}
        //   onClose={() => closeDialog()}
        //   aria-labelledby="alert-dialog-title"
        //   aria-describedby="alert-dialog-description"
        // >
        //   <DialogTitle id="alert-dialog-title">
        //     {"Delete Confirmation"}
        //   </DialogTitle>
        //   <DialogContent>
        //     <DialogContentText id="alert-dialog-description">
        //       Are you sure you want to do this action?
        //     </DialogContentText>
        //   </DialogContent>
        //   <DialogActions className="fs-dialog-margin">
        //     <MuiButton
        //       variant="outlined"
        //       color="secondary"
        //       size="small"
        //       onClick={() => fieldDelete("permanent")}
        //       disabled={getFormFieldInfo().variable === "system"}
        //     >
        //       Delete Permanently
        //     </MuiButton>
        //     <MuiButton
        //       variant="outlined"
        //       color="primary"
        //       size="small"
        //       onClick={() => fieldDelete("unUse")}
        //     >
        //       Move to Unused Fields
        //     </MuiButton>
        //     <MuiButton
        //       variant="outlined"
        //       size="small"
        //       onClick={() => closeDialog()}
        //     >
        //       Cancel
        //     </MuiButton>
        //   </DialogActions>
        // </Dialog>
      )}

      {/* Delete Field Dialog */}
      {isDialog == "unUseDelete" && (
        <Modal show={true} onHide={closeDialog}>
          <Modal.Header closeButton>
            <Modal.Title>Permanent Delete Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body className="text-center">
            <img src={common.loadImg("svg/delete-confirm.svg")} style={{ width: 120, height: 120 }} alt="logo" />
            <p className="text-muted fs-16 mt-2">
              Are you sure you want to permanently delete this field?
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => unUseDelete()}>
              Yes
            </Button>
            <Button variant="light" onClick={() => closeDialog()}>
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>
        // <Dialog
        //   open={true}
        //   onClose={() => closeDialog()}
        //   aria-labelledby="alert-dialog-title"
        //   aria-describedby="alert-dialog-description"
        // >
        //   <DialogTitle id="alert-dialog-title">
        //     {"Permanent Delete Confirmation"}
        //   </DialogTitle>
        //   <DialogContent>
        //     <DialogContentText id="alert-dialog-description">
        //       Are you sure you want to permanently delete this field?
        //     </DialogContentText>
        //   </DialogContent>
        //   <DialogActions className="fs-dialog-margin">
        //     <MuiButton
        //       variant="outlined"
        //       color="secondary"
        //       size="small"
        //       onClick={() => unUseDelete()}
        //     >
        //       Yes
        //     </MuiButton>
        //     <MuiButton
        //       variant="outlined"
        //       size="small"
        //       onClick={() => closeDialog()}
        //     >
        //       Cancel
        //     </MuiButton>
        //   </DialogActions>
        // </Dialog>
      )}
    </div>
  );
}
