import { Fragment, useState } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  Spinner,
  Image,
  OverlayTrigger,
  Tooltip,
  Form,
} from "react-bootstrap";
import Lightbox from "react-image-lightbox";
import BreadCrumb from "elements/System/BreadCrumb";
import { common } from "includes";

const galleryImages = [
  "https://frontend-collective.github.io/react-image-lightbox/1.0d2c9d24.jpg",
  "https://frontend-collective.github.io/react-image-lightbox/2.a8dae120.jpg",
  "https://frontend-collective.github.io/react-image-lightbox/3.07f63430.jpg",
  "https://frontend-collective.github.io/react-image-lightbox/4.f0b0636a.jpg",
];

export default function ImageGallery() {
  const [galleryView, SetGalleryView] = useState(false);
  const [galleryIndex, SetGalleryIndex] = useState(0);
  return (
    <Fragment>
      <BreadCrumb
        breadCrumbItems={[{ label: "Components", path: "", active: true }]}
        title={"All Components"}
      />
      <Row>
        <Col xl={6} lg={6}>
          <Card>
            <Card.Body>
              <h4 className="header-title">Loader</h4>
              <div className="text-center">
                <Spinner className="spinner-grow text-primary m-1" size="sm" />
                <Spinner className="spinner-grow text-warning m-1" size="sm" />
                <Spinner className="spinner-grow text-success m-1" size="sm" />
                <h4 className="header-title text-muted">Loading...</h4>
              </div>

              <div className="text-center">
                <Spinner className="spinner-grow text-primary m-1" size="sm" />
                <h4 className="header-title text-muted">Loading...</h4>
              </div>

              <h4 className="header-title">No Record Found</h4>
              <div className="text-center">
                <i className="ri-database-2-line text-warning fs-48"></i>
                <h4 className="header-title text-muted">No data Found !</h4>
              </div>

              <h4 className="header-title">Error</h4>
              <div className="text-center">
                <i className="ri-error-warning-line text-danger fs-48"></i>
                <h4 className="header-title text-muted">
                  Something went wrong!
                </h4>
                <Button variant="primary" size="sm">
                  <i className="ri-restart-line me-1" /> Try again
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>

        <Col xl={6} lg={6}>
          <Card>
            <Card.Body>
              <h4 className="header-title">Image Gallery</h4>
              <Button
                variant="primary"
                size="sm"
                onClick={() => SetGalleryView(true)}
              >
                Open Gallery
              </Button>
              {galleryView && (
                <Lightbox
                  mainSrc={galleryImages[galleryIndex]}
                  nextSrc={
                    galleryImages[(galleryIndex + 1) % galleryImages.length]
                  }
                  prevSrc={
                    galleryImages[
                      (galleryIndex + galleryImages.length - 1) %
                        galleryImages.length
                    ]
                  }
                  onCloseRequest={() => SetGalleryView(false)}
                  onMovePrevRequest={() =>
                    SetGalleryIndex(
                      (galleryIndex + galleryImages.length - 1) %
                        galleryImages.length
                    )
                  }
                  onMoveNextRequest={() =>
                    SetGalleryIndex((galleryIndex + 1) % galleryImages.length)
                  }
                />
              )}
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <div className="target-user-wrap">
                <Row>
                  <Col md={6}>
                    <div className="d-flex align-items-start py-2 px-3">
                      <div className="avatar-group-item">
                        <div className="avatar-xs me-2">
                          <div className="avatar-title rounded-circle text-bg-info">
                            V
                          </div>
                        </div>
                      </div>
                      <div className="w-100">
                        <h5 className="my-0">
                          Vimalaystems{" "}
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="">Super Admin</Tooltip>}
                          >
                            <Image
                              src={common.loadImg("svg/verified-badge.svg")}
                              alt="verified"
                              height="18"
                            />
                          </OverlayTrigger>
                        </h5>
                        <p className="mt-1 mb-0 text-muted">
                          <span className="w-75">
                            vimaladevi@atomgroups.com
                          </span>
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="w-75">
                        <Form.Group className="mb-3">
                          <Form.Label>Enter Value</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Target"
                          />
                        </Form.Group>
                      </div>
                      <div className="me-2">
                        <Button variant="light" size="sm">
                          <i className="ri-delete-bin-line fs-16" />
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="d-flex align-items-start py-2 px-3">
                      <div className="avatar-group-item">
                        <div className="avatar-xs me-2">
                          <div className="avatar-title rounded-circle text-bg-info">
                            D
                          </div>
                        </div>
                      </div>
                      <div className="w-100">
                        <h5 className="my-0">
                          Dharmaraj
                        </h5>
                        <p className="mt-1 mb-0 text-muted">
                          <span className="w-75">
                           dharmaraj@atomgroups.com
                          </span>
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="w-75">
                        <Form.Group className="mb-3">
                          <Form.Label>Enter Value</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter Target"
                          />
                        </Form.Group>
                      </div>
                      <div className="me-2">
                        <Button variant="light" size="sm">
                          <i className="ri-delete-bin-line fs-16" />
                        </Button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}
