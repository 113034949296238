import { Fragment } from "react";
import BreadCrumb from "elements/System/BreadCrumb";

export default function WhatsappHome() {
  return (
    <Fragment>
      <BreadCrumb
        breadCrumbItems={[
          { label: "Whatsapp", path: "/pages/whatsapp", active: true },
        ]}
        title={"Whatsapp"}
      />
      <p>This page will allow you to define how you share the data among users based on your organization's role hierarchy. For more information, refer to online help.</p>
      <p>New Page: Refer Zoho / Osmo CRM</p>
    </Fragment>
  );
}
