// package
import { Fragment, useState, useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Badge,
  Row,
  Form,
  OverlayTrigger,
  Tooltip,
  Dropdown,
  Modal,
  Spinner,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import SimpleBar from "simplebar-react";
import AmlHashForm from "aml-hash-form";
// component
import BreadCrumb from "elements/System/BreadCrumb";
import FormSelect from "elements/System/FormSelect";
import AgStatus from "elements/System/AgStatus";
import { api, common, config } from "includes";

export default function PermissionHome() {
  // state
  const [process, setProcess] = useState<any>({
    result: 100,
    info: 100,
    edit: 100,
  });
  const [submit, setSubmit] = useState<String>("");
  const [popup, setPopup] = useState<String>("");
  const [resultList, setResultList] = useState<any>([]);
  const [resultInfo, setResultInfo] = useState<any>(null);
  const [utils, setUtils] = useState<any>({
    expand: true,
    search: "",
    editId: null,
    viewId: null,
  });

  // form
  const formFields = {
    name: { validate: ["req#Permission Name is required"] },
    description: { validate: [] },
    permission_id: {
      validate: [
        "req#Clone Permission is required!",
        "objValReq#Clone Permission is required!",
      ],
      obj: "value",
    },
  };

  const {
    fields,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
    setValue,
    setMultiValue,
  } = AmlHashForm(formFields);

  // effect
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [utils.search]);

  // api
  const getData = () => {
    let query = "";
    if (utils.search) {
      query = `concat(name) ilike  '%%${utils.search}%%'`;
    }

    let data: any = {
      url: `/permission/read?type=mp&fields=id,name,description,is_system&query=${query}&join=&limit=100&offset=0&order=created_at DESC`,
      method: "GET",
    };
    api.call(data, (res: any) => {
      processState("result", res.status);
      if (res.status === 200) {
        let result = res.data;
        setResultList(result);

        if (result.length > 0) {
          utilsState("viewId", result[0].id);
          processState("info", 100);
          getPermissionInfo(result[0]?.id);
        } else {
          processState("info", 204);
        }
      }
    });
  };

  const getPermissionInfo = (permissionId: any) => {
    let data: any = {
      url: `/permission/read?type=sp&fields=id,name,modules&query=id=${permissionId}&join=&limit=100&offset=0&order=created_at DESC`,
      method: "GET",
    };
    api.call(data, (res: any) => {
      processState("info", res.status);

      if (res.status === 200) {
        let result = res.data;
        setResultInfo(result);
      }
    });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    const isValid = handleSubmit();
    if (isValid) {
      let payload = common.formPayload(fields, []);
      setSubmit("permission");

      if (utils.editId) {
        delete payload.permission_id;
      } else {
        payload.permission_id = Object.keys(fields.permission_id)[0];
      }

      let param: any = {
        url: utils.editId ? "/permission/update/" : "/permission/create",
        query: utils.editId,
        method: utils.editId ? "PUT" : "POST",
        body: payload,
      };

      api.call(param, (res: any) => {
        if (res.status === 200) {
          common.notify("S", res.message);
          setSubmit("");
          setPopup("");
          getData();
        } else {
          setSubmit("");
        }
      });
    }
  };

  const onFieldChange = (e: any, module: string, field: any) => {
    let newData: any;
    Object.entries(resultInfo.modules).map((obj: any) => {
      if (obj[0] === module) {
        obj[1][field] = e.value;
        newData = obj[1];
      }
    });

    setResultInfo((prevState: any) => {
      return {
        ...prevState,
        [module.toString()]: newData,
      };
    });

    let payload = { modules: { [module]: newData } };

    let param = {
      url: "/permission/update/module/",
      query: utils.viewId,
      method: "PUT",
      body: payload,
    };
    api.call(param, (res: any) => {
      if (res.status === 200) {
        common.notify("S", res.message);
      } else {
        processState("info", res.status);
      }
    });
  };

  // events
  const onSelectPermission = (data: any) => {
    utilsState("viewId", data.id);
    processState("info", 100);
    getPermissionInfo(data.id);
  };

  const refresh = () => {
    setProcess(100);
    utilsState("viewId", null);
    utilsState("editId", null);
    setSubmit("");
    getData();
  };

  const addPermission = () => {
    processState("edit", 200);
    setSubmit("");
    utilsState("editId", null);
    setMultiValue({
      name: "",
      description: "",
      permission_id: null,
    });
    handleReset();
    setPopup("permission");
  };

  const editPermission = (type: any, data: any) => {
    let filtered = resultList.filter((obj: any) => obj.id == data.id);
    let option = { [filtered[0].id]: filtered[0].name };
    if (type == "clone") {
      processState("edit", 200);
      if (filtered.length > 0) {
        setMultiValue({
          name: "",
          description: "",
          permission_id: option,
        });
      }
      handleReset();
      utilsState("editId", null);
      setPopup("permission");
    } else if (type == "edit") {
      setPopup("permission");
      processState("edit", 100);
      let payload = {
        url: `/permission/read?type=sp&fields=id,name,description,is_system&query=id=${data.id}&join=&limit=100&offset=0&order=created_at DESC`,
        method: "GET",
      };
      api.call(payload, (res: any) => {
        processState("edit", res.status);
        if (res.status === 200) {
          let result = res.data;
          setMultiValue({
            name: result.name,
            description: result.description,
            permission_id: option,
          });
          utilsState("editId", data.id);
        }
      });
    }
  };

  // callback
  const onAgCallBack = () => {
    getData();
  };

  // support
  const filterSelect = (access: any) => {
    let selected = config.options.permissions.filter(
      (obj) => obj.value == access
    );
    if (selected.length > 0) {
      return selected[0];
    }
    return null;
  };

  const getAccessName = (access: string) => {
    let filtered = config.options.accessNames.filter(
      (obj: any) => obj.value === access
    );
    return filtered[0]?.label || "";
  };

  const processState = (label: any, value: any) => {
    setProcess((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };

  const utilsState = (label: any, value: any) => {
    setUtils((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };

  // render
  return (
    <Fragment>
      <BreadCrumb
        breadCrumbItems={[
          { label: "Settings", path: "/settings/home" },
          { label: "Permissions", path: "/permission/home", active: true },
        ]}
        title={"Permissions"}
      />
      {process.result != 200 ? (
        <Card>
          <Card.Body className="py-1 px-3 border-bottom border-light">
            <AgStatus
              process={process.result}
              message={{ 100: "Loading Permissions...Please wait." }}
              size="m"
              img="nopermission.svg"
              onAgCallBack={onAgCallBack}
            />
          </Card.Body>
        </Card>
      ) : (
        <Row>
          {utils.expand && (
            <Col xl={4} lg={5}>
              <Card>
                <Card.Body className="py-1">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="header-title mb-0">
                        Permission List
                        <Badge className="ms-1" bg="primary">
                          {resultList.length}
                        </Badge>
                      </h4>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="">Add New</Tooltip>}
                      >
                        <Link
                          to="#"
                          className="text-reset fs-20 p-1 d-inline-block"
                          onClick={() => addPermission()}
                        >
                          <i
                            className={"ri-chat-new-fill ms-1"}
                            data-bs-container="#tooltips-container"
                            data-bs-toggle="tooltip"
                          />
                        </Link>
                      </OverlayTrigger>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="">Refresh</Tooltip>}
                      >
                        <Link
                          to="#"
                          className="text-reset fs-20 p-1 d-inline-block"
                          onClick={refresh}
                        >
                          <i
                            className={"ri-refresh-line fs-16 ms-1"}
                            data-bs-container="#tooltips-container"
                            data-bs-toggle="tooltip"
                          />
                        </Link>
                      </OverlayTrigger>
                    </div>
                  </div>
                  <hr className="mt-0 mb-1" />
                  <div className="app-search">
                    <form>
                      <div className="w-100 position-relative">
                        <input
                          type="search"
                          className="form-control"
                          placeholder="Search permissions..."
                          onChange={(e: any) =>
                            utilsState("search", e.target.value)
                          }
                        />
                        <span className="ri-search-line search-icon"></span>
                      </div>
                    </form>
                  </div>
                </Card.Body>

                <Card.Body className="py-0 px-0 mb-1">
                  <SimpleBar style={{ maxHeight: 400 }}>
                    {resultList.map((item: any, idx: number) => (
                      <div
                        key={idx}
                        className={
                          item.id === utils.viewId
                            ? "bg-success-subtle px-3 py-2"
                            : "px-3 py-2"
                        }
                        style={{ cursor: "pointer", position: "relative" }}
                      >
                        <div
                          onClick={(e: any) => {
                            // e.stopPropagation();
                            onSelectPermission(item);
                          }}
                        >
                          <Card.Header className="d-flex justify-content-between align-items-center p-0">
                            <h4 className="header-title">{item.name}</h4>
                          </Card.Header>
                          <p className="mb-0 pb-0 short-description">
                            {item.description}
                          </p>
                        </div>
                        <div className="permission-action-menu">
                          <Dropdown align="end">
                            <Dropdown.Toggle
                              variant="no"
                              className="dropdown-toggle arrow-none card-drop p-0"
                            >
                              <i className="ri-more-fill px-1" />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={(e: any) => {
                                  e.stopPropagation();
                                  editPermission("clone", item);
                                }}
                              >
                                <i className="ri-file-copy-2-line" /> Clone
                              </Dropdown.Item>

                              {!item.is_system && (
                                <Dropdown.Item
                                  onClick={(e: any) => {
                                    e.stopPropagation();
                                    editPermission("edit", item);
                                  }}
                                >
                                  <i className="ri-edit-line" /> Rename
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    ))}
                    {resultList.length == 0 && (
                      <AgStatus
                        process={204}
                        size="m"
                        img="nopermission.svg"
                        message={{
                          204: utils.search
                            ? `No permissions found for "${utils.search}"`
                            : "No permissions found",
                        }}
                      />
                    )}
                  </SimpleBar>
                </Card.Body>
              </Card>
              {resultList.length > 0 && (
                <div>
                  Showing 1 to {resultList.length} of {resultList.length}{" "}
                  entries
                </div>
              )}
            </Col>
          )}
          {process.info != 200 ? (
            <Col
              xl={utils.expand ? "8" : "12"}
              lg={utils.expand ? "9" : "12"}
              className="detail-right-wrap"
            >
              <Card>
                <Card.Body className="py-1 px-3 border-bottom border-light">
                  <AgStatus
                    process={process.info}
                    size="m"
                    img="nopermission.svg"
                    message={{
                      100: "Loading Permissions...Please wait.",
                    }}
                    onAgCallBack={onAgCallBack}
                  />
                </Card.Body>
              </Card>
            </Col>
          ) : (
            <Col
              xl={utils.expand ? "8" : "12"}
              lg={utils.expand ? "9" : "12"}
              className="detail-right-wrap"
            >
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id="">
                    {utils.expand ? "Collapse" : "Expand"}
                  </Tooltip>
                }
              >
                <span className="expand-collapse-icon">
                  {utils.expand ? (
                    <i
                      className="ri-skip-left-fill fs-22"
                      onClick={() => utilsState("expand", false)}
                    />
                  ) : (
                    <i
                      className="ri-skip-right-fill fs-22"
                      onClick={() => utilsState("expand", true)}
                    />
                  )}
                </span>
              </OverlayTrigger>
              <Card>
                <Card.Body className="py-1 px-3 border-bottom border-light">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="float-start">
                      <label className="form-check-label fs-16 pt-1">
                        {resultInfo.name
                          ? `Configure Permission For ${resultInfo.name}`
                          : "Configure Permission"}
                      </label>
                    </div>
                  </div>
                </Card.Body>
                <Card.Body>
                  {resultInfo.modules &&
                    Object.keys(resultInfo.modules).length > 0 &&
                    Object.keys(resultInfo.modules).map(
                      (key: any, idx: number) => {
                        let modPermissions = resultInfo.modules[key];
                        return (
                          <Fragment key={idx}>
                            <h5 className="mb-3 text-uppercase bg-success-subtle p-2">
                              <i className="ri-door-lock-box-line me-1" /> {key}
                            </h5>
                            <Row className="mb-2">
                              {Object.entries(modPermissions).map(
                                (access: any, idx: number) => {
                                  return (
                                    <Col md={3} key={idx}>
                                      <Form.Group className="mb-3">
                                        <Form.Label>
                                          {getAccessName(access[0])}
                                        </Form.Label>
                                        <Select
                                          className="select2 z-5"
                                          options={config.options.permissions}
                                          value={filterSelect(access[1])}
                                          onChange={(e) =>
                                            onFieldChange(e, key, access[0])
                                          }
                                        />
                                      </Form.Group>
                                    </Col>
                                  );
                                }
                              )}
                            </Row>
                          </Fragment>
                        );
                      }
                    )}
                </Card.Body>
              </Card>
            </Col>
          )}
        </Row>
      )}
      {/* Permission Modal */}
      <Modal
        show={popup === "permission"}
        size="lg"
        scrollable={true}
        onHide={() => setPopup("")}
      >
        <Modal.Header closeButton>
          <Modal.Title>{!utils.editId ? "Add" : "Edit"} Permission</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {process.edit != 200 ? (
            <AgStatus
              process={process.edit}
              message={{ 100: "Loading permission detail.Please wait..." }}
              onAgCallBack={onAgCallBack}
              size="m"
            />
          ) : (
            <>
              <Row>
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Permission Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={fields.name}
                      onChange={handleChange}
                      placeholder="Enter permission name"
                      isInvalid={errors.name ? true : false}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name != null ? errors.name : "-"}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                {!utils.editId && (
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Clone permission from </Form.Label>
                      <FormSelect
                        url="/permission/read"
                        fields="id,name"
                        query=""
                        order=""
                        value={fields.permission_id}
                        fixed={false}
                        multi={false}
                        async={false}
                        limit={10}
                        render={"yes"}
                        error={errors.permission_id}
                        onSelect={(data: any) => {
                          setValue("permission_id", data);
                        }}
                      />
                      {errors.permission_id && (
                        <span className="error-text">
                          {errors.permission_id}
                        </span>
                      )}
                    </Form.Group>
                  </Col>
                )}
              </Row>
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="description"
                      value={fields.description}
                      onChange={handleChange}
                      rows={4}
                      data-toggle="maxlength"
                      maxLength={5000}
                      placeholder="Enter description"
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => setPopup("")}
            disabled={submit === "permission"}
          >
            <i className="ri-close-line" /> Close
          </Button>
          <Button
            variant="success"
            onClick={onSubmit}
            disabled={submit === "permission"}
          >
            {submit === "permission" ? (
              <>
                <Spinner className="spinner-border-sm me-1" color="white" />
                Processing...
              </>
            ) : (
              <>
                <i className="ri-save-line" /> Submit
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}
