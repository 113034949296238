//package
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Col,
  Row,
  Form,
  Button,
  Pagination as BootstrapPagination,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";
import AmlHashForm from "aml-hash-form";
//includes
import FormSelect from "elements/System/FormSelect";
import { api, common } from "includes";
import AgStatus from "elements/System/AgStatus";

export default function SmsHome(props: any) {
  //const
  const navigate = useNavigate();
  const Loading = () => <div>Loading...</div>;
  const Item = (entity: any) => <div>{`${entity.entity?.label}`}</div>;

  //state
  const [process, setProcess] = useState<any>({ result: 100, edit: 100 });
  const [data, setData] = useState<any>({
    field: [],
    moduleId: "",
  });
  const [utils, setUtils] = useState<any>({
    templateAdd: false,
  });

  //form
  const formFields = {
    name: { validate: ["req#Template name is required"] },
    module: { validate: ["objValReq#Module is required"], obj: "value" },
    subject: { validate: ["req#Subject is required"] },
    body: { validate: ["req#Message is required"] },
  };
  const {
    fields,
    errors,
    handleChange,
    handleSubmit,
    setMultiValue,
    handleReset,
  } = AmlHashForm(formFields);

  // effect

  useEffect(() => {
    console.log("MOduleId", data.moduleId);
    if (data.moduleId) {
      getMergeTag(data.moduleId, "");
    }
  }, [data.moduleId]);

  useEffect(() => {
    if (props.editMode) {
      getData();
    }
  }, [props.editMode]);

  useEffect(() => {
    if (props.type == "sms") {
      onReset();
    }
  }, [props.type]);

  // api

  const getData = () => {
    processState("result", 100);
    const encodedTypeValue = encodeURIComponent(`'${props.id}'`);
    let url = `?type=sp&fields=*&query=id=${encodedTypeValue}&join=&limit=2&offset=0&order=id ASC`;
    let data = {
      url: "/template/read",
      query: url,
      method: "GET",
    };

    api.call(data, (res: any) => {
      if (res.status == 200) {
        processState("result", res.status);
        const result = res.data;
        const moduleId = Object.keys(result.module)[0];
        dataState("moduleId", moduleId);
        let body = result.body;
        getMergeTag(moduleId, result);
      } else {
        processState("edit", res.status);
      }
    });
  };

  const getMergeTag = (value: string, result: any) => {
    processState("edit", 100);
    const encodedTypeValue = encodeURIComponent(`'${value}'`);
    let url = `?type=mp&fields=id,data&query=id=${encodedTypeValue}&join=&limit=2&offset=0&order=id ASC`;
    let data = {
      url: `/module/read`,
      query: url,
      method: "GET",
    };

    api.call(data, (res: any) => {
      if (res.status === 200) {
        processState("edit", res.status);
        const fields = res.data[0].data.fields.map((field: any) => ({
          label: field.label,
          value: field.label,
          key: field.key,
        }));

        if (props.editMode) {
          dataState("field", fields);
          fields.forEach((mergeTag: any) => {
            const regexPattern = `\\$\\{${mergeTag.key}\\}`;
            const regex = new RegExp(regexPattern, "g");
            result.body = result.body.replace(
              regex,
              "${" + mergeTag.label + "}"
            );
          });
          setMultiValue({
            name: result.name,
            module: result.module,
            subject: result.subject,
            body: result.body,
          });
        } else {
          dataState("field", fields);
          processState("edit", res.status);
        }
      }
    });
  };

  const onEdit = () => {
    utilsState("templateAdd", true);
    data.field.forEach((mergeTag: any) => {
      const regexPattern = `\\$\\{${mergeTag.label}\\}`;
      const regex = new RegExp(regexPattern, "g");
      fields.body = fields.body.replace(regex, "${" + mergeTag.key + "}");
    });

    let payload = {
      name: fields.name,
      subject: fields.subject,
      type: "Sms",
      body: fields.body,
      data: null,
      module: fields.module,
    };

    const result = {
      url: `/template/update/${props.id}`,
      method: "PUT",
      auth: "token",
      body: payload,
    };

    api.call(result, (res: any) => {
      if (res.status === 200) {
        common.notify("S", "Successfully Updated");
        navigate("/services/sms/list");
        props.close();
      } else {
        common.notify("E", "Something Went wrong ,Try Again");
      }

      utilsState("templateAdd", false);
    });
  };

  const onSave = () => {
    utilsState("templateAdd", true);
    data.field.forEach((mergeTag: any) => {
      const regexPattern = `\\$\\{${mergeTag.label}\\}`;
      const regex = new RegExp(regexPattern, "g");
      fields.body = fields.body.replace(regex, "${" + mergeTag.key + "}");
    });

    let payload = {
      name: fields.name,
      subject: fields.subject,
      type: "Sms",
      body: fields.body,
      data: null,
      module: fields.module,
    };

    const datas = {
      url: "/template/create",
      method: "POST",
      auth: "token",
      body: payload,
    };

    api.call(datas, (res: any) => {
      if (res.status === 200) {
        common.notify("S", "Successfully saved");
        navigate("/services/sms/list");
        props.close();
      } else {
        common.notify("E", "Something went wrong,Try Again");
      }

      utilsState("templateAdd", false);
    });
  };

  // events

  const onSubmit = () => {
    const isValid = handleSubmit();
    if (isValid) {
      if (props.editMode) {
        onEdit();
      } else {
        onSave();
      }
    }
  };
  const mapKey = (key: string) => {
    if (key) {
      const filters = data.field.filter((i: { label: string }) =>
        i.label.toUpperCase().includes(key.toUpperCase())
      );
      return filters ? filters : data.field;
    } else {
      return data.field;
    }
  };

  const onReset = () => {
    handleReset();
    setMultiValue({
      name: "",
      module: null,
      subject: "",
      body: "",
    });
  };

  // callback

  const onAgCallBack = (status: any) => {
    navigate(`/services/sms/list`);
    props.close();
  };

  // support

  const processState = (label: any, value: any) => {
    setProcess((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };

  const dataState = (label: any, value: any) => {
    setData((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };
  const utilsState = (label: any, value: any) => {
    setUtils((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };

  //render
  return (
    <Modal show={props.open} size="lg" scrollable={true} onHide={props.close}>
      <Modal.Header closeButton>
        <Modal.Title>
          {props.editMode ? "Edit Sms Template" : "Add Sms Template"}
        </Modal.Title>
      </Modal.Header>
      {process.result != 200 && props.editMode ? (
        <Modal.Body>
          <AgStatus
            process={process.result}
            message={{
              100: "Loading data.Please wait...",
              204: `No Templates found!`,
              400: "You're trying to access invalid records.",
            }}
            button={{ 400: "Go to List" }}
            size="l"
            onAgCallBack={onAgCallBack}
          />
        </Modal.Body>
      ) : (
        <Modal.Body>
          <Row>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Select Module</Form.Label>
                <FormSelect
                  url="/module/read"
                  fields="id,name"
                  query=""
                  order="name ASC"
                  fixed={false}
                  value={fields.module}
                  limit={50}
                  multi={false}
                  render={"yes"}
                  async={false}
                  onSelect={(data: any) => {
                    setMultiValue({
                      module: data,
                    });
                    if (data) {
                      const keys: any = Object.keys(data);
                      const key: any = Number(keys[0]);
                      dataState("moduleId", key);
                    } else {
                      console.log("data.value is undefined");
                    }
                  }}
                  error={errors.module}
                  disabled={props.editMode ? true : false}
                />
                <p className="error-text">{errors.module}</p>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Template Name</Form.Label>
                <Form.Control
                  type="text"
                  maxLength={255}
                  data-toggle="maxlength"
                  name="name"
                  value={fields.name}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter Template name"
                  isInvalid={errors.name ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col lg={6}>
              <Form.Group className="mb-3">
                <Form.Label>Subject</Form.Label>
                <Form.Control
                  type="text"
                  maxLength={255}
                  data-toggle="maxlength"
                  name="subject"
                  value={fields.subject}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  placeholder="Enter the Subject"
                  isInvalid={errors.subject ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.subject}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>

            <Col lg={12}>
              <Form.Label>
                Message (To merge the required field type "#" in textbox){" "}
              </Form.Label>

              <ReactTextareaAutocomplete
                name="body"
                value={fields.body}
                onChange={handleChange}
                loadingComponent={Loading}
                minChar={0}
                style={{
                  height: "200px",
                }}
                trigger={{
                  "#": {
                    dataProvider: (token) => {
                      return mapKey(token)
                        .slice(0, 10)
                        .map(({ label, key }: any) => ({
                          label,
                          key,
                        }));
                    },
                    component: Item,
                    output: (item: any, trigger: any) =>
                      "${" + item.label + "}",
                  },
                }}
                className={
                  errors.body
                    ? "form-control required-input w-100"
                    : "form-control w-100"
                }
              />
              {errors.body && <span className="error-text">{errors.body}</span>}
            </Col>
          </Row>
        </Modal.Body>
      )}
      <Modal.Footer>
        <Button variant="light" onClick={props.close}>
          Close
        </Button>
        <Button
          variant="success"
          onClick={onSubmit}
          disabled={utils.templateAdd}
        >
          {utils.templateAdd ? "Processing" : "Submit"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
