// package
import { Fragment, useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
  InputGroup,
  Modal,
  Spinner
} from "react-bootstrap";
import AmlHashForm from "aml-hash-form";
import { useNavigate } from "react-router-dom";
// component
import AgStatus from "elements/System/AgStatus";
import RoleModal from "elements/System/RoleModal";
import BreadCrumb from "elements/System/BreadCrumb";
import { api, common } from "includes";

export default function Roles() {
  // const
  const navigate = useNavigate();
  // state
  const [process, setProcess] = useState<any>({ result: 100, info: 100, edit: 100 });
  const [submit, setSubmit] = useState<String>("");
  const [popup, setPopup] = useState<String>("");
  const [resultList, setResultList] = useState<any>([]);
  const [resultUser, setResultUser] = useState<any>([]);
  const [data, setData] = useState<any>({ info: null });

  // form
  const formFields = {
    name: { validate: ["req#Role Name is required"] },
    description: { validate: [] },
    role_id: { validate: [] },
    parent_name: { validate: ["req#Role Name is required"] },
  };
  const {
    fields,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
    setMultiValue,
  } = AmlHashForm(formFields);

  // effect
  useEffect(() => {
    getData();
  }, []);

  // api
  const getData = () => {
    let data: any = {
      url: "/role/read/tree?type=mp&fields=*&query=&join=&limit=100&offset=0&order=id ASC",
      method: "GET",
    };
    api.call(data, (res: any) => {
      processState("result", res.status);
      if (res.status === 200) {
        let result = res.data;
        setResultList(result);
      }
    });
  };

  const editRole = (record: any) => {
    setPopup("role");
    processState("edit", 100);
    let payload = {
      url: `/role/read?type=sp&fields=r.id,r.name,r1.name as parent_name,r.description,r.role_id&query=r.id=${record.id}&join=LEFT JOIN roles r1 on r1.id=r.role_id&limit=30&offset=0&order=r.id ASC`,
      method: "GET",
    };

    api.call(payload, (res: any) => {
      processState("edit", res.status);
      if (res.status === 200) {
        let result = res.data;
        dataState("info", result);
        setMultiValue({
          name: result.name || "",
          description: result.description || "",
          role_id: result.role_id,
          parent_name: result?.parent_name,
        });
      }
    });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    const isValid = handleSubmit();
    if (isValid) {
      let payload = common.formPayload(fields, []);
      delete payload.parent_name;
      payload.share_peers = true;

      setSubmit("role");
      let param: any = {
        url: data.info ? "/role/update/" : "/role/create",
        query: data.info?.id,
        method: data.info ? "PUT" : "POST",
        body: payload,
      };

      api.call(param, (res: any) => {
        if (res.status === 200) {
          dataState("info", null);
          setSubmit("");
          setPopup("");
          processState("result", 100);
          getData();
        } else {
          setSubmit("");
        }
      });
    }
  };

  const getUsers = (roleId: any) => {
    setPopup("user");
    processState("info", 100);
    let data: any = {
      url: `/role/read/user/${roleId}`,
      method: "GET",
    };
    api.call(data, (res: any) => {
      processState("info", res.status);
      if (res.status === 200) {
        let response = res.data;
        setResultUser(response);
        if (response.length == 0) {
          processState("info", 204);
        }
      }
    });
  };

  // events
  const addRole = (data: any) => {
    setSubmit("");
    dataState("info", null);
    setMultiValue({
      name: "",
      description: "",
      role_id: data.id,
      parent_name: data.name,
    });
    handleReset();
    processState("edit", 200);
    setPopup("role");
  };

  const closePopup = () => {
    setSubmit("");
    dataState("info", null);
    setMultiValue({
      name: "",
      description: "",
      role_id: resultList.id,
      parent_name: resultList.name,
    });
    setPopup("");
  };

  const setParent = (data: any) => {
    setMultiValue({
      name: fields.name || "",
      description: fields.description || "",
      role_id: data.id,
      parent_name: data.name,
    });
    setPopup("role");
  };

  // callback
  const onAgCallBack = (status: any) => {
    if (status == 204) {
      navigate(`/user/list`);
    } else {
      getData();
    }
  };

  // support
  const processState = (label: any, value: any) => {
    setProcess((prev: any) => ({
      ...prev,
      [label]: value
    }));
  }

  const dataState = (label: any, value: any) => {
    setData((prev: any) => ({
      ...prev,
      [label]: value
    }));
  }

  const renderMenu = (obj: any) => {
    return (
      <span className="roles-tree-options ms-2">
        {/* User menu */}
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="">Users</Tooltip>}
        >
          <Button
            variant="light"
            size="sm"
            className="position-relative me-1"
            onClick={() => getUsers(obj.id)}
          >
            <i className="ri-group-line" />
          </Button>
        </OverlayTrigger>

        {/* Add Menu */}
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="">Add</Tooltip>}
        >
          <Button
            variant="light"
            size="sm"
            className="me-1"
            onClick={() => addRole(obj)}
          >
            <i className="ri-add-line" />
          </Button>
        </OverlayTrigger>

        {/* Edit Menu */}
        {!obj.is_system && (
          <OverlayTrigger
            placement="bottom"
            overlay={<Tooltip id="">Edit</Tooltip>}
          >
            <Button
              variant="light"
              size="sm"
              className="me-1"
              onClick={() => editRole(obj)}
            >
              <i className="ri-edit-line" />
            </Button>
          </OverlayTrigger>
        )}
      </span>
    );
  };

  const renderChild = (data: any, isMenu: boolean) => {
    return (
      <>
        <ul>
          {data?.children?.length > 0 &&
            data?.children?.map((obj: any, idx: number) => {
              return (
                <li key={obj.name + idx}>
                  {obj?.children?.length > 0 ? (
                    <details open>
                      <summary className="role-row">
                        <span className="ps-1 fs-16">{obj.name}</span>
                        {isMenu && renderMenu(obj)}
                      </summary>
                      {renderChild(obj, isMenu)}
                    </details>
                  ) : (
                    <>
                      <div className="role-row">
                        <span className="fs-16 crm-cp">{obj.name}</span>
                        {isMenu && renderMenu(obj)}
                      </div>
                    </>
                  )}
                </li>
              );
            })}
        </ul>
      </>
    );
  };

  // render
  return (
    <Fragment>
      <BreadCrumb
        breadCrumbItems={[
          { label: "Settings", path: "/settings/home" },
          { label: "Roles", path: "/roles/home", active: true },
        ]}
        title={"Roles"}
      />
      <Row>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Body>
              {process.result != 200 ? (
                <AgStatus
                  process={process.result}
                  message={{ 100: "Loading Roles... Please wait...." }}
                  size="l"
                  img="noroles.svg"
                  onAgCallBack={onAgCallBack} />
              ) : (
                <Fragment>
                  <p className="text-muted">
                    This page will allow you to define how you share the data
                    among users based on your organization's role hierarchy. For
                    more information, refer to online help.
                  </p>
                  <ul className="tree">
                    <li>
                      <details open>
                        <summary className="role-row">
                          <span className="ps-1 fs-16">{resultList.name}</span>
                          {renderMenu(resultList)}
                        </summary>
                        {renderChild(resultList, true)}
                      </details>
                    </li>
                  </ul>
                </Fragment>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {popup === "parent" && (
        <RoleModal
          onParentSelect={setParent}
          closeModal={() => { setPopup("role") }}
          editInfo={data.info}
        />
      )}

      {/* Add/Edit Role popup */}
      <Modal
        show={popup === "role" || popup === "parent"}
        size="lg"
        scrollable={true}
        onHide={() => closePopup()}
      >
        <Modal.Header closeButton>
          <Modal.Title>{!data.info ? "Add" : "Edit"} Role</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {process.edit != 200 ? (
            <AgStatus
              process={process.edit}
              message={{ 100: "Loading role detail..." }}
              onAgCallBack={onAgCallBack}
              size="m" />
          ) : (
            process.edit == 200 && (
              <>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Role Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        value={fields.name}
                        onChange={handleChange}
                        placeholder="Enter role name"
                        isInvalid={errors.name ? true : false}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.name != null ? errors.name : "-"}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Parent Role</Form.Label>
                      <div className="input-group">
                        <Form.Control
                          type="text"
                          name="parent_name"
                          value={fields.parent_name}
                          isInvalid={errors.parent_name ? true : false}
                          disabled={true}
                        />
                        <InputGroup.Text
                          onClick={() => setPopup("parent")}
                          className="crm-cp"
                        >
                          <i className="ri-account-circle-line" />
                        </InputGroup.Text>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.parent_name != null ? errors.parent_name : "-"}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        name="description"
                        value={fields.description}
                        onChange={handleChange}
                        rows={4}
                        data-toggle="maxlength"
                        maxLength={5000}
                        placeholder="Enter description"
                      ></Form.Control>
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => closePopup()}
            disabled={submit === "role"}
          >
            <i className="ri-close-line" /> Close
          </Button>
          <Button variant="success" onClick={onSubmit} disabled={submit === "role"}>
            {submit === "role" ? (
              <>
                <Spinner className="spinner-border-sm me-1" color="white" />
                Processing...
              </>
            ) : (
              <>
                <i className="ri-save-line" /> Save
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal >

      {/* User popup */}
      < Modal
        variant="info"
        show={popup === "user"}
        size="lg"
        scrollable={true}
        onHide={() => setPopup("")
        }
      >
        <Modal.Header closeButton>
          <Modal.Title>Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {process.info != 200 ? (
            <AgStatus
              process={process.info}
              message={{ 100: "Loading users details... Please wait.", 204: "No users assigned for this role." }}
              button={{ 204: "Assign user" }}
              img="noroles.svg"
              onAgCallBack={onAgCallBack}
              size="m"
            />
          ) : (
            resultUser.map((user: any, idx: number) => {
              return (
                <div
                  key={idx}
                  className={`d-flex align-items-start py-2 px-3${idx === 3 ? " bg-success-subtle" : ""
                    }`}
                >
                  <img
                    src={common.cloudImg(user.avatar)}
                    className="me-2 rounded-circle"
                    height="42"
                    alt={user.name}
                  />

                  {/* <div className="avatar-group-item">
                    <div className="avatar-xs me-2">
                      <div
                        className={
                          idx % 2 == 1
                            ? "avatar-title rounded-circle text-bg-info"
                            : "avatar-title rounded-circle text-bg-primary"
                        }
                      >
                        {user.first_name && user.first_name[0].toUpperCase()}
                      </div>
                    </div>
                  </div> */}
                  <div className="w-100">
                    <h5 className="my-0">
                      <span className="float-end text-muted fw-normal fs-12">
                        {user.role_name}
                      </span>
                      {user.last_name
                        ? `${user.first_name}  ${user.last_name}`
                        : user.first_name}
                    </h5>
                    <p className="mt-1 mb-0 text-muted">
                      <span className="w-25 float-end text-end">
                        {user.totalUnread !== 0 && (
                          <span className="badge bg-primary-subtle text-primary">
                            {user.status}
                          </span>
                        )}
                      </span>
                      <span className="w-75">{user.email}</span>
                    </p>
                  </div>
                </div>
              );
            })
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setPopup("")}>
            Close
          </Button>
        </Modal.Footer>
      </Modal >
    </Fragment >
  );
}
