// package
import { Fragment, useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
// component
import AgStatus from "elements/System/AgStatus";
import { api } from "includes";

const RoleModal: React.FC<any> = (props: any) => {
    // const
    const { onParentSelect, closeModal } = props;
    //state
    const [process, setProcess] = useState<number>(100);
    const [resultList, setResultList] = useState<any>([]);

    // effect
    useEffect(() => {
        getData();
    }, []);

    // api
    const getData = () => {
        let data: any = {
            url: "/role/read/tree?type=mp&fields=*&query=&join=&limit=100&offset=0&order=id ASC",
            method: "GET",
        };
        api.call(data, (res: any) => {
            setProcess(res.status);
            if (res.status === 200) {
                let result = res.data;
                setResultList(result);
            }
        });
    };

    // events
    const onRoleSelect = (e: any, data: any) => {
        e.preventDefault();
        onParentSelect(data);
    };

    // support
    const onAgCallBack = () => {
        getData();
    };

    const renderChild = (data: any, isMenu: boolean) => {
        return (
            <>
                <ul>
                    {data?.children?.length > 0 &&
                        data?.children?.map((obj: any, idx: number) => {
                            return (
                                <li key={obj.name + idx}>
                                    {obj?.children?.length > 0 ? (
                                        <details open>
                                            <summary className="role-row">
                                                <span
                                                    onClick={(e: any) => onRoleSelect(e, obj)}
                                                    className={
                                                        props?.editInfo?.name === obj.name
                                                            ? "ps-1 fs-16 node-disabled"
                                                            : "ps-1 fs-16 "
                                                    }
                                                >
                                                    {obj.name}
                                                </span>
                                            </summary>
                                            {renderChild(obj, isMenu)}
                                        </details>
                                    ) : (
                                        <>
                                            <div className="role-row">
                                                <span
                                                    onClick={(e: any) => onRoleSelect(e, obj)}
                                                    className={
                                                        props?.editInfo?.name === obj?.name
                                                            ? "fs-16 crm-cp node-disabled"
                                                            : "fs-16 crm-cp"
                                                    }
                                                >
                                                    {obj.name}
                                                </span>
                                            </div>
                                        </>
                                    )}
                                </li>
                            );
                        })}
                </ul>
            </>
        );
    };

    // render
    return (
        <Fragment>
            <Modal
                show={true}
                dialogClassName="modal-top"
                scrollable={true}
                onHide={() => closeModal()}
            >
                <Modal.Header closeButton className={`modal-colored-header bg-primary`}>
                    <h4 className="modal-title">Roles List</h4>
                </Modal.Header>
                <Modal.Body>
                    {process != 200 ? (
                        <AgStatus
                            message={{ 100: "Loading roles... Please wait!" }}
                            process={process}
                            onAgCallBack={onAgCallBack}
                            size="m"
                            img="noroles.svg"
                        />
                    ) : (
                        <Fragment>
                            <h5>Please select one role from the list.</h5>

                            <ul className="tree">
                                <li
                                    className={
                                        props?.editInfo?.name === resultList.name ? "node-disabled" : ""
                                    }
                                >
                                    <details open>
                                        <summary className="role-row">
                                            <span onClick={(e: any) => onRoleSelect(e, resultList)} className="ps-1">
                                                {resultList.name}
                                            </span>
                                        </summary>
                                        {renderChild(resultList, false)}
                                    </details>
                                </li>
                            </ul>
                        </Fragment>
                    )}
                </Modal.Body>
            </Modal>
        </Fragment>
    );
};
export default RoleModal;
