import { useState, useEffect, Fragment } from "react";
import { Container } from "react-bootstrap";
// layouts
import Background from "./Background";
import { Outlet } from "react-router-dom";
import { common } from "includes";

const FrontLayout = () => {
  // state
  const [isAuth] = useState(common.checkAuth());
  // effect
  useEffect(() => {
    if (isAuth) {
      common.redirect("app/home");
    }
  }, [isAuth]);

  useEffect(() => {
    if (document.body) {
      document.body.classList.add("authentication-bg", "position-relative");
    }
    return () => {
      if (document.body) {
        document.body.classList.remove(
          "authentication-bg",
          "position-relative"
        );
      }
    };
  }, []);

  return (
    !isAuth ? (
      <div className="authentication-bg position-relative">
        <Background />
        <div className="account-pages pt-2 pt-sm-5 pb-4 pb-sm-5 position-relative">
          <Container>
            <Outlet />
          </Container>
        </div>
        <footer className="footer footer-alt fw-medium">
          <span className="bg-body">
            {new Date().getFullYear()} © CRM -
            <a
              href="http://3.6.23.174/"
              rel="noreferrer"
              target="_blank"
              className="bg-body"
            >
              {" "}
              Osmocrm.com
            </a>
          </span>
        </footer>
      </div>
    ) : (<Fragment></Fragment>)
  );
};

export default FrontLayout;
