// package
import { Fragment, useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Form,
  OverlayTrigger,
  Tooltip,
  Button,
  InputGroup,
  Image,
  Modal,
  Spinner
} from "react-bootstrap";
import { Link } from "react-router-dom";
import AmlHashForm from "aml-hash-form";
import PhoneInput from "react-phone-input-2";
import { DatePicker } from "rsuite";
import isAfter from 'date-fns/isBefore';
import "react-time-picker-typescript/dist/style.css";
import "rsuite/dist/rsuite-no-reset.min.css";

// component
import ProfileUpload from "elements/System/ProfileUpload";
import AgStatus from "elements/System/AgStatus";
import RoleModal from "elements/System/RoleModal";
import FormSelect from "elements/System/FormSelect";
import { common, api, config } from "includes";

export default function UserProfile(props: any) {
  // const
  const { view, userId, module, refresh, close, add } = props;
  const authData = common.getAuth();
  // state
  const [process, setProcess] = useState<any>({ result: 100 });
  const [submit, setSubmit] = useState<any>("");
  const [popup, setPopup] = useState<String>("");
  const [resultList, setResultList] = useState<any>({});
  const [utils, setUtils] = useState({
    country: "yes",
    state: "empty",
    city: "empty",
    old_password: null,
    new_password: null,
    readMore: false,
    aboutMore: false,
    upload: false,
    password: "",
    showPwd: false,
    showConfPwd: false,
    userEdit: false
  });

  // form
  const formFieldsA = {
    old_password: {
      validate: [
        "req#Current Password is required!",
        "min:8#Current Password should be minimum 8 Digits",
        "max:15#Current Password should be maximum 15 Digits",
      ],
    },
    new_password: {
      validate: [
        "req#New Password is required!",
        "min:8#New Password should be minimum 8 Digits",
        "max:15#New Password should be maximum 15 Digits",
      ],
    },
  };
  const {
    fields: fieldsA,
    errors: errorsA,
    setMultiValue: setMultiValueA,
    handleChange: handleChangeA,
    handleSubmit: handleSubmitA,
    handleReset: handleResetA,
  } = AmlHashForm(formFieldsA);

  const formFieldsB = {
    salutation: { value: "Mr" },
    first_name: { validate: ["req#First Name  is required!"] },
    last_name: {},
    gender: { validate: [], obj: "value" },
    address: {},
    email: { validate: ["req#Email  is required!"] },
    role: { validate: ["objValReq#Role is required!"], obj: "value" },
    permission: {
      validate: ["objValReq#Please select permission"],
      obj: "value",
    },
    phone: {},
    marital_status: {},
    // hired_date: {},
    // confirm_date: {},
    // notice_date: {},
    country: { validate: [], obj: "value" },
    state: { validate: [], obj: "value" },
    city: { validate: [], obj: "value" },
    postal_code: {},
    birth_date: {},
  };

  const {
    fields: fieldsB,
    errors: errorsB,
    setValue: setValueB,
    handleReset: handleResetB,
    setMultiValue: setMultiValueB,
    handleChange: handleChangeB,
    handleSubmit: handleSubmitB,
  } = AmlHashForm(formFieldsB);

  // effect
  useEffect(() => {
    if (props?.userId) {
      processState("result", 100)
      getUser(props?.userId);
    } else {
      processState("result", 204);
    }
  }, [props?.userId]);

  useEffect(() => {
    if (add) {
      setMultiValueB({
        salutation: "Mr",
        first_name: "",
        last_name: "",
        gender: null,
        birth_date: null,
        marital_status: null,
        email: "",
        address: "",
        country: null,
        state: null,
        city: null,
        postal_code: "",
        description: "",
        phone: "",
        role: null,
        permission: null,
        avatar: null,
      });
      handleResetB();
    }
  }, [add]);

  useEffect(() => {
    if (add) {
      let password = common.generatePassword();
      utilsState("password", password);
    }
  }, [add]);

  // api
  const getUser = (userId: any) => {
    let data: any = {
      url: "/user/read",
      query: `?type=sp&fields=id,first_name,last_name,address,avatar,birth_date,city,country,description,email,gender,marital_status,permission,phone,postal_code,role,salutation,state,is_admin,status&query=id=${userId}&join=&limit=1&offset=0&order=id DESC`,
      method: "GET",
    };
    api.call(data, (res: any) => {
      if (res.status === 200) {
        setResultList(res.data);
      }
      processState("result", res.status);
    });
  };

  const onSubmitA = (e: any) => {
    e.preventDefault();
    let isValid = handleSubmitA();
    if (isValid) {
      setSubmit("submitA");

      let data = {
        url: "/user/update/password",
        method: "PUT",
        body: fieldsA,
      };
      api.call(data, (response: any) => {
        if (response.status === 200) {
          common.notify("S", response.message);
          setPopup("");
          common.logout();
        } else if (response.status === 400) {
          if (response.code == "password_wrong") {
            setUtils((prev) => ({
              ...prev,
              old_password: response.message,
              new_password: null,
            }));
          } else {
            setUtils((prev) => ({
              ...prev,
              old_password: null,
              new_password: response.message,
            }));
          }
        } else {
          processState("result", response.status);
        }


        setSubmit("");
      });
    }
  };

  const onActionClick = (action: string) => {
    if (action === "ReInvite") {
      setSubmit("submitC");

      let data: any = {
        url: "/user/invite/",
        query: userId,
        method: "POST",
      };

      api.call(data, (res: any) => {
        if (res.status === 200) {
          common.notify("S", res.message);
          setSubmit("");
          refresh();
        } else {
          common.notify("E", res.message);
        }
      });
    } else if (action === "Deactivate") {
      setSubmit("submitD");

      let data: any = {
        url: "/user/update/status",
        method: "PUT",
        body: { id: userId, status: resultList.status == "Active" ? "Inactive" : "Active" },
      };

      api.call(data, (res: any) => {
        if (res.status === 200) {
          common.notify("S", res.message);
          setSubmit("");
          refresh();
        } else {
          common.notify("E", res.message);
        }
      });
    }
  };

  const addUser = () => {
    let payload = common.formPayload(fieldsB, [
      "phone",
      "address",
      "salutation",
    ]);
    payload.salutation = payload.salutation || "Mr";
    payload.password = utils.password;

    let data = {
      url: "/user/create",
      method: "POST",
      body: payload,
    };
    api.call(data, (res: any) => {
      if (res.status == 200) {
        common.notify("S", res.message);
        if (module != "MyProfile") {
          refresh();
        }
        close();
      } else {
        common.notify("E", res.message);
      }
      setSubmit("");
    });
  };

  const editUser = () => {
    setSubmit("submitB");
    let payload = common.formPayload(fieldsB, [
      "phone",
      "address",
      "salutation",
    ]);
    payload.salutation = payload.salutation || "Mr";
    delete payload.email;

    payload.avatar = resultList.avatar || null;

    let data = {
      url: "/user/update/",
      query: `${userId}`,
      method: "PUT",
      body: payload,
    };
    api.call(data, (res: any) => {
      if (res.status == 200) {
        common.notify("S", res.message);
        if (module != "MyProfile") {
          refresh();
        } else {
          processState("result", 100);
          getUser(userId);
          utilsState("userEdit", false);
        }
      } else {
        common.notify("E", res.message);
      }
      setSubmit("");
    });
  };

  const profileImageUpdate = (img: any) => {
    let payload = {
      avatar: img,
    };
    let data = {
      url: "/user/update/",
      query: `${userId}`,
      method: "PUT",
      body: payload,
    };
    api.call(data, (res: any) => {
      if (res.status == 200) {
        setResultList((prev: any) => ({
          ...prev,
          avatar: img
        }));
      } else {
        common.notify("E", res.message);
      }
    });
  }

  // events
  const changePassword = (e: any) => {
    e.preventDefault();
    setMultiValueA({
      old_password: "",
      new_password: "",
    });
    handleResetA();
    setUtils((prev: any) => ({
      ...prev,
      old_password: null,
      new_password: null,
    }));
    setPopup("password");
  };

  const setParent = (data: any) => {
    setMultiValueB({
      role: { [data.id]: data.name },
    });
    setPopup("edit");
  };

  const onEdit = () => {
    utilsState("userEdit", true);

    setMultiValueB({
      salutation: resultList.salutation || "Mr",
      first_name: resultList.first_name || "",
      last_name: resultList.last_name || "",
      email: resultList.email || "",
      gender: resultList.gender,
      birth_date: resultList.birth_date || null,
      marital_status: resultList.marital_status,
      address: resultList.address || "",
      country: resultList.country || null,
      state: resultList.state || null,
      city: resultList.city || null,
      postal_code: resultList.postal_code || "",
      avatar: resultList.avatar || null,
      description: resultList.description || "",
      phone: resultList.phone || null,
      role: resultList.role,
      permission: resultList.permission
    });
    setUtils((prev: any) => ({
      ...prev,
      country: "yes",
      state: resultList.country != null ? "yes" : "empty",
      city: resultList.state != null ? "yes" : "empty",
    }));
    handleResetB();
  };

  const onSubmitB = () => {
    let isValid = handleSubmitB();
    if (isValid) {
      setSubmit("submitB");
      if (!add) {
        editUser();
      } else {
        addUser();
      }
    }
  };

  const imageDelete = () => {
    profileImageUpdate("");
  };

  // callback
  const onAgCallBack = (status: any) => {
    getUser(userId);
  };

  // support
  const processState = (label: any, value: any) => {
    setProcess((prev: any) => ({
      ...prev,
      [label]: value
    }));
  }

  const utilsState = (label: any, value: any) => {
    setUtils((prev: any) => ({
      ...prev,
      [label]: value
    }));
  }

  // render
  return (
    <Fragment>
      {process.result != 200 ? (
        <Card>
          <Card.Body className="py-1 px-3 border-bottom border-light">
            <AgStatus
              message={{
                100: "Loading Profile...Please wait.",
                204: "No users found",
              }}
              process={process.result}
              size="m"
              img="nousers.svg"
              onAgCallBack={onAgCallBack}
            />
          </Card.Body>
        </Card>
      ) : (
        process.result == 200 && (
          <Row>
            {view == "page" && (
              <Col xl={4} lg={4}>
                <Card className="text-center">
                  <Card.Body>
                    <ProfileUpload
                      src={resultList.avatar}
                      onUpdate={profileImageUpdate}
                      onDelete={imageDelete}
                      width={"150px"}
                      height={"150px"}
                      className="col-md-12 d-flex justify-content-center"
                    />

                    <h4 className="mb-1 mt-2">
                      {resultList.first_name != null ? (
                        <>
                          {resultList.first_name}
                          {"  "}
                          {resultList.is_admin && (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip id="">Super Admin</Tooltip>}
                            >
                              <Image
                                src={common.loadImg("svg/verified-badge.svg")}
                                alt="verified"
                                height="20"
                              />
                            </OverlayTrigger>
                          )}
                        </>
                      ) : (
                        <>
                          {resultList.first_name}{" "}
                          {resultList.is_admin && (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip id="">Super Admin</Tooltip>}
                            >
                              <Image
                                src={common.loadImg("svg/verified-badge.svg")}
                                alt="verified"
                                height="20"
                              />
                            </OverlayTrigger>
                          )}
                        </>
                      )}
                    </h4>
                    <p className="text-muted">
                      {resultList.role_name != null ? resultList.role_name : ""}
                    </p>
                    <div className="text-start mt-3">
                      <p className="text-muted mb-2">
                        <strong>Email :</strong>{" "}
                        <span className="ms-2 ">
                          {resultList.email ? (
                            <a
                              href={`mailto:${resultList.email}`}
                              target="_blank"
                              className="text-muted"
                            >
                              {resultList.email}
                            </a>
                          ) : (
                            "-"
                          )}
                        </span>
                      </p>

                      <p className="text-muted mb-2">
                        <strong>Phone :</strong>
                        <span className="ms-2">
                          {resultList.phone ? (
                            <a
                              href={`tel:+${resultList.phone}`}
                              target="_blank"
                              className="text-muted"
                            >
                              +{resultList.phone}
                            </a>
                          ) : (
                            "-"
                          )}
                        </span>
                      </p>

                      <p className="text-muted mb-2">
                        <strong>Location :</strong>{" "}
                        <span className="ms-2">
                          {common.readable(resultList.country)}
                        </span>
                      </p>

                      <p
                        className={
                          utils.aboutMore
                            ? "text-muted fs-14 full-description mb-0"
                            : "text-muted fs-14 short-description mb-0"
                        }
                      >
                        <strong>About Me :</strong>{" "}
                        {resultList.description || "-"}
                      </p>
                      {resultList.description &&
                        resultList.description.length > 100 && (
                          <Link
                            to="#"
                            className="text-muted fw-bold fs-13"
                            onClick={() => utilsState("aboutMore", !utils.aboutMore)}
                          >
                            {!utils.aboutMore ? "Read more" : "Show Less"}
                          </Link>
                        )}
                    </div>

                    <ul className="social-list list-inline mt-3 mb-0">
                      <li className="list-inline-item">
                        <Link
                          to="#"
                          className="social-list-item border-primary text-primary"
                        >
                          <i className="ri-facebook-circle-fill" />
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link
                          to="#"
                          className="social-list-item border-danger text-danger"
                        >
                          <i className="ri-google-fill" />
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link
                          to="#"
                          className="social-list-item border-info text-info"
                        >
                          <i className="ri-twitter-fill" />
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <Link
                          to="#"
                          className="social-list-item border-secondary text-secondary"
                        >
                          <i className="ri-github-fill" />
                        </Link>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              </Col>
            )}
            <Col xl={view == "page" ? 8 : 12} lg={view == "page" ? 8 : 12}>
              <Card>
                <Card.Body className="py-1 px-3 border-bottom border-light">
                  {["card"].includes(view) && (
                    <div
                      className={`d-flex align-items-start jusfity-content-between ${view == "profile" ? "mb-4" : ""
                        }`}
                    >
                      <div className="d-flex align-items-center gap-3 w-50">
                        <div>
                          <Image
                            src={common.loadImg("users/avatar-2.jpg")}
                            className="rounded-circle avatar-xxl img-thumbnail"
                            alt="profile-avatar"
                          />
                        </div>

                        <div>
                          <h4 className="mb-1 mt-2">
                            {`${resultList.first_name} ${resultList.last_name || " "
                              }`}
                          </h4>
                          <p className="text-muted mb-0">
                            <i className="ri-smartphone-line" />
                            <span className="ms-2">
                              {resultList.phone ? (
                                <a
                                  href={`tel:+${resultList.phone}`}
                                  target="_blank"
                                  className="text-muted"
                                >
                                  +{resultList.phone}
                                </a>
                              ) : (
                                "-"
                              )}
                            </span>
                          </p>
                          <p className="text-muted mb-0">
                            <i className="ri-mail-line" />
                            <span className="ms-2 ">
                              {resultList.email ? (
                                <a
                                  href={`mailto:${resultList.email}`}
                                  target="_blank"
                                  className="text-muted"
                                >
                                  {resultList.email}
                                </a>
                              ) : (
                                "-"
                              )}
                            </span>
                          </p>
                          <p className="text-muted mb-0">
                            <i className="ri-map-pin-line" />
                            <span className="ms-2">
                              {common.readable(resultList.country)}
                            </span>
                          </p>
                          <div className="d-flex justify-content-start">
                            <ul className="social-list list-inline mt-1 mb-0 text-center">
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item border-primary text-primary"
                                >
                                  <i className="ri-facebook-circle-fill" />
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item border-danger text-danger"
                                >
                                  <i className="ri-google-fill" />
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item border-info text-info"
                                >
                                  <i className="ri-twitter-fill" />
                                </Link>
                              </li>
                              <li className="list-inline-item">
                                <Link
                                  to="#"
                                  className="social-list-item border-secondary text-secondary"
                                >
                                  <i className="ri-github-fill" />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="d-flex justify-content-end text-center w-50">
                        <Button variant="success" onClick={() => onEdit()}>
                          <i className="ri-edit-line" /> Submit
                        </Button>
                      </div>
                    </div>
                  )}
                  {["page", "profile"].includes(view) && (
                    <div
                      className={`d-flex align-items-center justify-content-between`}
                    >
                      <div className="float-start">
                        <label className="form-check-label fs-16">
                          Personal Details
                        </label>
                      </div>

                      <div id="tooltips-container">
                        {!(
                          module == "Edit" &&
                          !authData?.is_admin &&
                          resultList.is_admin
                        ) && (
                            <OverlayTrigger
                              placement="bottom"
                              overlay={<Tooltip id="">Edit</Tooltip>}
                            >
                              <Button
                                variant="no"
                                // to="#"
                                className={
                                  submit === "submitB" ? "btn-action-disable" : ""
                                }
                                onClick={() => onEdit()}
                              >
                                <i
                                  className="ri-edit-line"
                                  data-bs-container="#tooltips-container"
                                  data-bs-toggle="tooltip"
                                />
                              </Button>
                            </OverlayTrigger>
                          )}
                        {module != "MyProfile" && (
                          <Fragment>
                            {!resultList.is_admin &&
                              resultList.status == "NotVerified" && (
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={<Tooltip id="">ReInvite</Tooltip>}
                                >
                                  <Button
                                    variant="no"
                                    // to="#"
                                    className={
                                      submit === "submitC" ? "btn-action-disable" : ""
                                    }
                                    onClick={() => onActionClick("ReInvite")}
                                  >
                                    <i
                                      className="ri-mail-open-line"
                                      data-bs-container="#tooltips-container"
                                      data-bs-toggle="tooltip"
                                    />
                                  </Button>
                                </OverlayTrigger>
                              )}
                            {!resultList.is_admin &&
                              resultList.status != "NotVerified" && (
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Tooltip id="">
                                      {resultList.status == "Active"
                                        ? "Deactivate"
                                        : "Activate"}
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    variant="no"
                                    // to="#"
                                    className={
                                      submit === "submitD" ? "btn-action-disable" : ""
                                    }
                                    onClick={() => onActionClick("Deactivate")}
                                  >
                                    <i
                                      className={
                                        resultList.status == "Active"
                                          ? "ri-user-unfollow-line"
                                          : "ri-user-follow-line"
                                      }
                                      data-bs-container="#tooltips-container"
                                      data-bs-toggle="tooltip"
                                    />
                                  </Button>
                                </OverlayTrigger>
                              )}
                          </Fragment>
                        )}
                      </div>
                    </div>
                  )}
                </Card.Body>
                {["page", "profile"].includes(view) && (
                  <Card.Body>
                    <h5 className="mb-3 text-uppercase bg-light p-2">
                      <i className="ri-profile-line me-1" /> Personal Info
                    </h5>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>First Name</Form.Label>
                          <p className="text-muted fs-14">
                            {resultList.first_name
                              ? resultList.salutation
                                ? resultList.salutation +
                                " " +
                                resultList.first_name
                                : " " + resultList.first_name
                              : "-"}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Last Name</Form.Label>
                          <p className="text-muted fs-14">
                            {resultList.last_name || "-"}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Email Address</Form.Label>
                          <p className="text-muted fs-14">
                            {resultList.email ? (
                              <a
                                href={`mailto:${resultList.email}`}
                                target="_blank"
                                className="text-muted"
                              >
                                {resultList.email}
                              </a>
                            ) : (
                              "-"
                            )}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Password</Form.Label>
                          <p className="text-muted fs-14 mb-0">********</p>
                          {module == "MyProfile" && (
                            <span className="form-text text-muted">
                              <small>
                                If you want to change password please{" "}
                                <Link to="#" onClick={(e) => changePassword(e)}>
                                  click
                                </Link>{" "}
                                here.
                              </small>
                            </span>
                          )}
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Role</Form.Label>
                          <p className="text-muted fs-14">
                            {common.readable(resultList.role)}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Permission</Form.Label>
                          <p className="text-muted fs-14">
                            {common.readable(resultList.permission)}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Phone</Form.Label>
                          <p className="text-muted fs-14">
                            {resultList.phone ? (
                              <a
                                href={`tel:+${resultList.phone}`}
                                target="_blank"
                                className="text-muted"
                              >
                                +{resultList.phone}
                              </a>
                            ) : (
                              "-"
                            )}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Gender</Form.Label>
                          <p className="text-muted fs-14">
                            {resultList.gender || "-"}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Birth Date</Form.Label>
                          <p className="text-muted fs-14">
                            {resultList.birth_date
                              ? common.formatDate(
                                resultList.birth_date,
                                "YYYY-MM-DD",
                                false
                              )
                              : "-"}
                          </p>
                        </Form.Group>
                      </Col>

                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Marital Status</Form.Label>
                          <p className="text-muted fs-14">
                            {resultList.marital_status || "-"}
                          </p>
                        </Form.Group>
                      </Col>

                      <Col md={12}>
                        <Form.Group className="mb-3">
                          <Form.Label>Bio</Form.Label>
                          <p
                            className={
                              utils.readMore
                                ? "text-muted fs-14 full-description mb-0"
                                : "text-muted fs-14 short-description mb-0"
                            }
                          >
                            {resultList.description || "-"}
                          </p>
                          {resultList.description &&
                            resultList.description.length > 100 && (
                              <Link
                                to="#"
                                className="text-muted fw-bold fs-13"
                                onClick={() => utilsState("readMore", !utils.readMore)}
                              >
                                {!utils.readMore ? "Read more" : "Show Less"}
                              </Link>
                            )}
                        </Form.Group>
                      </Col>
                    </Row>
                    <h5 className="mb-3 text-uppercase bg-light p-2">
                      <i className="ri-building-line me-1" /> Address Info
                    </h5>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Country</Form.Label>
                          <p className="text-muted fs-14">
                            {common.readable(resultList.country)}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>State</Form.Label>
                          <p className="text-muted fs-14">
                            {common.readable(resultList.state)}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>City</Form.Label>
                          <p className="text-muted fs-14">
                            {common.readable(resultList.city)}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Address</Form.Label>
                          <p className="text-muted fs-14">
                            {resultList.address || "-"}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Zip Code</Form.Label>
                          <p className="text-muted fs-14">
                            {resultList.postal_code || "-"}
                          </p>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Card.Body>
                )}
              </Card>
            </Col>
          </Row>
        )
      )}
      <Modal
        show={popup === "password"}
        scrollable={true}
        onHide={() => setPopup("")}
        style={config.elements.modalStyle}
      >
        <Modal.Header closeButton>
          <Modal.Title>Change Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group className={"mb-3"}>
            <Form.Label>Current Password</Form.Label>
            <InputGroup>
              <Form.Control
                type={utils.showPwd ? "text" : "password"}
                isInvalid={
                  errorsA.old_password || utils.old_password != null
                    ? true
                    : false
                }
                name="old_password"
                placeholder="Enter current password"
                value={fieldsA.old_password}
                onChange={handleChangeA}
              />
              <div
                className={`input-group-text input-group-password ${utils.showPwd ? "" : "show-password"
                  }`}
                data-password={utils.showPwd ? "true" : "false"}
              >
                <span
                  className="password-eye"
                  onClick={() => utilsState("showPwd", !utils.showPwd)}
                ></span>
              </div>

              <Form.Control.Feedback type="invalid">
                {utils.old_password != null
                  ? utils.old_password
                  : errorsA.old_password}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group className={"mb-3"}>
            <Form.Label>New Password</Form.Label>
            <InputGroup>
              <Form.Control
                type={utils.showConfPwd ? "text" : "password"}
                isInvalid={
                  errorsA.new_password || utils.new_password != null
                    ? true
                    : false
                }
                name="new_password"
                placeholder="Enter new password"
                value={fieldsA.new_password}
                onChange={handleChangeA}
              />
              <div
                className={`input-group-text input-group-password ${utils.showConfPwd ? "" : "show-password"
                  }`}
                data-password={utils.showConfPwd ? "true" : "false"}
              >
                <span
                  className="password-eye"
                  onClick={() => utilsState("showConfPwd", !utils.showConfPwd)}
                ></span>
              </div>
              <Form.Control.Feedback type="invalid">
                {utils.new_password != null
                  ? utils.new_password
                  : errorsA.new_password}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => {
              setPopup("");
            }}
            disabled={submit === "submitA"}
          >
            <i className="ri-close-line" /> Close
          </Button>
          <Button variant="success" onClick={onSubmitA} disabled={submit === "submitA"}>
            {submit === "submitA" ? (
              <>
                <Spinner className="spinner-border-sm me-1" color="white" />
                Processing...
              </>
            ) : (
              <>
                <i className="ri-save-line" /> Submit
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={add ? add : utils.userEdit}
        size="lg"
        scrollable={true}
        onHide={() => {
          add ? close() : utilsState("userEdit", false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{!add ? "Edit User" : "Add User"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Salutation</Form.Label>
                <FormSelect
                  url="salutation"
                  fields=""
                  query=""
                  order=""
                  value={fieldsB.salutation}
                  fixed={true}
                  multi={false}
                  async={false}
                  limit={10}
                  render={"yes"}
                  onSelect={(data: any) => {
                    setMultiValueB({ salutation: data })
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  id="singleLine"
                  maxLength={100}
                  data-toggle="maxlength"
                  placeholder="Enter first name"
                  name="first_name"
                  value={fieldsB.first_name}
                  onChange={handleChangeB}
                  isInvalid={errorsB.first_name ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {errorsB.first_name}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  name="last_name"
                  id="singleLine"
                  maxLength={100}
                  data-toggle="maxlength"
                  placeholder="Enter last name"
                  value={fieldsB.last_name}
                  onChange={handleChangeB}
                />
              </Form.Group>
            </Col>

            {add && (
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    id="singleLine"
                    placeholder="Enter Email"
                    value={fieldsB.email}
                    onChange={handleChangeB}
                    isInvalid={errorsB.email ? true : false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errorsB.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            )}
            {add && (
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="text"
                    name="passoword"
                    id="singleLine"
                    value={utils.password}
                    readOnly
                  />
                </Form.Group>
              </Col>
            )}
            {module != "MyProfile" &&
              ((add && add == true) ||
                resultList.is_admin === false) && (
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Role</Form.Label>
                    <div className="input-group">
                      <Form.Control
                        type="text"
                        placeholder="Select a role"
                        name="role"
                        id="singleLine"
                        value={common.readable(fieldsB.role)}
                        disabled={true}
                        isInvalid={errorsB.role ? true : false}
                      />
                      <InputGroup.Text
                        onClick={() => setPopup("role")}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="ri-account-circle-line" />
                      </InputGroup.Text>
                    </div>
                    <p className="error-text">{errorsB.role}</p>
                  </Form.Group>
                </Col>
              )}
            {module != "MyProfile" &&
              ((add && add == true) ||
                resultList.is_admin === false) && (
                <Col md={6}>
                  <Form.Group className="mb-3">
                    <Form.Label>Permissions</Form.Label>
                    <FormSelect
                      url="/permission/read"
                      fields="id,name"
                      query=""
                      order="name ASC"
                      value={fieldsB.permission}
                      limit={50}
                      multi={false}
                      ixed={false}
                      render={"yes"}
                      async={false}
                      onSelect={(data: any) =>
                        setMultiValueB({
                          permission: data,
                        })
                      }
                      error={errorsB.permission}
                    />
                    <p className="error-text">{errorsB.permission}</p>
                  </Form.Group>
                </Col>
              )}
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Gender</Form.Label>
                <FormSelect
                  url="gender"
                  fields=""
                  query=""
                  order=""
                  value={fieldsB.gender}
                  fixed={true}
                  multi={false}
                  async={false}
                  limit={10}
                  render={"yes"}
                  onSelect={(data: any) => {
                    setMultiValueB({ gender: data })
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Marital Status</Form.Label>
                <FormSelect
                  url="marital"
                  fields=""
                  query=""
                  order=""
                  value={fieldsB.marital_status}
                  fixed={true}
                  multi={false}
                  async={false}
                  limit={10}
                  render={"yes"}
                  onSelect={(data: any) => {
                    setMultiValueB({ marital_status: data })
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Phone</Form.Label>
                <PhoneInput
                  country={"gb"}
                  value={fieldsB.phone}
                  onChange={(e) => {
                    setValueB("phone", e);
                  }}
                />
                <p className="error-text">
                  {errorsB.phone ? errorsB.phone : ""}
                </p>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Birth date</Form.Label>
                <DatePicker
                  block
                  oneTap
                  showMeridian

                  shouldDisableDate={date => isAfter(new Date(), date)}
                  value={
                    fieldsB.birth_date ? new Date(fieldsB.birth_date) : null
                  }
                  onChange={(date) => {
                    setValueB("birth_date", date);
                  }}
                />
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>Address</Form.Label>
                <Form.Control
                  type="text"
                  id="singleLine"
                  placeholder="Enter Address"
                  name="address"
                  value={fieldsB.address}
                  onChange={handleChangeB}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Country</Form.Label>
                <FormSelect
                  url="/service/world/countries"
                  fields="id,name"
                  query=""
                  order="name ASC"
                  value={fieldsB.country}
                  fixed={false}
                  multi={false}
                  async={true}
                  limit={10}
                  render={utils.country}
                  onSelect={(data: any) => {
                    setMultiValueB({
                      country: data,
                      state: null,
                      city: null,
                    });
                    setUtils((prev: any) => ({
                      ...prev,
                      state: !data ? "empty" : new Date(),
                      city: "empty",
                    }));
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>State</Form.Label>
                <FormSelect
                  url={`/service/world/states`}
                  fields="id,name"
                  order="name ASC"
                  query={`country_id=${fieldsB.country ? Object.keys(fieldsB.country)[0] : ""}`}
                  value={fieldsB.state}
                  fixed={false}
                  multi={false}
                  async={true}
                  limit={10}
                  render={utils.state}
                  onSelect={(data: any) => {
                    setMultiValueB({
                      state: data,
                      city: null,
                    });
                    setUtils((prev: any) => ({
                      ...prev,
                      city: !data ? "empty" : new Date(),
                    }));
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>City</Form.Label>
                <FormSelect
                  url="/service/world/cities"
                  fields="id,name"
                  order="name ASC"
                  query={`state_id=${fieldsB.state ? Object.keys(fieldsB.state)[0] : ""}`}
                  value={fieldsB.city}
                  fixed={false}
                  multi={false}
                  async={true}
                  limit={10}
                  render={utils.city}
                  onSelect={(data: any) => {
                    setMultiValueB({
                      city: data,
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Postal code</Form.Label>
                <Form.Control
                  type="text"
                  id="singleLine"
                  name="postal_code"
                  maxLength={100}
                  data-toggle="maxlength"
                  placeholder="Enter  your postal code"
                  value={fieldsB.postal_code}
                  onChange={handleChangeB}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>About Me</Form.Label>
                <Form.Control
                  as="textarea"
                  id="multiLine"
                  name="description"
                  rows={4}
                  data-toggle="maxlength"
                  maxLength={3000}
                  placeholder="Write something..."
                  value={fieldsB.description}
                  onChange={handleChangeB}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => (add ? close() : utilsState("userEdit", false))}
            disabled={submit === "submitB"}
          >
            Close
          </Button>
          <Button
            variant="success"
            onClick={onSubmitB}
            disabled={submit === "submitB" ? true : false}
          >
            {submit === "submitB" ? (
              <>
                <Spinner className="spinner-border-sm me-1" color="white" />
                Processing...
              </>
            ) : (
              <>
                <i className="ri-save-line" /> Submit
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>

      {popup === "role" && (
        <RoleModal onParentSelect={setParent} closeModal={() => setPopup("edit")} />
      )}
    </Fragment >
  );
}
