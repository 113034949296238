import { AES, enc } from "crypto-js";
import { toast } from "react-toastify";
import moment from "moment";
// includes
import { SideBarType, LayoutWidth } from "includes/types";
import { config } from "includes";

interface ConfigTypes {
  leftSideBarTypes:
    | SideBarType.LEFT_SIDEBAR_TYPE_COMPACT
    | SideBarType.LEFT_SIDEBAR_TYPE_CONDENSED
    | SideBarType.LEFT_SIDEBAR_TYPE_DEFAULT
    | SideBarType.LEFT_SIDEBAR_TYPE_FULL
    | SideBarType.LEFT_SIDEBAR_TYPE_FULLSCREEN
    | SideBarType.LEFT_SIDEBAR_TYPE_HOVER;
}

export const common = {
  loadImg(img: string) {
    let result = require("../assets/images/" + img);
    return result; //.default;
  },

  cloudImg(img: string) {
    let result = img
      ? config.api.cloudImgPath + img
      : common.loadImg("dummy-avatar.jpg");
    return result; //.default;
  },

  openCloudUrl(url: string) {
    window.open(config.api.cloudImgPath + url, "_blank");
  },

  fileName(file: string) {
    let ext = file.substring(file.lastIndexOf("."));
    let fileName = file.split("_").slice(0, -1).join("_") + ext;
    return fileName.substring(fileName.indexOf("/") + 1);
  },

  // local storage
  localSet(key: string, value: any) {
    if (typeof value === "object") {
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      localStorage.setItem(key, value);
    }
  },

  localGet(key: string, json: boolean = false) {
    let value: any = localStorage.getItem(key);
    if (json) {
      value = JSON.parse(value);
    }
    return value;
  },

  localRemove(key: string) {
    localStorage.removeItem(key);
  },

  // local data
  localDataSet(value: any) {
    this.localSet("localData", value);
  },

  localDataGet() {
    let res = this.localGet("localData", true);
    this.localDataRemove();
    return res;
  },

  localDataRemove() {
    return this.localRemove("localData");
  },

  // tempdata
  tempDataSet(value: any) {
    this.localSet("tempData", common.crypt(value, true));
  },

  tempDataGet() {
    let data = this.localGet("tempData");
    if (data) {
      return this.deCrypt(data, true);
    }
    return null;
  },

  tempDataRemove() {
    return this.localRemove("tempData");
  },

  // auth
  checkAuth() {
    let authData = this.localGet("authData");
    if (authData == "" || authData == null) {
      return false;
    } else {
      return !!this.getAuth();
    }
  },

  getAuth() {
    let data = this.localGet("authData");
    return this.deCrypt(data, true);
  },

  // encode decode
  crypt(str: any, json = false) {
    if (json) {
      str = JSON.stringify(str);
    }
    return AES.encrypt(str, config.cryptKey).toString();
  },

  deCrypt(str: any, json = false) {
    try {
      let bytes = AES.decrypt(str, config.cryptKey);
      let data = bytes.toString(enc.Utf8);
      if (data == "") {
        localStorage.clear();
        this.redirect("login");
      } else if (json) {
        return JSON.parse(data);
      } else {
        return data;
      }
    } catch (err) {
      localStorage.clear();
      this.redirect("login");
    }
  },

  // validation
  isEmail(mail: string) {
    return !!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(mail);
  },

  scrollToRef(myRef: any, adjust = 0) {
    window.scroll({
      top: myRef.current.offsetTop - adjust,
      behavior: "smooth",
    });
  },

  scrollTo(to = "") {
    if (to === "top") {
      window.scroll({ top: 0, behavior: "smooth" });
    } else if (to === "bottom") {
      window.scrollTo({
        left: 0,
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  },

  // validate

  validateMobile(number: string) {
    return number.replace(/[^0-9]/g, "");
  },

  // input
  onlyNumbers(str: string) {
    return str.replace(/[^0-9]/g, "");
  },

  onlyFloat(str: string) {
    return str.replace(/[^0-9.]/g, "").replace(/(\..*?)\..*/g, "$1");
  },

  getNumWithComma(str: string) {
    return common.onlyFloat(str).replace(/(\d)(?=(\d{3})+\b)/g, "$1,");
  },

  redirect(url: string = "") {
    window.location.href = config.public_url + url;
  },

  splitArray(array: Array<any>, chunkSize: number) {
    const chunks = Array(Math.ceil(array.length / chunkSize))
      .fill(1)
      .map((_, index) => index * chunkSize)
      .map((begin) => array.slice(begin, begin + chunkSize));
    return chunks;
  },

  getLayoutConfigs(layoutWidth: string) {
    // add property to change in particular layoutWidth
    let config: ConfigTypes = {
      leftSideBarTypes: SideBarType.LEFT_SIDEBAR_TYPE_DEFAULT,
    };

    switch (layoutWidth) {
      case LayoutWidth.LAYOUT_WIDTH_FLUID:
        config.leftSideBarTypes = SideBarType.LEFT_SIDEBAR_TYPE_DEFAULT;
        break;
      case LayoutWidth.LAYOUT_WIDTH_BOXED:
        config.leftSideBarTypes = SideBarType.LEFT_SIDEBAR_TYPE_CONDENSED;
        break;
      default:
        return config;
    }
    return config;
  },

  changeHTMLAttribute(attribute: string, value: string) {
    if (document.body)
      document.getElementsByTagName("html")[0].setAttribute(attribute, value);
  },

  reParse(data: any) {
    return JSON.parse(JSON.stringify(data));
  },

  /**
   * Format date
   */
  formatDate(date: Date, format: string, gmt?: boolean) {
    if (!format) format = "D MMM - h:mm a";
    if (gmt) {
      return moment.utc(date).format(format);
    } else {
      return moment.utc(date).local().format(format);
    }
  },

  randomString() {
    let length = 15;
    let chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  },

  ValidateEmail(mail: any) {
    //let regExp = /^[A-Za-z0-9-+&]+(\.[A-Za-z0-9-+]+)*(\+[A-Za-z0-9-&]+)?@[A-Za-z0-9-.]+(\.\w{2,10})+$/; // allow +&
    let regExp = new RegExp(
      "([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|\"([]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(.[!#-'*+/-9=?A-Z^-~-]+)*|[[\t -Z^-~]*])"
    );
    if (regExp.test(mail)) {
      return true;
    } else {
      return false;
    }
  },

  formPayload(fields: any, nullExlcudeFields: Array<String>) {
    let data: any = {};

    for (const key in fields) {
      let value: any = fields[key];
      if (value instanceof Date) {
        value = common.formatDate(value, "YYYY-MM-DDTHH:mm:ssZ", true);
      } else if (!value && nullExlcudeFields.includes(key)) {
        value = "";
      }
      data[key] = value;
    }
    return data;
  },

  logout() {
    localStorage.clear();
    this.redirect("/login");
  },

  generatePassword() {
    const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    let password = "";
    for (let i = 0; i < 10; i++) {
      const randomIndex = Math.floor(Math.random() * chars.length);
      password += chars[randomIndex];
    }
    return password;
  },

  notify(type: string, msg: string) {
    toast.dismiss();
    switch (type) {
      case "S":
        toast.success(msg, config.elements.toast);
        break;
      case "W":
        toast.warning(msg, config.elements.toast);
        break;
      case "E":
      case "E":
        toast.error(msg, config.elements.toast);
        break;
      case "I":
        toast.info(msg, config.elements.toast);
        break;
      case "M":
        toast(msg, config.elements.toast);
        break;
    }
  },

  compare(a: any, b: any) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  },

  readable(data: any = {}) {
    return data ? Object.values(data).join(", ") : "-";
  },
};
