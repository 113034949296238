import { Fragment, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import BreadCrumb from "elements/System/BreadCrumb";

export default function Roles() {
  const [isMenuRequired, setIsMenuRequired] = useState<boolean>(true);
  const [disableNode, setDisableNode] = useState<any>("Financier");
  const treeData = {
    name: "CEO",
    children: [
      {
        name: "Financier",
        children: [
          {
            name: "Financier Assistant",
          },
          {
            name: "Financier Writer",
          },
        ],
      },
      {
        name: "Managing Director",
        children: [
          {
            name: "Assistant Manager",
          },
          {
            name: "Sales Manager",
          },
        ],
      },
    ],
  };

  const onRoleSelect = (e: any, data: any) => {
    e.preventDefault();
    console.log("data", data);

    if (!isMenuRequired) {
      //props.onRoleSelect(data)
    }
  };

  const renderMenu = () => {
    return (
      <span className="roles-tree-options ms-2">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="">Users</Tooltip>}
        >
          <Button variant="light" size="sm" className="position-relative me-1">
            <i className="ri-group-line" />
            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
              8
            </span>
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="">Add</Tooltip>}
        >
          <Button variant="light" size="sm" className="me-1">
            <i className="ri-add-line" />
          </Button>
        </OverlayTrigger>

        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id="">Edit</Tooltip>}
        >
          <Button variant="light" size="sm" className="me-1">
            <i className="ri-edit-line" />
          </Button>
        </OverlayTrigger>
      </span>
    );
  };

  const renderChild = (data: any) => {
    return (
      <>
        <ul>
          {data?.children?.length > 0 &&
            data?.children?.map((obj: any, idx: number) => {
              return (
                <li
                  key={obj?.name + idx}
                >
                  <details open>
                    <summary className="role-row">
                      <span
                        className={
                          disableNode === obj?.name ? "node-disabled" : ""
                        }
                      >
                        {obj?.name}
                      </span>
                      {isMenuRequired && renderMenu()}
                    </summary>
                    {renderChild(obj)}
                  </details>
                </li>
              );
            })}
        </ul>
      </>
    );
  };

  return (
    <Fragment>
      <>
        <BreadCrumb
          breadCrumbItems={[
            { label: "Settings", path: "/settings/home" },
            { label: "Roles", path: "/roles/home", active: true },
          ]}
          title={"Roles"}
        />
        <Row>
          <Col xl={12} lg={12}>
            <Card>
              <Card.Body>
                {treeData && (
                  <ul className="tree">
                    <li
                      className={disableNode === treeData?.name ? "" : ""}
                    >
                      <details open>
                        <summary className="role-row">
                          <span>{treeData.name}</span>
                          {isMenuRequired && renderMenu()}
                        </summary>
                        {renderChild(treeData)}
                      </details>
                    </li>
                  </ul>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    </Fragment>
  );
}