// package
import { useEffect, useState, Fragment, useRef } from "react";
import {
  Button,
  Card,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
  Form,
} from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";
import { Link, useNavigate } from "react-router-dom";
import { DatePicker } from "rsuite";
import Select from "react-select";
import { useParams } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import Slider from "react-slick";
// component
import { api, common } from "includes";
import DataSelect from "elements/System/DataSelect";
import AgStatus from "elements/System/AgStatus";
import "react-phone-input-2/lib/style.css";

function DynamicForm(props: any) {
  // const
  const { moduleId, dataId }: any = useParams();
  const navigate = useNavigate();
  let fieldCount = 0;
  // state
  const myRefs: any = useRef([]);
  const propsDefault: any = {};
  const propsDisabled: any = {};
  const [isMount, setIsMount] = useState<any>(false);
  const [isFormSubmit, setFormSubmit] = useState<any>(false);
  const [isSubmit, setIsSubmit] = useState<any>(false);
  const [status, setStatus] = useState<any>(100);
  const [data, setData] = useState<any>([]);
  const [moduleKey, setModuleKey] = useState<any>("");
  const [fieldTypes] = useState<any>({
    default: ["text", "long_text", "email", "phone", "number", "url"],
    text: ["text", "long_text", "email", "url"],
    bool: ["bool"],
    date: ["date", "date_time"],
    select: ["pick_list", "lookup"],
    file: ["imageUpload", "fileUpload"],
  });
  const [fieldIndex, setFieldIndex] = useState<any>(null);
  const [stage, setStage] = useState<any>([]);
  const [selectedStage, setSelectedStage] = useState<any>(null);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // effect
  useEffect(() => {
    setStatus(100);
    getColumnsData();
  }, []);

  // api
  const getColumnsData = () => {
    let data: any = {
      url: `/module/read?type=sp&fields=id,name,data,key,stage&query=id=${moduleId}&join=&limit=30&offset=0&order=sort ASC`,
      method: "GET",
      auth: "token",
    };
    api.call(data, (res: any) => {
      if (res.status === 200) {
        let result: any = res.data;
        setModuleKey(result.key);
        setStage(result.stage);
        if (dataId > 0) {
          setData(result.data);
          getEditData(result.key);
        } else {
          setSelectedStage(result.stage[0]);
          setData(setFormData(result.data));
          setStatus(200);
          setIsMount(true);
        }
      } else {
        setStatus(res.status);
      }
    });
  };

  const getEditData = (moduleKey: any) => {
    let data: any = {
      url: "/data/read/",
      query:
        moduleKey +
        "/" +
        moduleId +
        "?query=id=" +
        dataId +
        "&type=sp&fields=*&limit=1&offset=0&order=id ASC&join=",
      method: "GET",
    };
    api.call(data, (res: any) => {
      if (res.status === 200) {
        let result: any = res.data;
        if (result.conversion) {
          navigate("/data/detail/" + moduleId + "/" + dataId);
        }
        setSelectedStage(result.stage);
        setData((oldData: any) => {
          let newData = reParse(oldData);
          newData.fields.map((item: any) => {
            let value = result[item.key];
            value = value === null ? "" : value;
            if (value) {
              if (item.format === "currency") {
                value = common
                  .onlyFloat(String(value))
                  .replace(/(\d)(?=(\d{3})+\b)/g, "$1,");
              } else if (fieldTypes.select.includes(item.type)) { // lookup & pick_list
                let sel: any = [];
                Object.keys(value).map((i: any) => {
                  sel.push({ value: i, label: value[i] });
                });
                value = sel;
              }
            }
            // default
            if (!value && item.key in propsDefault) {
              value = propsDefault[item.key];
            }
            item.value = value;
            // disabled
            if (item.key in propsDisabled) {
              item.disabled = propsDisabled[item.key];
            }
          });
          return newData;
        });
        setIsMount(true);
      }
      setStatus(res.status);
    });
  };

  const onSubmit = () => {
    setFormSubmit(true);
    let allow = validateForm();
    if (allow) {
      setIsSubmit(true);
      // fields
      let fields: any = {
        module_id: moduleId,
      };

      data.fields
        .filter((i: any) => i.store === true)
        .map((item: any) => {
          let value = item.value;
          if (value) {
            if (fieldTypes.date.includes(item.type)) {
              value = common.formatDate(value, "YYYY-MM-DDTHH:mm:ssZ", true);
            } else if (item.format === "currency") {
              value = String(value).replace(/,/g, "");
            } else if (fieldTypes.select.includes(item.type)) {
              let sel: any = {};
              if (value?.value) {
                sel[value.value] = value.label;
              } else if (value?.length > 0) {
                value.map((i: any) => {
                  sel[i.value] = i.label;
                });
              } else {
                sel = null;
              }
              value = sel;
            }
          }
          // text
          fields[item.key] = fieldTypes.text.includes(item.type)
            ? value || ""
            : value || null;
        });

      // stages
      if (selectedStage?.name) {
        fields.stage = selectedStage;
      }

      // api
      let payload = {
        url: "/data/",
        query:
          dataId === "0"
            ? "create/" + moduleKey
            : "update/" + moduleKey + "/" + dataId,
        method: dataId === "0" ? "POST" : "PUT",
        body: fields,
      };

      api.call(payload, (res: any) => {
        common.notify("S", res.message);
        if (dataId === "0") {
          navigate("/data/list/" + moduleId);
        } else {
          navigate("/data/detail/" + moduleId + "/" + dataId);
        }
      });
    }
  };

  // event
  const onFieldChange = (e: any, index: any) => {
    let newData = reParse(data);
    let field = newData.fields[index];
    let value = "";
    if (fieldTypes.default.includes(field.type) && field.type == "phone") {
      value = e;
    } else if (fieldTypes.default.includes(field.type)) {
      value = e.target.value;
    } else if (fieldTypes.bool.includes(field.type)) {
      value = e.target.checked;
    } else if (fieldTypes.date.includes(field.type)) {
      value = e;
    } else if (fieldTypes.select.includes(field.type)) {
      value = e ? e : "";
    }
    value = validateType(index, value);
    field.error = validateField(index, value);
    field.value = value;
    field.touched = true;
    setData(newData);
    setFieldIndex(index);
  };

  const onImageChange = (e: any, index: any) => {
    let file = "";
    if (e.target.files) {
      file = e.target.files[0];
      if (!file) {
        file = "";
      }
      let newData = reParse(data);
      let field = newData.fields[index];
      field.value = file;
      field.error = validateField(index, file);
      field.touched = true;
      setData(newData);
    }
  };

  const onFileChange = (e: any, index: any) => {};

  const onCancel = () => {
    if (dataId > 0) {
      navigate("/data/detail/" + moduleId + "/" + dataId);
    } else {
      navigate("/data/list/" + moduleId);
    }
  };

  // support
  const setFormData = (form: any) => {
    form.fields.map((item: any) => {
      // default
      if (item.key in propsDefault) {
        item.value = propsDefault[item.key];
      }
      // disabled
      if (item.key in propsDisabled) {
        item.disabled = propsDisabled[item.key];
      }
    });
    return form;
  };

  const getFormFields = (sKey: any) => {
    let nData = reParse(data);
    let sId = data.sections[sKey].id;
    let quickList = [true, false];
    /* if (props.quick) {
      quickList = [true];
    } */
    return nData.fields.filter(
      (item: any) =>
        item.layout === sId &&
        item.status === "use" &&
        item.visibility &&
        item.store &&
        quickList.includes(item.quick)
    );
  };

  const validateForm = () => {
    let allow = true;
    let focus = true;
    let newData = reParse(data);
    newData.fields.map((item: any) => {
      let error = "";
      if (
        item.required &&
        (item.value === "" || item.value === null || item.value?.length === 0)
      ) {
        error = `${item.label} is required`;
        allow = false;
      } else if (
        item.type === "email" &&
        item.value !== "" &&
        !common.ValidateEmail(item.value)
      ) {
        error = `Please enter a valid email for ${item.label}`;
        allow = false;
      }
      if (error !== "" && focus) {
        try {
          if (fieldTypes.date.includes(item.type)) {
            myRefs.current[item.index].setFocus(true);
          } else {
            myRefs.current[item.index].focus();
          }
        } catch (e) {
          //alert(e);
        }
        focus = false;
      }
      item.error = error;
    });

    if (!allow) {
      setData(newData);
    }
    return allow;
  };

  const validateField = (index: any, value: any) => {
    let error = "";
    if (isFormSubmit) {
      let newData = reParse(data);
      let field = newData.fields[index];
      if (field.required && (value === "" || value === null)) {
        error = `${field.label} is required`;
      } else if (
        field.type === "email" &&
        value !== "" &&
        !common.ValidateEmail(value)
      ) {
        error = `Please enter a valid email`;
      }
    }
    return error;
  };

  const validateType = (index: any, value: any) => {
    let newData = reParse(data);
    let field = newData.fields[index];
    if (field.type === "phone") {
      return common.validateMobile(value);
    } else if (field.type === "number") {
      if (field.format === "numeric") {
        return common.onlyNumbers(value);
      } else if (field.format === "decimal") {
        return common.onlyFloat(value);
      } else if (field.format == "currency") {
        return common.onlyFloat(value).replace(/(\d)(?=(\d{3})+\b)/g, "$1,");
      }
    }
    return value;
  };

  const reParse = (data: any) => {
    return JSON.parse(JSON.stringify(data));
  };

  const labelValue = (data: any) => {
    if (typeof data == "object") {
      return data
        ? data.map((item: any) => ({ label: item, value: item }))
        : "";
    } else {
      return data ? { label: data, value: data } : "";
    }
  };

  // callback
  const onAgCallBack = (status: any) => {
    if (status == 400) {
      navigate("/data/list/" + moduleId);
    } else {
      getColumnsData();
    }
  };

  // render
  return (
    <Fragment>
      {status == 200 && (
        <Row className="mt-1">
          <Col xl={12} lg={12}>
            <Card>
              <Card.Body className="py-1 px-1 border-bottom border-light">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="float-start">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="back">Back</Tooltip>}
                    >
                      <Link
                        to="#"
                        onClick={() => onCancel()}
                        className="text-reset fs-20 p-1 d-inline-block"
                      >
                        <i
                          className="ri-arrow-left-s-line pe-2"
                          data-bs-container="#tooltips-container"
                          data-bs-toggle="tooltip"
                        />
                      </Link>
                    </OverlayTrigger>
                    <label className="form-check-label fs-16">
                      {dataId === "0" ? "Add" : "Edit"} {" Form"}
                    </label>
                  </div>
                  <div id="tooltips-container d-flex align-items-center">
                    <div className="d-flex flex-wrap gap-2">
                      <Button
                        variant="secondary"
                        onClick={() => onCancel()}
                        disabled={isSubmit}
                      >
                        <i className=" ri-arrow-go-back-line me-1" />
                        Cancel
                      </Button>

                      <Button
                        variant="primary"
                        onClick={() => onSubmit()}
                        disabled={isSubmit}
                      >
                        {isSubmit ? (
                          <>
                            <Spinner
                              className="spinner-border-sm me-1"
                              color="white"
                            />
                            Processing...
                          </>
                        ) : (
                          <>
                            <i className="ri-save-line me-1" />
                            Submit
                            {/* {dataId === "0" ? "Create" : "Update"} */}
                          </>
                        )}
                      </Button>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {status != 200 ? (
        <AgStatus
          process={status}
          message={{
            100: "Loading form.Please wait...",
            400: "You're trying to access invalid records.",
          }}
          button={{ 400: "Go to Module" }}
          onAgCallBack={onAgCallBack}
          size="l"
          img="modules.svg"
        />
      ) : (
        <Fragment>
          <Row className="mt-1">
            <Col xl={12} lg={12}>
              <Card>
                <Card.Body>
                  <h5 className="mb-3 text-uppercase bg-light p-2">
                    <i className="ri-hotel-line me-1" />
                    Stage
                  </h5>
                  <div className="slider-container status-slider">
                    <Slider {...settings}>
                      {stage.map((obj: any, idx: number) => {
                        return (
                          // <div className={`status-card ${selectedStage?.name === obj.name ? "active" : ""}`} key={idx} onClick={() => setSelectedStage(obj)}>
                          //   {obj.name}
                          // </div>
                          <div
                            className={`status-card ${
                              selectedStage?.name === obj.name ? "active" : ""
                            }`}
                            key={idx}
                            onClick={() => setSelectedStage(obj)}
                          >
                            {obj.category == "Won" ? (
                              <i className="ri-thumb-up-fill text-success mx-1" />
                            ) : (
                              obj.category == "Lost" && (
                                <i className="ri-thumb-down-fill text-danger mx-1" />
                              )
                            )}
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip id="">{`${obj.category} : ${obj.probability} %`}</Tooltip>
                              }
                            >
                              <p className="status-card-txt">{obj.name}</p>
                            </OverlayTrigger>
                          </div>
                        );
                      })}
                    </Slider>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card>
                <Card.Body>
                  {Object.keys(data.sections).map((sItem, sKey) => {
                    let sData = data.sections[sItem];
                    let fields = getFormFields(sKey);
                    if (fields.length > 0) {
                      ++fieldCount;
                      return (
                        <div key={sKey}>
                          <h5 className="mb-3 text-uppercase bg-light p-1">
                            <i className="ri-survey-line me-1" /> {sData.label}
                          </h5>
                          <Row>
                            {fields.map((fItem: any, fKey: any) => {
                              return (
                                <Col
                                  sm={sData.layout === "double" ? 6 : 12}
                                  key={fKey}
                                >
                                  <Form.Group className="mb-3">
                                    <div className="text-dark-emphasis fs-14 pb-1">
                                      {" "}
                                      {fItem.label}
                                      {fItem.required && (
                                        <span className="fs-11 text-danger">
                                          *
                                        </span>
                                      )}
                                    </div>
                                    {fItem.type == "text" ? (
                                      <Form.Control
                                        ref={(el: any) =>
                                          (myRefs.current[fItem.index] = el)
                                        }
                                        type="text"
                                        className={
                                          fItem?.error ? "required-input" : ""
                                        }
                                        maxLength={255}
                                        data-toggle="maxlength"
                                        placeholder={`Enter ${fItem.label}`}
                                        value={fItem.value}
                                        isInvalid={fItem?.error ? true : false}
                                        onChange={(e) =>
                                          onFieldChange(e, fItem.index)
                                        }
                                      />
                                    ) : fItem.type == "long_text" ? (
                                      <Form.Control
                                        as="textarea"
                                        rows={3}
                                        className={
                                          fItem?.error ? "required-input" : ""
                                        }
                                        isInvalid={fItem?.error ? true : false}
                                        placeholder={`Enter ${fItem.label}`}
                                        maxLength={fItem.attr.maxChar}
                                        value={fItem.value}
                                        onChange={(e) =>
                                          onFieldChange(e, fItem.index)
                                        }
                                        ref={(el: any) =>
                                          (myRefs.current[fItem.index] = el)
                                        }
                                      />
                                    ) : fItem.type == "email" ? (
                                      <Form.Control
                                        type="text"
                                        placeholder={`Enter ${fItem.label}`}
                                        className={
                                          fItem?.error ? "required-input" : ""
                                        }
                                        isInvalid={fItem?.error ? true : false}
                                        maxLength={fItem.attr.maxChar}
                                        value={fItem.value}
                                        onChange={(e) =>
                                          onFieldChange(e, fItem.index)
                                        }
                                        ref={(el: any) =>
                                          (myRefs.current[fItem.index] = el)
                                        }
                                      />
                                    ) : fItem.type == "phone" ? (
                                      <PhoneInput
                                        country={"lk"}
                                        value={fItem.value}
                                        onChange={(e) =>
                                          onFieldChange(e, fItem.index)
                                        }
                                        containerClass={
                                          fItem?.error ? "required-input" : ""
                                        }
                                      />
                                    ) : fItem.type == "date" ? (
                                      <DatePicker
                                        ref={(el: any) =>
                                          (myRefs.current[fItem.index] = el)
                                        }
                                        block
                                        placeholder={`Select ${fItem.label}`}
                                        oneTap
                                        value={
                                          fItem.value
                                            ? new Date(fItem.value)
                                            : null
                                        }
                                        onChange={(e) =>
                                          onFieldChange(e, fItem.index)
                                        }
                                        showMeridian
                                        className={
                                          fItem?.error ? "required-input" : ""
                                        }
                                      />
                                    ) : fItem.type == "date_time" ? (
                                      <DatePicker
                                        ref={(el: any) =>
                                          (myRefs.current[fKey] = el)
                                        }
                                        block
                                        placeholder={`Select ${fItem.label}`}
                                        format="yyyy-MM-dd hh:mm aa"
                                        ranges={[
                                          {
                                            label: "Now",
                                            value: new Date(),
                                          },
                                        ]}
                                        value={
                                          fItem.value
                                            ? new Date(fItem.value)
                                            : null
                                        }
                                        onChange={(e) =>
                                          onFieldChange(e, fItem.index)
                                        }
                                        showMeridian
                                        className={
                                          fItem?.error ? "required-input" : ""
                                        }
                                      />
                                    ) : fItem.type == "number" ? (
                                      <Form.Control
                                        type="text"
                                        className={
                                          fItem?.error ? "required-input" : ""
                                        }
                                        isInvalid={fItem?.error ? true : false}
                                        placeholder={`Enter ${fItem.label}`}
                                        maxLength={fItem.attr.maxChar}
                                        value={fItem.value}
                                        onChange={(e) =>
                                          onFieldChange(e, fItem.index)
                                        }
                                        ref={(el: any) =>
                                          (myRefs.current[fItem.index] = el)
                                        }
                                      />
                                    ) : fItem.type == "bool" ? (
                                      <Form.Check
                                        label={fItem.label}
                                        checked={
                                          !fItem.value
                                            ? fItem.attr.defaultChecked
                                            : fItem.value
                                        }
                                        onChange={(e) =>
                                          onFieldChange(e, fItem.index)
                                        }
                                        ref={(el: any) =>
                                          (myRefs.current[fItem.index] = el)
                                        }
                                        disabled={fItem.disabled}
                                      />
                                    ) : fItem.type == "url" ? (
                                      <Form.Control
                                        type="text"
                                        className={
                                          fItem?.error ? "required-input" : ""
                                        }
                                        isInvalid={fItem?.error ? true : false}
                                        placeholder={`Enter ${fItem.label}`}
                                        maxLength={fItem.attr.maxChar}
                                        value={fItem.value}
                                        onChange={(e) =>
                                          onFieldChange(e, fItem.index)
                                        }
                                        ref={(el: any) =>
                                          (myRefs.current[fItem.index] = el)
                                        }
                                      />
                                    ) : fItem.type == "pick_list" ? (
                                      <Select
                                        isMulti={fItem.attr.multi}
                                        isClearable={true}
                                        value={
                                          !fItem.touched &&
                                          !fItem.value &&
                                          dataId === "0"
                                            ? labelValue(
                                                fItem.attr.optionDefault
                                              )
                                            : fItem.value
                                        }
                                        classNamePrefix="react-select"
                                        className={
                                          fItem?.error
                                            ? "select2 z-5 required-select-input"
                                            : "select2 z-5 cust-select"
                                        }
                                        options={labelValue(fItem.attr.option)}
                                        onChange={(data) =>
                                          onFieldChange(data, fItem.index)
                                        }
                                        ref={(el: any) =>
                                          (myRefs.current[fItem.index] = el)
                                        }
                                      />
                                    ) : fItem.type == "lookup" ? (
                                      <DataSelect
                                        isMulti={fItem.attr.multi}
                                        isRender={false}
                                        field={fItem}
                                        fieldIndex={fieldIndex}
                                        data={data}
                                        params={props}
                                        recordId={dataId}
                                        onSelect={(data: any) =>
                                          onFieldChange(data, fItem.index)
                                        }
                                        ref={(el) =>
                                          (myRefs.current[fItem.index] = el)
                                        }
                                        disabled={fItem.disabled}
                                      />
                                    ) : fItem.type == "fileUpload" ? (
                                      <Form.Control
                                        type="file"
                                        onChange={(e) =>
                                          onFileChange(e, fItem.index)
                                        }
                                        ref={(el: any) =>
                                          (myRefs.current[fItem.index] = el)
                                        }
                                      />
                                    ) : fItem.type == "imageUpload" ? (
                                      <Form.Control
                                        type="file"
                                        accept="image/*"
                                        onChange={(e) =>
                                          onImageChange(e, fItem.index)
                                        }
                                        ref={(el: any) =>
                                          (myRefs.current[fItem.index] = el)
                                        }
                                      />
                                    ) : null}

                                    {isFormSubmit && fItem.error !== "" ? (
                                      <span className="fs-11 text-danger-emphasis">
                                        {fItem?.error}
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                  </Form.Group>
                                </Col>
                              );
                            })}
                          </Row>
                        </div>
                      );
                    }
                  })}
                  {fieldCount === 0 && (
                    <div className="dynamicFieldNone">
                      <p>
                        Quick create fields are not available please create
                        under form settings
                      </p>
                      <img
                        src={common.loadImg("noRecordFound.svg")}
                        alt="Select"
                      />
                      <Button
                        variant="secondary"
                        className="btn-sm"
                        onClick={() => onCancel()}
                      >
                        {" "}
                        Got It!
                      </Button>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Fragment>
      )}
    </Fragment>
  );
}

export default DynamicForm;
