//package
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Form,
  InputGroup,
  Card,
  Col,
  Row,
  Image,
} from "react-bootstrap";

// includes
import { common } from "includes";
import { api } from "includes";
import AmlHashForm from "aml-hash-form";

export default function Register() {
  //state
  const [error, setError] = useState<any>({ email: null, name: null });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [utils, setUtils] = useState<any>({
    registerSubmit: false,
    registerSuccess: false,
  });

  //form
  const formFields = {
    email: {
      validate: [
        "req#Email is required",
        // "email#Please Enter Valid email address",
        "regExp#Please Enter valid email address",
        "max:60",
      ],
      pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    },
    name: {
      validate: ["req#Name is required", "min:3", "max:25"],
    },
    password: {
      validate: [
        "req#Password is required",
        "min:8#Password should be minimum 8 Characters",
        "max:15#Password should be maximum 15 Characters",
      ],
    },
  };
  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  //event
  const onSubmit = (e: any) => {
    e.preventDefault();
    let Valid = handleSubmit();
    if (Valid) {
      Register();
    }
  };

  // api
  const Register = () => {
    utilsState("registerSubmit", true);

    let data = {
      url: "/auth/register",
      method: "POST",
      auth: "none",
      body: fields,
    };
    api.call(data, (res: any) => {
      if (res.status == 200) {
        utilsState("registerSuccess", true);
      } else if (res.status == 400) {
        setError((prev: any) => ({
          ...prev,
          email: res.message,
        }));

        utilsState("registerSuccess", false);
      } else {
        common.notify("E", "Cannot Connect to Server, Please try Again!");
      }

      utilsState("registerSubmit", false);
    });
  };

  // support
  const utilsState = (label: any, value: any) => {
    setUtils((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };

  // render
  return (
    <Row className="justify-content-center">
      <Col xxl={4} lg={5}>
        <Card>
          <Card.Header className="py-4 text-center bg-primary">
            <Link to="/">
              <span>
                <img src={common.loadImg("logo.png")} alt="logo" height="30" />
              </span>
            </Link>
          </Card.Header>
          <Card.Body className="p-4">
            {utils.registerSuccess && (
              <>
                {" "}
                <div className="text-center m-auto">
                  <h4 className={`text-dark-50 text-center pb-0 fw-bold`}>
                    Email Verification
                  </h4>
                  <Image
                    src={common.loadImg("svg/mail_sent.svg")}
                    alt="mail sent icon"
                    height="64"
                  />
                  <h4 className="text-dark-50 text-center mt-4 fw-bold">
                    Please check your email
                  </h4>
                  <p className="text-muted mb-4">
                    A email has been send to <b>your email {fields.email}</b>.
                    Please verify your account to Login.
                  </p>
                </div>
                <Link to="/login">
                  <div className="mb-0 text-center">
                    <Button variant="primary" type="submit">
                      <i className="ri-home-4-line me-1" /> Back to Login
                    </Button>
                  </div>
                </Link>
              </>
            )}
            {!utils.registerSuccess && (
              <>
                <div className="text-center w-75 m-auto">
                  <h4 className={`text-dark-50 text-center pb-0 fw-bold`}>
                    Register your company
                  </h4>
                  <p className="text-muted mb-4">
                    Don't have an account? Create your account, it takes less
                    than a minute
                  </p>
                </div>
                <form onSubmit={() => {}}>
                  <Form.Group className={"mb-3"}>
                    <Form.Label>Company Name</Form.Label>
                    <Form.Control
                      type="text"
                      isInvalid={errors.name ? true : false}
                      name="name"
                      placeholder="Enter company name"
                      value={fields.name}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className={"mb-3"}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      isInvalid={
                        errors.email || error.email != null ? true : false
                      }
                      name="email"
                      placeholder="Enter your email"
                      value={fields.email}
                      onChange={handleChange}
                    />
                    <Form.Control.Feedback type="invalid">
                      {error.email != null ? error.email : errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className={"mb-3"}>
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        isInvalid={errors.password ? true : false}
                        name="password"
                        placeholder="Enter your password"
                        value={fields.password}
                        onChange={handleChange}
                      />
                      <div
                        className={`input-group-text input-group-password ${
                          showPassword ? "" : "show-password"
                        }`}
                        data-password={showPassword ? "true" : "false"}
                      >
                        <span
                          className="password-eye"
                          onClick={() => setShowPassword(!showPassword)}
                        ></span>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.password}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <div className="mb-3 text-center">
                    <Button
                      variant="primary"
                      onClick={(e) => onSubmit(e)}
                      disabled={utils.registerSubmit ? true : false}
                    >
                      {utils.registerSubmit && (
                        <span
                          role="status"
                          className="spinner-border text-white  spinner-border-sm me-2"
                        ></span>
                      )}
                      {utils.registerSubmit ? "Processing.." : "Sign Up"}
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Card.Body>
        </Card>
        <Row className="mt-3">
          <Col className="text-center">
            <p className="text-muted bg-body">
              Already have an account?
              <Link
                to="/login"
                className="text-muted ms-1 link-offset-3 text-decoration-underline"
              >
                <b>Sign In</b>
              </Link>
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
