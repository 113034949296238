// package
import { Fragment } from "react";
import { Button, Spinner } from "react-bootstrap";
// includes
import { common } from "includes";

const AgStatus = (props: any) => {
  // const
  const data: any = {
    process: 100,
    message: {
      100: "Thank you for your patience as we loading the data you requested. We're almost there!",
      204: "No data Found!",
      400: "Something went wrong! Please Try Again",
      404: "Internal Server Error"
    },
    button: {
      204: "Add New",
      400: "Try Again",
      404: "Refresh"
    },
    img: {
      204: "nodata.svg",
      400: "error-400.svg",
      404: "error-404.svg"
    },
    size: "s"
  }

  //return
  return (
    <Fragment>
      {props.process === 100 ? (
        <div className="text-center py-3">
          <Spinner animation="border"
            variant="primary"
            className={`spinner-grow text-primary avatar-${props.size === "s" ? "xs" : props.size === "m" ? "sm" : "md"
              } m-2`}
          />
          <p className={`header-title fs-${props.size === "s" ? 12 : props.size === "m" ? 14 : 16} fw-bold mt-2`}>
            {props?.message?.[100] || data.message[100]}
          </p>
        </div>
      ) : (
        props.process !== 200 && <div className="text-center py-3">
          <img
            src={common.loadImg(`svg/${props.img ? props.img : data.img[props.process]}`)}
            width={props.size === "s" ? 120 : props.size === "m" ? 240 : 360}
            height={props.size === "s" ? 120 : props.size === "m" ? 240 : 360}
            alt="title"
          />
          <p className={`header-title fs-${props.size === "s" ? 12 : props.size === "m" ? 14 : 16} fw-bold mt-1`}>
            {props.message?.[props.process] || data.message[props.process]}
          </p>
          {props?.description &&
            <p className={`fs-${props.size === "s" ? 12 : props.size === "m" ? 14 : 16} px-3`}>
              {props?.description}
            </p>
          }
          {props?.button?.[props.process] && (
            <Button
              className={`btn btn-info mt-2 ${props.size === "s" ? "btn-sm" : ""}`}
              onClick={() => props?.onAgCallBack(props.process)}
            >
              {props?.button?.[props.process] || data.button[props.process]}
            </Button>
          )}
        </div>
      )}
    </Fragment>
  );
};
export default AgStatus;
