// package
import { useState, Fragment } from "react";
import { Col, Row, Button, Form, ListGroup } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
// component
import { common } from "includes";

export default function FormProperty(props) {
  let isDrag = null;
  //const
  const [data, setData] = useState(props.data);
  const [bulk, setBulk] = useState(false);
  const [bulkData, setBulkData] = useState("");
  const [isDragMode, setDragMode] = useState(null);
  const [dragItem, setDragItem] = useState(null);
  const [dropItem, setDropItem] = useState(null);

  // Drag Option
  const onDragStartItem = (data) => {
    if (isDrag == null) {
      isDrag = "item";
      setDragMode("item");
      setDragItem(data);
    }
  };

  const handleDragEndItem = () => {
    if (dropItem != null) {
      setData((oldData) => {
        let newData = reParse(oldData);
        let option = newData.field.attr.option;
        let option1 = option[dragItem];
        option.splice(dragItem, 1);
        option.splice(dropItem, 0, option1);
        newData.field.attr.option = option;
        return newData;
      });
      setDropItem(null);
    }
  };

  const onDragOverItem = (item) => {
    setDropItem(item);
  };

  const itemStyles = (key) => {
    if (
      isDragMode == "item" &&
      dropItem !== null &&
      dropItem == key &&
      dragItem != key
    ) {
      return "dragStart optionDD";
    } else if (isDragMode == "section") {
      return "dragStart";
    } else {
      return "";
    }
  };

  // handler
  const onChangeLabel = (e) => {
    fieldAttr("label", e.target.value);
  };

  const onChangeRequired = (e) => {
    fieldAttr("required", e.target.checked ? true : false);
    if (e.target.checked) {
      fieldAttr("quick", true);
    }
  };

  const onChangeQuick = (e) => {
    fieldAttr("quick", e.target.checked ? true : false);
  };

  const onChangeMulti = (e) => {
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.field.attr.multi = e.target.checked ? true : false;
      return newData;
    });
  };

  const fieldAttr = (type, value) => {
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.field[type] = value;
      return newData;
    });
  };

  const onChangeOption = (e, key) => {
    const { value } = e.target;
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.field.attr.option[key] = value;
      return newData;
    });
  };

  const onDefaultOption = (e) => {
    const { value } = e.target;
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.field.attr.optionDefault = value;
      return newData;
    });
  };

  const onMaxChar = (e) => {
    const { value } = e.target;
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.field.attr.maxChar = value;
      return newData;
    });
  };

  const onFormatSelect = (e) => {
    const { value } = e.target;
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.field.format = value;
      return newData;
    });
  };

  const onChangeChecked = (e) => {
    let checked = e.target.checked ? true : false;
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.field.attr.defaultChecked = checked;
      return newData;
    });
  };

  const onLookupSelect = (e) => {
    const { value } = e.target;
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.field.attr.lookup = value;
      return newData;
    });
  };

  // submit handler
  const onComplete = () => {
    let error = "";
    if (data.field.label.trim() === "") {
      error = "Field name is required";
    }
    if (data.label.includes(data.field.label.trim().toUpperCase())) {
      error = "Field name already exist please use another name";
    } else if (["pick_list"].includes(data.field.type)) {
      if (
        data.field.attr.option.filter((item) => item.trim() === "").length > 0
      ) {
        error = "Options cannot be empty";
      }
    } else if (["lookup"].includes(data.field.type)) {
      if (data.field.attr.lookup === "") {
        error = "Please select lookup";
      }
    }
    // show
    if (error) {
      common.notify("E", error);
    } else {
      props.onComplete(data);
    }
  };

  // option
  const addOption = () => {
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.field.attr.option.push("");
      return newData;
    });
  };

  const removeOption = (key) => {
    setData((oldData) => {
      let newData = reParse(oldData);
      newData.field.attr.option.splice(key, 1);
      if (newData.field.attr.optionDefault == key) {
        newData.field.attr.optionDefault = "";
      }
      return newData;
    });
  };

  // bulk option
  const bulkAdd = () => {
    setBulkData("");
    setBulk(true);
  };

  const bulkCancel = () => {
    setBulkData("");
    setBulk(false);
  };

  const bulkSubmit = () => {
    if (bulkData.trim() === "") {
      common.notify("E", "Please add your choices");
    } else {
      let newChoices = bulkData
        .trim()
        .split("\n")
        .map((i) => i.trim());
      setData((oldData) => {
        let newData = reParse(oldData);
        let oldChoice = newData.field.attr.option;
        newData.field.attr.option = [...new Set([...oldChoice, ...newChoices])];
        return newData;
      });
      bulkCancel();
      common.notify("S", "Bulk list has been added");
    }
  };

  // support
  const reParse = (data) => {
    return JSON.parse(JSON.stringify(data));
  };

  // render
  return (
    <Modal show={true} scrollable onHide={props.closeDialog}>
      <Modal.Header closeButton>
        <Modal.Title>{data.field.label} Properties</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-start">
        <div>
          <Form.Group className="mb-2">
            <Form.Label>Field Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Field Name"
              data-toggle="maxlength"
              value={data.field.label}
              disabled={data.field.variable === "system"}
              onChange={(e) => onChangeLabel(e)}
            />
          </Form.Group>
          {data.field.attr.maxChar !== null && (
            <Form.Group className="mb-2">
              <Form.Label>Number of characters allowed</Form.Label>
              <Form.Select
                aria-label="Default select"
                value={data.field.attr.maxChar}
                onChange={(e) => onMaxChar(e)}
              >
                {Array.from(Array(data.item.maxChar), (e, i) => {
                  i++;
                  return (
                    <option value={i} key={i}>
                      {i}
                    </option>
                  );
                })}
              </Form.Select>
            </Form.Group>
          )}
          {data.field.type === "number" && (
            <Form.Group className="mb-2">
              <Form.Label>Select Format</Form.Label>
              <Form.Select
                aria-label="Default select"
                value={data.field.format || "numeric"}
                onChange={(e) => onFormatSelect(e)}
                disabled={data.field.variable === "system"}
              >
                {[
                  { key: "numeric", name: "Numeric (E.g '1', '2', '1000')" },
                  {
                    key: "decimal",
                    name: "Decimal (E.g '1.25', '2.50', '3.145')",
                  },
                  { key: "currency", name: "Currency (E.g '1,000', '100,50)" },
                ].map((item, key) => (
                  <option value={item.key} key={key}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
          {/* Checkbox */}
          {data.field.type == "checkbox" && (
            <Form.Check
              label="Enable By Default"
              value={true}
              className="mb-2"
              checked={data.field.attr.defaultChecked}
              onChange={(e) => onChangeChecked(e)}
            />
          )}
          {["lookup"].includes(data.field.type) && (
            <Form.Group className="mb-2">
              <Form.Label>Select Lookup</Form.Label>
              <Form.Select
                aria-label="Default select"
                value={data.field.attr.lookup}
                onChange={(e) => onLookupSelect(e)}
                disabled={data.field.variable === "system"}
              >
                <option value="">Select</option>
                {data.lookup.map((item, key) => (
                  <option value={item.lookup} key={key}>
                    {item.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          )}
          {/* Option */}
          {["pick_list"].includes(data.field.type) && (
            <Fragment>
              {/* <Paper elevation={3} className="fs-pick-wrap mb-3"> */}
              <Row>
                <Col sm={12}>
                  <h5>{bulk ? "Add Bulk Options" : "Add Options"}</h5>
                  {!bulk ? (
                    <Fragment>
                      <ListGroup className="fs-pick-list">
                        {data.field.attr.option.map((item, key) => (
                          <div
                            key={key}
                            draggable
                            onDragStart={() => onDragStartItem(key)}
                            onDragOver={
                              isDragMode == "item"
                                ? () => {
                                    onDragOverItem(key);
                                  }
                                : null
                            }
                            onDragEnd={() => handleDragEndItem()}
                          >
                            <ListGroup.Item
                              onChange={(e) => onChangeOption(e, key)}
                              className={`${itemStyles(key)} py-1`}
                            >
                              <div className="d-flex align-items-center justify-content-between">
                                <div className="d-flex align-center gap-2">
                                  <i
                                    className="ri-menu-line crm-cp"
                                    onClick={() => removeOption(key)}
                                  />
                                  <p className="mb-0 fs-16">{item}</p>
                                </div>
                                <i
                                  className="ri-delete-bin-6-line crm-cp"
                                  onClick={() => removeOption(key)}
                                />
                              </div>
                            </ListGroup.Item>
                          </div>
                        ))}
                      </ListGroup>
                      <div className="my-2">
                        <Button
                          variant="outline-primary"
                          className="me-2"
                          onClick={() => addOption()}
                        >
                          Add New
                        </Button>
                        <Button
                          variant="outline-primary"
                          onClick={() => bulkAdd()}
                        >
                          Add Bulk Options
                        </Button>
                      </div>
                    </Fragment>
                  ) : (
                    <div>
                      <Form.Control
                        as="textarea"
                        rows={6}
                        placeholder="Please enter your values sequentially"
                        value={bulkData}
                        onChange={(e) => setBulkData(e.target.value)}
                        className="fs-textarea"
                      />
                      <div className="my-2">
                        <Button
                          variant="outline-primary"
                          className="me-2"
                          onClick={() => bulkSubmit()}
                        >
                          Add Choices
                        </Button>
                        <Button
                          variant="outline-secondary"
                          onClick={() => bulkCancel()}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  )}
                </Col>
              </Row>
              {/* </Paper> */}
              {data.field.type == "pick_list" && (
                <Form.Group className="mb-2">
                  <Form.Label>Select Default Option</Form.Label>
                  <Form.Select
                    aria-label="Default select"
                    value={data.field.attr.optionDefault}
                    onChange={(e) => onDefaultOption(e)}
                  >
                    <option value="">None</option>
                    {data.field.attr.option
                      .filter((i) => i != "")
                      .map((item, key) => (
                        <option value={item} key={key}>
                          {item}
                        </option>
                      ))}
                  </Form.Select>
                </Form.Group>
              )}
            </Fragment>
          )}

          {/* Multi Select */}
          {["pick_list", "lookup"].includes(data.field.type) && (
            <Form.Check
              label="Multi Select"
              value={true}
              className="mb-2"
              checked={data.field.attr.multi}
              onChange={(e) => onChangeMulti(e)}
            />
          )}

          {/* Required */}
          <Form.Group>
            <Form.Check
              label="Required"
              value={true}
              className="mb-2"
              checked={data.field.required}
              onChange={(e) => onChangeRequired(e)}
            />
          </Form.Group>
          {/* Quick Create */}
          <Form.Check
            label="Quick Create"
            value={true}
            className="mb-2"
            checked={data.field.quick}
            disabled={data.field.required}
            onChange={(e) => onChangeQuick(e)}
          />
        </div>
      </Modal.Body>
      <Modal.Footer>
        {props.isMode == "drag" ? (
          <Button variant="secondary" onClick={() => props.onDontSave()}>
            Don't save this field
          </Button>
        ) : (
          <Button variant="light" onClick={() => props.closeDialog()}>
            Cancel
          </Button>
        )}
        <Button variant="primary" onClick={() => onComplete()}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
