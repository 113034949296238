// packages
import { Fragment, useState, useEffect } from "react";
import {
    Button,
    OverlayTrigger,
    Tooltip,
    Row,
    Col,
    Modal,
    Form,
    Spinner,
} from "react-bootstrap";
import AmlHashForm from "aml-hash-form";
import ReactTextareaAutocomplete from "@webscopeio/react-textarea-autocomplete";

// component
import AgStatus from "elements/System/AgStatus";
import AgDelete from "./AgDelete";
import { common, api, config } from "includes";

const Note = (props: any) => {
    //const
    const { data_id, module_id } = props;
    // state
    const [process, setProcess] = useState<any>({ result: 100, edit: 100 });
    const [confirm, setConfirm] = useState<any>({ id: null, type: null });
    const [submit, setSubmit] = useState<any>("");
    const [popup, setPopup] = useState<String>("");
    const [resultList, setResultList] = useState<any>([]);
    const [resultUser, setResultUser] = useState<any>([]);
    const [data, setData] = useState<any>({ noteId: null });

    // form
    const formFields = {
        name: { validate: ["req#Title is required"] },
        description: { validate: ["req#Description is required"] },
    };

    const {
        fields,
        errors,
        handleChange,
        handleSubmit,
        handleReset,
        setMultiValue,
    } = AmlHashForm(formFields);

    //effect
    useEffect(() => {
        getUsers();
        getNotes();
    }, []);

    // api
    const getUsers = () => {
        let data: any = {
            url: "/user/read?type=mp&fields=id,TRIM(CONCAT(first_name,' ',last_name)) as name,avatar&query=&join=&limit=200&offset=0&order=created_at DESC", //Todo
            method: "GET",
        };
        api.call(data, (res: any) => {
            if (res.status === 200) {
                let result = res.data;
                setResultUser(result);
            }
        });
    };

    const getNotes = () => {
        let data: any = {
            url: `/notes/read?type=mp&fields=*&query=data_id=${data_id} and module_id=${module_id}&join=&limit=200&offset=0&order=created_at DESC`,
            method: "GET",
        };
        api.call(data, (res: any) => {
            processState("result", res.status);
            if (res.status === 200) {
                let result = res.data;
                if (result.length == 0) {
                    processState("result", 204);
                }
                setResultList(result);
            }
        });
    };

    const editNote = (id: any) => {
        dataState("noteId", id);
        setPopup("note");
        processState("edit", 100);
        let payload = {
            url: `/notes/read?type=sp&fields=*&query=id=${id}&join=&limit=1&offset=0&order=created_at DESC`,
            method: "GET",
        };

        api.call(payload, (res: any) => {
            processState("edit", res.status);
            if (res.status === 200) {
                let result = res.data;
                setMultiValue({
                    name: result.name || "",
                    description: result.description ? parseUserNameAndId(result.description, "id") : "",
                });
                handleReset();
            }
        });
    };

    const onSubmit = (e: any) => {
        e.preventDefault();
        const isValid = handleSubmit();
        if (isValid) {
            setSubmit("note");
            let param: any = {
                url: data.noteId ? "/notes/update/" : "/notes/create",
                query: data.noteId,
                method: data.noteId ? "PUT" : "POST",
                body: {
                    ...fields,
                    description: parseUserNameAndId(fields.description, "name"),
                    data_id,
                    module_id
                }
            };

            api.call(param, (res: any) => {
                if (res.status === 200) {
                    dataState("noteId", null);
                    setSubmit("");
                    setPopup("");
                    processState("result", 100);
                    getNotes();
                } else {
                    setSubmit("");
                }
            });
        }
    };

    const onDelete = (isDelete: boolean) => {
        if (isDelete) {
            let data: any = {
                url: "/notes/delete/",
                query: `${confirm.id}`,
                method: "DELETE",
            };

            api.call(data, (res: any) => {
                setConfirm({ id: null, type: "" });
                if (res.status === 200) {
                    getNotes();
                    common.notify("S", res.message);
                } else {
                    common.notify("E", res.message);
                }
            });
        } else {
            setConfirm({ id: null, type: "" });
        }
    };

    // events
    const addNote = () => {
        setSubmit("");
        dataState("noteId", null);
        setMultiValue({
            name: "",
            description: "",
        });
        handleReset();
        processState("edit", 200);
        setPopup("note");
    };

    const closeNote = () => {
        setSubmit("");
        dataState("noteId", null);
        setMultiValue({
            name: "",
            description: "",
        });
        setPopup("");
    };

    // callback
    const onAgCallBack = (status: any) => {
        getNotes();
    };

    const onAgEditCallBack = (status: any) => {
        if (status == 404) {
            editNote(data.noteId);
        }
    };

    // support
    const processState = (label: any, value: any) => {
        setProcess((prev: any) => ({
            ...prev,
            [label]: value
        }));
    }

    const dataState = (label: any, value: any) => {
        setData((prev: any) => ({
            ...prev,
            [label]: value
        }));
    }

    const parseUserNameAndId = (desc: string, mapKey: string) => {
        let userArray: any = desc.match(/\B@[a-z0-9_-]+/gi);
        let mapValue: string = mapKey === "id" ? "name" : "id";
        if (userArray?.length > 0) {
            userArray.map((user: any) => {
                user = user.substring(1); //remove @
                let filterUser = resultUser.filter((obj: any) => obj[mapKey] == user);
                if (filterUser.length > 0) {
                    desc = desc.replace(user, filterUser[0][mapValue]);
                }
            });
            return desc;
        } else {
            return desc;
        }
    }

    const filterUsers = (key: any) => {
        if (key) {
            const filters = resultUser.filter((i: any) =>
                i.name.toUpperCase().includes(key.toUpperCase())
            );
            return filters ? filters : resultUser;
        } else {
            return resultUser;
        }
    };

    const getProfileAvatar = (key: any) => {
        const filters = resultUser.filter((i: any) => i.id === key);
        return filters.length > 0 && common.cloudImg(filters[0].avatar);
    };

    const Item = (entity: any) => <div>{`${entity.entity?.name}`}</div>;
    const Loading = (data: any) => <div>Loading</div>;

    //render
    return (
        <Fragment>
            <Row>
                <Col md={12} className="text-left ps-3 mb-1">
                    <Button variant="primary" size="sm" onClick={() => addNote()}>
                        <i className="ri-add-line" />Add
                    </Button>
                </Col>
            </Row>
            <Row>
                <Col md={12}>
                    {process.result !== 200 ? (
                        <AgStatus
                            process={process}
                            message={{
                                100: "Loading notes... Please wait.",
                                204: "No notes.",
                                400: "We're not able to process your request at this moment.",
                            }}
                            button={{ 400: "Reload" }}
                            onAgCallBack={onAgCallBack}
                            img="notes.svg"
                            size="s"
                        />
                    ) : (
                        resultList.map((note: any, idx: number) => {
                            return (
                                <div className="p-2 role-row" key={idx}>
                                    <div className="d-flex justify-content-between">
                                        <div className="d-flex">
                                            <img
                                                className="me-2 rounded-circle"
                                                src={getProfileAvatar(note.created_by.id)}
                                                alt="Generic placeholder image"
                                                height={32}
                                            />
                                            {/* <div className="avatar-group-item">
                                                <div className="avatar-xs me-2">
                                                    <div
                                                        className={
                                                            idx % 2 == 1
                                                                ? "avatar-title rounded-circle text-bg-info"
                                                                : "avatar-title rounded-circle text-bg-primary"
                                                        }
                                                    >
                                                        {note.created_by.name[0]}
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div>
                                                <h5 className="m-0">{note.created_by.name}</h5>
                                                <p className="text-muted">
                                                    <small>
                                                        {common.formatDate(
                                                            note.created_at,
                                                            "YYYY-MM-DD hh:mm A"
                                                        )}
                                                    </small>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="roles-tree-options ms-2">
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={<Tooltip id="">Edit</Tooltip>}
                                            >
                                                <Button
                                                    variant="light"
                                                    size="sm"
                                                    className="me-1"
                                                    onClick={() => editNote(note.id)}
                                                >
                                                    <i className="ri-edit-line" />
                                                </Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="bottom"
                                                overlay={<Tooltip id="">Delete</Tooltip>}
                                            >
                                                <Button
                                                    variant="light"
                                                    size="sm"
                                                    className="me-1"
                                                    onClick={() => setConfirm({ id: note.id, type: "note" })}
                                                >
                                                    <i className="ri-delete-bin-line" />
                                                </Button>
                                            </OverlayTrigger>
                                        </div>
                                    </div>
                                    <h5>{note.name}</h5>
                                    <p>{note.description ? parseUserNameAndId(note.description, "id") : ""}</p>
                                </div>
                            );
                        })
                    )}
                </Col>
            </Row>

            {/* Notes Modal */}
            <Modal
                show={popup === "note"}
                size="lg"
                scrollable={true}
                onHide={() => closeNote()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{!data.noteId ? "Add" : "Edit"} Notes</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {process.edit != 200 ? (
                        <AgStatus
                            process={process.edit}
                            message={{ 100: "Loading notes detail.Please wait..." }}
                            onAgCallBack={onAgEditCallBack}
                            size="m"
                        />
                    ) : (
                        <>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Title</Form.Label>
                                        <Form.Control
                                            type="text"
                                            name="name"
                                            value={fields.name}
                                            onChange={handleChange}
                                            placeholder="Enter title"
                                            isInvalid={errors.name ? true : false}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.name != null ? errors.name : "-"}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Description</Form.Label>
                                        <ReactTextareaAutocomplete
                                            name="description"
                                            value={fields.description}
                                            onChange={handleChange}
                                            loadingComponent={Loading}
                                            minChar={0}
                                            style={{
                                                height: "200px",
                                            }}
                                            trigger={{
                                                "@": {
                                                    dataProvider: (token) => {
                                                        return filterUsers(token);
                                                    },
                                                    component: Item,
                                                    output: (item: any, trigger: any) =>
                                                        "@" + item.name,
                                                },
                                            }}
                                            className={errors.description ? "form-control required-input" : "form-control"}
                                        />
                                        {errors.description && (
                                            <span className="error-text">{errors.description}</span>
                                        )}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="light"
                        onClick={() => closeNote()}
                        disabled={submit}
                    >
                        <i className="ri-close-line" /> Close
                    </Button>
                    <Button variant="success" onClick={onSubmit} disabled={submit}>
                        {submit ? (
                            <>
                                <Spinner className="spinner-border-sm me-1" color="white" />
                                Processing...
                            </>
                        ) : (
                            <>
                                <i className="ri-save-line" /> Submit
                            </>
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
            {confirm.id && (
                <AgDelete
                    type="delete"
                    onAgDeleteCallBack={onDelete}
                />
            )}
        </Fragment>
    );
};
export default Note;
