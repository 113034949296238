import moment from "moment";
import { useState, useEffect, forwardRef } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
// includes
import { api, common } from "includes";

const FormSelect = forwardRef((props: any, ref: any) => {
  // const
  const {
    url,
    query,
    order,
    multi,
    async,
    render,
    limit,
    fixed,
    value,
    disabled,
  } = common.reParse(props);
  const fields = props.fields?.split(/,(.*)/s);//split(",");

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isStart, setIsStart] = useState<any>(null);
  const [resultList, setResultList] = useState([]);
  const [search, setSearch] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [offset, setOffset] = useState(0);
  const [data, setData] = useState([]);
  const [enumData] = useState<any>({
    gender: ["Male", "Female"].map((i) => ({ label: i, value: i })),
    salutation: ["Mr", "Ms", "Mrs"].map((i) => ({ label: i, value: i })),
    marital: ["Single", "Married"].map((i) => ({ label: i, value: i })),
    pages: [10, 20, 30, 40, 50].map((i) => ({ label: i, value: i })),
    stageCategories: ["Open", "Won", "Lost"].map((i) => ({ label: i, value: i })),
    targetTypes: [
      { label: "Year", value: "year" },
      { label: "Quarter 1", value: "q1" },
      { label: "Quarter 2", value: "q2" },
      { label: "Quarter 3", value: "q3" },
      { label: "Quarter 4", value: "q4" },
      { label: "Jan", value: "jan" },
      { label: "Feb", value: "feb" },
      { label: "Mar", value: "mar" },
      { label: "Apr", value: "apr" },
      { label: "May", value: "may" },
      { label: "Jun", value: "jun" },
      { label: "Jul", value: "jul" },
      { label: "Aug", value: "aug" },
      { label: "Sep", value: "sep" },
      { label: "Oct", value: "oct" },
      { label: "Nov", value: "nov" },
      { label: "Dec", value: "dec" }
    ],
    permissions: [
      { value: "1", label: "All" },
      { value: "2", label: "Access Denied" },
      { value: "3", label: "Personal" },
      { value: "4", label: "Personal & Department" },
      { value: "5", label: "Personal, Department & Child Department" },
    ],
    access: [
      { value: "r", label: "Read" },
      { value: "a", label: "Add" },
      { value: "u", label: "Update" },
      { value: "d", label: "Delete" },
      { value: "i", label: "Import" },
      { value: "e", label: "Export" },
    ]
  });
  let delayTimer: any;

  // effect
  useEffect(() => {
    if (isStart !== null) {
      if (fixed) {
        loadEnumData();
      } else {
        loadData();
      }
    }
  }, [isStart]);

  useEffect(() => {
    if (render === "empty") {
      onChange(null);
      setResultList([]);
      setIsStart(null);
    } else if (render !== "focus") {
      setOffset(0);
      setResultList([]);
      setIsLoading(true);
      setIsStart(moment().milliseconds());
    }
  }, [render]);

  useEffect(() => {
    let val: any = [];
    if (fixed && value) {
      val = enumData[url].filter((i: any) => typeof value === "string" ? value === i.value : value.includes(i.value));
    } else if (value != null) {
      Object.keys(value).map((key: any) => {
        val.push({
          label: value[key],
          value: parseInt(key),
        });
      });
    }
    setData(val);
  }, [props.value]);

  // api
  const loadData = () => {
    let data: any = {
      url,
      query: `?type=mp&fields=${fields[0]} as value, ${fields[1]
        } as label&query=${query}&join=&order=${order || "id asc"
        }&limit=${limit}&offset=${offset * limit}`,
      method: "GET",
    };
    api.call(data, (res: any) => {
      if (res.status === 200) {
        let data = res.data;
        let newList: any = [...new Set([...resultList, ...data])];
        setResultList(newList);
        setIsLoadingMore(false);
        setHasMore(res.paging.total > res.paging.live);
        if (isStart === "focus") {
          setIsFocus(true);
          setIsMenuOpen(true);
        }
      }
      setIsLoading(false);
    });
  };

  const loadEnumData = () => {
    setResultList(enumData[url]);
    if (isStart === "focus") {
      setIsFocus(true);
      setIsMenuOpen(true);
    }
    setIsLoading(false);
  };

  // load
  const loadOptions = async (inputValue: any) => {
    let data: any = {
      url,
      query: `?type=mp&fields=${fields[0]} as value, ${fields[1]
        } as label&query=${fields[1]} ILIKE '%%${inputValue}%%'${query ? ` and ${query}` : ""
        }&join=&order=${order || "id asc"}&limit=${limit}&offset=0`,
      method: "GET",
    };

    let result: any = await new Promise((resolve) => {
      clearTimeout(delayTimer);
      delayTimer = setTimeout(() => {
        setSearch(inputValue);
        api.call(data, (res: any) => {
          if (res.status === 200) {
            let result = res.data;
            resolve(result);
          } else {
            resolve([]);
          }
          setIsLoading(false);
        });
      }, 1000);
    });
    return result;
  };

  // handler
  const onChange = (result: any) => {
    let val: any = null;
    if (result !== null) {
      if (fixed) {
        val = [];
        if (multi) {
          result.map((item: any) => {
            val.push(item.value);
          });
        } else {
          val = result.value;
        }
      } else {
        val = {};
        if (multi) {
          result.map((item: any) => {
            val[item.value] = item.label;
          });
        } else {
          val[result.value] = result.label;
        }
      }
    }
    props.onSelect(val);
  };

  const onInputChange = (value: any) => {
    if (value === "") {
      setSearch("");
    }
  };

  const onFocus = () => {
    if (render === "focus" && !isFocus) {
      setIsLoading(true);
      setIsStart("focus");
    }
  };

  const onLoadMore = () => {
    if (hasMore && !search && !isLoadingMore) {
      setIsLoadingMore(true);
      setOffset((prev) => ++prev);
      setIsStart(moment().milliseconds());
    }
  };

  return isLoading ? (
    <Form.Control type="text" defaultValue={"Loading..."} readOnly={true} />
  ) : async ? (
    <AsyncSelect
      ref={ref}
      isDisabled={disabled}
      isMulti={multi}
      isClearable={true}
      value={data}
      defaultOptions={resultList}
      loadOptions={loadOptions}
      onChange={onChange}
      onFocus={onFocus}
      menuIsOpen={isMenuOpen}
      onInputChange={onInputChange}
      onMenuOpen={() => {
        setIsMenuOpen(true);
      }}
      onMenuClose={() => {
        setIsMenuOpen(false);
      }}
      autoFocus={isFocus}
      onMenuScrollToBottom={() => onLoadMore()}
      isLoading={isLoadingMore}
      classNamePrefix="react-select"
      className={
        props?.error
          ? "select2 z-5 required-select-input"
          : "select2 z-5 cust-select"
      }
    />
  ) : (
    <Select
      isMulti={multi}
      isDisabled={disabled}
      isClearable={true}
      options={resultList}
      value={data}
      onChange={onChange}
      onFocus={onFocus}
      menuIsOpen={isMenuOpen}
      menuPosition="fixed"
      onMenuOpen={() => setIsMenuOpen(true)}
      onMenuClose={() => setIsMenuOpen(false)}
      autoFocus={isFocus}
      classNamePrefix="react-select"
      className={
        props?.error ? "select2 z-5 required-select-input" : "select2 z-5"
      }
    />
  );
});

export default FormSelect;
