// package
import { Fragment, useState, useEffect, useRef } from "react";
import {
  Card,
  Col,
  Row,
  OverlayTrigger,
  Tooltip,
  Badge,
  Dropdown,
  ButtonGroup,
  Image,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import SimpleBar from "simplebar-react";
// component
import { common, api } from "includes";
import BreadCrumb from "elements/System/BreadCrumb";
import AgStatus from "elements/System/AgStatus";
import UserProfile from "./UserProfile";

export default function UserList() {
  // state
  const [process, setProcess] = useState<any>({ result: 100 });
  const [result, setResult] = useState<any>([]);
  const [utils, setUtils] = useState<any>({
    search: "",
    filter: "",
    userAdd: false,
    expand: true,
    userView: null
  });

  // effect
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [utils.search, utils.filter]);

  // api
  const getData = () => {
    let query = "";
    if (utils.search) {
      query = `concat(first_name,last_name) ilike  '%%${utils.search}%%'`;
    }
    if (utils.filter) {
      query = query ? query + ` and status='${utils.filter}'` : `status='${utils.filter}'`;
    }

    let data: any = {
      url: `/user/read?type=mp&fields=TRIM(CONCAT(first_name,' ',last_name,'')) as name,id,email,status,role,is_admin,avatar&query=${query}&join=&limit=200&offset=0&order=created_at DESC`,
      method: "GET",
    };
    api.call(data, (res: any) => {
      if (res.status === 200) {
        let result = res.data;
        utilsState("userView", result.length > 0 ? result[0].id : null);
        setResult(result);
      }
      processState("result", res.status);
    });
  };

  // events
  const viewUserInfo = (e: any, user: any) => {
    e.preventDefault();
    utilsState("userView", user.id);
  };

  const refresh = () => {
    utilsState("filter", "");
    utilsState("search", "");
    processState("result", 100);
    utilsState("userView", null);
    utilsState("userAdd", false);
    getData();
  };

  // callback
  const onAgCallBack = () => {
    getData();
  };

  // support
  const processState = (label: any, value: any) => {
    setProcess((prev: any) => ({
      ...prev,
      [label]: value
    }));
  }

  const utilsState = (label: any, value: any) => {
    setUtils((prev: any) => ({
      ...prev,
      [label]: value
    }));
  }

  // render
  return (
    <Fragment>
      <BreadCrumb
        breadCrumbItems={[
          { label: "Settings", path: "/settings/home" },
          { label: "Users", path: "/user/list", active: true },
        ]}
        title={"Users"}
      />
      {process.result != 200 ? (
        <Card>
          <Card.Body className="py-1 px-3 border-bottom border-light">
            <AgStatus
              process={process.result}
              message={{ 100: "Loading Users...Please wait." }}
              size="s"
              img="nousers.svg"
              onAgCallBack={onAgCallBack}
            />
          </Card.Body>
        </Card>
      ) : (
        <Row>
          {utils.expand && (
            <Col xl={4} lg={5}>
              <Card>
                <Card.Body className="py-1">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="header-title mb-0">
                        User List
                        <Badge className="ms-1" bg="primary">
                          {result.length}
                        </Badge>
                      </h4>
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="">Add New User</Tooltip>}
                      >
                        <Link
                          to="#"
                          className="text-reset fs-20 p-1 d-inline-block"
                          onClick={() => utilsState("userAdd", true)}
                        >
                          <i
                            className={"ri-chat-new-fill ms-1"}
                            data-bs-container="#tooltips-container"
                            data-bs-toggle="tooltip"
                          />
                        </Link>
                      </OverlayTrigger>
                    </div>

                    <div className="d-flex justify-content-between align-items-center">
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="">Refresh</Tooltip>}
                      >
                        <Link
                          to="#"
                          className="text-reset fs-20 p-1 d-inline-block"
                          onClick={refresh}
                        >
                          <i
                            className={"ri-refresh-line fs-16 ms-1"}
                            data-bs-container="#tooltips-container"
                            data-bs-toggle="tooltip"
                          />
                        </Link>
                      </OverlayTrigger>
                      <Dropdown>
                        <Dropdown.Toggle
                          as={ButtonGroup}
                          variant="bg-primary"
                          className="arrow-none"
                          style={{ cursor: "pointer" }}
                        >
                          <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="">Filter</Tooltip>}
                          >
                            <i
                              className={
                                utils.filter
                                  ? "ri-filter-fill fs-16 ms-1 text-primary"
                                  : "ri-filter-line fs-16 ms-1"
                              }
                            />
                          </OverlayTrigger>
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="end">
                          <Dropdown.Item
                            className="dropdown"
                            onClick={(e) => utilsState("filter", "Active")}
                          >
                            <i className="me-1 ri-user-line" />
                            Active Users
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="dropdown"
                            onClick={(e) => utilsState("filter", "Inactive")}
                          >
                            <i className="me-1 ri-user-unfollow-line" />
                            Inactive Users
                          </Dropdown.Item>
                          <Dropdown.Item
                            className="dropdown"
                            onClick={(e) => utilsState("filter", "NotVerified")}
                          >
                            <i className="me-1 ri-admin-line" />
                            Unconfirmed Users
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                  <hr className="mt-0 mb-1" />
                  <div className="app-search">
                    <form>
                      <div className="w-100 position-relative">
                        <input
                          type="search"
                          className="form-control"
                          placeholder="Search users..."
                          onChange={(e: any) => utilsState("search", e.target.value)}
                        />
                        <span className="ri-user-search-line search-icon"></span>
                      </div>
                    </form>
                  </div>
                </Card.Body>

                <Card.Body className="p-0">
                  <Row>
                    <Col>
                      <SimpleBar style={{ maxHeight: 530 }}>
                        {result.map((user: any, idx: number) => {
                          return (
                            <Link
                              to="#"
                              key={idx}
                              className="text-body"
                              onClick={(e: any) => {
                                viewUserInfo(e, user);
                              }}
                            >
                              <div
                                className={`d-flex align-items-start py-2 px-3${user.id === utils.userView
                                  ? " bg-success-subtle"
                                  : ""
                                  }`}
                              >
                                <img
                                  src={common.cloudImg(user.avatar)}
                                  className="me-2 rounded-circle"
                                  height="42"
                                  alt={user.name}
                                />
                                <div className="w-100">
                                  <h5 className="my-0">
                                    <span className="float-end text-muted fw-normal fs-12">
                                      {common.readable(user.role)}
                                    </span>
                                    {user.name || ""}{" "}
                                    {user.is_admin && (
                                      <OverlayTrigger
                                        placement="bottom"
                                        overlay={
                                          <Tooltip id="">Super Admin</Tooltip>
                                        }
                                      >
                                        <Image
                                          src={common.loadImg(
                                            "svg/verified-badge.svg"
                                          )}
                                          alt="verified"
                                          height="18"
                                        />
                                      </OverlayTrigger>
                                    )}
                                  </h5>
                                  <p className="mt-1 mb-0 text-muted">
                                    <span className="w-25 float-end text-end">
                                      <span className="badge bg-primary-subtle text-primary">
                                        {user.status}
                                      </span>
                                    </span>
                                    <span className="w-75">{user.email}</span>
                                  </p>
                                </div>
                              </div>
                            </Link>
                          );
                        })}
                        {result.length == 0 && (
                          <AgStatus
                            process={204}
                            size="s"
                            img="nousers.svg"
                            message={{
                              204: utils.search ? `No users found for "${utils.search}"` : "No users found",
                            }}
                          />
                        )}
                      </SimpleBar>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {result.length > 0 && (
                <div>
                  Showing 1 to {result.length} of {result.length} entries
                </div>
              )}
            </Col>
          )}

          <Col
            xl={utils.expand ? "8" : "12"}
            lg={utils.expand ? "9" : "12"}
            className="detail-right-wrap"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="">{utils.expand ? "Collapse" : "Expand"}</Tooltip>
              }
            >
              <span className="expand-collapse-icon">
                {utils.expand ? (
                  <i
                    className="ri-skip-left-fill fs-22"
                    onClick={() => utilsState("expand", false)}
                  />
                ) : (
                  <i
                    className="ri-skip-right-fill fs-22"
                    onClick={() => utilsState("expand", true)}
                  />
                )}
              </span>
            </OverlayTrigger>
            <UserProfile
              view="profile"
              userId={utils.userView}
              module="Edit"
              add={utils.userAdd}
              close={() => utilsState("userAdd", false)}
              refresh={refresh}
            />
          </Col>
        </Row>
      )}
    </Fragment>
  );
}
