// package
import { Fragment, useEffect, useState } from "react";
import { Button, Card, Col, Image, Row, Form, OverlayTrigger, Tooltip, Spinner, Modal } from "react-bootstrap";
import AmlHashForm from "aml-hash-form";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
// component
import AgStatus from "elements/System/AgStatus";
import FormSelect from "elements/System/FormSelect";
import { common, api } from "includes";
import ProfileUpload from "./ProfileUpload";

const Company = (props: any) => {
  // const 
  const { view } = props;
  // state
  const [process, setProcess] = useState<any>({ result: 100 });
  const [submit, setSubmit] = useState<any>("");
  const [popup, setPopup] = useState<String>("");

  const [resultList, setResultList] = useState<any>(null);

  const [utils, setUtils] = useState<any>({
    country: "yes",
    state: "empty",
    city: "empty",
    readMore: false,
    upload: false
  });


  // form
  const formFields = {
    avatar: { validate: [] },
    name: { validate: ["req#Company Name is required"] },
    description: { validate: [] },
    phone: { validate: [] },
    address: { validate: [] },
    postal_code: { validate: [] },
    country: { validate: [], obj: "value" },
    state: { validate: [], obj: "value" },
    city: { validate: [], obj: "value" },
    time_zone: { validate: [], obj: "value" },
    currency: { validate: [], obj: "value" },
  };

  const { fields, errors, handleChange, handleSubmit, setValue, setMultiValue } = AmlHashForm(formFields);

  // effect
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (utils.upload) {
      update();
      setUtils((prev: any) => ({
        ...prev,
        upload: false,
      }));
    }
  }, [utils.upload]);

  // api 
  const getData = () => {
    processState("result", 100)
    let data: any = {
      url: "/company/read?fields=*",
      method: "GET",
    };
    api.call(
      data,
      (res: any) => {
        processState("result", res.status)
        if (res.status === 200) {
          let result = res.data;
          setResultList(result);
          setMultiValue({
            avatar: result.avatar || "",
            name: result.name || "",
            description: result.description || "",
            phone: result.phone || "",
            address: result.address || "",
            postal_code: result.postal_code || "",
            country: result.country,
            state: result.state,
            city: result.city,
            currency: result.currency,
            time_zone: result.time_zone
          });
          setUtils((prev: any) => ({
            ...prev,
            country: "yes",
            state: result.country != null ? "yes" : "empty",
            city: result.state != null ? "yes" : "empty",
          }));
        }
      }
    );
  };

  const update = () => {
    let data = common.formPayload(fields, ["phone"]);

    let param = {
      url: "/company/update",
      method: "PUT",
      body: data,
    };

    api.call(
      param,
      (res: any) => {
        if (res.status === 200) {
          common.notify("S", res.message);
          processState("result", 100)
          getData();
        } else {
          common.notify("E", res.message);
        }
        setPopup("");
        setSubmit("");
      }
    );
  };

  // events
  const onSubmit = (e: any) => {
    e.preventDefault();
    const isValid = handleSubmit();
    if (isValid) {
      setSubmit("company");
      update();
    }
  };

  const onProfileImgUpload = (path: any) => {
    setMultiValue({ avatar: path });
    setUtils((prev: any) => ({
      ...prev,
      upload: true,
    }));
  };

  const onProfileImgDelete = () => {
    setMultiValue({ avatar: null });
    setUtils((prev: any) => ({
      ...prev,
      upload: true,
    }));
  };

  // callback
  const onAgCallBack = (status: any) => {
    getData();
  };

  // support
  const processState = (label: any, value: any) => {
    setProcess((prev: any) => ({
      ...prev,
      [label]: value
    }));
  }

  // render
  return (
    <Fragment>
      {process.result != 200 ? (
        <AgStatus
          process={process.result}
          message={{
            100: "Loading company profile... Please wait!",
            404: "Something went wrong! Please Refresh Again",
          }}
          button={{ 400: "Refresh", 404: "Refresh" }}
          size="l"
          img="company.svg"
          onAgCallBack={onAgCallBack}
        />
      ) : (
        <Row>
          {view == "page" && (
            <Col xl={4} lg={4}>
              <Card className="text-center">
                <Card.Body>
                  <ProfileUpload
                    src={resultList.avatar}
                    onUpdate={onProfileImgUpload}
                    onDelete={onProfileImgDelete}
                    width={"100px"}
                    height={"100px"}
                    className="col-md-12 d-flex justify-content-center"
                  />
                  <h4 className="mb-1 mt-2">{resultList.name || "-"}</h4>
                  <div className="text-start mt-3">
                    <p className="text-muted mb-2">
                      <strong>Email :</strong>{" "}
                      <span className="ms-2 ">
                        {resultList.email ? (
                          <a
                            href={`mailto:${resultList.email}`}
                            target="_blank"
                            className="text-muted"
                          >
                            {resultList.email}
                          </a>
                        ) : (
                          "-"
                        )}
                      </span>
                    </p>
                    <p className="text-muted mb-2">
                      <strong>Mobile :</strong>
                      <span className="ms-2">
                        {resultList.phone ? (
                          <a
                            href={`tel:+${resultList.phone}`}
                            target="_blank"
                            className="text-muted"
                          >
                            +{resultList.phone}
                          </a>
                        ) : (
                          "-"
                        )}
                      </span>
                    </p>
                    <p className="text-muted mb-1">
                      <strong>Location :</strong>{" "}
                      <span className="ms-2">
                        {common.readable(resultList.country)}
                      </span>
                    </p>
                  </div>

                  <ul className="social-list list-inline mt-3 mb-0">
                    <li className="list-inline-item">
                      <Link
                        to="#"
                        className="social-list-item border-primary text-primary"
                      >
                        <i className="ri-facebook-circle-fill" />
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to="#"
                        className="social-list-item border-danger text-danger"
                      >
                        <i className="ri-google-fill" />
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to="#"
                        className="social-list-item border-info text-info"
                      >
                        <i className="ri-twitter-fill" />
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link
                        to="#"
                        className="social-list-item border-secondary text-secondary"
                      >
                        <i className="ri-github-fill" />
                      </Link>
                    </li>
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          )}

          <Col
            xl={view == "page" ? 8 : 12}
            lg={view == "page" ? 8 : 12}
          >
            <Card>
              {view == "page" && (
                <Card.Body className="py-1 px-3 border-bottom border-light">
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="float-start">
                      <label className="form-check-label fs-16">
                        Company Profile
                      </label>
                    </div>
                    <div id="tooltips-container">
                      {view === "page" && (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="">Edit</Tooltip>}
                        >
                          <Link
                            to="#"
                            onClick={() => setPopup("edit")}
                            className="text-reset fs-20 p-1 d-inline-block"
                          >
                            <i
                              className="ri-edit-line me-1"
                              data-bs-container="#tooltips-container"
                              data-bs-toggle="tooltip"
                            />
                          </Link>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                </Card.Body>
              )}
              <Card.Body>
                {["profile", "card"].includes(view) && (
                  <div
                    className={`d-flex align-items-start jusfity-content-between ${view == "profile" ? "mb-4" : ""
                      }`}
                  >
                    <div className="d-flex align-items-center gap-3 w-50">
                      <div>
                        <Image
                          src={common.loadImg("users/avatar-2.jpg")}
                          className="rounded-circle avatar-xxl img-thumbnail"
                          alt="profile-avatar"
                        //   style={{ marginTop: "-60px" }}
                        />
                      </div>

                      <div>
                        <h4 className="mb-1 mt-2">{resultList.name || "-"}</h4>
                        <p className="text-muted mb-0">
                          <i className="ri-mail-line" />
                          <span className="ms-2 ">
                            {resultList.email ? (
                              <a
                                href={`mailto:${resultList.email}`}
                                className="text-muted"
                                target="_blank"
                              >
                                {resultList.email}
                              </a>
                            ) : (
                              "-"
                            )}
                          </span>
                        </p>
                        <p className="text-muted mb-0">
                          <i className="ri-smartphone-line" />
                          <span className="ms-2">
                            {resultList.phone ? (
                              <a
                                href={`tel:+${resultList.phone}`}
                                target="_blank"
                                className="text-muted"
                              >
                                +{resultList.phone}
                              </a>
                            ) : (
                              "-"
                            )}
                          </span>
                        </p>
                        <p className="text-muted mb-0">
                          <i className="ri-map-pin-line" />
                          <span className="ms-2">
                            {common.readable(resultList.country)}
                          </span>
                        </p>
                        <div className="d-flex justify-content-start">
                          <ul className="social-list list-inline mt-1 mb-0 text-center">
                            <li className="list-inline-item">
                              <Link
                                to="#"
                                className="social-list-item border-primary text-primary"
                              >
                                <i className="ri-facebook-circle-fill" />
                              </Link>
                            </li>
                            <li className="list-inline-item">
                              <Link
                                to="#"
                                className="social-list-item border-danger text-danger"
                              >
                                <i className="ri-google-fill" />
                              </Link>
                            </li>
                            <li className="list-inline-item">
                              <Link
                                to="#"
                                className="social-list-item border-info text-info"
                              >
                                <i className="ri-twitter-fill" />
                              </Link>
                            </li>
                            <li className="list-inline-item">
                              <Link
                                to="#"
                                className="social-list-item border-secondary text-secondary"
                              >
                                <i className="ri-github-fill" />
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end text-center w-50">
                      <Button
                        variant="primary"
                        onClick={() => setPopup("edit")}
                      >
                        <i className="ri-edit-line" /> Edit
                      </Button>
                    </div>
                  </div>
                )}
                {["page", "profile"].includes(view) && (
                  <Fragment>
                    {/* Company Information */}
                    <h5 className="mb-3 text-uppercase bg-light p-2">
                      <i className="ri-building-4-line me-1" /> General
                      Information
                    </h5>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3">
                          <Form.Label>Description</Form.Label>
                          <p
                            className={
                              utils.readMore
                                ? "text-muted fs-14 full-description mb-0"
                                : "text-muted fs-14 short-description mb-0"
                            }
                          >
                            {resultList.description || "-"}
                          </p>
                          {resultList.description &&
                            resultList.description.length > 100 && (
                              <Link
                                to="#"
                                className="text-muted fw-bold fs-13"
                                onClick={() => setUtils((prev: any) => ({
                                  ...prev,
                                  readMore: !utils.readMore,
                                }))}>
                                {!utils.readMore ? "Read more" : "Show Less"}
                              </Link>
                            )}
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* Address Information */}
                    <h5 className="mb-3 text-uppercase bg-light p-2">
                      <i className="ri-building-line me-1" /> Address
                      Information
                    </h5>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Country</Form.Label>
                          <p className="text-muted fs-14">
                            {common.readable(resultList.country)}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>State</Form.Label>
                          <p className="text-muted fs-14">
                            {common.readable(resultList.state)}
                          </p>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>City</Form.Label>
                          <p className="text-muted fs-14">
                            {common.readable(resultList.city)}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Street</Form.Label>
                          <p className="text-muted fs-14">
                            {resultList.address || "-"}
                          </p>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Zip Code</Form.Label>
                          <p className="text-muted fs-14">
                            {resultList.postal_code || "-"}
                          </p>
                        </Form.Group>
                      </Col>
                    </Row>

                    {/* Locale Information */}
                    <h5 className="mb-3 text-uppercase bg-light p-2">
                      <i className="ri-map-pin-time-line me-1" /> Locale
                      Information
                    </h5>
                    <Row>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Time Zone</Form.Label>
                          <p className="text-muted fs-14">
                            {common.readable(resultList.time_zone)}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-3">
                          <Form.Label>Currency</Form.Label>
                          <p className="text-muted fs-14">
                            {common.readable(resultList.currency)}
                          </p>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Fragment>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {/* Company profile Update */}
      <Modal
        show={popup === "edit"}
        size="lg"
        scrollable={true}
        onHide={() => setPopup("")}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Company</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Company Name</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={fields.name}
                  onChange={handleChange}
                  placeholder="Enter company name"
                  isInvalid={errors.name ? true : false}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.name != null ? errors.name : "-"}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Mobile</Form.Label>
                <PhoneInput
                  country={"gb"}
                  value={fields.phone}
                  onChange={(data) => {
                    setValue("phone", data);
                  }}
                />
              </Form.Group>
            </Col>

            <Col md={12}>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  value={fields.description}
                  onChange={handleChange}
                  rows={4}
                  data-toggle="maxlength"
                  maxLength={5000}
                  placeholder="Enter description"
                ></Form.Control>
              </Form.Group>
            </Col>

            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Country</Form.Label>
                <FormSelect
                  url="/service/world/countries"
                  fields="id,name"
                  query=""
                  order="name ASC"
                  value={fields.country}
                  fixed={false}
                  multi={false}
                  async={true}
                  limit={10}
                  render={utils.country}
                  onSelect={(data: any) => {
                    setMultiValue({
                      country: data,
                      state: null,
                      city: null,
                    });
                    setUtils((prev: any) => ({
                      ...prev,
                      state: !data ? "empty" : new Date(),
                      city: "empty",
                    }));
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>State</Form.Label>
                <FormSelect
                  url={`/service/world/states`}
                  fields="id,name"
                  order="name ASC"
                  query={`country_id=${fields.country ? Object.keys(fields.country)[0] : ""}`}
                  value={fields.state}
                  fixed={false}
                  multi={false}
                  async={true}
                  limit={10}
                  render={utils.state}
                  onSelect={(data: any) => {
                    setMultiValue({
                      state: data,
                      city: null,
                    });
                    setUtils((prev: any) => ({
                      ...prev,
                      city: !data ? "empty" : new Date(),
                    }));

                  }}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>City</Form.Label>
                <FormSelect
                  url="/service/world/cities"
                  fields="id,name"
                  order="name ASC"
                  query={`state_id=${fields.state ? Object.keys(fields.state)[0] : ""}`}
                  value={fields.city}
                  fixed={false}
                  multi={false}
                  async={true}
                  limit={10}
                  render={utils.city}
                  onSelect={(data: any) => {
                    setMultiValue({
                      city: data,
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Street</Form.Label>
                <Form.Control
                  type="text"
                  name="address"
                  value={fields.address}
                  onChange={handleChange}
                  placeholder="Enter street"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Zip Code</Form.Label>
                <Form.Control
                  type="text"
                  name="postal_code"
                  value={fields.postal_code}
                  onChange={handleChange}
                  placeholder="Enter zip code"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Time Zone</Form.Label>
                <FormSelect
                  url="/service/world/time-zone"
                  fields="id,name"
                  order="name ASC"
                  query=""
                  fixed={false}
                  value={fields.time_zone}
                  multi={false}
                  async={false}
                  limit={250}
                  render={"yes"}
                  onSelect={(data: any) => {
                    setMultiValue({
                      time_zone: data,
                    });
                  }}
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Currency</Form.Label>
                <FormSelect
                  url="/service/world/countries"
                  fields="currency,currency_name"
                  order="name ASC"
                  query=""
                  fixed={false}
                  value={fields.currency}
                  multi={false}
                  async={false}
                  limit={250}
                  render={"yes"}
                  onSelect={(data: any) => {
                    setMultiValue({
                      currency: data,
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => setPopup("")}
            disabled={submit}
          >
            <i className="ri-close-line" /> Close
          </Button>
          <Button variant="success" onClick={onSubmit} disabled={submit}>
            {submit ? (
              <>
                <Spinner className="spinner-border-sm me-1" color="white" />
                Processing...
              </>
            ) : (
              <>
                <i className="ri-save-line" /> Submit
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal>
    </Fragment>
  );
}
export default Company;
