// package
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import BreadCrumb from "elements/System/BreadCrumb";
import {
  Card,
  Col,
  Row,
  Table,
  Button,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Pagination as BootstrapPagination,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";

// includes
import AgStatus from "elements/System/AgStatus";
import AgDelete from "elements/System/AgDelete";
import { api, common, config } from "includes";
import SmsHome from "./Sms";

export default function List() {
  // const
  const navigate = useNavigate();
  const pathname = useLocation();

  // state
  const [process, setProcess] = useState<any>({ result: 100 });
  const [confirm, setConfirm] = useState<any>({ id: null, type: null });
  const [popup, setPopup] = useState<String>("");
  const [resultList, setResultList] = useState({
    columns: [],
    data: [],
    totalResults: 0,
    totalRecords: 0,
  });
  const [data, setData] = useState<any>({
    templateId: "",
  });

  const [listPaging, setListPaging] = useState<any>({
    limit: 10,
    page: 0,
    total: 0,
  });

  const [listSorting, setListSorting] = useState({
    field: "id",
    order: "DESC",
    default: "ASC",
  });
  const [utils, setUtils] = useState<any>({
    listReload: false,
  });

  //effect

  useEffect(() => {
    getData();
  }, [pathname]);

  useEffect(() => {
    if (utils.listReload) {
      getData();
    }
  }, [utils.listReload]);

  //api
  const getData = () => {
    const typeValue = pathname.pathname.includes("email") ? "Email" : "Sms";
    const encodedTypeValue = encodeURIComponent(`'${typeValue}'`);

    let url = `?type=mp&fields=*&query=type=${encodedTypeValue}&join=&limit=${
      listPaging.limit
    }&offset=${listPaging.limit * listPaging.page}`;
    if (listSorting.field) {
      url = url + `&order=${listSorting.field} ${listSorting.order}`;
    }
    let data: any = {
      url: "/template/read",
      query: url,
      method: "GET",
    };
    api.call(data, (res: any) => {
      processState("result", res.status);
      if (res.status === 200) {
        let result: any = res.data;
        setResultList((prev) => ({
          ...prev,
          data: result,
        }));
        setListPaging((pData: any) => {
          let nData = common.reParse(pData);
          nData.limit = nData.limit;
          nData.page = nData.page;
          nData.total = res.paging.total;
          return nData;
        });

        utilsState("listReload", false);
      }
    });
  };

  const onDelete = (isDelete: boolean) => {
    if (isDelete) {
      utilsState("listReload", false);
      let data: any = {
        url: `/template/delete/${confirm.id}`,

        method: "DELETE",
        auth: "token",
      };
      api.call(data, (res: any) => {
        if (res.status === 200) {
          setConfirm({ id: null, type: "" });
          common.notify("S", res.message);

          utilsState("listReload", true);
        } else {
          common.notify("E", res.message);
        }
      });
    } else {
      setConfirm({ id: null, type: "" });
    }
  };

  //events

  const onPageChange = (record: any, page: number) => {
    setListPaging((pData: any) => {
      let nData = common.reParse(pData);
      nData.limit = record;
      nData.page = page;
      return nData;
    });

    utilsState("listReload", true);
  };

  const refresh = () => {
    setListSorting({
      field: "id",
      order: "DESC",
      default: "ASC",
    });

    utilsState("listReload", true);
  };
  const getPaging = () => {
    let start = listPaging.limit * listPaging.page + 1;
    let pageEnd =
      listPaging.page === 0
        ? listPaging.limit
        : listPaging.limit * listPaging.page + (listPaging.limit - 1);
    let end = pageEnd < listPaging.total ? pageEnd : listPaging.total;
    return (
      <div>
        Showing {listPaging.page === 0 ? 1 : start} to {end} of{" "}
        {listPaging.total} entries
      </div>
    );
  };
  // callback
  const onAgCallBack = (status: any) => {
    if (status === 204) {
      if (pathname.pathname.includes("email")) {
        navigate(`/services/email/0`);
      } else {
        setPopup("sms");
      }
    } else {
      navigate(`/settings/home`);
    }
  };

  // support
  const processState = (label: any, value: any) => {
    setProcess((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };
  const dataState = (label: any, value: any) => {
    setData((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };
  const utilsState = (label: any, value: any) => {
    setUtils((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };

  // render
  return (
    <Row>
      <BreadCrumb
        breadCrumbItems={[
          { label: "Settings", path: "/settings/home" },
          { label: "Templates", path: "/services/sms/list", active: true },
        ]}
        title={"Templates"}
      />
      {process.result != 200 ? (
        <AgStatus
          process={process.result}
          message={{
            100: "Loading data.Please wait...",
            // 204: `No ${moduleInfo?.name} found!`,
            204: `No Templates found!`,
            400: "You're trying to access invalid records.",
          }}
          button={{ 204: "add", 400: "Go to Settings" }}
          size="l"
          //   img={`${moduleInfo?.key}.svg`}
          onAgCallBack={onAgCallBack}
        />
      ) : (
        <Row>
          <Col xl={12} lg={12}>
            <Card>
              <Card.Body className="py-2 px-3 border-bottom border-light">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="float-start">
                    <label className="form-check-label fs-16">
                      {pathname.pathname.includes("emai")
                        ? "Email Template"
                        : "Sms Template"}
                    </label>
                  </div>
                  <div id="tooltips-container d-flex align-items-center">
                    {resultList.data.length > 0 && (
                      <>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="">Refresh</Tooltip>}
                        >
                          <Button
                            variant="light"
                            size="sm"
                            className="me-1"
                            onClick={() => refresh()}
                          >
                            <i className="ri-refresh-line fs-16" />
                          </Button>
                        </OverlayTrigger>
                      </>
                    )}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="">Add New</Tooltip>}
                    >
                      <Button
                        variant="primary"
                        size="sm"
                        className="me-1 d-felx align-items-center"
                        onClick={() =>
                          pathname.pathname.includes("email")
                            ? navigate(`/services/email/${0}`)
                            : setPopup("sms")
                        }
                      >
                        <i className="ri-add-line fs-16" />
                        <span className="ms-1">Add New</span>
                      </Button>
                    </OverlayTrigger>
                  </div>
                </div>
              </Card.Body>
              <Card.Body className="pt-1">
                {resultList.data.length == 0 ? (
                  <AgStatus
                    process={204}
                    message={{
                      100: "Loading data.Please wait...",
                      204: `No Templates Found!`,
                      400: "You're trying to access invalid records.",
                    }}
                    button={{ 204: "Add New", 400: "Go to Modules" }}
                    size="l"
                    // img={`${moduleInfo?.key}.svg`}
                    onAgCallBack={onAgCallBack}
                  />
                ) : (
                  <Fragment>
                    {utils.listReload && (
                      <div className="list-table-spinner">
                        <Spinner />
                      </div>
                    )}
                    <Table
                      className="table-sm table-centered mb-0 table-fixed-col"
                      responsive
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                            }}
                          >
                            Action
                          </th>

                          <th>Template Title</th>
                          <th>Subject</th>
                          <th>Module</th>
                          <th>Template Type</th>
                          <th>Created At</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/* {isReload && <Spinner />} */}
                        {resultList.data.map((item: any, key: any) => (
                          <tr key={key}>
                            <td
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              className="padding-zero"
                            >
                              <Dropdown>
                                <Dropdown.Toggle
                                  as={Link}
                                  to="#"
                                  className="arrow-none card-drop"
                                >
                                  <i className="ri-more-line" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu align="start">
                                  <Dropdown.Item
                                    className="dropdown"
                                    onClick={() => {
                                      pathname.pathname.includes("email")
                                        ? navigate(`/services/email/${item.id}`)
                                        : setPopup("edit");
                                      // setEditId(item.id);
                                      dataState("templateId", item.id);
                                    }}
                                  >
                                    <i className="me-1 ri-edit-line" />
                                    Edit
                                  </Dropdown.Item>

                                  <Dropdown.Item
                                    className="dropdown"
                                    onClick={() =>
                                      setConfirm({
                                        id: item.id,
                                        type: "template",
                                      })
                                    }
                                  >
                                    <i className="me-1 ri-delete-bin-6-line" />
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            <td>{item.name}</td>
                            <td>{item.subject}</td>
                            <td>{common.readable(item.module)}</td>
                            <td>{item.type}</td>
                            <td>
                              {" "}
                              {common.formatDate(
                                item.created_at,
                                config.elements.dp
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>

                    {listPaging.total > 0 && (
                      <div className="d-flex align-items-center justify-content-between mt-3">
                        {getPaging()}
                        <div className="d-flex align-items-center justify-content-end">
                          <Select
                            className="select2 z-5 me-1"
                            options={config.options.pages}
                            onChange={(e) => onPageChange(e?.value, 0)}
                            value={{
                              label: listPaging.limit + " Rows",
                              value: listPaging.limit,
                            }}
                          />
                          <nav>
                            <BootstrapPagination className="mb-0">
                              <BootstrapPagination.Prev
                                disabled={listPaging.page === 0}
                                onClick={(e) =>
                                  onPageChange(
                                    listPaging.limit,
                                    listPaging.page - 1
                                  )
                                }
                              />
                              {[
                                ...Array(
                                  Math.ceil(listPaging.total / listPaging.limit)
                                ),
                              ].map((x, i) => (
                                <BootstrapPagination.Item
                                  key={i}
                                  active={i === listPaging.page}
                                  onClick={(e) =>
                                    onPageChange(listPaging.limit, i)
                                  }
                                >
                                  {i + 1}
                                </BootstrapPagination.Item>
                              ))}
                              <BootstrapPagination.Next
                                disabled={
                                  listPaging.page ===
                                  Math.ceil(
                                    listPaging.total / listPaging.limit
                                  ) -
                                    1
                                }
                                onClick={(e) =>
                                  onPageChange(
                                    listPaging.limit,
                                    listPaging.page + 1
                                  )
                                }
                              />
                            </BootstrapPagination>
                          </nav>
                        </div>
                      </div>
                    )}
                  </Fragment>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {confirm.id && <AgDelete type="delete" onAgDeleteCallBack={onDelete} />}
      <SmsHome
        open={popup === "edit" ? popup == "edit" : popup === "sms"}
        close={() => {
          setPopup("");
        }}
        id={data.templateId}
        editMode={popup === "edit"}
        type={popup}
      />
    </Row>
  );
}
