import "./assets/scss/app.scss";
import "./assets/scss/icons.scss";
import "bootstrap/js/dist/scrollspy";
import "react-image-lightbox/style.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from "react-router-dom";

// Layout
import { LayoutType } from "includes/types";

// redux
import { RootState } from "redux/store";
import { useSelector } from "react-redux";

// layouts
import FrontLayout from "elements/Layer/FrontLayout";
import InnerVerticalLayout from "elements/Layer/InnerVerticalLayout";
import InnerHorizontalLayout from "elements/Layer/InnerHorizontalLayout";

// front
import FrontHome from "pages/front/Home";
import SignIn from "pages/auth/Login";
import SignUp from "pages/auth/Register";
import LogOut from "pages/auth/Logout";

import ForgotPassword from "pages/auth/ForgotPassword";
import ResetPassword from "pages/auth/ResetPassword";

// app
import AppStarter from "pages/app/Starter";
import AppHome from "pages/app/Home";

// settings
import SettingsHome from "pages/settings/Home";

// company
import CompanyProfile from "pages/company/Profile";

// user
import UserList from "pages/user/List";

// roles
import RolesHome from "pages/roles/Home";

// permission
import PermissionHome from "pages/permission/Home";

// module
import ModuleList from "pages/module/List";
import ModuleSettings from "pages/module/Settings";

// data
import DataCrud from "pages/data/Crud";
import DataList from "pages/data/List";
import DataDetail from "pages/data/Detail";
import Conversion from "pages/data/Conversion";

// pipeline
import PipelineHome from "pages/pipeline/Home";

// target
import Target from "pages/target/Home";

// mapping
import MappingHome from "pages/mapping/Home";

// automation
import AutomationHome from "pages/automation/Home";

// services
import EmailHome from "pages/services/Email";
import WhatsappHome from "pages/services/Whatsapp";
import List from "pages/services/List";

// general
import Error from "pages/general/Error404";
import SuccessReset from "pages/auth/SucessReset";
import SuccessVerification from "pages/auth/SuccessVerification";
import MyProfile from "pages/user/MyProfile";

// poc: ui
import UiElements from "pages/poc/ui/UiElements";
import PocEmailTemplate from "pages/poc/ui/EmailTemplate";
import Roles from "pages/poc/ui/Roles";
import ListView from "pages/poc/ui/ListView";
import KanbanView from "pages/poc/ui/kanban";
import DetailView from "pages/poc/ui/DetailsView";
import FormSettings from "pages/poc/ui/FormSettings";
import Pipeline from "pages/poc/ui/pipeline";
import Mapping from "pages/poc/ui/Mapping";
import Conversions from "pages/poc/ui/Conversion";
import CustomSelect from "pages/poc/ui/CustomSelect";
import TooltipDelay from "pages/poc/ui/TooltipDelay";

// poc: api
import DharmaPoc from "pages/poc/dev/DharmaPoc";
import VimalaPoc from "pages/poc/dev/VimalaPoc";
import AishwaryaPoc from "pages/poc/dev/AishwaryaPoc";


//poc: dev
import FormPoc from "pages/poc/dev/FormPoc";


function App() {
  const { Layout } = useSelector((state: RootState) => ({
    Layout: state.Layout,
  }));

  const getLayout = () => {
    let layoutCls = InnerVerticalLayout;
    if (Layout.layoutType === LayoutType.LAYOUT_HORIZONTAL) {
      layoutCls = InnerHorizontalLayout;
    }
    return layoutCls;
  };

  let RouterLayout = getLayout();
  // Configure nested routes with JSX
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route path="*" element={<Error />}></Route>
        <Route path="/" element={<FrontHome />} />
        <Route path="/" element={<FrontLayout />}>
          <Route path="login" element={<SignIn />} />
          <Route path="password/forgot" element={<ForgotPassword />} />
          <Route path="password/reset/:token" element={<ResetPassword />} />
          <Route path="password/success" element={<SuccessReset />} />
          <Route path="register" element={<SignUp />} />
          <Route path="logout" element={<LogOut />} />
          <Route path="email/verify/:token" element={<SuccessVerification />} />
        </Route>
        <Route path="/app" element={<RouterLayout />}>
          <Route path="home" element={<AppHome />} />
          <Route path="starter" element={<AppStarter />} />
        </Route>
        <Route path="user/" element={<RouterLayout />}>
          <Route path="list" element={<UserList />} />
          <Route path="myprofile" element={<MyProfile />} />
        </Route>
        <Route path="settings/" element={<RouterLayout />}>
          <Route path="home" element={<SettingsHome />} />
        </Route>
        <Route path="company/" element={<RouterLayout />}>
          <Route path="home" element={<CompanyProfile />} />
        </Route>
        <Route path="roles/" element={<RouterLayout />}>
          <Route path="home" element={<RolesHome />} />
        </Route>
        <Route path="permission/" element={<RouterLayout />}>
          <Route path="home" element={<PermissionHome />} />
        </Route>
        <Route path="module/" element={<RouterLayout />}>
          <Route path="list" element={<ModuleList />} />
          <Route path="settings/:moduleId" element={<ModuleSettings />} />
        </Route>
        <Route path="data/" element={<RouterLayout />}>
          <Route path="conversion/:moduleId/:dataId" element={<Conversion />} />
          <Route path="list/:moduleId" element={<DataList />} />
          <Route path="crud/:moduleId/:dataId" element={<DataCrud />} />
          <Route path="detail/:moduleId/:dataId" element={<DataDetail />} />
        </Route>
        <Route path="pipeline/" element={<RouterLayout />}>
          <Route path="home" element={<PipelineHome />} />
        </Route>
        <Route path="mapping/" element={<RouterLayout />}>
          <Route path="home" element={<MappingHome />} />
        </Route>
        <Route path="target/" element={<RouterLayout />}>
          <Route path="home" element={<Target />} />
        </Route>
        <Route path="automation/" element={<RouterLayout />}>
          <Route path="home" element={<AutomationHome />} />
        </Route>
        <Route path="services/" element={<RouterLayout />}>
          <Route path="email/list" element={<List />} />
          <Route path="sms/list" element={<List />} />
          <Route path="whatsapp" element={<WhatsappHome />} />
          <Route path="email/:tempId" element={<EmailHome />} />

        </Route>
        {/* POC */}
        <Route path="poc/dev" element={<RouterLayout />}>
          <Route path="form" element={<FormPoc />} />
          <Route path="dharma" element={<DharmaPoc />} />
          <Route path="vimala" element={<VimalaPoc />} />
          <Route path="aishwarya" element={<AishwaryaPoc />} />
        </Route>
        <Route path="poc/ui" element={<RouterLayout />}>
          <Route path="uielements" element={<UiElements />} />
          <Route path="email-template" element={<PocEmailTemplate />} />
          <Route path="roles" element={<Roles />} />
          <Route path="list" element={<ListView />} />
          <Route path="kanban" element={<KanbanView />} />
          <Route path="detail" element={<DetailView />} />
          <Route path="formsettings" element={<FormSettings />} />
          <Route path="pipeline" element={<Pipeline />} />
          <Route path="mapping" element={<Mapping />} />
          <Route path="conversion" element={<Conversions />} />
          <Route path="customselect" element={<CustomSelect />} />
          <Route path="tooltipdelay" element={<TooltipDelay />} />
        </Route>
      </Route>
    )
  );
  return <RouterProvider router={router} />;
}

export default App;
