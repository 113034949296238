import ReactDOM from "react-dom/client";
import { ToastContainer } from 'react-toastify';
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./redux/store";
//css
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <ToastContainer />
    <App />
  </Provider>
);