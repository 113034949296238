import {
  Card,
  Row,
  Col,
  Form,
  Image,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import BreadCrumb from "elements/System/BreadCrumb";
import { common } from "includes";

const userImage = common.loadImg("users/avatar-1.jpg");

export default function TooltipDelay() {
  return (
    <>
      <BreadCrumb
        breadCrumbItems={[
          { label: "Poc", path: "Tooltip" },
          { label: "Tooltip", path: "Tooltip", active: true },
        ]}
        title={"Tooltip Delay"}
      />
      <Row>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Body>
              <Col md={6}>
                <Form.Group className="mb-3">
                  <Form.Label>Username</Form.Label>
                  <OverlayTrigger
                    placement="right"
                    trigger={"hover"}
                    delay={{ show: 1000, hide: 400 }}
                    overlay={
                      <Popover id="">
                        <Popover.Body>
                          <div className="cust-tooltip-wrap">
                            <div
                              className="tooltip-user-wrap"
                              style={{ width: "500px" }}
                            >
                              <Image
                                src={userImage}
                                alt="user-img"
                                className="tooltip-user-img"
                              />
                              <p className="text-start">
                                Dharmaraj
                                <br />
                                CEO, Administartor
                              </p>
                            </div>
                            <div>
                              <p className="mb-1">
                                <i className="ri-mail-line me-1" />
                                dharmaraj@atomgroups.com
                              </p>
                              <p className="mb-1">
                                <i className="ri-phone-line me-1" />
                                9876543210
                              </p>
                            </div>
                          </div>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <p className="text-muted cust-tooltip-txt fs-14">
                      Dharmaraj
                    </p>
                  </OverlayTrigger>
                </Form.Group>
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
}