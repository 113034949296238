import { useState } from "react";
import {
  Card,
  Col,
  Row,
  Button,
  Form,
  Table,
  FormGroup,
} from "react-bootstrap";
import { DatePicker } from "rsuite";
import BreadCrumb from "elements/System/BreadCrumb";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";

const Conversion = () => {
  const [isExistModal, setIsExistModal] = useState<boolean>(true);
  //style
  const modalStyle = {
    maxWidth: "450px",
    margin: "0 auto",
    Position: "absolute",
    top: "10%",
    left: "50%",
    transform: "translateX(-50%)",
  };
  return (
    <>
      <BreadCrumb
        breadCrumbItems={[
          { label: "Settings", path: "/Settings/conversion" },
          { label: "Conversion", path: "/Settings/conversion", active: true },
        ]}
        title={"Conversion Mapping"}
      />
      <Row className="justify-content-center">
        <Col xxl={12}>
          <Row className="mt-3 mb-1">
            <Col md={4}>
              <Card>
                <Card.Body className="text-center">
                  <p className="fw-bold text-uppercase mb-3">Account</p>
                  <div className="avatar-md mx-auto">
                    <span className="avatar-title bg-info-subtle border-info border border-opacity-25 text-info fw-normal fs-24 rounded-circle">
                      <i className="ri-group-line" />
                    </span>
                  </div>
                  <div className="text-start pt-4">
                    {/* Existing */}
                    <div>
                      <p>
                        Account with similar details in <strong>Account Name</strong> already
                        exist.
                      </p>
                      <Form.Check
                        type="radio"
                        id="customRadio1"
                        name="customradio1"
                        label="Add to existing Account"
                        className="mb-2"
                      />
                      <div className="ps-3">
                        <p>
                          Contact with similar details in <strong>Contact Name</strong> already
                          exist.
                        </p>
                        <Form.Check
                          type="radio"
                          id="customRadio1"
                          name="customradio1"
                          label="Add to existing Contact"
                          className="mb-2"
                        />
                        <Form.Check
                          type="radio"
                          id="customRadio2"
                          name="customradio1"
                          label="Create New Contact"
                          className="mb-3"
                        />
                      </div>
                      <Form.Check
                        type="radio"
                        id="customRadio2"
                        name="customradio1"
                        label="Create New Account"
                      />
                      <p className="mt-2 ms-3">
                        Add New{" "}
                        <span className="badge bg-dark-subtle text-dark fs-14">
                          Contact
                        </span>{" "}
                        will be created for the Account.
                      </p>
                    </div>
                    {/* New */}
                    <div>
                      <p>
                        Create New Account{" "}
                        <span className="badge bg-dark-subtle text-dark fs-14">
                          Test Account
                        </span>
                      </p>
                      <Form.Check
                        label="Create a new Deal for this Account"
                        className="mt-4"
                        id="create-new-account"
                      />
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4}>
              <Card>
                <Card.Body className="text-center">
                  <p className="fw-bold text-uppercase mb-3">Contact</p>
                  <div className="avatar-md mx-auto">
                    <span className="avatar-title bg-danger-subtle border-danger border border-opacity-25 text-danger fw-normal fs-24 rounded-circle">
                      <i className="ri-briefcase-2-line" />
                    </span>
                  </div>
                  <div className="text-start mt-4">
                    <p>
                      Create New Contact{" "}
                      <span className="badge bg-dark-subtle text-dark fs-14">
                        Test Contact
                      </span>
                    </p>
                    <Form.Check
                      label="Create a new Deal for this Contact"
                      className="mt-4"
                      id="create-new-contact"
                    />
                  </div>
                </Card.Body>
              </Card>
            </Col>

            <Col md={4}>
              <Card className="card-pricing">
                <Card.Body className="text-center">
                  <p className="fw-bold text-uppercase mb-3">Deal</p>
                  <div className="avatar-md mx-auto">
                    <span className="avatar-title bg-info-subtle text-info border-info border border-opacity-25 fw-normal fs-24 rounded-circle">
                      <i className="ri-community-line" />
                    </span>
                  </div>
                  <div className="text-start mt-4">
                    <p>
                      Create New Deal{" "}
                      <span className="badge bg-dark-subtle text-dark fs-14">
                        Test Deal
                      </span>
                    </p>
                    <Form.Check
                      label="Create a new Deal for this Contact"
                      className="my-4"
                      id="create-new-deal"
                    />
                    <FormGroup className="mb-2">
                      <Form.Label>Amount</Form.Label>
                      <Form.Control type="text" placeholder="Enter Amount" />
                    </FormGroup>
                    <FormGroup className="mb-2">
                      <Form.Label>Deal Name</Form.Label>
                      <Form.Control type="text" placeholder="Enter deal name" />
                    </FormGroup>
                    <Form.Group className="mb-2">
                      <Form.Label>Closing Date</Form.Label>
                      <DatePicker block oneTap showMeridian />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label>Stage</Form.Label>
                      <Select
                        className="select2 z-5"
                        classNamePrefix="react-select"
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label>Pipeline</Form.Label>
                      <Select
                        className="select2 z-5"
                        classNamePrefix="react-select"
                      />
                    </Form.Group>
                    <Form.Group className="mb-2">
                      <Form.Label>Any Text</Form.Label>
                      <Form.Control
                        as="textarea"
                        id="multiLine"
                        name="description"
                        rows={3}
                        data-toggle="maxlength"
                        maxLength={3000}
                        placeholder="Write something..."
                      />
                    </Form.Group>
                    <FormGroup className="mb-2">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email address"
                      />
                    </FormGroup>
                    <Form.Group className="mb-2">
                      <Form.Label>Campaign Source</Form.Label>
                      <Select
                        className="select2 z-5"
                        classNamePrefix="react-select"
                      />
                    </Form.Group>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col className="text-end mb-3">
          <Button variant="secondary" size="sm" className="me-1">
            <i className=" ri-arrow-go-back-line me-1" />
            Cancel
          </Button>
          <Button variant="primary" size="sm">
            <i className="ri-save-line me-1" />
            Convert
          </Button>
        </Col>
      </Row>

      <Modal
        show={isExistModal}
        scrollable={true}
        onHide={() => setIsExistModal(false)}
        style={modalStyle}
      >
        <Modal.Header closeButton>
          <Modal.Title>Matching Account Record(s)</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table
            className="table-sm table-hover table-centered table-borderless mb-0"
            responsive
          >
            <thead className="table-light">
              <tr>
                <th></th>
                <th>Account Name</th>
                <th>Phone</th>
                <th>Website</th>
                <th>Account Type</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Form.Check type="radio" id="customCheck1" label="" />
                </td>
                <td>
                  <span className="badge bg-dark-subtle text-dark fs-14">
                    Company
                  </span>
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" size="sm" className="me-1">
            <i className=" ri-arrow-go-back-line me-1" />
            Cancel
          </Button>
          <Button variant="primary" size="sm">
            <i className="ri-tick-line me-1" />
            Done
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Conversion;