//package
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Card, Col, Row } from "react-bootstrap";
import Spinner from "react-bootstrap/Spinner";

// includes
import { common } from "includes";
import { api } from "includes";

export default function SuccessVerification() {
  // const
  const navigate = useNavigate();
  const { token } = useParams();

  //state
  const [message, setMessage] = useState<any>({ info: "" });
  const [count, setCount] = useState<number>(5);
  const [utils, setUtils] = useState<any>({
    verifyStatus: "process",
    redirecting: false,
  });

  //effect

  useEffect(() => {
    Verification();
  }, []);

  useEffect(() => {
    if (
      utils.verifyStatus == "alreadyVerified" &&
      count > 0 &&
      !utils.redirecting
    ) {
      const countdown = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    } else if (
      utils.verifyStatus == "alreadyVerified" &&
      count === 0 &&
      !utils.redirecting
    ) {
      utilsState("redirecting", true);
      navigate("/login");
    }
  }, [utils.verifyStatus, count, utils.redirecting]);

  //api
  const Verification = () => {
    let payload = {
      token: token,
    };

    let data = {
      url: "/auth/email/verify",
      method: "POST",
      auth: "none",
      body: payload,
    };
    api.call(data, (res: any) => {
      if (res.status === 200) {
        utilsState("verifyStatus", "verified");
      } else if (res.status === 400) {
        if (res.code == "email_already_verified") {
          setMessage({ info: res.message });
          utilsState("verifyStatus", "alreadyVerified");
        }
      } else {
        common.notify("E", "Cannot Connect to Server, Please try Again!");
      }
    });
  };

  // support

  const utilsState = (label: any, value: any) => {
    setUtils((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };

  //render
  return (
    <Row className="justify-content-center row">
      <Col xxl={4} lg={5}>
        <Card>
          <Card.Header className="py-4 text-center bg-primary">
            <Link to="/">
              <span>
                <img src={common.loadImg("logo.png")} alt="logo" height="30" />
              </span>
            </Link>
          </Card.Header>
          <Card.Body className="p-4">
            {utils.verifyStatus == "process" && (
              <div className="text-center py-3">
                <Spinner
                  animation="border"
                  variant="primary"
                  className="spinner-grow text-primary avatar-sm m-2"
                />
                <h4 className="header-title pt-2">
                  Your email verification is in progress..Please wait
                </h4>
              </div>
            )}
            {utils.verifyStatus == "alreadyVerified" && (
              <div className="text-center w-75 mx-auto">
                <h4 className={`text-dark-50 text-center pb-0 fw-bold`}>
                  Email Verified
                </h4>
                <div className="d-flex justify-content-center">
                  <img
                    src={common.loadImg("svg/email-verified.svg")}
                    alt="logo"
                    height="250px"
                    width="250px"
                  />
                </div>
                <h4 className={`text-dark-50 text-center pb-0 fw-bold`}>
                  {message.info}
                </h4>
                <p className="text-muted">
                  Redirecting to Login in 5 seconds...
                </p>
                <div className="countdown-container">
                  <h2 className="mt-2">{count}</h2>
                </div>
              </div>
            )}
            {utils.verifyStatus == "verified" && (
              <>
                <div className="text-center w-75 m-auto">
                  <h4 className={`text-dark-50 text-center pb-0 fw-bold`}>
                    Email Verification Success!
                  </h4>
                  {/* <div className="logout-icon mx-auto mb-3">
                    <LogoutIcon />
                  </div> */}
                  <div className="d-flex justify-content-center">
                    <img
                      src={common.loadImg("svg/email-verified.svg")}
                      alt="logo"
                      height="250px"
                      width="250px"
                    />
                  </div>
                  <p className="text-muted mb-4">
                    You are now successfully Verified your Email.
                  </p>
                </div>
                <div className="mb-3 text-center mt-2">
                  <Link
                    to="/login"
                    className="text-muted ms-1 link-offset-3 text-decoration-underline"
                  >
                    <b>Sign In</b>
                  </Link>
                </div>
              </>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
}
