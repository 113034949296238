//packages
import { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import ProgressBar from "react-bootstrap/ProgressBar";
import { Popover, OverlayProps, OverlayTrigger } from "react-bootstrap";

//includes
import { common } from "includes";
import { api, config } from "includes";
import AgStatus from "elements/System/AgStatus";
import AgDelete from "elements/System/AgDelete";

interface FileType {
  file: File;
  name: string;
  size: number;
  progress: number;
  uploaded: boolean;
  error: boolean;
  url: string;
  type: string;
  response: any;
  originalName: string;
  isEditing: boolean;
  filePath?: string;
  attached?: boolean;
  id?: number;
}

const Attachment = (props: any) => {
  //const
  const { id, multiple, force, editable, acceptedFileTypes } = props;
  const authData = common.getAuth();

  //state
  const [process, setProcess] = useState<any>({ result: 100 });
  const [confirm, setConfirm] = useState<any>({ id: null, type: null });
  const [popup, setPopup] = useState(null);
  const [resultfiles, setResultFiles] = useState<FileType[]>([]);
  const [attachedFiles, setAttachedFiles] = useState<FileType[]>([]);
  const [popoverIndex, setPopoverIndex] = useState(null);
  const [data, setData] = useState<any>({
    currentFileName: "",
    fileRename: "",
  });
  const [utils, setUtils] = useState<any>({
    fileProgress: false,
    editIndex: null,
    fileNameErr: null,
  });

  //effect
  useEffect(() => {
    getAttachmentList();
  }, []);

  // api
  const getAttachmentList = () => {
    const data = {
      url: `/attachment/read`,
      query: `?query=data_id=${props.data_id} and module_id=${props.module_id}&fields=*&join=&type=mp&limit=200&offset=0&order=created_at DESC`,
      method: "GET",
      auth: "token",
    };

    api.call(data, (res: any) => {
      processState("result", res.status);
      if (res.status === 200) {
        const attachedFiles = res.data.map((file: any) => ({
          id: file.id,
          name: file.name,
          size: file.size,
          type: file.mime,
          filePath: file.path,
          attached: true,
          progress: 100,
          uploaded: true,
          error: false,
          isEditing: false,
        }));
        if (attachedFiles.length == 0) {
          processState("result", 204);
        }
        setAttachedFiles(attachedFiles);
      }
    });
  };

  const handleFileUpload = (
    file: FileType,
    updateProgress: Function,
    index: number
  ) => {
    const payload = {
      name: file.name,
      file: file.originalName,
      mime: file.type,
      path: authData?.uuid,
    };

    const data = {
      url: "/service/file/generate",
      method: "POST",
      auth: "token",
      body: payload,
    };

    api.call(data, (res: any) => {
      if (res.status === 200) {
        const responseFields = res.data.s3.fields;
        file.filePath = res.data.path;
        uploadToS3(
          file.file,
          responseFields,
          updateProgress,
          index,
          file.filePath
        );
      } else {
        updateProgress(index, 10, true);
      }
    });
  };

  const uploadToS3 = (
    file: File,
    fields: any,
    updateProgress: Function,
    fileIndex: number,
    filePath?: string
  ) => {
    const formData = new FormData();
    for (const key in fields) {
      formData.append(key, fields[key]);
    }
    formData.append("file", file);

    const xhr = new XMLHttpRequest();
    xhr.open("POST", "https://osmo-crm.s3.amazonaws.com/", true);

    xhr.upload.onprogress = (event) => {
      if (event.lengthComputable) {
        const percentComplete = Math.round((event.loaded / event.total) * 100);
        updateProgress(fileIndex, percentComplete, false);
      }
    };

    xhr.onload = () => {
      if (xhr.status === 204) {
        common.notify("S", "File Uploaded successfully");
        updateProgress(fileIndex, 100, false);

        if (force) {
          const uploadedFile = {
            file,
            name: file.name,
            size: file.size,
            filePath: filePath,
            progress: 0,
            error: false,
            url: "",
            type: file.type,
            response: undefined,
            originalName: file.name,
            isEditing: false,
            uploaded: true,
            attached: false,
          };

          handleAttachFiles([uploadedFile]);
        }
      } else {
        common.notify("E", "Cannot upload file, try again");
        updateProgress(fileIndex, 10, true);
      }
    };

    xhr.onerror = () => {
      common.notify("E", "Cannot upload file, try again");
      updateProgress(fileIndex, 10, true);
    };

    xhr.send(formData);
  };
  const deleteUploadedFile = (filePath: string) => {
    const payload = { file: filePath };
    const data = {
      url: "/service/file/delete",
      method: "DELETE",
      auth: "token",
      body: payload,
    };

    return new Promise((resolve, reject) => {
      api.call(data, (res: any) => {
        if (res.status === 200) {
          common.notify("S", "File deleted successfully");
          resolve(res);
        } else {
          common.notify("E", "Cannot delete file, try again");
          reject(res);
        }
      });
    });
  };
  const deleteAttachedFile = () => {
    const data = {
      url: "/attachment/delete/",
      query: `${confirm.id}`,
      method: "DELETE",
      auth: "token",
    };

    return new Promise((resolve, reject) => {
      api.call(data, (res: any) => {
        if (res.status === 200) {
          common.notify("S", "File deleted successfully");
          resolve(res);
          getAttachmentList();

          setConfirm({ id: null, type: null });
        } else {
          common.notify("E", "Cannot delete file, try again");
          reject(res);
        }
      });
    });
  };

  const removeFile = (index: number) => {
    const fileToRemove = resultfiles[index];

    if (fileToRemove.uploaded && fileToRemove.filePath) {
      deleteUploadedFile(fileToRemove.filePath)
        .then(() => {
          setResultFiles((prevFiles) =>
            prevFiles.filter((_, i) => i !== index)
          );

          setConfirm({ id: null, type: null });
        })
        .catch((err) => {
          console.error("Error deleting file:", err);

          setConfirm({ id: null, type: null });
        });
    } else {
      setResultFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
    }
  };

  const handleAttachFiles = (filesToAttach = resultfiles) => {
    utilsState("fileProgress", true);
    const newFilesToAttach = filesToAttach.filter(
      (file) => file.uploaded && !file.attached
    );

    newFilesToAttach.forEach((file) => {
      const payload = {
        name: file.name,
        mime: file.type,
        path: file.filePath,
        data_id: props.data_id,
        module_id: props.module_id,
      };

      const data = {
        url: "/attachment/create",
        method: "POST",
        auth: "token",
        body: payload,
      };

      api.call(data, (res: any) => {
        if (res.status === 200) {
          common.notify("S", `File ${file.name} attached successfully`);
          setResultFiles((prevFiles) =>
            prevFiles.map((f) =>
              f.filePath === file.filePath ? { ...f, attached: true } : f
            )
          );
          getAttachmentList();
        } else {
          common.notify("E", `Cannot attach file ${file.name}, try again`);
        }

        utilsState("fileProgress", false);
      });
    });
  };

  // Events
  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: any) => {
      let error: string[] = [];
      fileRejections.forEach((file: any) => {
        file.errors.forEach((err: any) => {
          error.push(err.message);
        });
      });

      if (error.length > 0) {
        common.notify("E", error[0]);
      } else {
        const newFiles = acceptedFiles.map((file) => ({
          file,
          name: file.name,
          originalName: file.name,
          size: file.size,
          type: file.type,
          url: URL.createObjectURL(file),
          progress: 0,
          uploaded: false, // Initialize as false
          error: false,
          response: null,
          isEditing: false,
          attached: false,
        }));
        setResultFiles((prevFiles) => {
          const updatedFiles = [...prevFiles, ...newFiles];
          newFiles.forEach((file, index) =>
            handleFileUpload(file, updateFileProgress, prevFiles.length + index)
          );
          return updatedFiles;
        });
      }
    },
    [resultfiles, force]
  );

  const updateFileProgress = (
    fileIndex: number,
    progress: number,
    error: boolean = false
  ) => {
    setResultFiles((prevFiles) => {
      const newFilesState = [...prevFiles];
      if (newFilesState[fileIndex]) {
        newFilesState[fileIndex].progress = progress;
        if (progress === 100) {
          newFilesState[fileIndex].uploaded = true;
        }
        newFilesState[fileIndex].error = error;
      }
      return newFilesState;
    });
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    multiple: true,
    noClick: true,
    noKeyboard: true,
    accept: acceptedFileTypes ? acceptedFileTypes : "*",
  });

  const getFileIcon = (type: string) => {
    if (type.includes("pdf")) return <i className="bi bi-file-pdf me-1"></i>;
    if (type.includes("image")) return <i className="bi bi-images me-1"></i>;
    if (type.includes("doc")) return <i className="bi bi-file-word me-1"></i>;
    if (type.includes("csv"))
      return <i className="bi bi-filetype-csv me-1"></i>;
    return <i className="bi bi-file me-1"></i>;
  };

  const openFile = (url: any) => {
    window.open(config.api.cloudImgPath + url, "_blank");
  };

  const handleFileNameSave = (index: number) => {
    if (data.currentFileName.length > 0) {
      setResultFiles((prevFiles) => {
        const updatedFiles = [...prevFiles];
        updatedFiles[index].name = data.currentFileName;
        return updatedFiles;
      });

      utilsState("editIndex", null);

      utilsState("fileNameErr", null);
      setPopup(null);
    } else {
      utilsState("fileNameErr", "File name required");
    }
  };

  const handleFileNameChange = (e: any) => {
    dataState("currentFileName", e.target.value);

    utilsState("fileNameErr", null);
  };
  const fileNameattach = (e: any) => {
    dataState("fileRename", e.target.value);
  };
  const handleFileNameAttach = (id: any) => {
    const payload = {
      name: data.fileRename,
    };
    if (data.fileRename.length > 0) {
      const data = {
        url: `/attachment/update/${id}`,
        method: "PUT",
        auth: "token",
        body: payload,
      };

      api.call(data, (res: any) => {
        if (res.status === 200) {
          common.notify("S", `File Name changed successfully`);

          getAttachmentList();
          setPopoverIndex(null);
        } else {
          common.notify("E", `Cannot change File Name, try again`);
        }
      });

      utilsState("fileNameErr", null);
    } else {
      utilsState("fileNameErr", "File Name Required");
    }
  };

  // toggle events for edit

  const handleTogglePopover = (index: any, file: any) => {
    dataState("fileRename", file.name);
    setPopoverIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const toggleEditing = (index: any) => {
    setPopup((prevIndex: any) => (prevIndex === index ? null : index));

    utilsState("editIndex", index);

    dataState("currentFileName", resultfiles[index].name);
  };

  // Delete Methods

  const deleteAfterUpload = (isDelete: boolean) => {
    if (isDelete) {
      removeFile(confirm.id);
    } else {
      setConfirm({ id: null, type: null });
    }
  };

  const deleteAfterAttach = (isDelete: boolean) => {
    if (isDelete) {
      deleteAttachedFile();
    } else {
      setConfirm({ id: null, type: null });
    }
  };

  // callback
  const tryAgain = () => {
    getAttachmentList();
  };

  //support
  const processState = (label: any, value: any) => {
    setProcess((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };
  const dataState = (label: any, value: any) => {
    setData((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };

  const utilsState = (label: any, value: any) => {
    setUtils((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };

  // filter
  const filesUploading = resultfiles.some((file) => file.progress < 100);
  const allFilesAttached = resultfiles.every((file) => file.attached);

  //render
  return (
    <div>
      <div
        {...getRootProps({ className: "dropzone" })}
        className="file-drag-drop-wrap mt-2"
      >
        <input {...getInputProps()} />
        <p className="drag-drop-input-lable mb-0">
          Drag and drop files here or{" "}
          <button type="button" onClick={open} className="browse-btn ms-2">
            Browse
          </button>
        </p>
      </div>
      <div id="file-list">
        {resultfiles.map((fileObj: FileType, index) => (
          <>
            {fileObj.attached == false && (
              <div
                key={index}
                className="file-item d-flex align-items-center justify-content-between mt-3"
              >
                <span
                  onClick={() => openFile(fileObj.filePath)}
                  className="file-name"
                  style={{ cursor: "pointer" }}
                >
                  {getFileIcon(fileObj.type)}
                  {fileObj.name} ({(fileObj.size / 1024).toFixed(2)} KB)
                </span>
                {resultfiles.some((file) => file.uploaded && !file.attached) &&
                  !force && (
                    <span>
                      <OverlayTrigger
                        trigger="click"
                        placement="right"
                        show={popup === index}
                        overlay={
                          <Popover id="popover-basic">
                            <Popover.Header as="h3">Rename</Popover.Header>
                            <Popover.Body>
                              <div className="input-group">
                                <input
                                  placeholder="File Rename"
                                  id="inlineFormInputGroup"
                                  className="form-control"
                                  type="text"
                                  value={
                                    utils.editIndex === index
                                      ? data.currentFileName
                                      : ""
                                  }
                                  onChange={handleFileNameChange}
                                />

                                <span
                                  className="input-group-text"
                                  onClick={() => handleFileNameSave(index)}
                                >
                                  OK
                                </span>
                              </div>
                              <p className="error-text">
                                {utils.fileNameErr ? utils.fileNameErr : null}
                              </p>
                            </Popover.Body>
                          </Popover>
                        }
                      >
                        <i
                          className="bi bi-pen"
                          style={{ cursor: "pointer", marginLeft: "10px" }}
                          onClick={() => {
                            toggleEditing(index);

                            utilsState("fileNameErr", null);
                          }}
                        />
                      </OverlayTrigger>
                    </span>
                  )}
                <ProgressBar
                  now={fileObj.progress}
                  label={fileObj.error ? "Error" : `${fileObj.progress}%`}
                  variant={fileObj.error ? "danger" : "success"}
                  className="flex-grow-1 mx-2"
                />
                {filesUploading ? (
                  <span style={{ cursor: "no-drop" }}>
                    <i className="bi bi-trash3"></i>
                  </span>
                ) : (
                  <span
                    onClick={() => {
                      setConfirm({ id: index, type: "uploaded" });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bi bi-trash3"></i>
                  </span>
                )}
              </div>
            )}
          </>
        ))}
      </div>
      {process.result != 200 ? (
        <AgStatus
          process={process.result}
          size="s"
          message={{
            100: "Loading Files... Please wait!",
            400: "We're not able to process your request at this moment.",
            204: "No Attachment Found.",
          }}
          button={{ 400: "Reload" }}
          img="attachment.svg"
          onAgCallBack={tryAgain}
        />
      ) : (
        <div className="mt-2">
          {attachedFiles.map((file, index) => (
            <>
              <div
                key={file.id}
                className="file-item d-flex align-items-center justify-content-between mt-3"
              >
                <span
                  onClick={() => {
                    openFile(file.filePath);
                  }}
                  className="file-name"
                  style={{ cursor: "pointer" }}
                >
                  {getFileIcon(file.type)}
                  {file.name}
                </span>
                <div>
                  <OverlayTrigger
                    trigger="click"
                    placement="right"
                    show={popoverIndex === index}
                    overlay={
                      <Popover id="popover-basic">
                        <Popover.Header as="h3">Rename</Popover.Header>
                        <Popover.Body>
                          <div className="input-group">
                            <input
                              placeholder="File Rename"
                              id="inlineFormInputGroup"
                              className="form-control"
                              type="text"
                              value={data.fileRename}
                              onChange={(e) => fileNameattach(e)}
                            />

                            <span
                              className="input-group-text"
                              onClick={() => {
                                handleFileNameAttach(file.id);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              OK
                            </span>
                          </div>
                          <p className="error-text">
                            {utils.fileNameErr ? utils.fileNameErr : null}
                          </p>
                        </Popover.Body>
                      </Popover>
                    }
                  >
                    <i
                      className="bi bi-pen me-3"
                      style={{ cursor: "pointer", marginLeft: "10px" }}
                      onClick={() => {
                        handleTogglePopover(index, file);

                        utilsState("fileNameErr", null);
                      }}
                    />
                  </OverlayTrigger>
                  <span
                    onClick={() => {
                      // setDeleteId(file.id);
                      // setDeleteConfirm(true);
                      setConfirm({ id: file.id, type: "attached" });
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <i className="bi bi-trash3"></i>
                  </span>
                </div>
              </div>
            </>
          ))}
        </div>
      )}

      {!force && (
        <button
          type="button"
          onClick={() =>
            handleAttachFiles(
              resultfiles.filter((file) => file.uploaded && !file.attached)
            )
          }
          className="btn btn-primary mt-3"
          disabled={filesUploading || utils.fileProgress || allFilesAttached}
        >
          {utils.fileProgress ? "Processing..." : "Attach"}
        </button>
      )}

      {confirm.type == "attached" && (
        <AgDelete type="delete" onAgDeleteCallBack={deleteAfterAttach} />
      )}
      {confirm.type == "uploaded" && (
        <AgDelete type="delete" onAgDeleteCallBack={deleteAfterUpload} />
      )}
    </div>
  );
};

export default Attachment;
