//package
import { useState, useEffect } from "react";
import {
    Card,
    Col,
    Row,
    Button,
    Form,
    FormGroup,
    Spinner,
} from "react-bootstrap";
import { DatePicker } from "rsuite";
import AmlHashForm from "aml-hash-form";
import { useNavigate, useParams } from "react-router-dom";
// component
import BreadCrumb from "elements/System/BreadCrumb";
import AgStatus from "elements/System/AgStatus";
import { api, common } from "includes";

const Conversion = () => {
    // const
    const { moduleId, dataId }: any = useParams();
    const navigate = useNavigate();
    // state
    const [process, setProcess] = useState<any>({ result: 100, info: 100, edit: 100 });
    const [submit, setSubmit] = useState<String>("");
    const [resultConvVerify, setResultConvVerify] = useState<any>(null);
    const [resultLead, setResultLead] = useState<any>(null);

    const [resultExistAccounts, setResultExistAccounts] = useState<any>([]);
    const [resultExistContacts, setResultExistContacts] = useState<any>([]);

    const [data, setData] = useState<any>({
        attachment: "accounts",
        createDeal: false,
        isAccountDuplicate: false,
        isContactDuplicate: false,
        isNewAccount: "",
        isNewContact: "",
        existAccountSelected: null,
        existContactSelected: null
    });

    // form
    const formFields = {
        number_1: { validate: ["req#Amount is required"] },
        text_1: { validate: ["req#Deal Name is required"] },
        date_1: { validate: ["req#Closing Date is required"] },
        stage: { validate: ["req#Please select stage"] },
    };

    const {
        fields,
        errors,
        handleChange,
        handleSubmit,
        handleReset,
        setValue,
        setMultiValue,
    } = AmlHashForm(formFields);

    // effect
    useEffect(() => {
        getData();
    }, []);

    useEffect(() => {
        if (data.isAccountDuplicate) {
            getExistingAccounts(resultConvVerify.modules.accounts);
        }
    }, [data.isAccountDuplicate])

    useEffect(() => {
        if (data.existAccountSelected) {
            getExistingContacts();
        } else {
            dataState("isContactDuplicate", false);
        }
    }, [data.existAccountSelected]);

    // api
    const getData = () => {
        let payload: any = {
            url: "/conversion/verify",
            method: "POST",
            body: { id: dataId }
        };
        api.call(payload, (res: any) => {
            processState("result", res.status);
            if (res.status === 200) {
                let result: any = res.data;
                setResultConvVerify(result);
                dataState("isAccountDuplicate", result.account ? true : false);
                if (!result.account) {
                    dataState("isContactDuplicate", false);
                    dataState("isNewAccount", "yes");
                    dataState("isNewContact", "yes");
                }
                setResultLead(result.lead);
                setMultiValue({
                    number_1: result.lead.number_2 || "",
                    text_1: result.lead.text_3 || "",
                    date_1: "",
                    stage: ""
                });
                handleReset();
            } else if (res.status === 400) {
                navigate(`/data/detail/${moduleId}/${dataId}`);
            }
        });
    };

    const getExistingAccounts = (id: any) => {
        let payload: any = {
            url: `/data/read/accounts/${id}`,
            query: `?fields=id,text_1,email_1,lookup_2&query=text_1 ILIKE '${resultLead?.text_3}'&limit=1000&offset=0&order=text_1 ASC&join=&type=mp`,
            method: "GET",
        };
        api.call(payload, (res: any) => {
            if (res.status === 200) {
                let result: any = res.data;
                setResultExistAccounts(result);
            }
        });
    };

    const getExistingContacts = () => {
        let payload: any = {
            url: `/data/read/contacts/${resultConvVerify.modules.contacts}`,
            query: `?fields=id,text_1,text_2,email_1,phone_1&query=text_1 ILIKE '${resultLead.text_1 || ""}' and text_2 ILIKE '${resultLead.text_2 || ""}'&limit=1000&offset=0&order=text_1 ASC&join=&type=mp`,
            method: "GET",
        };
        api.call(payload, (res: any) => {
            if (res.status === 200) {
                let result: any = res.data;
                if (result.length > 0) {
                    dataState("isContactDuplicate", true);

                }
                setResultExistContacts(result);
            }
        });
    };

    // event
    const onSubmit = (e: any) => {
        e.preventDefault();
        if (data.isNewAccount) {
            if (data.isNewAccount === "yes" || (data.isNewAccount === "no" && data.existAccountSelected)) {

                if (data.isNewContact) {
                    if (data.isNewContact === "yes" || (data.isNewContact === "no" && data.existContactSelected)) {
                        if (data.createDeal) {
                            const isValid = handleSubmit();
                            if (isValid) {
                                convertLead();
                            }
                        } else {
                            convertLead();
                        }
                    } else {
                        common.notify("E", "Please choose existng contact to convert")
                    }
                } else {
                    common.notify("E", "Please choose Contact type to convert")
                }


            } else {
                common.notify("E", "Please choose existing account to convert")
            }
        } else {
            common.notify("E", "Please choose Account type to convert")
        }

    };
    console.log('fields', fields)

    const convertLead = () => {
        setSubmit("conversion");
        let param: any = {
            id: dataId,
            account_id: data.existAccountSelected || null,
            contact_id: data.existContactSelected || null,
            attachment_to: data.attachment,
            deal: null
        }
        if (data.createDeal) {
            let stage = fields.stage.split(" - ");
            fields.stage = {
                name: stage[0],
                category: stage[1],
                probability: stage[2]
            }

            param.deal = fields;
        }
        let payload: any = {
            url: "/conversion/single",
            method: "POST",
            body: param
        };
        api.call(payload, (res: any) => {
            if (res.status === 200) {
                common.notify("S", res.message);
                navigate(`/data/list/${moduleId}`);
            } else {
                common.notify("E", res.message);
                setSubmit("");
            }
        });
    }

    // callback
    const onAgCallBack = (status: any) => {
        if (status === 400) {
            navigate("/data/detail/" + dataId);
        } else {
            getData();
        }
    };

    // support
    const createNewDeal = (e: any) => {
        dataState("createDeal", e.target.checked);
        if (!e.target.checked) {
            dataState("attachment", "accounts");
        }
    }

    const processState = (label: any, value: any) => {
        setProcess((prev: any) => ({
            ...prev,
            [label]: value
        }));
    }

    const dataState = (label: any, value: any) => {
        setData((prev: any) => ({
            ...prev,
            [label]: value
        }));
    }

    // render
    return (
        <>
            <BreadCrumb
                breadCrumbItems={[
                    { label: "Settings", path: "/settings/home" },
                    { label: "Modules", path: "/settings/home", active: true },
                ]}
                title={"Lead Conversion"}
            />
            {process.result !== 200 ? (
                <AgStatus
                    process={process.result}
                    message={{
                        100: "Loading details... Please wait.",
                        400: "You're trying to access invalid records.",
                    }}
                    button={{ 400: "Back to List", 404: "Reload" }}
                    onAgCallBack={onAgCallBack}
                    size="l"
                    img={"modules.svg"}
                />
            ) : (
                <Card>
                    <Card.Body>
                        <Row className="justify-content-center">
                            <Col xxl={12}>
                                <Row className="mt-1 mb-1">
                                    <Col md={4}>
                                        <Card className="border-2 border-secondary" style={{ height: 600 }}>
                                            <Card.Body className="text-center rounded-3">
                                                <p className="fw-bold text-uppercase mb-3">Account</p>
                                                <div className="avatar-md mx-auto">
                                                    <span className="avatar-title bg-info-subtle border-info border border-opacity-25 text-info fw-normal fs-24 rounded-circle">
                                                        <i className="ri-community-line" />
                                                    </span>
                                                </div>
                                                <div className="text-start mt-3">
                                                    {/* Existing */}
                                                    {data.isAccountDuplicate ? (
                                                        <div>
                                                            <p>
                                                                Account with similar details in{" "}
                                                                <strong>Account Name</strong> already exist.
                                                            </p>
                                                            <div>
                                                                <div className="d-flex align-items-center mt-2 mb-2">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        name="isNewAccount"
                                                                        value="no"
                                                                        id="account1"
                                                                        className="form-checkbox-info"
                                                                        checked={data.isNewAccount === "no" ? true : false}
                                                                        onChange={(e) =>
                                                                            dataState("isNewAccount", e.target.value)
                                                                        }
                                                                        label="Add to existing Account"
                                                                    />{" "}
                                                                    {/* <Link to="#" className="badge text-info">
                                                                        View
                                                                    </Link> */}
                                                                    {data.isNewAccount && <span className="float-end badge bg-dark-subtle text-dark fs-14 ms-1">
                                                                        {resultLead?.text_3}
                                                                    </span>}
                                                                </div>
                                                                {data.isNewAccount === "no" && (
                                                                    <Form.Select
                                                                        value={data.existAccountSelected}
                                                                        onChange={(e) => {
                                                                            dataState("existAccountSelected", e.target.value)
                                                                        }}>
                                                                        <option value={""}>
                                                                            Select
                                                                        </option>
                                                                        {resultExistAccounts.map((obj: any, idx: number) => {
                                                                            return (
                                                                                <option value={obj.id} key={idx}>
                                                                                    {`${obj.text_1} ${obj.phone_1 || ""} ${obj.email_1 || ""}`}
                                                                                </option>
                                                                            )
                                                                        })}
                                                                    </Form.Select>
                                                                )}
                                                                <div className="d-flex align-items-center mt-2 mb-2">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        name="isNewAccount"
                                                                        id="account2"
                                                                        value="yes"
                                                                        className="form-checkbox-info"
                                                                        checked={
                                                                            data.isNewAccount === "yes" ? true : false
                                                                        }
                                                                        onChange={(e) => {
                                                                            dataState("isNewAccount", e.target.value);
                                                                            dataState("isNewContact", e.target.value === "yes" ? "yes" : "no")
                                                                        }
                                                                        }
                                                                        label="Create New Account"
                                                                    />
                                                                    {data.isNewAccount && <span className="float-end badge bg-dark-subtle text-dark fs-14 ms-1">
                                                                        {resultLead?.text_3}
                                                                    </span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <p className="mt-2">
                                                            Create New Account{" "}
                                                            <span className="badge bg-dark-subtle text-dark fs-14">
                                                                {resultLead?.text_3}
                                                            </span>
                                                        </p>
                                                    )}
                                                    <div>
                                                        <Form.Check
                                                            type="radio"
                                                            label="Would you like to move attachments here?"
                                                            id="attach1"
                                                            name="attachment"
                                                            value={"account"}
                                                            className="mt-2 form-checkbox-secondary"
                                                            checked={data.attachment === "accounts" ? true : false}
                                                            onChange={(e) => dataState("attachment", "accounts")}
                                                        />
                                                    </div>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col md={4}>
                                        <Card className="border-2 border-success" style={{ height: 600 }}>
                                            <Card.Body className="text-center">
                                                <p className="fw-bold text-uppercase mb-3">Contact</p>
                                                <div className="avatar-md mx-auto">
                                                    <span className="avatar-title bg-danger-subtle border-danger border border-opacity-25 text-danger fw-normal fs-24 rounded-circle">
                                                        <i className="ri-group-line" />
                                                    </span>
                                                </div>
                                                <div className="text-start mt-3">
                                                    {data.isNewAccount === "yes" ||
                                                        (!data.isContactDuplicate && !data.isAccountDuplicate) ? (
                                                        <p>
                                                            Create New Contact{" "}
                                                            <span className="badge bg-dark-subtle text-dark fs-14">
                                                                {resultLead?.text_1}
                                                            </span>
                                                        </p>
                                                    ) : data.isNewAccount === "no" &&
                                                        data.isAccountDuplicate &&
                                                        !data.isContactDuplicate ? (
                                                        <p className="mt-2">
                                                            Add New{" "}
                                                            <span className="badge bg-dark-subtle text-dark fs-14">
                                                                {resultLead?.text_1}
                                                            </span>{" "}
                                                            will be created for the Account.
                                                        </p>
                                                    ) : (
                                                        data.isNewAccount && <div>
                                                            <p>
                                                                Contact with similar details in{" "}
                                                                <strong>Contact Name</strong> already exist.
                                                            </p>
                                                            <div>
                                                                <div className="d-flex align-items-center mt-2 mb-2">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        name="isNewContact"
                                                                        value="no"
                                                                        className="form-checkbox-success"
                                                                        checked={data.isNewContact === "no" ? true : false}
                                                                        onChange={(e) =>
                                                                            dataState("isNewContact", e.target.value)
                                                                        }
                                                                        label="Add to existing Contact"
                                                                    />
                                                                    {/* <Link to="#" className="badge text-info">
                                                                        View
                                                                    </Link> */}
                                                                    {data.isNewContact !== "" && <span className="float-end badge bg-dark-subtle text-dark fs-14 ms-1">
                                                                        {resultLead?.text_1}
                                                                    </span>}
                                                                </div>
                                                                {data.isNewContact === "no" && (
                                                                    <Form.Select
                                                                        value={data.existContactSelected}
                                                                        onChange={(e) => {
                                                                            dataState("existContactSelected", e.target.value)
                                                                        }}>
                                                                        <option value={""}>
                                                                            Select
                                                                        </option>
                                                                        {resultExistContacts.map((obj: any, idx: number) => {
                                                                            return (
                                                                                <option value={obj.id} key={idx}>
                                                                                    {`${obj.text_1} ${obj.text_2 || ""} ${obj.lookup_2?.label || ""} ${obj.email_1 || ""}`}
                                                                                </option>
                                                                            )
                                                                        })}
                                                                    </Form.Select>
                                                                )}
                                                                <div className="d-flex align-items-center mt-2 mb-2">
                                                                    <Form.Check
                                                                        type="radio"
                                                                        name="isNewContact"
                                                                        value="yes"
                                                                        className="form-checkbox-success"
                                                                        checked={
                                                                            data.isNewContact === "yes" ? true : false
                                                                        }
                                                                        onChange={(e) =>
                                                                            dataState("isNewContact", e.target.value)
                                                                        }
                                                                        label="Create New Contact"
                                                                    />
                                                                    {data.isNewContact && <span className="float-end badge bg-dark-subtle text-dark fs-14 ms-1">
                                                                        {resultLead?.text_1}
                                                                    </span>}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <Form.Check
                                                        type="radio"
                                                        label="Would you like to move attachments here?"
                                                        id="attach2"
                                                        name="attachment"
                                                        value={"contact"}
                                                        className="mt-2 form-checkbox-secondary"
                                                        checked={data.attachment === "contacts" ? true : false}
                                                        onChange={(e) => dataState("attachment", "contacts")}
                                                    />
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>

                                    <Col md={4}>
                                        <Card className="border-2 border-primary" style={{ height: 600 }}>
                                            <Card.Body className="text-center">
                                                <p className="fw-bold text-uppercase mb-3">Deal</p>
                                                <div className="avatar-md mx-auto">
                                                    <span className="avatar-title bg-success-subtle text-success border-success border border-opacity-25 fw-normal fs-24 rounded-circle">
                                                        <i className="ri-briefcase-2-line" />
                                                    </span>
                                                </div>
                                                <div className="text-start">
                                                    <div className="d-flex align-items-center mt-3 mb-2">
                                                        <Form.Check
                                                            label="Create a new Deal"
                                                            id="create-new-deal"
                                                            name="createDeal"
                                                            checked={data.createDeal}
                                                            onChange={(e) => createNewDeal(e)}
                                                        />
                                                        <span className="float-end badge bg-dark-subtle text-dark fs-14 ms-1">
                                                            {resultLead?.text_3}
                                                        </span>
                                                    </div>
                                                    {data.createDeal && (
                                                        <div>
                                                            <FormGroup className="mb-2">
                                                                <Form.Label>Amount</Form.Label>
                                                                <Form.Control
                                                                    name="number_1"
                                                                    type="number"
                                                                    placeholder="Enter Amount"
                                                                    value={fields.number_1}
                                                                    onChange={handleChange}
                                                                    isInvalid={errors.number_1 ? true : false}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.number_1 != null
                                                                        ? errors.number_1
                                                                        : "-"}
                                                                </Form.Control.Feedback>
                                                            </FormGroup>
                                                            <FormGroup className="mb-2">
                                                                <Form.Label>Deal Name</Form.Label>
                                                                <Form.Control
                                                                    name="text_1"
                                                                    type="text"
                                                                    placeholder="Enter Deal name"
                                                                    value={fields.text_1}
                                                                    onChange={handleChange}
                                                                    isInvalid={errors.text_1 ? true : false}
                                                                />
                                                                <Form.Control.Feedback type="invalid">
                                                                    {errors.text_1 != null ? errors.text_1 : "-"}
                                                                </Form.Control.Feedback>
                                                            </FormGroup>
                                                            <Form.Group className="mb-2">
                                                                <Form.Label>Closing Date</Form.Label>
                                                                <DatePicker
                                                                    block
                                                                    oneTap
                                                                    showMeridian
                                                                    format="dd-MM-yyyy"
                                                                    value={
                                                                        fields.date_1 ? new Date(fields.date_1) : null
                                                                    }
                                                                    onChange={(date) => {
                                                                        setValue("date_1", date);
                                                                    }}
                                                                    className={
                                                                        errors.date_1 ? "required-input" : ""
                                                                    }
                                                                />
                                                                {errors.date_1 && (
                                                                    <span className="error-text">
                                                                        {errors.date_1}
                                                                    </span>
                                                                )}
                                                            </Form.Group>
                                                            <Form.Group className="mb-2">
                                                                <Form.Label>Stage</Form.Label>
                                                                <Form.Select
                                                                    className={errors.stage
                                                                        ? "select2 z-5 required-select-input"
                                                                        : "select2 z-5 cust-select"}
                                                                    name="stage"
                                                                    value={fields.stage}
                                                                    aria-label="Select"
                                                                    onChange={(e) => {
                                                                        setMultiValue({ "stage": e.target.value })
                                                                    }}>
                                                                    <option value="">
                                                                        Select
                                                                    </option>
                                                                    {resultConvVerify?.deal?.stage.map((obj: any, idx: number) => {
                                                                        return (
                                                                            <option value={`${obj.name} - ${obj.category} - ${obj.probability}`} key={idx}>
                                                                                {`${obj.name} - ${obj.category} - ${obj.probability}`}
                                                                            </option>
                                                                        )
                                                                    })}
                                                                </Form.Select>
                                                                {errors.stage && (
                                                                    <span className="error-text">
                                                                        {errors.stage}
                                                                    </span>
                                                                )}
                                                            </Form.Group>
                                                            <Form.Check
                                                                type="radio"
                                                                label="Would you like to move attachments here?"
                                                                id="attach3"
                                                                value={"deal"}
                                                                className="form-checkbox-secondary"
                                                                checked={data.attachment === "deals" ? true : false}
                                                                onChange={(e) => dataState("attachment", "deals")}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="text-end mb-3">
                                <Button variant="secondary" className="me-1" onClick={() => navigate("/module/list")}>
                                    <i className=" ri-arrow-go-back-line me-1" />
                                    Cancel
                                </Button>
                                <Button
                                    variant="primary"
                                    onClick={onSubmit}
                                    disabled={submit === "conversion"}
                                >
                                    {submit === "conversion" ? (
                                        <>
                                            <Spinner className="spinner-border-sm me-1" color="white" />
                                            Processing...
                                        </>
                                    ) : (
                                        <>
                                            <i className="ri-save-line me-1" />
                                            Convert
                                        </>
                                    )}
                                </Button>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            )}
        </>
    );
};

export default Conversion;
