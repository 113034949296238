//package
import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Card, Col, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

// component
import AmlHashForm from "aml-hash-form";
import { api, common } from "includes";

export default function Login() {
  // const
  const navigate = useNavigate();

  // state
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [utils, setUtils] = useState<any>({
    loginSubmit: false,
    emailErr: null,
    pwdErr: null,
  });
  // form
  const formFields = {
    email: {
      validate: [
        "req#Email is required",
        // "email#Please enter a valid email address",
        "regExp#Please Enter valid email address",
      ],
      pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    },
    password: {
      validate: [
        "req#Password is required!",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
      ],
    },
  };

  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // effect

  useEffect(() => {
    common.tempDataRemove();
  }, []);

  // event

  const onSubmit = (e: any) => {
    e.preventDefault();
    const isValid = handleSubmit();
    if (isValid) {
      Login();
    }
  };

  // api

  const Login = () => {
    utilsState("loginSubmit", true);

    let data: any = {
      url: "/auth/login",
      method: "POST",
      auth: "none",
      body: fields,
    };

    api.call(data, (res: any) => {
      if (res.status === 200) {
        common.localSet("authData", common.crypt(res?.data, true));
        common.notify("S", `Welcome ${res.data.name}!`);
        setTimeout(function () {
          navigate("/app/home");
        }, 500);

        utilsState("emailErr", null);
        utilsState("pwdErr", null);
      } else if (res.status == 400) {
        switch (res?.code) {
          case "unauthorized":
          case "not_verified":
          case "inactive":
            utilsState("emailErr", res.message);
            utilsState("pwdErr", null);
            break;
          case "password_incorrect":
            utilsState("pwdErr", res.message);
            utilsState("emailErr", null);
            break;
          default:
            break;
        }
      } else {
        common.notify("E", "Cannot Connect to Server, Please try Again!");
      }
      utilsState("loginSubmit", false);
    });
  };

  //support

  const handleKeyPress = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      onSubmit(e);
    }
  };
  const utilsState = (label: any, value: any) => {
    setUtils((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };

  //render

  return (
    <Row className="justify-content-center">
      <Col xxl={4} lg={5}>
        <Card>
          <Card.Header className="py-4 text-center bg-primary">
            <Link to="/">
              <span>
                <img src={common.loadImg("logo.png")} alt="logo" height="30" />
              </span>
            </Link>
          </Card.Header>
          <Card.Body className="p-4">
            <div className="text-center w-75 m-auto">
              <h4 className={`text-dark-50 text-center pb-0 fw-bold`}>
                Sign In
              </h4>
              <p className="text-muted mb-4">
                Enter your email address and password to access admin panel.
              </p>
            </div>
            <form
              onKeyDown={(e: React.KeyboardEvent<HTMLFormElement>) =>
                handleKeyPress(e)
              }
            >
              <Form.Group className={"mb-3"}>
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  isInvalid={
                    errors.email || utils.emailErr != null ? true : false
                  }
                  name="email"
                  placeholder="Enter your email"
                  value={fields.email}
                  onChange={handleChange}
                />
                <Form.Control.Feedback type="invalid">
                  {utils.emailErr != null ? utils.emailErr : errors.email}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className={"mb-3"}>
                <Form.Label>Password</Form.Label>
                <InputGroup>
                  <Form.Control
                    type={showPassword ? "text" : "password"}
                    isInvalid={
                      errors.password || utils.pwdErr != null ? true : false
                    }
                    name="password"
                    placeholder="Enter your password"
                    value={fields.password}
                    onChange={handleChange}
                  />
                  <div
                    className={`input-group-text input-group-password ${
                      showPassword ? "" : "show-password"
                    }`}
                    data-password={showPassword ? "true" : "false"}
                  >
                    <span
                      className="password-eye"
                      onClick={() => setShowPassword(!showPassword)}
                    ></span>
                  </div>
                  <Form.Control.Feedback type="invalid">
                    {utils.pwdErr != null ? utils.pwdErr : errors.password}
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <div className="d-flex align-items-center justify-content-between">
                <div>
                  {/* <Form.Group className={"mb-3"}>
                  
                    <Form.Check type="checkbox">
                      <Form.Check.Input type="checkbox" />
                      <Form.Check.Label>Remember me</Form.Check.Label>
                    </Form.Check>
                    <Form.Control.Feedback type="invalid">
                      Error
                    </Form.Control.Feedback>
                  </Form.Group> */}
                </div>
                <div className={"mb-3"}>
                  <Link to="/password/forgot">Forgot password</Link>
                </div>
              </div>

              <div className="mb-3 text-center">
                <Button
                  variant="primary"
                  type="button"
                  onClick={onSubmit}
                  disabled={utils.loginSubmit ? true : false}
                >
                  {" "}
                  {utils.loginSubmit && (
                    <span
                      role="status"
                      className="spinner-border text-white  spinner-border-sm me-2"
                    ></span>
                  )}
                  {utils.loginSubmit ? "Loading.." : "Log In"}
                </Button>
              </div>
            </form>
          </Card.Body>
        </Card>
        <Row className="mt-3">
          <Col className="text-center">
            <p className="text-muted bg-body">
              Don't have an account?
              <Link
                to="/register"
                className="text-muted ms-1 link-offset-3 text-decoration-underline"
              >
                <b>Sign Up</b>
              </Link>
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
