//packages
import { useState, Fragment, useRef } from "react";
import {
  Image,
  ListGroup as BootstrapListGroup,
  Overlay,
  Tooltip,
} from "react-bootstrap";

//includes
import { common, api, config } from "includes";
import { CropIt } from "./CropIt";
import AgDelete from "elements/System/AgDelete";

export default function ProfileUpload(props: any) {
  //const
  const dummyAvatar = common.loadImg("dummy-avatar.jpg");
  const authData = common.getAuth();
  const fileInputRef = useRef<any>(null);
  const target = useRef(null);
  const channelImageCroper = useRef<CropIt | null>(null);
  //state
  const [show, setShow] = useState(false);
  const [confirm, setConfirm] = useState<any>({ id: null, type: null });
  const [data, setData] = useState<any>({
    croppedImage: props.src ? config.api.cloudImgPath + props.src : null,
    selectedLogo: null,
    path: null,
  });

  //API
  const handleFileUpload = async (file: any) => {
    const payload = {
      name: file.name,
      file: file.name,
      mime: file.type,
      path: authData?.uuid,
    };

    const data = {
      url: "/service/file/generate",
      method: "POST",
      auth: "token",
      body: payload,
    };

    return new Promise((resolve) => {
      api.call(data, async (res: any) => {
        if (res.status === 200) {
          const responseFields = res.data.s3.fields;
          file.filePath = res.data.path;

          dataState("path", res.data.path);
          props.onUpdate(res.data.path);
          const success = await uploadToS3(file, responseFields);
          resolve(success);
        } else {
          resolve(false);
        }
      });
    });
  };

  const uploadToS3 = (file: File, fields: any): Promise<boolean> => {
    const formData = new FormData();
    for (const key in fields) {
      formData.append(key, fields[key]);
    }
    formData.append("file", file);

    const data = {
      url: "",
      method: "POST",
      auth: "none",
      body: formData,
      cType: 6,
    };

    return new Promise((resolve, reject) => {
      api.call(data, (res: any) => {
        if (res.status === 204) {
          common.notify("S", "Profile uploaded");
          resolve(true);
        } else {
          common.notify("E", "Cannot upload profile");
          resolve(false);
        }
      });
    });
  };

  const handleLogo = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (file) {
      const cropSize = { width: 256, height: 256 };
      if (channelImageCroper.current) {
        channelImageCroper.current.onFileChange(event, cropSize);
      }
      // setSelectedLogo(file);
      dataState("selectedLogo", file);
    } else {
      dataState("croppedImage", null);
      dataState("selectedLogo", null);
    }
    setShow(false);
  };

  const handleButtonClick = () => {
    if (data.selectedLogo) {
      setShow(true);
    } else {
      if (fileInputRef.current) {
        fileInputRef.current.click();
      }
    }
  };

  const deleteUploadedFile = (filePath: string) => {
    const payload = { file: filePath };
    const data = {
      url: "/service/file/delete",
      method: "DELETE",
      auth: "token",
      body: payload,
    };

    return new Promise((resolve, reject) => {
      api.call(data, (res: any) => {
        if (res.status === 200) {
          common.notify("S", "File deleted successfully");
          resolve(res);

          // setSelectedLogo(null);
          // setCroppedImage(null);
          dataState("croppedImage", null);
          dataState("selectedLogo", null);
          setShow(false);
          setConfirm({ id: null, type: null });
          if (props.onDelete) {
            props.onDelete();
          }
        } else {
          common.notify("E", "Cannot delete file, try again");
          reject(res);
        }
      });
    });
  };

  //Event
  const cropComplete = async (data: {
    status: string;
    url: string;
    image: any;
  }) => {
    if (data.status === "success") {
      const success: any = await handleFileUpload(data.image);
      if (success) {
        // setCroppedImage(data.url);
        dataState("croppedImage", data.url);
      } else {
        common.notify("E", "Upload failed. Please try again.");
        // setSelectedLogo(null);
        // setCroppedImage(null);
        dataState("croppedImage", null);
        dataState("selectedLogo", null);
      }
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } else {
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
      if (props.src) {
        // setCroppedImage(config.api.cloudImgPath + props.src);
        dataState("croppedImage", config.api.cloudImgPath + props.src);
      }

      dataState("selectedLogo", null);
    }
  };

  const onDelete = (isDelete: boolean) => {
    if (isDelete) {
      deleteUploadedFile(data.path ? data.path : props.src);
    } else {
      setConfirm({ id: null, type: null });
    }
  };

  const dataState = (label: any, value: any) => {
    setData((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };

  //render
  return (
    <Fragment>
      <div className={props.className}>
        <div
          className="logo-container"
          style={{ width: props.width, height: props.height }}
        >
          {data.croppedImage && (
            <div className="prof-img">
              <Image
                src={data.croppedImage}
                alt="Brand Logo"
                className="uploaded-logo rounded-circle
                avatar-lg
                img-thumbnail"
              />
              <div
                className="picture-menu-wrap"
                ref={target}
                onClick={() => setShow(!show)}
              >
                <i className="ri-camera-line profimg-change-icon"></i>
              </div>
            </div>
          )}

          {data.croppedImage == null && (
            <div className="click-load" onClick={handleButtonClick}>
              <Image
                src={dummyAvatar}
                alt="Avatar"
                className="rounded-circle
                          avatar-lg
                          img-thumbnail"
              />
            </div>
          )}
        </div>
        <input
          type="file"
          ref={fileInputRef}
          className="form-control form-input visually-hidden"
          accept="image/*"
          onChange={handleLogo}
        />
        <CropIt
          ref={(instance: CropIt | null) => {
            channelImageCroper.current = instance;
          }}
          onComplete={(data: any) => cropComplete(data)}
          shape="square"
        />
        <Overlay
          target={target.current}
          show={show}
          placement="bottom"
          rootClose
          onHide={() => setShow(false)}
        >
          {(props) => (
            <Tooltip
              id="overlay-example"
              className="profimg-menu-tooltip"
              {...props}
            >
              <BootstrapListGroup>
                <BootstrapListGroup.Item
                  action
                  className="py-1"
                  onClick={() => {
                    setShow(false);
                    if (fileInputRef.current) {
                      fileInputRef.current.click();
                    }
                  }}
                >
                  <i className="ri-edit-line"></i> Change
                </BootstrapListGroup.Item>
                <BootstrapListGroup.Item
                  action
                  className="py-1"
                  onClick={() => (
                    setConfirm({ id: null, type: "image" }), setShow(false)
                  )}
                >
                  <i className="ri-delete-bin-line"></i> Remove
                </BootstrapListGroup.Item>
              </BootstrapListGroup>
            </Tooltip>
          )}
        </Overlay>
      </div>

      {confirm.type == "image" && (
        <AgDelete type="delete" onAgDeleteCallBack={onDelete} />
      )}
    </Fragment>
  );
}
