import React from "react";
import Cropper from "react-easy-crop";
import { Button, Modal, Spinner } from "react-bootstrap";


interface CropItProps {
  onComplete: (data: {
    status: string;
    image?: File;
    url?: string;
    croppedImage?: string;
  }) => void;
  shape: any;
}

interface CropItState {
  imageOriginalSrc: File | null;
  isModal: boolean;
  isLoading: boolean;
  isImgLoading: boolean;
  imageSrc: string;
  crop: { x: number; y: number };
  zoom: number;
  aspect: number;
  cropSize: { width: number; height: number };
  initialCropSize: { width: number; height: number; x: number; y: number };
  croppedAreaPixels: any;
  croppedImage: string;
}

class CropIt extends React.Component<CropItProps, CropItState> {
  constructor(props: CropItProps) {
    super(props);
    this.state = {
      imageOriginalSrc: null,
      isModal: false,
      isLoading: false,
      isImgLoading: false,
      imageSrc: "",
      crop: { x: 0, y: 0 },
      zoom: 1,
      aspect: 4 / 3,
      cropSize: { width: 150, height: 150 },
      initialCropSize: { width: 150, height: 150, x: 100, y: 100 },
      croppedAreaPixels: null,
      croppedImage: "",
    };
  }
  createImage = (url:any) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });
getRadianAngle=(degreeValue:any)=> {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
getCroppedImg=async(imageSrc:any, pixelCrop:any, rotation = 0)=> {
  const image:any = await this.createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx:any = canvas.getContext("2d");

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(this.getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve) => {
    canvas.toBlob((file:any) => {
      resolve({ file: file, image: URL.createObjectURL(file) });
    }, "image/jpeg");
  });
}

 getRotatedImage=async(imageSrc:any, rotation = 0) =>{
  const image:any = await this.createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx:any = canvas.getContext("2d");

  const orientationChanged =
    rotation === 90 ||
    rotation === -90 ||
    rotation === 270 ||
    rotation === -270;
  if (orientationChanged) {
    canvas.width = image.height;
    canvas.height = image.width;
  } else {
    canvas.width = image.width;
    canvas.height = image.height;
  }

  ctx.translate(canvas.width / 2, canvas.height / 2);
  ctx.rotate((rotation * Math.PI) / 180);
  ctx.drawImage(image, -image.width / 2, -image.height / 2);

  return new Promise((resolve) => {
    canvas.toBlob((file:any) => {
      resolve(URL.createObjectURL(file));
    }, "image/jpeg");
  });
}


  onFileChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    cropSize: { width: number; height: number }
  ) => {
    const { initialCropSize } = this.state;
    if (e.target.files && e.target.files.length > 0) {
      this.setState({
        isModal: true,
        isImgLoading: true,
      });
      const file = e.target.files[0];
      const fileSize = await this.getSize(file);
      const imageDataUrl = await this.readFile(file);
      initialCropSize.width = cropSize.width;
      initialCropSize.height = cropSize.height;
      initialCropSize.x = fileSize.width / 2;
      initialCropSize.y = fileSize.height / 2;

      this.setState({
        imageSrc: imageDataUrl as string,
        cropSize,
        initialCropSize,
        isImgLoading: false,
        imageOriginalSrc: file,
      });
    }
  };

  showModal = (status: boolean) => {
    this.setState({ isModal: status });
  };

  showLoader = (status: boolean) => {
    this.setState({ isLoading: status });
  };

  showImgLoader = (status: boolean) => {
    this.setState({ isImgLoading: status });
  };

  onCropChange = (crop: { x: number; y: number }) => {
    this.setState({ crop });
  };

  onZoomChange = (zoom: number) => {
    this.setState({ zoom });
  };

  onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    this.setState({ croppedAreaPixels });
  };

  blobToFile = (theBlob: Blob, fileName: string): File => {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
    });
  };

  cropImage = async () => {
    try {
      const { imageSrc, croppedAreaPixels, imageOriginalSrc } = this.state;
      this.setState({ isLoading: true });
      const croppedImage:any = await this.getCroppedImg(imageSrc, croppedAreaPixels);
      // const file = this.blobToFile(croppedImage.file, imageOriginalSrc!.name);
          const file = new File([croppedImage.file], imageOriginalSrc!.name, {
            type: croppedImage.file.type || "image/jpeg", // Set a default MIME type if not provided
          });
      this.setState(
        {
          isModal: false,
          isLoading: false,
        },
        () => {
          const data = {
            status: "success",
            image: file,
            url: croppedImage.image,
          };
          this.props.onComplete(data);
        }
      );
    } catch (e) {
      console.error(e);
    }
  };

  readFile = (file: File): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener("load", () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  };

  getSize = (file: File): Promise<{ width: number; height: number }> => {
    return new Promise((resolve) => {
      const img = new Image();
      img.src = window.URL.createObjectURL(file);
      img.onload = () => {
        resolve({ width: img.width, height: img.height });
      };
    });
  };

  dataURLtoFile = (dataurl: string, filename: string = "file.jpeg"): File => {
    const arr: any = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };

  cancelCrop = () => {
    this.setState({
      isModal: false,
      isLoading: false,
      isImgLoading: false,
      imageSrc: "",
    });
    this.props.onComplete({ status: "cancel", croppedImage: "" });
  };

  render() {
    const {
      imageSrc,
      cropSize,
      initialCropSize,
      isModal,
      isLoading,
      isImgLoading,
    } = this.state;
    return (
      <>
        <Modal
          size="lg"
          show={isModal}
          onHide={() => this.cancelCrop()}
          className="cuisine-modal mt-5"
        >
          <Modal.Header closeButton>
            <Modal.Title>Upload Image</Modal.Title>
          </Modal.Header>
          <Modal.Body className="cropApp">
            {isImgLoading}
            {imageSrc && !isImgLoading && (
              <div className="cropContainer" style={{ height: "50vh" }}>
                <Cropper
                  image={imageSrc}
                  crop={this.state.crop}
                  zoom={this.state.zoom}
                  aspect={this.state.aspect}
                  cropSize={cropSize}
                  cropShape={this.props.shape}
                  initialCroppedAreaPixels={initialCropSize}
                  zoomWithScroll={false}
                  onCropChange={this.onCropChange}
                  onCropComplete={this.onCropComplete}
                  onZoomChange={this.onZoomChange}
                />
              </div>
            )}
          </Modal.Body>
          <div className="d-flex justify-content-center">
            <input
              type="range"
              value={this.state.zoom}
              min={1}
              max={6}
              step={0.1}
              aria-labelledby="Zoom"
              onChange={(e) => this.setState({ zoom: Number(e.target.value) })}
              className="zoom-range"
            />
          </div>
          <Modal.Footer className="cropFooter">
            {/* <Button className="config-btn" onClick={this.cropImage} disabled={isLoading}>
              {isLoading ? "Cropping..." : "Crop Image"}
            </Button>
            <Button className="config-cancel" disabled={isLoading} onClick={this.cancelCrop}>
              Cancel
            </Button> */}
            <Button
              variant="success"
              size="sm"
              onClick={this.cropImage}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Spinner className="spinner-border-sm me-1" color="white" />
                  Cropping...
                </>
              ) : (
                <>
                  <i className="ri-crop-line"></i> Crop Image
                </>
              )}
            </Button>
            <Button
              variant="light"
              size="sm"
              onClick={this.cancelCrop}
              disabled={isLoading}
            >
              <i className="ri-close-line" /> Cancel
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export { CropIt };
