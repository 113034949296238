import { useState } from "react";
import {
  Card,
  Col,
  Row,
  ListGroup,
  Dropdown,
  Button,
  Form,
} from "react-bootstrap";
import { ReactSortable } from "react-sortablejs";
import BreadCrumb from "elements/System/BreadCrumb";
import Modal from "react-bootstrap/Modal";
import Select from "react-select";

interface DataType {
  id: number;
  name: string;
}

const MovableCard2 = ({ item }: { item: DataType }) => {
  return (
    <ListGroup className="fs-pick-list">
      <ListGroup.Item className="py-1">
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-center gap-2">
            <i className="ri-menu-line crm-cdrag" />
            <p className="mb-0">{item.name}</p>
          </div>
          <div>
            <i className="ri-thumb-up-fill" />
            {/* <i className="ri-thumb-down-fill" /> */}
            <i className="ri-close-line crm-cdrag ms-1" />
          </div>
        </div>
      </ListGroup.Item>
    </ListGroup>
  );
};
const Pipeline = () => {
  const [stagePopup, setStageShow] = useState(false);
  const stageClose = () => setStageShow(false);
  const stageShow = () => setStageShow(true);

  const [data1, setData1] = useState<DataType[]>([
    {
      id: 1,
      name: "Qualification",
    },
    {
      id: 2,
      name: "Value Proposition",
    },
    {
      id: 3,
      name: "Proposal/Price Quote",
    },
  ]);

  const [data2, setData2] = useState<DataType[]>([
    {
      id: 1,
      name: "Stage A",
    },
    {
      id: 2,
      name: "Stage B",
    },
    {
      id: 3,
      name: "Stage C",
    },
  ]);

  return (
    <>
      <BreadCrumb
        breadCrumbItems={[
          { label: "Settings", path: "/Settings/pipeline" },
          { label: "Pipeline", path: "/Settings/pipeline", active: true },
        ]}
        title={"Pipeline"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4 className="header-title">Pipelines</h4>
              <p className="text-muted fs-14 mb-3">
                This page allows you to manage various sales processes by
                organising the Lead stages in different pipelines.
              </p>

              <Row
                data-plugin="dragula"
                data-containers='["data-list-left", "data-list-right"]'
              >
                <Col md={6}>
                  <Card className="bg-light-subtle border border-secondary-subtle bg-opacity-50">
                    <Card.Body className="py-1 px-2 border-bottom border-light">
                      <div className="float-start">
                        <label className="form-check-label fs-16">Lead</label>
                      </div>
                      <div className="float-end">
                        <Dropdown align="end">
                          <Dropdown.Toggle
                            variant="light"
                            className="dropdown-toggle arrow-none card-drop p-0"
                          >
                            <i className="ri-more-fill px-1" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item as="a" href="#">
                              <i className="ri-settings-3-line me-1" />
                              Set as default
                            </Dropdown.Item>
                            <Dropdown.Item as="a" href="#">
                              <i className="ri-save-line me-1" />
                              Clone
                            </Dropdown.Item>
                            <Dropdown.Item as="a" href="#">
                              <i className="ri-delete-bin-6-line me-1" />
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="float-end">
                        <Button
                          type="button"
                          className="btn-sm btn btn btn-light me-1"
                          onClick={stageShow}
                        >
                          <i className="ri-add-line" />
                        </Button>
                      </div>
                    </Card.Body>
                    <Card.Body className="p-1">
                      <ReactSortable
                        group="dataList1"
                        list={data1}
                        setList={setData1}
                        className="py-2"
                      >
                        {(data1 || []).map((item, idx) => {
                          return <MovableCard2 key={idx} item={item} />;
                        })}
                      </ReactSortable>
                    </Card.Body>
                    <Card.Body className="border-top p-1">
                      <Button
                        type="button"
                        className="btn-sm btn btn btn-outline-primary me-1"
                      >
                        <i className="ri-save-line" /> Save
                      </Button>
                      <Button
                        type="button"
                        className="btn-sm btn btn btn-outline-secondary me-1"
                      >
                        <i className="ri-close-line" /> Cancel
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>

                <Col md={6}>
                  <Card className="bg-light-subtle border border-secondary-subtle bg-opacity-50">
                    <Card.Body className="py-1 px-2 border-bottom border-light">
                      <div className="float-start">
                        <label className="form-check-label fs-16">Lead</label>
                      </div>
                      <div className="float-end">
                        <Dropdown align="end">
                          <Dropdown.Toggle
                            variant="light"
                            className="dropdown-toggle arrow-none card-drop p-0"
                          >
                            <i className="ri-more-fill px-1" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item as="a" href="#">
                              <i className="ri-save-line me-1" />
                              Clone
                            </Dropdown.Item>
                            <Dropdown.Item as="a" href="#">
                              <i className="ri-delete-bin-6-line me-1" />
                              Delete
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div className="float-end">
                        <Button
                          type="button"
                          className="btn-sm btn btn btn-light me-1"
                          onClick={stageShow}
                        >
                          <i className="ri-add-line" />
                        </Button>
                      </div>
                    </Card.Body>
                    <Card.Body className="p-1">
                      <ReactSortable
                        group="dataList1"
                        list={data2}
                        setList={setData2}
                        className="py-2"
                      >
                        {(data2 || []).map((item, idx) => {
                          return <MovableCard2 key={idx} item={item} />;
                        })}
                      </ReactSortable>
                    </Card.Body>
                    <Card.Body className="border-top p-1">
                      <Button
                        type="button"
                        className="btn-sm btn btn btn-outline-primary me-1"
                      >
                        <i className="ri-save-line" /> Save
                      </Button>
                      <Button
                        type="button"
                        className="btn-sm btn btn btn-outline-secondary me-1"
                      >
                        <i className="ri-close-line" /> Cancel
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal show={stagePopup} size="lg" scrollable={true} onHide={stageClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Stage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Stage Name</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="Enter Stage name"
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Probability</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="Enter Stage name"
                ></Form.Control>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Deal Category</Form.Label>
                <Select
                  className="select2 z-5"
                  classNamePrefix="react-select"
                />
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Forecast Category</Form.Label>
                <Select
                  className="select2 z-5"
                  classNamePrefix="react-select"
                />
              </Form.Group>
            </Col>
          </Row>
          <div className="pt-2 text-end">
            <Button
              type="button"
              className="btn-sm btn btn btn-outline-success"
            >
              <i className="ri-add-line" /> Add Stage
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Pipeline;