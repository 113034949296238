//package
import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Form, InputGroup, Card, Col, Row } from "react-bootstrap";

// includes
import AmlHashForm from "aml-hash-form";
import { api, common } from "includes";

export default function ResetPassword() {
  // const
  const navigate = useNavigate();
  const { token } = useParams();

  // state
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirm, setShowConfirm] = useState<boolean>(false);
  const [count, setCount] = useState<number>(5);
  const [utils, setUtils] = useState<any>({
    passwordSubmit: false,
    tokenStatus: "success",
    pwdErr: null,
    redirecting: false,
  });

  // form

  const formFields = {
    password: {
      validate: [
        "req#Password is required!",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
      ],
    },
    confirmpassword: {
      validate: [
        "req#Password is required!",
        "min:8#Password should be minimum 8 Digits",
        "max:15#Password should be maximum 15 Digits",
        "sameAsField:password#Password and confirm password does not match",
      ],
    },
  };

  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // effect

  useEffect(() => {
    common.tempDataRemove();
  }, []);

  useEffect(() => {
    if (utils.tokenStatus != "success" && count > 0 && !utils.redirecting) {
      const countdown = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);

      return () => {
        clearInterval(countdown);
      };
    } else if (
      utils.tokenStatus != "success" &&
      count === 0 &&
      !utils.redirecting
    ) {
      utilsState("redirecting", true);
      navigate("/login");
    }
  }, [utils.tokenStatus, count, utils.redirecting]);

  //event
  const onSubmit = () => {
    let isValid = handleSubmit();
    if (isValid) {
      ResetPassword();
    }
  };

  // api
  const ResetPassword = () => {
    utilsState("passwordSubmit", true);
    let payload = {
      token: token,
      password: fields.password,
    };

    let data = {
      url: "/auth/password/reset",
      method: "POST",
      auth: "none",
      body: payload,
    };
    api.call(data, (res: any) => {
      if (res.status === 200) {
        utilsState("tokenStatus", "success");
        navigate("/password/success");
      } else if (res.status === 400) {
        if (res.code == "token_expired") {
          utilsState("tokenStatus", "tokenExpired");
        } else if ((res.code = "password_same")) {
          utilsState("pwdError", res.message);
        }
      } else {
        common.notify("E", "Cannot Connect to Server, Please try Again!");
      }

      utilsState("passwordSubmit", false);
    });
  };

  // support

  const utilsState = (label: any, value: any) => {
    setUtils((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };
  //render
  return (
    <Row className="justify-content-center">
      <Col xxl={4} lg={5}>
        <Card>
          <Card.Header className="py-4 text-center bg-primary">
            <Link to="/">
              <span>
                <img src={common.loadImg("logo.png")} alt="logo" height="30" />
              </span>
            </Link>
          </Card.Header>
          <Card.Body className="p-4">
            {utils.tokenStatus == "tokenExpired" && (
              <div className="text-center w-75 mx-auto">
                <h4 className={`text-dark-50 text-center pb-0 fw-bold`}>
                  Token Expired
                </h4>
                <div className="logout-icon mx-auto mb-2">
                  <i className="ri-error-warning-line auto-card-icon"></i>
                </div>
                <h4 className={`text-dark-50 text-center pb-0 fw-bold`}>
                  Reset Token has Expired! Try Again!!
                </h4>{" "}
                <p className="text-muted mb-2">
                  Redirecting to Login in 5 seconds...
                </p>{" "}
                <div className="countdown-container">
                  <h2 className="my-2">{count}</h2>
                </div>
              </div>
            )}
            {utils.tokenStatus == "success" && (
              <>
                <div className="text-center w-75 m-auto">
                  <h4 className={`text-dark-50 text-center pb-0 fw-bold`}>
                    Reset Password
                  </h4>
                  <p className="text-muted mb-4">Reset your password here.</p>
                </div>
                <form onSubmit={() => {}}>
                  <Form.Group className={"mb-3"}>
                    <Form.Label>New Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        isInvalid={
                          utils.pwdError != null || errors.password
                            ? true
                            : false
                        }
                        name="password"
                        placeholder="Enter new password"
                        value={fields.password}
                        onChange={handleChange}
                      />
                      <div
                        className={`input-group-text input-group-password ${
                          showPassword ? "" : "show-password"
                        }`}
                        data-password={showPassword ? "true" : "false"}
                      >
                        <span
                          className="password-eye"
                          onClick={() => setShowPassword(!showPassword)}
                        ></span>
                      </div>

                      <Form.Control.Feedback type="invalid">
                        {utils.pwdError != null
                          ? utils.pwdError
                          : errors.password}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className={"mb-3"}>
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={showConfirm ? "text" : "password"}
                        isInvalid={errors.confirmpassword ? true : false}
                        name="confirmpassword"
                        placeholder="Re-enter new password"
                        value={fields.confirmpassword}
                        onChange={handleChange}
                      />
                      <div
                        className={`input-group-text input-group-password ${
                          showConfirm ? "" : "show-password"
                        }`}
                        data-password={showConfirm ? "true" : "false"}
                      >
                        <span
                          className="password-eye"
                          onClick={() => setShowConfirm(!showConfirm)}
                        ></span>
                      </div>
                      <Form.Control.Feedback type="invalid">
                        {errors.confirmpassword}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <div className="mb-3 text-center">
                    <Button
                      variant="primary"
                      type="button"
                      onClick={onSubmit}
                      disabled={utils.passwordSubmit ? true : false}
                    >
                      {utils.passwordSubmit && (
                        <span
                          role="status"
                          className="spinner-border text-white  spinner-border-sm me-2"
                        ></span>
                      )}
                      {utils.passwordSubmit ? "Processing.." : "Reset Password"}
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Card.Body>
        </Card>
        <Row className="mt-3">
          <Col className="text-center">
            <p className="text-muted bg-body">
              Want to Go Back?
              <Link
                to="/login"
                className="text-muted ms-1 link-offset-3 text-decoration-underline"
              >
                <b>Sign In</b>
              </Link>
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
