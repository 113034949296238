import { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "elements/System/BreadCrumb";
import {
  Card,
  Col,
  Row,
  Table,
  Form,
  Button,
  ButtonGroup,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Spinner,
  ListGroup,
  Pagination as BootstrapPagination,
} from "react-bootstrap";
import { ReactSortable } from "react-sortablejs";
import Modal from "react-bootstrap/Modal";
import Offcanvas from "react-bootstrap/Offcanvas";

interface ItemType {
  id: number;
  key: string;
  name: string;
  is_system: boolean;
}
interface columnDataType {
  id: number;
  name: string;
}

const ColumnFilterData = ({ item }: { item: columnDataType }) => {
  return (
    <ListGroup className="fs-pick-list ">
      <ListGroup.Item className="p-0">
        <div className="d-flex align-items-center justify-content-between gap-2 p-1 column-filteritem-bg">
          <div className="d-flex align-center">
            {/* <i className="ri-draggable crm-cdrag" /> */}
            <Form.Check id={item.name} label={item.name} />
            <p className="mb-0"></p>
          </div>
          <div>
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="">Informatio here...</Tooltip>}
            >
              <i className="ri-information-fill me-1 text-warning" />
            </OverlayTrigger>
            <i className="ri-draggable crm-cdrag" />
          </div>
        </div>
      </ListGroup.Item>
    </ListGroup>
  );
};

export default function ListView() {
  const [columnFilerShow, setColumnFilterShow] = useState(false);
  const columnFilterClose = () => setColumnFilterShow(false);
  const columnFilterShow = () => setColumnFilterShow(true);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [columnData, setColumnData] = useState<columnDataType[]>([
    {
      id: 1,
      name: "Lead Name",
    },
    {
      id: 2,
      name: "Email",
    },
    {
      id: 3,
      name: "Type",
    },
    {
      id: 4,
      name: "Department",
    },
    {
      id: 5,
      name: "Module",
    },
    {
      id: 6,
      name: "Active",
    },
  ]);

  return (
    <Fragment>
      <BreadCrumb
        breadCrumbItems={[
          { label: "Settings", path: "/settings/home" },
          { label: "Module Customization", path: "/module/list", active: true },
        ]}
        title={"Module Customization"}
      />
      <Row>
        <Col xl={12} lg={12}>
          <Card>
            <Card.Body>
              <div className="text-center py-5">
                <Spinner className="spinner-grow text-primary avatar-sm m-2" />
                <h4 className="header-title pt-2">Loading ...</h4>
              </div>
            </Card.Body>
            <Card.Body>
              <div className="text-center py-5">
                <i className="me-1 ri-error-warning-line fs-48" />
                <h4 className="header-title">Error!</h4>
                <Button variant="primary">
                  <i className="ri-refresh-line me-1" />
                  Try Again!
                </Button>
              </div>
            </Card.Body>
            <Card.Body>
              <div className="text-center py-5">
                <i className="me-1 ri-database-2-line fs-48" />
                <h4 className="header-title">No data Found !</h4>
              </div>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <div className="d-flex align-items-center justify-content-between">
                <div className="float-start">
                  <label className="form-check-label fs-16">Module Name</label>
                </div>
                <div id="tooltips-container d-flex align-items-center">
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="">Refresh</Tooltip>}
                  >
                    <Button variant="light" size="sm" className="me-1">
                      <i className="ri-refresh-line fs-16" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="">Column Filter</Tooltip>}
                  >
                    <Button
                      variant="light"
                      size="sm"
                      className="me-1 d-felx align-items-center"
                      onClick={columnFilterShow}
                    >
                      <i className=" ri-check-double-line fs-16" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="">Filter</Tooltip>}
                  >
                    <Button
                      variant="light"
                      //   variant={isFilter ? "warning" : "light"}
                      size="sm"
                      className="me-1"
                      onClick={handleShow}
                    >
                      <i className="ri-filter-line fs-16" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="">Clear Filter</Tooltip>}
                  >
                    <Button variant="light" size="sm" className="me-1">
                      <i className="ri-filter-off-line fs-16" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="">List View</Tooltip>}
                  >
                    <Button variant="light" size="sm" className="me-1">
                      <i className="ri-bar-chart-horizontal-line fs-16" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="">Kanban View</Tooltip>}
                  >
                    <Button variant="light" size="sm" className="me-1">
                      <i className="ri-bar-chart-line fs-16" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="">Export</Tooltip>}
                  >
                    <Button
                      variant="secondary"
                      size="sm"
                      className="me-1 d-felx align-items-center"
                    >
                      <i className="ri-upload-2-line fs-16" />
                      <span className="ms-1">Export</span>
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="">Add New</Tooltip>}
                  >
                    <Button
                      variant="primary"
                      size="sm"
                      className="me-1 d-felx align-items-center"
                    >
                      <i className="ri-add-line fs-16" />
                      <span className="ms-1">Add New</span>
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="">Action</Tooltip>}
                  >
                    <ButtonGroup as={Dropdown} size="sm">
                      <Dropdown.Toggle
                        variant="light"
                        className="arrow-none"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="ri-more-2-line fs-16" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item as="a" href="#">
                          <i className="me-1 ri-mail-line" />
                          Mass Email
                        </Dropdown.Item>
                        <Dropdown.Item as="a" href="#">
                          <i className="me-1 ri-download-2-line" />
                          Mass Import
                        </Dropdown.Item>
                        <Dropdown.Item as="a" href="#">
                          <i className="me-1 ri-save-line" />
                          Mass Update
                        </Dropdown.Item>
                        <Dropdown.Item as="a" href="#">
                          <i className="me-1 ri-delete-bin-6-line" />
                          Mass Delete
                        </Dropdown.Item>
                        <Dropdown.Item as="a" href="#">
                          <i className="me-1 ri-file-transfer-line" />
                          Mass Transfer
                        </Dropdown.Item>
                        <Dropdown.Item as="a" href="#">
                          <i className="me-1 ri-folder-transfer-line" />
                          Mass Conversion
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </ButtonGroup>
                  </OverlayTrigger>
                </div>
              </div>
              <Table
                className="table-sm table-hover table-centered mb-0 table-fixed-col"
                responsive
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        padding: "4px",
                      }}
                    >
                      <Form.Check id="customCheck1" label="" />
                    </th>
                    <th>
                      Name <i className="ri-arrow-up-down-line ms-2" />
                    </th>
                    <th>Type</th>
                    <th>Active</th>
                    <th>Department</th>
                    <th>Status</th>
                    <th>Created Date</th>
                    <th>Updated Date</th>
                    <th>Label Name</th>
                    <th>Label Name</th>
                    <th>Label Name</th>
                    <th>Label Name</th>
                    <th>Label Name</th>
                    <th>Label Name</th>
                    <th>Label Name</th>
                    <th>Label Name</th>
                    <th>Label Name</th>
                    <th>Label Name</th>
                    <th>Label Name</th>
                    <th>Label Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ display: "flex", alignItems: "center" }}>
                      <Form.Check id="customCheck1" label="" />
                      <Dropdown>
                        <Dropdown.Toggle
                          as={Link}
                          to="#"
                          className="arrow-none card-drop"
                        >
                          <i className="ri-more-line" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="start">
                          <Dropdown.Item className="dropdown">
                            <i className="me-1 ri-edit-line" />
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item className="dropdown">
                            <i className="me-1 ri-delete-bin-6-line" />
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td className="td-plain-link">
                      <Link to="#">Tunku Ousmand</Link>
                    </td>
                    <td>Marketing</td>
                    <td>
                      <span className="badge bg-primary">Yes</span>
                    </td>
                    <td>Administration</td>
                    <td>
                      <span className="badge bg-success">Approved</span>
                    </td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                  </tr>
                  <tr>
                    <td style={{ display: "flex", alignItems: "center" }}>
                      <Form.Check id="customCheck1" label="" />
                      <Dropdown>
                        <Dropdown.Toggle
                          as={Link}
                          to="#"
                          className="arrow-none card-drop"
                        >
                          <i className="ri-more-line" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="start">
                          <Dropdown.Item className="dropdown">
                            <i className="me-1 ri-edit-line" />
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item className="dropdown">
                            <i className="me-1 ri-delete-bin-6-line" />
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td className="td-plain-link">
                      <Link to="#">Tunku Ousmand</Link>
                    </td>
                    <td>Marketing</td>
                    <td>
                      <span className="badge bg-primary">Yes</span>
                    </td>
                    <td>Administration</td>
                    <td>
                      <span className="badge bg-success">Approved</span>
                    </td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                    <td>Dummy Text</td>
                  </tr>
                  <tr>
                    <td style={{ display: "flex", alignItems: "center" }}>
                      <Form.Check id="customCheck1" label="" />
                      <Dropdown>
                        <Dropdown.Toggle
                          as={Link}
                          to="#"
                          className="arrow-none card-drop"
                        >
                          <i className="ri-more-line" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="start">
                          <Dropdown.Item className="dropdown">
                            <i className="me-1 ri-edit-line" />
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item className="dropdown">
                            <i className="me-1 ri-delete-bin-6-line" />
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td className="td-plain-link">
                      <Link to="#">Tunku Ousmand</Link>
                    </td>
                    <td>Marketing</td>
                    <td>
                      <span className="badge bg-primary">Yes</span>
                    </td>
                    <td>Administration</td>
                    <td>
                      <span className="badge bg-success">Approved</span>
                    </td>
                    <td>Dummy</td>
                    <td>Dummy</td>
                    <td>Dummy</td>
                    <td>Dummy</td>
                    <td>Dummy</td>
                    <td>Dummy</td>
                    <td>Dummy</td>
                    <td>Dummy</td>
                    <td>Dummy</td>
                    <td>Dummy</td>
                    <td>Dummy</td>
                    <td>Dummy</td>
                    <td>Dummy</td>
                    <td>Dummy</td>
                  </tr>
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card>
            <Card.Body className="py-2 px-3 border-bottom border-light">
              <div className="d-flex align-items-center justify-content-between">
                <div className="float-start">
                  <label className="form-check-label fs-16">Title</label>
                </div>
                <div>Button</div>
              </div>
            </Card.Body>

            <Card.Body>Table</Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Column Filter */}
      <Modal
        show={columnFilerShow}
        size="sm"
        scrollable={true}
        onHide={columnFilterClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Manage Column</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group flex-nowrap">
            <span className="input-group-text">
              <i className="ri-refresh-line fs-16" />
            </span>
            <Form.Control type="text" placeholder="Search" />
          </div>
          {/* <div className="mb-1">
            <Form.Check id="check1" label="Lead Name" />
          </div>
          <div className="mb-1">
            <Form.Check id="check2" label="Lead Status" />
          </div>
          <div className="mb-1">
            <Form.Check id="check1" label="Lead Name" />
          </div>
          <div className="mb-1">
            <Form.Check id="check2" label="Lead Status" />
          </div>
          <div className="mb-1">
            <Form.Check id="check1" label="Lead Name" />
          </div>
          <div className="mb-1">
            <Form.Check id="check2" label="Lead Status" />
          </div>
          <div className="mb-1">
            <Form.Check id="check1" label="Lead Name" />
          </div>
          <div className="mb-1">
            <Form.Check id="check2" label="Lead Status" />
          </div>
          <div className="mb-1">
            <Form.Check id="check1" label="Lead Name" />
          </div>
          <div className="mb-1">
            <Form.Check id="check2" label="Lead Status" />
          </div>
          <div className="mb-1">
            <Form.Check id="check1" label="Lead Name" />
          </div>
          <div className="mb-1">
            <Form.Check id="check2" label="Lead Status" />
          </div>
          <div className="mb-1">
            <Form.Check id="check1" label="Lead Name" />
          </div>
          <div className="mb-1">
            <Form.Check id="check2" label="Lead Status" />
          </div>
          <div className="mb-1">
            <Form.Check id="check1" label="Lead Name" />
          </div>
          <div className="mb-1">
            <Form.Check id="check2" label="Lead Status" />
          </div>
          <div className="mb-1">
            <Form.Check id="check1" label="Lead Name" />
          </div>
          <div className="mb-1">
            <Form.Check id="check2" label="Lead Status" />
          </div> */}
          <ReactSortable
            group="dataList1"
            list={columnData}
            setList={setColumnData}
            className="py-2"
          >
            {(columnData || []).map((item, idx) => {
              return <ColumnFilterData key={idx} item={item} />;
            })}
          </ReactSortable>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light">Show All</Button>
          <Button variant="light">Hide All</Button>
          <Button variant="primary">Save</Button>
        </Modal.Footer>
      </Modal>

      {/* Filter */}
      <Offcanvas
        placement="top"
        show={show}
        onHide={handleClose}
        className="w-50 mx-auto"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas>
    </Fragment>
  );
}
