// package
import { Fragment, useState, useEffect } from "react";
import { Card, Col, Row, Button, Form, Table, Spinner } from "react-bootstrap";
import BreadCrumb from "elements/System/BreadCrumb";
import { useNavigate } from "react-router-dom";

// component
import AgStatus from "elements/System/AgStatus";
import { common, api } from "includes";

export default function MappingHome() {
  // const
  const navigate = useNavigate();

  // state
  const [process, setProcess] = useState<any>({ result: 100 });
  const [submit, setSubmit] = useState<String>("");
  const [resultList, setResultList] = useState<any>({});

  // effect
  useEffect(() => {
    getData();
  }, []);

  // api
  const getData = () => {
    processState("result", 100);
    let data: any = {
      url: "/conversion/mapping",
      method: "GET",
    };
    api.call(data, (res: any) => {
      processState("result", res.status);
      if (res.status === 200) {
        let result = res.data;
        let leads = result.leads.sort(common.compare);
        result.leads = leads;
        setResultList(result);
      }
    });
  };

  const onSubmit = () => {
    setSubmit("mapping");
    let params: any = {
      mapping: resultList?.mapping,
    };
    let payload = {
      url: "/conversion/mapping",
      method: "PUT",
      body: params,
    };
    api.call(payload, () => {
      setSubmit("");
      common.notify("S", "Mapping updated successfully");
      window.scrollTo(0, 0);
    });
  };

  // event
  const onCancel = () => {
    navigate("/settings/home");
  };

  const mapping = (key: string, value: any, module: string) => {
    value = value || null;
    setResultList((oldData: any) => {
      let newData = common.reParse(oldData);
      if (newData.mapping && newData.mapping[key]) {
        let mapValues = newData.mapping[key];
        if (module == "accounts") {
          mapValues[0] = value;
        } else if (module == "contacts") {
          mapValues[1] = value;
        } else if (module == "deals") {
          mapValues[2] = value;
        }
      } else if (newData.mapping) {
        newData.mapping[key] =
          module == "contacts"
            ? [null, value, null]
            : module == "deals"
              ? [null, null, value]
              : [value, null, null];
      } else {
        newData.mapping = {
          [key]:
            module == "contacts"
              ? [null, value, null]
              : module == "deals"
                ? [null, null, value]
                : [value, null, null],
        };
      }
      return newData;
    });
  };

  // callback
  const onAgCallBack = (status: any) => {
    getData();
  };

  // support
  const getOptions = (field: any, module: string) => {
    let filtered = [];
    if (field.type == "lookup") {
      let excludeModuleKeys: any = [];
      if (module === "contacts") {
        excludeModuleKeys = ["lookup_2"];
      } else if (module === "deals") {
        excludeModuleKeys = ["lookup_2"];
      }

      filtered = resultList[module].filter(
        (obj: any) =>
          obj.variable === field.variable &&
          obj.type === field.type &&
          obj.attr.multi === field.attr.multi &&
          field.attr.lookup === obj.attr.lookup &&
          !excludeModuleKeys.includes(obj?.key)
      );
    } else if (field.type == "number") {
      filtered = resultList[module].filter(
        (obj: any) => obj.variable === field.variable && obj.type === field.type && obj.format === field.format
      );
    } else if (field.type == "text") {
      let excludeModuleKeys: any = [];
      if (module === "contacts") {
        excludeModuleKeys = ["text_1", "text_2"];
      } else if (module === "accounts") {
        excludeModuleKeys = ["text_1"];
      } else if (module === "deals") {
        excludeModuleKeys = ["text_1"];
      }
      filtered = resultList[module].filter(
        (obj: any) =>
          obj.variable === field.variable && obj.type === field.type && !excludeModuleKeys.includes(obj?.key)
      );
    } else if (field.type == "date") {
      let excludeModuleKeys: any = [];
      if (module === "deals") {
        excludeModuleKeys = ["date_1"];
      }
      filtered = resultList[module].filter(
        (obj: any) =>
          obj.variable === field.variable && obj.type === field.type && !excludeModuleKeys.includes(obj?.key)
      );
    } else {
      filtered = resultList[module].filter((obj: any) => obj.variable === field.variable && obj.type === field.type);
    }
    let result = filtered.length > 0 ? filtered.sort(common.compare) : [];
    return (
      <Form.Select
        aria-label="Default select"
        value={getSelected(field, module)}
        onChange={(e) => mapping(field.key, e.target.value, module)}
      >
        <option value={""}>None</option>
        {result.map((obj: any, idx: number) => {
          return (
            <option value={obj.key} key={idx}>
              {obj.label}
            </option>
          );
        })}
      </Form.Select>
    );
  };

  const getSelected = (field: any, module: string) => {
    if (resultList.mapping && resultList.mapping[field.key]) {
      let key = resultList.mapping[field.key][0];
      if (module == "contacts") {
        key = resultList.mapping[field.key][1];
      } else if (module == "deals") {
        key = resultList.mapping[field.key][2];
      }
      if (key) {
        let filtered = resultList[module].filter((obj: any) => obj.key == key);
        return filtered.length > 0 ? filtered[0].key : "None";
      } else {
        return "None";
      }
    } else {
      return "None";
    }
  };

  const processState = (label: any, value: any) => {
    setProcess((prev: any) => ({
      ...prev,
      [label]: value
    }));
  }


  // render
  return (
    <Fragment>
      <BreadCrumb
        breadCrumbItems={[
          { label: "Settings", path: "/settings/home" },
          { label: "Mapping", path: "/mapping/home", active: true },
        ]}
        title={"Mapping"}
      />
      <Row>
        <Col>
          {process.result != 200 ? (
            <AgStatus
              process={process.result}
              message={{
                100: "Loading company profile... Please wait!",
                404: "Something went wrong! Please Refresh Again",
              }}
              size="l"
              img="company.svg"
              onAgCallBack={onAgCallBack}
            />
          ) : (
            <Card>
              <Card.Body className="border-bottom py-2">
                <div className="d-flex align-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <h4 className="header-title mb-0">Conversion Mapping</h4>
                  </div>
                  <div>
                    <Button
                      variant="light"
                      className="me-1"
                      onClick={() => onCancel()}
                      disabled={submit === "mapping"}
                    >
                      <i className=" ri-arrow-go-back-line me-1" />
                      Cancel
                    </Button>
                    <Button
                      variant="primary"
                      onClick={() => onSubmit()}
                      disabled={submit === "mapping"}
                    >
                      {submit === "mapping" ? (
                        <>
                          <Spinner
                            className="spinner-border-sm me-1"
                            color="white"
                          />
                          Processing...
                        </>
                      ) : (
                        <>
                          <i className="ri-save-line me-1" />
                          Save
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              </Card.Body>
              <Card.Body>
                <p className="text-muted fs-14 mb-3">
                  Map the fields in the Lead module to the fields in Contact,
                  Account and Deal modules to make Lead conversion easy. With
                  the mapped fields converting a Lead will automatically
                  transfer the information you have gathered to an Contact,
                  Account and Deal.
                </p>

                <div className="d-flex align-items-center justify-content-between border-bottom px-1">
                  <Form.Label>Field Mapping</Form.Label>
                  <p className="text-muted">
                    <span className="text-danger">* </span> Custom Field
                  </p>
                </div>
                <Row>
                  <Table
                    className="table-sm table-hover table-centered table-bordered table-scrollable"
                    responsive
                  >
                    <thead className="table-light">
                      <tr>
                        <th>Lead</th>
                        <th>Account</th>
                        <th>Contact</th>
                        <th>Deal</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resultList.leads.map((field: any, idx: number) => {
                        return (
                          !["text_1", "text_2", "text_3", "lookup_1"].includes(
                            field.key
                          ) && (
                            <tr key={idx}>
                              <td>
                                {field.variable != "system" && (
                                  <span className="text-danger">* </span>
                                )}
                                {field.label}
                              </td>
                              <td>{getOptions(field, "accounts")}</td>
                              <td>{getOptions(field, "contacts")}</td>
                              <td>{getOptions(field, "deals")}</td>
                            </tr>
                          )
                        );
                      })}
                    </tbody>
                  </Table>
                </Row>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>
    </Fragment>
  );
}
