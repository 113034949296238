import { Fragment } from "react";
import BreadCrumb from "elements/System/BreadCrumb";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { common } from "includes";

export default function EmailTemplate() {
  return (
    <Fragment>
      <BreadCrumb
        breadCrumbItems={[{ label: "Email Template", path: "", active: true }]}
        title={"Email Template"}
      />
      <Row className="justify-content-center">
        <Col xxl={10} lg={10}>
          <Card>
            <Card.Header className="py-4 text-center bg-primary">
              <Link to="/">
                <span>
                  <img
                    src={common.loadImg("logo.png")}
                    alt="logo"
                    height="30"
                  />
                </span>
              </Link>
            </Card.Header>
            <Card.Body className="p-4">
              <p className="text-muted fs-14 short-description mb-0">
                Email content will goes here ...
              </p>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}
