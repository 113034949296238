// package
import { useEffect, useState } from "react";
import { Button, Form, Card, Col, Row, Image } from "react-bootstrap";
import { Link } from "react-router-dom";

//includes
import AmlHashForm from "aml-hash-form";
import { api, common } from "includes";

export default function ForgotPassword() {
  // state
  const [email, setEmail] = useState<any>("");
  const [utils, setUtils] = useState<any>({
    emailSubmit: false,
    requestSent: false,
    emailErr: null,
  });

  // form
  const formFields = {
    email: {
      validate: [
        "req#Email is required",
        // "email#Please enter a valid email address",
        "regExp#Please Enter valid email address",
      ],
      pattern: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    },
  };

  const { fields, errors, handleChange, handleSubmit } =
    AmlHashForm(formFields);

  // effect

  useEffect(() => {
    common.tempDataRemove();
  }, []);

  // event
  const onSubmit = () => {
    let isValid = handleSubmit();
    if (isValid) {
      ForgotPassword();
    }
  };

  // api

  const ForgotPassword = () => {
    utilsState("emailSubmit", true);
    let data = {
      url: "/auth/password/forgot",
      method: "POST",
      auth: "none",
      body: fields,
    };
    api.call(data, (res: any) => {
      if (res.status === 200) {
        utilsState("emailErr", null);
        utilsState("requestSent", true);
        setEmail(res?.data?.email);
      } else if (res.status == 400) {
        utilsState("emailErr", res.message);
      } else {
        common.notify("E", "Cannot Connect to Server, Please try Again!");
      }

      utilsState("emailSubmit", false);
    });
  };

  // support

  const utilsState = (label: any, value: any) => {
    setUtils((prev: any) => ({
      ...prev,
      [label]: value,
    }));
  };

  //render

  return (
    <Row className="justify-content-center">
      <Col xxl={4} lg={5}>
        <Card>
          <Card.Header className="py-4 text-center bg-primary">
            <Link to="/">
              <span>
                <img src={common.loadImg("logo.png")} alt="logo" height="30" />
              </span>
            </Link>
          </Card.Header>
          <Card.Body className="p-4">
            {utils.requestSent && (
              <div className="text-center w-75 m-auto">
                <h4 className={`text-dark-50 text-center pb-0 fw-bold`}>
                  Reset Password
                </h4>
                <Image
                  src={common.loadImg("svg/mail_sent.svg")}
                  alt="mail sent icon"
                  height="64"
                />
                <h4 className="text-dark-50 text-center mt-4 fw-bold">
                  Please check your email
                </h4>
                <p className={`text-muted text-center pb-0`}>
                  Reset Password Link Sent to your Email address{" "}
                  <span className="fw-bold">{email}</span>
                </p>
              </div>
            )}
            {!utils.requestSent && (
              <>
                <div className="text-center w-75 m-auto">
                  <h4 className={`text-dark-50 text-center pb-0 fw-bold`}>
                    Forgot Password?
                  </h4>
                  <p className="text-muted mb-4">
                    Enter your email address and we'll send you an email with
                    instructions to reset your password.
                  </p>
                </div>
                <form>
                  <Form.Group className={"mb-3"}>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      onChange={handleChange}
                      isInvalid={
                        errors.email || utils.emailErr != null ? true : false
                      }
                    />
                    <Form.Control.Feedback type="invalid">
                      {utils.emailErr ? utils.emailErr : errors.email}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <div className="mb-3 text-center">
                    <Button
                      variant="primary"
                      type="button"
                      onClick={onSubmit}
                      disabled={utils.emailSubmit ? true : false}
                    >
                      {utils.emailSubmit && (
                        <span
                          role="status"
                          className="spinner-border text-white  spinner-border-sm me-2"
                        ></span>
                      )}
                      {utils.emailSubmit ? "Processing.." : "Proceed"}
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Card.Body>
        </Card>
        <Row className="mt-3">
          <Col className="text-center">
            <p className="text-muted bg-body">
              Want to go back?
              <Link
                to="/login"
                className="text-muted ms-1 link-offset-3 text-decoration-underline"
              >
                <b>Sign In</b>
              </Link>
            </p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
