// package
import { useState, useEffect, Fragment } from "react";
import {
    Card,
    Col,
    Row,
    Dropdown,
    Button,
    Form,
    OverlayTrigger,
    Tooltip,
    Table,
    Spinner,
    Modal,
    Pagination as BootstrapPagination,
} from "react-bootstrap";
import Select from "react-select";
import { Link } from "react-router-dom";
import AmlHashForm from "aml-hash-form";

// component
import AgStatus from "elements/System/AgStatus";
import AgDelete from "elements/System/AgDelete";
import BreadCrumb from "elements/System/BreadCrumb";
import FormSelect from "elements/System/FormSelect";
import { api, common, config } from "includes";

const Target = () => {
    //state
    const [process, setProcess] = useState<any>({ result: 100, edit: 100 });
    const [confirm, setConfirm] = useState<any>({ id: null, type: null });
    const [submit, setSubmit] = useState<String>("");
    const [popup, setPopup] = useState<String>("");
    const [resultList, setResultList] = useState<any>([]);
    const [resultUser, setResultUser] = useState<any>(null);
    const [resultTargetUser, setResultTargetUser] = useState<any>([{
        id: 0,
        target: 0,
        completed: 0,
        balance: 0,
        progress: 0
    }]);
    const [resultTargetField, setResultTargetField] = useState<any>([]);

    const [utils, setUtils] = useState<any>({
        listReload: false,
        userShow: false,
        targetId: null,
        userAdd: null
    });

    const [listPaging, setListPaging] = useState<any>({
        limit: 10,
        page: 0,
        total: 0,
    });
    const [listSorting, setListSorting] = useState({
        field: "id",
        order: "DESC",
        default: "ASC",
    });

    // form
    const formFields = {
        name: { validate: ["req#Please select target title"] },
        year: { validate: ["req#Please select target year"] },
        type: { validate: ["req#Please select target type"] },
        module: {
            validate: ["objValReq#Please select target module"],
            obj: "value",
        },
        field: { validate: ["req#Please select target field"] },
    };

    const {
        fields,
        errors,
        handleChange,
        handleSubmit,
        handleReset,
        setValue,
        setMultiValue,
    } = AmlHashForm(formFields);

    // effect
    useEffect(() => {
        getData();
        getUsers();
    }, []);

    useEffect(() => {
        if (utils.listReload) {
            getData();
        }
    }, [utils.listReload]);

    // api
    const getData = () => {
        let url = `/target/read?type=mp&fields=*&query=&limit=${listPaging.limit
            }&offset=${listPaging.limit * listPaging.page}&order=${listSorting.field} ${listSorting.order}&join=`;

        let data: any = {
            url: url,
            method: "GET",
        };
        api.call(data, (res: any) => {
            processState("result", res.status);
            if (res.status === 200) {
                let result: any = res.data;
                setResultList(result);
                setListPaging((pData: any) => {
                    let nData = common.reParse(pData);
                    nData.limit = nData.limit;
                    nData.page = nData.page;
                    nData.total = res.paging.total;
                    return nData;
                });

                utilsState("listReload", false);
            }
        });
    };

    const getUsers = () => {
        let data: any = {
            url: "/user/read?type=mp&fields=CONCAT(first_name,' ',last_name,'') as name,id&query=&join=&limit=200&offset=0&order=created_at DESC",
            method: "GET",
        };
        api.call(data, (res: any) => {
            processState("result", res.status);
            if (res.status === 200) {
                let result = res.data;
                setResultUser(result);
            }
        });
    };

    const getTargetData = (id?: number) => {
        processState("edit", 100);
        let url;

        if (id) {
            url = `/target/read?type=sp&fields=*&query=id=${id}&field=${fields.field}&limit=1&offset=0&order=id ASC&join=`;
            setPopup("target");
        } else {
            url = `/target/read?type=sp&fields=*&query=year='${fields.year}' and type='${fields.type}' and module='${Object.keys(fields.module)[0]}'&field=${fields.field}&limit=1&offset=0&order=id ASC&join=`;
        }

        let data: any = {
            url: url,
            method: "GET",
        };
        api.call(data, (res: any) => {
            processState("edit", res.status);
            if (res.status === 200) {
                let result: any = res.data;
                if (result) {
                    utilsState("targetId", result.id)
                    setMultiValue({
                        name: result.name,
                        year: result.year,
                        type: result.type,
                        module: result.module,
                        field: result.field
                    });
                    handleReset();

                    let userResult: any = [];
                    Object.keys(result.users).map((user: any) => {
                        let obj = result.users[user];
                        let data = {
                            id: user,
                            ...obj
                        };
                        userResult.push(data);
                    })
                    userResult.push({
                        id: 0,
                        target: 0,
                        completed: 0,
                        balance: 0,
                        progress: 0
                    })
                    setResultTargetUser(userResult);

                    if (utils.targetId && !id) {
                        common.notify("W", "Already you have this target configuration");
                        utilsState("userShow", true);
                    } else {
                        setPopup("target");
                        utilsState("userShow", true);
                    }
                } else {
                    utilsState("userShow", true);
                }
            } else {
                common.notify("E", res.message);
            }
        });
    };

    const getModuleFields = (module: any) => {
        console.log('first', module)
        setMultiValue({
            module: module,
        });
        if (module) {
            let data: any = {
                url: `/module/read?type=sp&fields=*&query=id=${Object.keys(module)[0]}&field=data&limit=1&offset=0&order=id ASC&join=`,
                method: "GET",
                auth: "token",
            };
            api.call(data, (res: any) => {
                if (res.status === 200) {
                    let result: any = res.data;
                    setResultTargetField(columnValidate(result.data.fields));
                } else {
                    setResultTargetField([]);
                    setValue("field", "");
                }
            });
        } else {
            setResultTargetField([]);
            setValue("field", "");
        }
    };

    const onDelete = (isDelete: boolean) => {
        if (isDelete) {
            utilsState("listReload", false)
            let data: any = {
                url: "/target/",
                query: confirm.id,
                method: "DELETE",
                auth: "token",
            };
            api.call(data, (res: any) => {
                if (res.status === 200) {
                    common.notify("S", res.message);
                    utilsState("listReload", true);
                    setConfirm({ id: null, type: "" });

                } else {
                    common.notify("E", res.message);
                }
            });
        } else {
            setConfirm({ id: null, type: "" });
        }
    };

    const onSubmit = () => {
        let isValid = handleSubmit();
        if (isValid) {
            let payload = common.formPayload(fields, []);
            payload.users = {};
            setSubmit("target");
            let userPayLoad: any = {};

            let userList = resultTargetUser.filter((obj: any) => obj.id != 0);
            userList.map((user: any) => {
                let id = user.id;
                userPayLoad[id] = { target: user.target, completed: user.completed, balance: user.balance, progress: user.progress }
            })
            payload.users = userList.length == 0 ? {} : userPayLoad;

            //add
            let param: any = {
                url: utils.targetId ? "/target/update/" : "/target/create",
                query: utils.targetId,
                method: utils.targetId ? "PUT" : "POST",
                body: payload,
            };

            api.call(param, (res: any) => {
                if (res.status === 200) {
                    common.notify("S", res.message);
                    setSubmit("");
                    resetInit();
                    utilsState("listReload", true);
                } else {
                    common.notify("E", res.message);
                    setSubmit("");
                }
            });
        }
    };

    // event
    const onPageChange = (record: any, page: number) => {
        setListPaging((pData: any) => {
            let nData = common.reParse(pData);
            nData.limit = record;
            nData.page = page;
            return nData;
        });
        utilsState("listReload", true);
    };

    const handleSortChange = (field: any) => {
        setListSorting({
            field,
            order: listSorting.field === field ? listSorting.order === "ASC" ? "DESC" : "ASC" : listSorting.default,
            default: listSorting.default,
        });
        utilsState("listReload", true);
    };

    const closeTarget = () => {
        let isValid = handleSubmit();
        if (isValid) {
            getTargetData();
        }
    };

    const addUserTarget = () => {
        if (utils.userAdd) {
            let exist = resultTargetUser.filter((obj: any) => obj.id == Object.keys(utils.userAdd)[0]);
            if (exist.length > 0) {
                common.notify("W", Object.values(utils.userAdd)[0] + " already added");
            } else {
                setResultTargetUser((pData: any) => {
                    let nData = common.reParse(pData);
                    let popped = nData.pop();
                    popped.id = 0;
                    nData.push({
                        id: Object.keys(utils.userAdd)[0],
                        target: 0,
                        completed: 0,
                        balance: 0,
                        progress: 0
                    });
                    nData.push(popped);
                    return nData;
                });
                utilsState("userAdd", null);
            }
        } else {
            common.notify("E", "Please select user");
        }
    }

    const updateTargets = (e: any, idx: number) => {
        setResultTargetUser((pData: any) => {
            let nData = common.reParse(pData);
            nData[idx].target = e.target.value;
            return nData;
        });
    }

    const addNewTarget = () => {
        setPopup("target");
        processState("edit", 200);
    }

    const onDeleteUser = (isDelete: boolean) => {
        if (isDelete) {
            setResultTargetUser((pData: any) => {
                let nData = common.reParse(pData);
                nData.splice(confirm.id, 1)
                return nData;
            });
            setConfirm({ id: null, type: "" });
        } else {
            setConfirm({ id: null, type: "" });
        }
    };

    // support
    const refresh = () => {
        setListSorting({
            field: "created_at",
            order: "DESC",
            default: "ASC",
        });
        resetInit();
        utilsState("listReload", true);
    };

    const resetInit = () => {
        setResultTargetUser([{
            id: 0,
            target: 0,
            completed: 0,
            balance: 0,
            progress: 0
        }]);
        setPopup("");
        utilsState("userShow", false);
        setMultiValue({
            name: "",
            year: "",
            type: "",
            module: null,
            field: ""
        });
        utilsState("targetId", null)
        handleReset();
    }

    const columnValidate = (fields: any) => {
        let res = fields.filter(
            (i: any) =>
                i.status === "use" &&
                i.visibility &&
                i.type == "number" &&
                i.format == "currency"
        );
        return res;
    };

    const getPaging = () => {
        let start = listPaging.limit * listPaging.page + 1;
        let pageEnd =
            listPaging.page === 0 ? listPaging.limit : listPaging.limit * listPaging.page + (listPaging.limit - 1);
        let end = pageEnd < listPaging.total ? pageEnd : listPaging.total;
        return (
            <div>
                Showing {listPaging.page === 0 ? 1 : start} to {end} of {listPaging.total} entries
            </div>
        );
    };

    const getUsername = (id: any) => {
        let filter = resultUser.filter((obj: any) => obj.id == id);
        return filter.length > 0 ? filter[0].name : id;
    }

    const processState = (label: any, value: any) => {
        setProcess((prev: any) => ({
            ...prev,
            [label]: value
        }));
    }

    const utilsState = (label: any, value: any) => {
        setUtils((prev: any) => ({
            ...prev,
            [label]: value
        }));
    }

    // callback
    const onAgCallBack = () => {
        getData();
    };

    // render
    return (
        <>
            <BreadCrumb
                breadCrumbItems={[
                    { label: "Settings", path: "/settings/home" },
                    { label: "Target", path: "/target/home", active: true },
                ]}
                title={"Target"}
            />
            {process.result != 200 ? (
                <Card>
                    <Card.Body className="py-1 px-3 border-bottom border-light">
                        <AgStatus
                            process={process.result}
                            message={{ 100: "Loading Targets...Please wait." }}
                            size="m"
                            onAgCallBack={onAgCallBack}
                        />
                    </Card.Body>
                </Card>
            ) : (
                <Card>
                    <Card.Body className="py-2 px-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="float-start">
                                <label className="form-check-label fs-16">Target</label>
                            </div>
                            <div id="tooltips-container d-flex align-items-center">
                                {resultList.length > 0 && (
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id="">Refresh</Tooltip>}
                                    >
                                        <Button variant="light" size="sm" className="me-1" onClick={() => refresh()}>
                                            <i className="ri-refresh-line fs-16" />
                                        </Button>
                                    </OverlayTrigger>
                                )}

                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id="">Add New</Tooltip>}
                                >
                                    <Button
                                        variant="primary"
                                        size="sm"
                                        className="me-1 d-felx align-items-center"
                                        onClick={() => addNewTarget()}
                                    >
                                        <i className="ri-add-line fs-16" />
                                        <span className="ms-1">Add New</span>
                                    </Button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </Card.Body>
                    <Card.Body className="pt-1">
                        {resultList.length == 0 ? (
                            <AgStatus
                                process={204}
                                message={{
                                    100: "Loading data.Please wait...",
                                    204: `No Target Found!`,
                                    400: "You're trying to access invalid records.",
                                }}
                                button={{ 400: "Refresh" }}
                                size="m"
                                onAgCallBack={onAgCallBack}
                            />
                        ) : (
                            <Fragment>
                                {utils.listReload && (
                                    <div className="list-table-spinner">
                                        <Spinner />
                                    </div>
                                )}
                                {/* Table */}
                                <Table className="table-sm table-centered mb-0" responsive>
                                    <thead className="border-top border-bottom bg-light-subtle border-light">
                                        <tr>
                                            <th>Action</th>
                                            <th onClick={() => handleSortChange("name")}>
                                                Title{" "}
                                                {listSorting.field === "name" && (
                                                    <i
                                                        className={`${listSorting.order === "ASC"
                                                            ? "ri-arrow-down-line"
                                                            : "ri-arrow-up-line"
                                                            } ms-2`}
                                                    />
                                                )}
                                            </th>
                                            <th onClick={() => handleSortChange("year")}>
                                                Year{" "}
                                                {listSorting.field === "year" && (
                                                    <i
                                                        className={`${listSorting.order === "ASC"
                                                            ? "ri-arrow-down-line"
                                                            : "ri-arrow-up-line"
                                                            } ms-2`}
                                                    />
                                                )}
                                            </th>
                                            <th onClick={() => handleSortChange("type")}>Type</th>
                                            <th onClick={() => handleSortChange("module")}>
                                                Module
                                            </th>
                                            <th onClick={() => handleSortChange("field")}>Field</th>
                                            <th onClick={() => handleSortChange("users_total")}>
                                                User Count
                                            </th>
                                            <th onClick={() => handleSortChange("created_at")}>
                                                Created Date
                                            </th>
                                            <th onClick={() => handleSortChange("updated_at")}>
                                                Updated Date
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {resultList.map((item: any, idx: number) => (
                                            <tr key={idx}>
                                                <td style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
                                                    <Dropdown>
                                                        <Dropdown.Toggle
                                                            as={Link}
                                                            to="#"
                                                            className="ms-1 arrow-none card-drop"
                                                        >
                                                            <i className="ri-more-line" />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu align="start" className="target-dropdown-menu">
                                                            <Dropdown.Item
                                                                className="dropdown"
                                                                onClick={() => {
                                                                    getTargetData(item.id)
                                                                }}
                                                            >
                                                                <i className="me-1 ri-edit-line" />
                                                                Edit
                                                            </Dropdown.Item>
                                                            <Dropdown.Item
                                                                className="dropdown"
                                                                onClick={() => setConfirm({ id: item.id, type: "target" })}
                                                            >
                                                                <i className="me-1 ri-delete-bin-6-line" />
                                                                Delete
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                                <td className="td-plain-link">
                                                    <Link
                                                        to="#"
                                                        onClick={() => {
                                                            getTargetData(item.id)
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Link>
                                                </td>
                                                <td>{item.year}</td>
                                                <td>{item.type}</td>
                                                <td>{common.readable(item.module)}</td>
                                                <td>{item.field}</td>
                                                <td>
                                                    <Link
                                                        to="#"
                                                        onClick={() => {
                                                            getTargetData(item.id)
                                                        }}
                                                    >
                                                        {Object.keys(item.users).length}
                                                    </Link>
                                                </td>
                                                <td>
                                                    {common.formatDate(item.created_at, config.elements.dp)}
                                                </td>
                                                <td>
                                                    {item.updated_at ? common.formatDate(item.updated_at, config.elements.dp) : "-"}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                                {listPaging.total > 0 && (
                                    <div className="d-flex align-items-center justify-content-between mt-3">
                                        {getPaging()}
                                        <div className="d-flex align-items-center justify-content-end">
                                            <Select
                                                className="select2 z-5 me-1"
                                                options={config.options.pages}
                                                onChange={(e) => onPageChange(e?.value, 0)}
                                                value={{
                                                    label: listPaging.limit + " Rows",
                                                    value: listPaging.limit,
                                                }}
                                            />

                                            <nav>
                                                <BootstrapPagination className="mb-0">
                                                    <BootstrapPagination.Prev
                                                        disabled={listPaging.page === 0}
                                                        onClick={(e) =>
                                                            onPageChange(listPaging.limit, listPaging.page - 1)
                                                        }
                                                    />
                                                    {[...Array(Math.ceil(listPaging.total / listPaging.limit))].map(
                                                        (x, i) => (
                                                            <BootstrapPagination.Item
                                                                key={i}
                                                                active={i === listPaging.page}
                                                                onClick={(e) => onPageChange(listPaging.limit, i)}
                                                            >
                                                                {i + 1}
                                                            </BootstrapPagination.Item>
                                                        )
                                                    )}
                                                    <BootstrapPagination.Next
                                                        disabled={
                                                            listPaging.page ===
                                                            Math.ceil(listPaging.total / listPaging.limit) - 1
                                                        }
                                                        onClick={(e) =>
                                                            onPageChange(listPaging.limit, listPaging.page + 1)
                                                        }
                                                    />
                                                </BootstrapPagination>
                                            </nav>
                                        </div>
                                    </div>
                                )}
                            </Fragment>
                        )}
                    </Card.Body>
                </Card>
            )}

            {/* Model  */}
            <Modal
                show={popup === "target"}
                scrollable={true}
                size="lg"
                onHide={() => resetInit()}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{utils.targetId ? "Edit" : "Add"} Target</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {process.edit != 200 ? (
                        <AgStatus
                            process={process.edit}
                            message={{ 100: "Loading Target details..." }}
                            size="s"
                            onAgCallBack={onAgCallBack}
                        />
                    ) :
                        <> <Row>
                            <Col md={6}>
                                <Form.Group className="mb-3">
                                    <Form.Label>Title</Form.Label>
                                    <Form.Control
                                        type="text"
                                        id="singleLine"
                                        maxLength={100}
                                        data-toggle="maxlength"
                                        placeholder="Enter Title"
                                        name="name"
                                        value={fields.name}
                                        onChange={handleChange}
                                        isInvalid={errors.name ? true : false}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Label>Module</Form.Label>
                                <FormSelect
                                    url="/module/read"
                                    fields="id,name"
                                    query=""
                                    order="name ASC"
                                    value={fields.module}
                                    fixed={false}
                                    limit={50}
                                    multi={false}
                                    render={"yes"}
                                    async={false}
                                    onSelect={(data: any) => getModuleFields(data)}
                                    error={errors.module}
                                    disabled={utils.targetId}
                                />
                                <p className="error-text">{errors.module}</p>
                            </Col>

                        </Row>
                            <Row>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Year</Form.Label>
                                        <Form.Select
                                            className={errors.year ? "is-invalid" : ""}
                                            value={fields.year}
                                            onChange={(e: any) => setMultiValue({ year: e.target.value })}
                                            disabled={utils.targetId}
                                        >
                                            <option value={""}>Select</option>
                                            {/* {[...Array(5)].map((e, i: number) => <option key={i} value={new Date().getFullYear() - (5 - i)}>{new Date().getFullYear() - (5 - i)}</option>)} */}
                                            {[...Array(10)].map((e, i: number) => (
                                                <option key={i} value={new Date().getFullYear() + i}>
                                                    {new Date().getFullYear() + i}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        <p className="error-text">{errors.year}</p>
                                    </Form.Group>
                                </Col>
                                <Col md={4}>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Type</Form.Label>
                                        <FormSelect
                                            url="targetTypes"
                                            fields=""
                                            query=""
                                            order=""
                                            value={fields.type}
                                            fixed={true}
                                            multi={false}
                                            async={false}
                                            limit={10}
                                            render={"yes"}
                                            onSelect={(data: any) => {
                                                setMultiValue({ "type": data });
                                            }}
                                            error={errors.type}
                                            disabled={utils.targetId}
                                        />
                                        <p className="error-text">{errors.type}</p>
                                    </Form.Group>
                                </Col>

                                <Col md={4}>
                                    <Form.Label>Target Field</Form.Label>
                                    <Form.Select
                                        onChange={(e) => {
                                            setMultiValue({ field: e.target.value });
                                        }}
                                        value={fields.field}
                                        className={errors.field ? "is-invalid" : ""}
                                        disabled={utils.targetId}
                                    >
                                        <option value={""}>Select</option>
                                        {fields.module && (
                                            <option value="count">
                                                Number of {Object.values(fields.module)}
                                            </option>
                                        )}
                                        {resultTargetField.map((field: any, idx: number) => (
                                            <option value={field.key} key={idx}>{field.label}</option>
                                        ))}
                                    </Form.Select>
                                    <p className="error-text">{errors.field}</p>
                                </Col>
                            </Row>
                            {utils.userShow && (
                                <Row>
                                    <Col className="pt-1">
                                        <Table
                                            className="table-sm table-centered mb-0 mt-2 border"
                                            responsive
                                        >
                                            <thead className="border-top border-bottom bg-light-subtle border">
                                                <tr>
                                                    <th>User</th>
                                                    <th>Target</th>
                                                    <th>Action</th>
                                                    <th>Completed</th>
                                                    <th>Remaining</th>
                                                    <th>Progress</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {resultTargetUser.map((user: any, idx: number) =>
                                                    user.id != 0 ? (
                                                        <tr key={idx}>
                                                            <td className="td-plain-link">{getUsername(user.id)}</td>
                                                            <td>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter Target"
                                                                    value={user.target}
                                                                    onChange={(e) => updateTargets(e, idx)}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Button variant="light" size="sm" onClick={() => setConfirm({ id: idx, type: "user" })}>
                                                                    <i className="ri-delete-bin-line fs-16" />
                                                                </Button>
                                                            </td>
                                                            <td>{user.completed}</td>
                                                            <td>{user.balance}</td>
                                                            <td>{user.progress}</td>
                                                        </tr>
                                                    ) : (
                                                        <tr key={idx}>
                                                            <td className="td-plain-link">Add New</td>
                                                            <td>
                                                                <FormSelect
                                                                    url="/user/read"
                                                                    fields="id,TRIM(CONCAT(first_name,' ',last_name))"
                                                                    query=""
                                                                    order="id ASC"
                                                                    fixed={false}
                                                                    value={utils.userAdd}
                                                                    limit={50}
                                                                    multi={false}
                                                                    render={"yes"}
                                                                    async={false}
                                                                    onSelect={(data: any) =>
                                                                        utilsState("userAdd", data)
                                                                    }
                                                                //   onSelect={(data: any) =>
                                                                //     setMultiValueA({
                                                                //       module: data,
                                                                //     })
                                                                //   }
                                                                //   error={errorsA.module}
                                                                />
                                                            </td>
                                                            <td>
                                                                <Button variant="primary" size="sm" onClick={() => addUserTarget()}>
                                                                    <i className="ri-add-line" />
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    )
                                                )}
                                            </tbody>
                                        </Table>
                                    </Col>
                                </Row>
                            )}</>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {!utils.userShow && (
                        <Button variant="primary" onClick={() => closeTarget()}>
                            Next
                        </Button>
                    )}
                    {utils.userShow && (
                        <>
                            <Button variant="light" onClick={() => resetInit()} disabled={submit === "target"}>
                                <i className=" ri-arrow-go-back-line me-1" />
                                Cancel
                            </Button>
                            <Button variant="success" onClick={() => onSubmit()} disabled={submit === "target"}>
                                {submit === "target" ? (
                                    <>
                                        <Spinner className="spinner-border-sm me-1" color="white" />
                                        Processing...
                                    </>
                                ) : (
                                    <>
                                        <i className="ri-save-line" /> {utils.targetId ? "Update" : "Save"}
                                    </>
                                )}
                            </Button>
                        </>
                    )}
                </Modal.Footer>
            </Modal>

            {confirm.id && (
                <AgDelete
                    type="delete"
                    onAgDeleteCallBack={confirm.type === "target" ? onDelete : onDeleteUser}
                />
            )}
        </>
    );
};

export default Target;
