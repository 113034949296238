import {
  Card,
  Col,
  Row,
  Button,
  Form,
  Table,
} from "react-bootstrap";
import BreadCrumb from "elements/System/BreadCrumb";
import Select from "react-select";

const Mapping = () => {
  return (
    <>
      <BreadCrumb
        breadCrumbItems={[
          { label: "Settings", path: "/Settings/mapping" },
          { label: "Mapping", path: "/Settings/mapping", active: true },
        ]}
        title={"Mapping"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body className="border-bottom py-2">
              <div className="d-flex align-center justify-content-between">
                <div className="d-flex align-items-center">
                  <h4 className="header-title mb-0">Conversion Mapping</h4>
                </div>
                <div>
                  <Button variant="light" size="sm" className="me-1">
                    <i className=" ri-arrow-go-back-line me-1" />
                    Cancel
                  </Button>
                  <Button variant="primary" size="sm">
                    <i className="ri-save-line me-1" />
                    Save
                  </Button>
                </div>
              </div>
            </Card.Body>
            <Card.Body>
              <p className="text-muted fs-14 mb-3">
                Map the fields in the Lead module to the fields in Contact,
                Account and Deal modules to make Lead conversion easy. With the
                mapped fields converting a Lead will automatically transfer the
                information you have gathered to an Contact, Account and Deal.
              </p>

              <div className="d-flex align-items-center justify-content-between border-bottom px-1">
                <Form.Label>Field Mapping</Form.Label>
                <p className="text-muted">
                  <span className="text-danger">* </span> Custome Field
                </p>
              </div>
              <Row>
                <Table
                  className="table-sm table-hover table-centered table-bordered table-scrollable"
                  responsive
                >
                  <thead className="table-light">
                    <tr>
                      <th>Lead</th>
                      <th>Account</th>
                      <th>Contact</th>
                      <th>Deal</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Annual Revenue</td>
                      <td>
                        <Select
                          className="select2 z-5"
                          classNamePrefix="react-select"
                          name="gender"
                        />
                      </td>
                      <td>
                        <Select
                          className="select2 z-5"
                          classNamePrefix="react-select"
                          name="gender"
                        />
                      </td>
                      <td>
                        <Select
                          className="select2 z-5"
                          classNamePrefix="react-select"
                          name="gender"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Annual Revenue</td>
                      <td>
                        <Select
                          className="select2 z-5"
                          classNamePrefix="react-select"
                          name="gender"
                        />
                      </td>
                      <td>
                        <Select
                          className="select2 z-5"
                          classNamePrefix="react-select"
                          name="gender"
                        />
                      </td>
                      <td>
                        <Select
                          className="select2 z-5"
                          classNamePrefix="react-select"
                          name="gender"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="text-danger">* </span> Annual Revenue
                      </td>
                      <td>
                        <Select
                          className="select2 z-5"
                          classNamePrefix="react-select"
                          name="gender"
                        />
                      </td>
                      <td>
                        <Select
                          className="select2 z-5"
                          classNamePrefix="react-select"
                          name="gender"
                        />
                      </td>
                      <td>
                        <Select
                          className="select2 z-5"
                          classNamePrefix="react-select"
                          name="gender"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span className="text-danger">* </span> Annual Revenue
                      </td>
                      <td>
                        <Select
                          className="select2 z-5"
                          classNamePrefix="react-select"
                          name="gender"
                        />
                      </td>
                      <td>
                        <Select
                          className="select2 z-5"
                          classNamePrefix="react-select"
                          name="gender"
                        />
                      </td>
                      <td>
                        <Select
                          className="select2 z-5"
                          classNamePrefix="react-select"
                          name="gender"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Annual Revenue</td>
                      <td>
                        <Select
                          className="select2 z-5"
                          classNamePrefix="react-select"
                          name="gender"
                        />
                      </td>
                      <td>
                        <Select
                          className="select2 z-5"
                          classNamePrefix="react-select"
                          name="gender"
                        />
                      </td>
                      <td>
                        <Select
                          className="select2 z-5"
                          classNamePrefix="react-select"
                          name="gender"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>Annual Revenue</td>
                      <td>
                        <Select
                          className="select2 z-5"
                          classNamePrefix="react-select"
                          name="gender"
                        />
                      </td>
                      <td>
                        <Select
                          className="select2 z-5"
                          classNamePrefix="react-select"
                          name="gender"
                        />
                      </td>
                      <td>
                        <Select
                          className="select2 z-5"
                          classNamePrefix="react-select"
                          name="gender"
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Mapping;
