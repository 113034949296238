import React from "react";
import { Card, Col, Row, Image, Form } from "react-bootstrap";
import BreadCrumb from "elements/System/BreadCrumb";
import Select, { components } from "react-select";
import { common } from "includes";

const userImage = common.loadImg("users/avatar-1.jpg");
const options = [
  {
    value: "Administrator",
    name: "Administartor",
    email: "administrator@gmail.com",
    image: userImage,
  },
  {
    value: "Manager",
    name: "Manager",
    email: "manager@gmail.com",
    image: userImage,
  },
  {
    value: "Asst-Manager",
    name: "Asst-Manager",
    email: "asst-manager@gmail.com",
    image: userImage,
  },
  {
    value: "Supervisor",
    name: "Supervisor",
    email: "supervisor@gmail.com",
    image: userImage,
  },
  {
    value: "Supervisor1",
    name: "Supervisor1",
    email: "supervisor1@gmail.com",
    image: userImage,
  },
];

const SingleValue = (props: any) => {
  const { name } = props.getValue()[0];

  return (
    <components.SingleValue {...props}>
      <span>{name}</span>
    </components.SingleValue>
  );
};
const Option = (props: any) => {
  const { name, email } = props.data;

  return (
    <components.Option {...props}>
      <div className="cust-select-options mb-1">
        <Image src={userImage} alt="user-img" className="cust-select-img" />
        <p className="cust-select-values">
          {name}
          <br />
          <span style={{ color: "darkgray" }}>{email}</span>
        </p>
      </div>
    </components.Option>
  );
};

const CustomSelect = () => {
  return (
    <>
      <BreadCrumb
        breadCrumbItems={[
          { label: "POC", path: "/POC/custom select" },
          { label: "POC", path: "/POC/custom select", active: true },
        ]}
        title={"Custom Select Box"}
      />
      <Card>
        <Card.Body>
          <Row>
            <Col md={4}>
              <Form.Label>Custom Select</Form.Label>
              <Select
                options={options}
                getOptionLabel={(options) => {
                  return `${options.name} ${options.email}`;
                }}
                components={{ SingleValue, Option }}
                classNamePrefix="selectbox-with-image"
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default CustomSelect;