//package
import { useState, useEffect, Fragment } from "react";
// includes
import BreadCrumb from "elements/System/BreadCrumb";

const VimalaPoc = () => {
  const [process, setProcess] = useState<any>({ result: 100, info: 100, edit: 100 });
  const [confirm, setConfirm] = useState<any>({ id: null, type: null });
  const [submit, setSubmit] = useState<any>("user") //string
  const [popup, setPopup] = useState<String>("");

  const [resultList, setResultList] = useState<any>([]);
  const [resultUser, setResultUser] = useState<any>([]);
  const [resultField, setResultField] = useState<any>([]);

  const [data, setData] = useState<any>({
    userTarget: [],
    userTemp: {},
    targetId: 4
  });
  const [error, setError] = useState<any>({
    userName: "Please enter name"
  })
  const [filter, setFilter] = useState<any>({
    list: "",
    user: "",
  })
  const [listPaging, setListPaging] = useState<any>({
    limit: 10,
    page: 0,
    total: 0,
  });
  const [listSorting, setListSorting] = useState({
    field: "id",
    order: "DESC",
    default: "ASC",
  });

  const [utils, setUtils] = useState<any>({
    listReload: false,
    userShow: false,
    fieldShow: false,
    userAdd: false,
    userEdit: false,
    country: "yes",
    state: "empty",
    city: "empty",
  });

  const processState = (label: any, value: any) => {
    setProcess((prev: any) => ({
      ...prev,
      [label]: value
    }));
  }

  const dataState = (label: any, value: any) => {
    setData((prev: any) => ({
      ...prev,
      [label]: value
    }));
  }

  const errorState = (label: any, value: any) => {
    setError((prev: any) => ({
      ...prev,
      [label]: value
    }));
  }

  const filterState = (label: any, value: any) => {
    setFilter((prev: any) => ({
      ...prev,
      [label]: value
    }));
  }

  const pageState = (label: any, value: any) => {
    setListPaging((prev: any) => ({
      ...prev,
      [label]: value
    }));
  }

  const sortState = (label: any, value: any) => {
    setListPaging((prev: any) => ({
      ...prev,
      [label]: value
    }));
  }

  const utilsState = (label: any, value: any) => {
    setUtils((prev: any) => ({
      ...prev,
      [label]: value
    }));
  }

  return (
    <>
      <BreadCrumb
        breadCrumbItems={[
          { label: "Settings", path: "/settings/home" },
          { label: "Modules", path: "/settings/home", active: true },
        ]}
        title={"Lead Conversion"}
      />

    </>
  );
};

export default VimalaPoc;
