// package
import { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  ButtonGroup,
  Dropdown,
  Button,
  Form,
  Badge,
  Spinner,
  Modal
} from "react-bootstrap";
import { ReactSortable } from "react-sortablejs";
import AmlHashForm from "aml-hash-form";
// component
import BreadCrumb from "elements/System/BreadCrumb";
import FormSelect from "elements/System/FormSelect";
import AgStatus from "elements/System/AgStatus";
import { api, common } from "includes";
interface DataType {
  id: number;
  name: string;
  category: string;
  probability: number;
}

const MovableCard = ({ item }: { item: DataType }) => {
  return (
    <Card className="mb-0 mb-2">
      <Card.Body className="p-2">
        <div className="d-flex align-items-start">
          <div className="w-100 overflow-hidden">
            <h5 className="mb-1 mt-1">{item.name}</h5>
            <p className="mb-0">
              {" "}
              {item.category}
              {item.category === "Open" ? (
                <i className=" ri-book-open-line text-primary mx-1" />
              ) : item.category === "Won" ? (
                <i className="ri-thumb-up-fill text-success mx-1" />
              ) : (
                <i className="ri-thumb-down-fill text-danger mx-1" />
              )}
              -
              <Badge
                className="ms-1"
                bg={
                  item.category === "Open"
                    ? "warning"
                    : item.category === "Won"
                      ? "success"
                      : "danger"
                }
              >
                {item.probability} %
              </Badge>
            </p>
          </div>
          <span className="dragula-handle" />
        </div>
      </Card.Body>
    </Card>
  );
};

const Pipeline = () => {
  // state
  const [process, setProcess] = useState<any>({ result: 100, info: 100, edit: 100 });
  const [submit, setSubmit] = useState<String>("");
  const [popup, setPopup] = useState<String>("");
  const [resultModule, setResultModule] = useState<any>([]);
  const [resultStage, setResultStage] = useState<DataType[]>([]);
  const [resultModuleStage, setResultModuleStage] = useState<DataType[]>([]);
  const [utils, setUtils] = useState<any>({ moduleView: null });

  // form
  const formFields = {
    name: { validate: ["req#Stage Name is required"] },
    probability: { validate: ["req#Probability is required", "lte:100#Maximum Probability is 100", "gte:0#Minimum Probability is 0"] },
    category: { validate: ["req#Category is required"] },
  };
  const {
    fields,
    errors,
    handleChange,
    handleSubmit,
    handleReset,
    setMultiValue,
  } = AmlHashForm(formFields);

  // effect
  useEffect(() => {
    getModules();
  }, []);

  useEffect(() => {
    if (utils.moduleView) {
      bindStages();
    }
  }, [utils.moduleView]);

  useEffect(() => {
    if (process.info !== 100) {
      processState("info", resultStage.length > 0 ? 200 : 204)
    }
  }, [resultStage]);

  useEffect(() => {
    if (process.edit !== 100) {
      processState("edit", resultModuleStage.length > 0 ? 200 : 204);
    }
  }, [resultModuleStage]);

  // api
  const getModules = () => {
    let data: any = {
      url: "/module/read?type=mp&fields=id,name&query=&join=&limit=30&offset=0&order=sort ASC",
      method: "GET",
    };

    api.call(data, (res: any) => {
      processState("result", res.status);
      if (res.status === 200) {
        let result: any = res.data;
        setResultModule(result);
        utilsState("moduleView", result[0]);
      }
    });
  };

  const getStages = async () => {
    return new Promise((resolve, reject) => {
      let data: any = {
        url: "/stage/read?type=mp&fields=id,category,name,probability&query=&join=&limit=50&offset=0&order=id ASC",
        method: "GET",
      };

      api.call(data, (res: any) => {
        resolve(res.data);
      });
    });
  };

  const getModuleStage = async () => {
    return new Promise((resolve, reject) => {
      let data: any = {
        url: `/module/read?type=sp&fields=id,name,stage&query=id=${utils.moduleView.id}&join=&limit=30&offset=0&order=id ASC`,
        method: "GET",
      };

      api.call(data, (res: any) => {
        if (res.status === 200) {
          let result: any = res.data;
          resolve(result.stage);
        }
      });
    });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    const isValid = handleSubmit();
    if (isValid) {
      let payload = common.formPayload(fields, []);
      setSubmit("pipeline");

      let param: any = {
        url: "/stage/create",
        method: "POST",
        body: payload,
      };
      api.call(param, (res: any) => {
        if (res.status === 200) {
          common.notify("S", res.message);
          setSubmit("");
          setPopup("");
          bindStages();
        } else {
          common.notify("E", res.message);
          setSubmit("");
        }
      });
    }
  };

  const saveModuleStages = () => {
    if (resultModuleStage.length > 0) {
      setSubmit("stage");
      let input = resultModuleStage.map(({ category, name, probability }) => ({
        category,
        name,
        probability,
      }));

      let param: any = {
        url: "/module/update/",
        query: utils.moduleView.id,
        method: "PUT",
        body: { stage: input },
      };
      api.call(param, (res: any) => {
        if (res.status === 200) {
          common.notify("S", res.message);
          bindStages();
        } else {
          common.notify("E", res.message);
        }
        setSubmit("");
      });
    } else {
      common.notify("E", "You should have at least one stage to save");
    }
  };

  // event
  const addStage = () => {
    setPopup("stage");
    setMultiValue({
      name: "",
      probability: "",
      category: "",
    });
    handleReset();
  };

  const onSelectModule = (e: any, obj: any) => {
    e.preventDefault();
    utilsState("moduleView", obj);
  };

  // support
  const bindStages = async () => {
    processState("info", 100)
    processState("edit", 100);
    setResultStage([]);
    setResultModuleStage([]);
    const res = await Promise.all([getStages(), getModuleStage()]);
    let stages: any = res[0];
    let modStages: any = res[1];

    var uniqueResultOne = stages.filter(function (obj: any) {
      return !modStages?.some(function (obj2: any) {
        return obj.name == obj2.name;
      });
    });

    if (!modStages || modStages?.length === 0) {
      setResultStage(stages);
      setResultModuleStage([]);
      processState("info", 200);
      processState("edit", 204);
    } else {
      processState("info", 200);

      setResultStage(uniqueResultOne);
      setResultModuleStage(modStages || []);
      processState("edit", modStages ? 200 : 204);
    }
  };

  const processState = (label: any, value: any) => {
    setProcess((prev: any) => ({
      ...prev,
      [label]: value
    }));
  }

  const utilsState = (label: any, value: any) => {
    setUtils((prev: any) => ({
      ...prev,
      [label]: value
    }));
  }

  // render
  return (
    <>
      <BreadCrumb
        breadCrumbItems={[
          { label: "Settings", path: "/settings/home" },
          { label: "Pipeline", path: "/pipeline/home", active: true },
        ]}
        title={"Pipeline"}
      />
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4 className="header-title">Pipelines</h4>
              <p className="text-muted fs-14 mb-3">
                This page allows you to manage various sales processes by
                organising the stages in different pipelines.
              </p>
              {process.result != 200 ? (
                <AgStatus
                  process={process.result}
                  message={{
                    100: "Loading pipeline. Please wait!",
                  }}
                  size="l"
                  img="pipeline.svg"
                />
              ) : (
                <Row>
                  <Col md={6}>
                    <Card className="bg-light-subtle border border-secondary-subtle bg-opacity-50">
                      <Card.Body className="py-1 px-2 border-bottom border-light">
                        <div className="d-flex align-center justify-content-between">
                          <label className="form-check-label fs-16">
                            Stages
                          </label>
                          <Button
                            type="button"
                            className="btn-sm btn btn btn-outline-success"
                            onClick={() => addStage()}
                          >
                            <i className="ri-add-line" /> Add Stages
                          </Button>
                        </div>
                      </Card.Body>

                      <Card.Body className="p-1">
                        <ReactSortable
                          group="teamList2"
                          handle=".dragula-handle"
                          list={resultStage}
                          setList={setResultStage}
                          className="py-1"
                        >
                          {resultStage.map((item, idx) => {
                            return <MovableCard key={idx} item={item} />;
                          })}
                          <AgStatus
                            process={process.info}
                            message={{
                              100: "Loading stages. Please wait!",
                              204: "All stages are used. Please add new stage",
                            }}
                            size="m"
                            img="pipeline.svg"
                            onAgCallBack={addStage}
                          />
                        </ReactSortable>
                      </Card.Body>
                      <Card.Body className="border-top p-2">
                        <Button
                          type="button"
                          className="btn-sm btn btn btn-outline-success me-1"
                          onClick={() => addStage()}
                        >
                          <i className="ri-add-line" /> Add Stages
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>

                  <Col md={6}>
                    <Card className="bg-light-subtle border border-secondary-subtle bg-opacity-50">
                      <Card.Body className="py-1 px-2 border-bottom border-light">
                        <div className="d-flex align-items-center justify-content-between">
                          <label className="form-check-label fs-16">
                            Module
                          </label>
                          <Dropdown as={ButtonGroup} size="sm">
                            <Dropdown.Toggle variant="light">
                              {" "}
                              {utils.moduleView.name}{" "}
                              <span className="caret"></span>{" "}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              {resultModule.map((obj: any, idx: number) => {
                                return (
                                  <Dropdown.Item
                                    key={idx}
                                    as="a"
                                    onClick={(e: any) => onSelectModule(e, obj)}
                                  >
                                    {obj.name}
                                  </Dropdown.Item>
                                );
                              })}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </Card.Body>
                      <Card.Body className="p-1">
                        <ReactSortable
                          group="teamList2"
                          handle=".dragula-handle"
                          list={resultModuleStage}
                          setList={setResultModuleStage}
                          className="py-1"
                        >
                          {resultModuleStage.map((item, idx) => {
                            return <MovableCard key={idx} item={item} />;
                          })}
                          <AgStatus
                            process={process.edit}
                            message={{
                              100: `Loading ${utils.moduleView.name} stages. Please wait!`,
                              204: "No stages assigned. Please drag and drop stages from left",
                            }}
                            size="m"
                            img="pipeline.svg"
                          />
                        </ReactSortable>

                      </Card.Body>
                      <Card.Body className="border-top p-2">
                        <Button
                          type="button"
                          className="btn-sm btn btn btn-outline-secondary me-1"
                          disabled={submit === "stage"}
                          onClick={() => bindStages()}
                        >
                          <i className="ri-close-line" /> Cancel
                        </Button>
                        <Button
                          type="button"
                          className="btn-sm btn btn btn-primary me-1"
                          onClick={() => saveModuleStages()}
                        >
                          {submit === "stage" ? (
                            <>
                              <Spinner
                                className="spinner-border-sm me-1"
                                color="white"
                              />
                              Processing...
                            </>
                          ) : (
                            <>
                              <i className="ri-save-line" /> Save
                            </>
                          )}
                        </Button>
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Modal
        show={popup === "stage"}
        size="lg"
        scrollable={true}
        onHide={() => setPopup("")}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Stage</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Stage Name</Form.Label>
                <Form.Control
                  name="name"
                  type="text"
                  placeholder="Enter Stage name"
                  value={fields.name}
                  onChange={handleChange}
                  isInvalid={errors.name ? true : false}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.name != null ? errors.name : "-"}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Probability</Form.Label>
                <Form.Control
                  name="probability"
                  type="number"
                  placeholder="Enter Probability"
                  value={fields.probability}
                  onChange={(e) => {
                    setMultiValue({ "probability": common.onlyNumbers(e.target.value) });
                  }}
                  // onChange={handleChange}
                  isInvalid={errors.probability ? true : false}
                ></Form.Control>
                <Form.Control.Feedback type="invalid">
                  {errors.probability != null ? errors.probability : "-"}
                </Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col md={6}>
              <Form.Group className="mb-3">
                <Form.Label>Category</Form.Label>
                <FormSelect
                  url="stageCategories"
                  fields=""
                  query=""
                  order=""
                  value={fields.category}
                  fixed={true}
                  multi={false}
                  async={false}
                  limit={10}
                  render={"yes"}
                  onSelect={(data: any) => {
                    setMultiValue({ "category": data });
                  }}
                  error={errors.category}
                />
                {errors.category && (
                  <span className="error-text">
                    {errors.category}
                  </span>
                )}
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="light"
            onClick={() => setPopup("")}
            disabled={submit === "pipeline"}
          >
            <i className="ri-close-line" /> Close
          </Button>
          <Button variant="success" onClick={onSubmit} disabled={submit === "pipeline"}>
            {submit === "pipeline" ? (
              <>
                <Spinner className="spinner-border-sm me-1" color="white" />
                Processing...
              </>
            ) : (
              <>
                <i className="ri-save-line" /> Submit
              </>
            )}
          </Button>
        </Modal.Footer>
      </Modal >
    </>
  );
};

export default Pipeline;
