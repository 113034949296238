import { Fragment } from "react";
import BreadCrumb from "elements/System/BreadCrumb";

export default function Starter() {
  return (
    <Fragment>
      <BreadCrumb
        breadCrumbItems={[
          { label: "Starter", path: "/pages/starter", active: true },
        ]}
        title={"Starter"}
      />
      <p>Starter</p>
    </Fragment>
  );
}
