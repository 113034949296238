import { useState, useEffect, forwardRef } from "react";
import { Form } from "react-bootstrap";
import AsyncSelect from "react-select/async";
// includes
import { api } from "includes";

const DataSelect = forwardRef((props: any, ref: any) => {
  const { field, fieldIndex, data } = props;
  const [mount, setMount] = useState(false);
  const [isLoading, setIsLoading] = useState(props.isRender);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [isRender, setIsRender] = useState(props.isRender);
  const [resultList, setResultList] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [limit] = useState(15);
  const [offset, setOffset] = useState(0);
  let delayTimer: any;

  // effect
  useEffect(() => {
    if (isRender) {
      if (checkDependent()) {
        loadData();
      } else {
        setMount(true);
        setResultList([]);
        setIsLoading(false);
      }
    }
  }, [isRender]);

  useEffect(() => {
    if (mount) {
      loadData();
    }
  }, [offset]);

  useEffect(() => {
    let dependent = field.attr.dependent;
    if (mount && fieldIndex !== null && dependent !== null) {
      let activeField = data.fields[fieldIndex];
      let isExist = activeField.key in dependent;
      if (isExist) {
        let value = activeField.value;
        if (typeof value == "object") {
          value = value.value;
        }
        onChange("");
        setOffset(0);
        setResultList([]);
        if (value !== "") {
          setIsLoading(true);
          loadData();
        }
      }
    }
  }, [field]);

  // support
  const checkDependent = () => {
    let dependent = field.attr.dependent;
    let allow = true;
    if (dependent !== null) {
      Object.keys(dependent).map((item) => {
        let dependField = data.fields.filter(
          (fItem: any) => fItem.key === item
        );
        let value = dependField[0].value;
        if (typeof value == "object") {
          value = value.value;
        }
        if (value === "") {
          allow = false;
        }
      });
    }
    return allow;
  };

  const dependentData = () => {
    let dependent = field.attr.dependent;
    let result = "";
    if (dependent !== null) {
      Object.keys(dependent).map((item) => {
        let dependField = data.fields.filter(
          (fItem: any) => fItem.key === item
        );
        let value = dependField[0].value;
        if (typeof value == "object") {
          value = value.value;
        }
        if (value !== "") {
          result += `&${dependent[item]}=${value}`;
        }
      });
    }
    return result;
  };

  // api
  const loadData = () => {
    let depQuery = dependentData();
    let fieldInfo = getFieldInfo();
    let data: any = {
      url: "/" + field.attr.lookup,
      query: `?type=mp&fields=${
        fieldInfo.field
      }&query=${depQuery}&join&order=id ASC&limit=${limit}&offset=${
        offset * limit
      }`,
      method: "GET",
    };
    api.call(data, (res: any) => {
      if (res.status === 200) {
        let result: any = res.data;
        let resultNewList: any = [...new Set([...resultList, ...result])];
        setResultList(resultNewList);
        setMount(true);
        setIsLoadingMore(false);
        setHasMore(res.paging.total > res.paging.live);
        if (!props.isRender) {
          setIsMenuOpen(true);
          setIsFocus(true);
        }
      }
      setIsLoading(false);
    });
  };

  // load
  const loadOptions = async (inputValue: any) => {
    let depQuery = dependentData();
    let fieldInfo = getFieldInfo();
    let data: any = {
      url: "/" + field.attr.lookup,
      query: `?type=mp&fields=${fieldInfo.field}&query=${fieldInfo.search} ILIKE '%%${inputValue}%%'${depQuery}&join&order=id ASC&limit=100&offset=0`,
      method: "GET",
    };
    let result: any = await new Promise((resolve, reject) => {
      clearTimeout(delayTimer);
      delayTimer = setTimeout(() => {
        api.call(data, (res: any) => {
          if (res.status === 200) {
            let result: any = res.data;
            resolve(result);
          } else {
            resolve([]);
          }
        });
      }, 500);
    });
    return result;
  };

  // handler
  const onChange = (value: any) => {
    props.onSelect(value, props.name);
  };

  const onFocus = () => {
    if (!isRender) {
      setIsLoading(true);
      setIsRender(true);
    }
  };

  const loadMore = () => {
    if (hasMore && !isLoadingMore) {
      setIsLoadingMore(true);
      setOffset((prev) => ++prev);
    }
  };

  // support
  const getFieldInfo = () => {
    let fieldResult = {
      field: "id::text as value, text_1 as label",
      search: "text_1",
    };
    if (field.attr.lookup === "user/read") {
      fieldResult = {
        field: "id::text as value, TRIM(CONCAT(first_name,' ',last_name)) as label",
        search: "first_name",
      };
    }
    return fieldResult;
  };

  return isLoading ? (
    <Form.Control type="text" defaultValue={"Loading..."} readOnly={true} />
  ) : (
    <AsyncSelect
      ref={ref}
      isMulti={props.isMulti}
      isClearable={true}
      value={field.value}
      defaultOptions={resultList}
      loadOptions={loadOptions}
      onChange={onChange}
      onFocus={onFocus}
      menuIsOpen={isMenuOpen}
      onMenuOpen={() => setIsMenuOpen(true)}
      onMenuClose={() => setIsMenuOpen(false)}
      autoFocus={isFocus}
      onMenuScrollToBottom={() => loadMore()}
      isLoading={isLoadingMore}
      isDisabled={!checkDependent() || props.disabled}
      classNamePrefix="react-select"
      className={field?.error ? "select2 required-select-input" : "select2"}
    />
  );
});

export default DataSelect;
