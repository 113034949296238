import { config } from "./config";
import { common } from "./common";
// const
const base_url = config.api.url;
const cloudImgPath = config.api.cloudImgPath;

// api
export const api = {
  async call(data: any = {}, response?: any, error?: any, final?: any) {
    getData(data);
    // server side rendering
    if (data.ssr) {
      let myPromise = new Promise(function (resolve) {
        fetch(data.url, getOptions(data))
          .then((response) =>
            response.text().then((data) => ({
              status: response.status,
              data: data,
            }))
          )
          .then((result: any) => {
            if (validateData(result)) {
              try {
                result = {
                  status: result.status,
                  ...JSON.parse(result.data)
                }
              } catch (e: any) {
                result = {
                  status: result.status,
                  code: "internal",
                  message: e.toString(),
                  data: [],
                  paging: {},
                }
              }
              resolve(result);
            }
          })
          .catch((err) => {
            console.log('err', err)
            let result = {
              status: 404,
              code: "server",
              message: err.toString(),
              data: [],
              paging: {},
            };
            resolve(result);
          });
      });
      return myPromise;
    } else {
      // client side rendering
      fetch(data.url, getOptions(data))
        .then((response) =>
          response.text().then((data) => ({
            status: response.status,
            data: data,
          }))
        )
        .then((result: any) => {
          if (validateData(result)) {
            try {
              result = {
                status: result.status,
                ...JSON.parse(result.data)
              }
            } catch (e: any) {
              result = {
                status: result.status,
                code: "internal",
                message: e.toString(),
                data: [],
                paging: {},
              }
            }
            response(result);
          }
        })
        .catch((err) => {
          console.log('err', err)
          let result = {
            status: 404,
            code: "server",
            message: err.toString(),
            data: [],
            paging: {},
          };
          response(result);
        })
        .finally(() => {
          if (typeof final !== "undefined") {
            final();
          }
        });
    }
  },
};

// support
const getData = (data: any = {}) => {
  data.repeat = data.repeat || false;
  if (!data.repeat) {
    data.auth = data.auth || "token";
    data.type = data.type || "standard";
    data.method = data.method || "POST";
    data.cType = data.cType || 1;
    data.query = data.query || "";
    data.body = data.body || "";
    data.keyQuery = data.keyQuery || "";
    // set url
    if (data.type == "third") {
      data.url = data.url + data.query;
    } else {
      if (data.cType == 6) {
        data.url = cloudImgPath
      } else {
        data.url = base_url + data.url + data.query;
      }

      if (data.keyQuery != "") {
        for (let key in data.keyQuery) {
          data.url = data.url.replace(`:${key}`, data.keyQuery[key]);
        }
      }
    }
    // set body
    if (data.body) {
      if (data.cType == 1) {
        data.body = data.body ? JSON.stringify(data.body) : "";
      } else if (data.cType == 2) {
        let bodyParam = [];
        for (let property in data.body) {
          let encodedKey = encodeURIComponent(property);
          let encodedValue = encodeURIComponent(data.body[property]);
          bodyParam.push(encodedKey + "=" + encodedValue);
        }
        data.body = bodyParam.join("&");
      }
    }
  }
};

function getOptions(data: any) {
  let reqOptions: any = {
    method: data.method,
    headers: getHeaders(data),
  };

  if (data.body) {
    reqOptions.body = data.body;
  }
  return reqOptions;
}

function getHeaders(data: any) {
  // default
  let headers: any = {};

  // content types
  let contentTypes: any = {
    1: "application/json",
    2: "application/x-www-form-urlencoded",
    3: "application/vnd.oracle.adf.action+json",
    4: "application/vnd.oracle.adf.resourceitem+json",
    5: "application/vnd.oracle.adf.batch+json",
    6: "multipart/form-data",
  };
  if (data.cType !== 6) {
    headers["Content-Type"] = contentTypes[data.cType];
  }

  // extra content types
  let moreHeaderList: any = {
    rfv: "REST-Framework-Version",
  };
  if (data.moreHead) {
    for (let item in data.moreHead) {
      headers[moreHeaderList[item]] = data.moreHead[item];
    }
  }

  // authentication
  if (data.auth == "token") {
    let authData = common.getAuth();
    headers.Authorization = "Bearer " + authData?.token; //.token;
  } else if (data.auth == "temp") {
    let tempData = common.tempDataGet();
    headers.Authorization = "Bearer " + tempData?.token;
  } else if (data.auth == "basic") {
    headers.Authorization =
      "Basic " + btoa(data.credentials.param1 + ":" + data.credentials.param2);
  }
  return headers;
}

const validateData = (result: any) => {
  if (result.status == config.api.isExpired) {
    localStorage.clear();
    common.redirect();
    return false;
  } else {
    return true;
  }
};