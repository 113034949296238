// package
import { Fragment, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "elements/System/BreadCrumb";
import {
  Card,
  Col,
  Row,
  Table,
  Form,
  Button,
  ButtonGroup,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Pagination as BootstrapPagination,
  Spinner,
} from "react-bootstrap";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import Offcanvas from "react-bootstrap/Offcanvas";
import moment from "moment";
// component
import AgStatus from "elements/System/AgStatus";
import AgDelete from "elements/System/AgDelete";
import { api, common, config } from "includes";

export default function ModuleTable() {
  // const
  const navigate = useNavigate();
  const { moduleId } = useParams();

  // state
  const [columnFilerShow, setColumnFilterShow] = useState(false);
  const [show, setShow] = useState(false);
  const [isReload, setIsReload] = useState(false);
  const [delConfirm, setDelConfirm] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<number>(0);
  const [status, setStatus] = useState<any>(100);
  const [moduleInfo, setModuleInfo] = useState<any>(null);
  const [selected, setSelected] = useState<any>([]);
  const [page, setPage] = useState<any>({
    limit: 10,
    page: 0,
    total: 0,
  });
  const [sort, setSort] = useState({
    field: "created_at",
    order: "DESC",
    default: "ASC",
  });
  const [result, setResult] = useState({
    columns: [],
    data: [],
    totalResults: 0,
    totalRecords: 0,
  });

  // effect
  useEffect(() => {
    getColumnsData();
  }, []);

  useEffect(() => {
    if (isReload) {
      getData();
    }
  }, [isReload]);

  // api
  const getColumnsData = () => {
    let data: any = {
      url: `/module/read?type=sp&fields=id,name,data,key,sort&query=id=${moduleId}&join=&limit=30&offset=0&order=sort ASC`,
      method: "GET",
      auth: "token",
    };
    api.call(data, (res: any) => {
      if (res.status === 200) {
        let result: any = res.data;
        setModuleInfo({
          key: result.key,
          name: result.name,
        });
        setResult((prev) => ({
          ...prev,
          columns: columnValidate(result.data.fields),
        }));
        setIsReload(true);
      } else {
        setStatus(res.status);
      }
    });
  };

  const getData = () => {
    let data: any = {
      url: `/data/read/${
        moduleInfo.key
      }/${moduleId}?type=mp&fields=*&query=&limit=${page.limit}&offset=${
        page.limit * page.page
      }&order=${sort.field} ${sort.order}&join=`,
      method: "GET",
    };
    api.call(data, (res: any) => {
      setStatus(res.status);
      if (res.status === 200) {
        let result: any = res.data;
        setResult((prev) => ({
          ...prev,
          data: result,
        }));
        setPage((pData: any) => {
          let nData = common.reParse(pData);
          nData.limit = nData.limit;
          nData.page = nData.page;
          nData.total = res.paging.total;
          return nData;
        });
        setIsReload(false);
      }
    });
  };

  const onDelete = (isDelete: boolean) => {
    if (isDelete) {
      setIsReload(false);
      // api call
      let data: any = {
        url: "/data/delete/",
        query: moduleInfo.key + "/" + deleteId,
        method: "DELETE",
        auth: "token",
      };
      api.call(data, (res: any) => {
        if (res.status === 200) {
          common.notify("S", res.message);
          setIsReload(true);
          setDelConfirm(false);
          setDeleteId(0);
        } else {
          common.notify("E", res.message);
        }
      });
    } else {
      setDelConfirm(isDelete);
    }
  };

  const deleteConfirm = (id: number) => {
    setDelConfirm(true);
    setDeleteId(id);
  };

  // events
  const onPageChange = (record: any, page: number) => {
    setPage((pData: any) => {
      let nData = common.reParse(pData);
      nData.limit = record;
      nData.page = page;
      return nData;
    });
    setIsReload(true);
  };

  const selectAllCheck = () => {
    let i = 0;
    result.data.map((item: any) => {
      if (selected.includes(item.id)) {
        ++i;
      }
    });
    return i > 0 && result.data.length === i;
  };

  const selectChange = (e: any) => {
    const { checked, value } = e.target;
    setSelected((pData: any) => {
      let nData = common.reParse(pData);
      if (checked) {
        nData.push(+value);
      } else {
        nData.splice(nData.indexOf(+value), 1);
      }
      return nData;
    });
  };

  const selectAll = (e: any) => {
    const { checked } = e.target;
    setSelected((pData: any) => {
      let nData = common.reParse(pData);
      if (checked) {
        result.data.map((i: any) => {
          if (!nData.includes(i.id)) {
            nData.push(i.id);
          }
        });
      } else {
        result.data.map((i: any) => {
          nData.splice(nData.indexOf(i.id), 1);
        });
      }
      return nData;
    });
  };

  const refresh = () => {
    setSelected([]);
    setSort({
      field: "created_at",
      order: "DESC",
      default: "ASC",
    });
    setIsReload(true);
  };

  const handleSortChange = (field: any) => {
    setSort({
      field,
      order:
        sort.field === field
          ? sort.order === "ASC"
            ? "DESC"
            : "ASC"
          : sort.default,
      default: sort.default,
    });
    setIsReload(true);
  };

  //support
  const columnValidate = (fields: any) => {
    let res = fields.filter((i: any) => i.status === "use" && i.visibility);
    return res;
  };

  const getPaging = () => {
    let start = page.limit * page.page + 1;
    let pageEnd =
      page.page === 0 ? page.limit : page.limit * page.page + (page.limit - 1);
    let end = pageEnd < page.total ? pageEnd : page.total;
    return (
      <div>
        Showing {page.page === 0 ? 1 : start} to {end} of {page.total} entries
      </div>
    );
  };

  // callback
  const onAgCallBack = (status: any) => {
    if (status === 204) {
      navigate(`/data/crud/${moduleId}/0`);
    } else {
      navigate(`/module/list`);
    }
  };

  // render
  return (
    <Row>
      <BreadCrumb
        breadCrumbItems={[{ label: "Module List", path: "/module/list" }]}
        title={"CRM Modules"}
      />
      {status != 200 ? (
        <AgStatus
          process={status}
          message={{
            100: "Loading data.Please wait...",
            204: `No ${moduleInfo?.name} found!`,
            400: "You're trying to access invalid records.",
          }}
          button={{ 204: "add", 400: "Go to Modules" }}
          size="l"
          img={`${moduleInfo?.key}.svg`}
          onAgCallBack={onAgCallBack}
        />
      ) : (
        <Row>
          <Col xl={12} lg={12}>
            <Card>
              <Card.Body className="py-2 px-3 border-bottom border-light">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="float-start">
                    <label className="form-check-label fs-16">
                      {moduleInfo.name}
                    </label>
                  </div>
                  <div id="tooltips-container d-flex align-items-center">
                    {result.data.length > 0 && (
                      <>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="">Refresh</Tooltip>}
                        >
                          <Button
                            variant="light"
                            size="sm"
                            className="me-1"
                            onClick={() => refresh()}
                          >
                            <i className="ri-refresh-line fs-16" />
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="">Column Filter</Tooltip>}
                        >
                          <Button
                            variant="light"
                            size="sm"
                            className="me-1 d-felx align-items-center"
                            onClick={() => setColumnFilterShow(true)}
                          >
                            <i className="ri-filter-3-line fs-16" />
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="">Filter</Tooltip>}
                        >
                          <Button
                            variant="light"
                            //   variant={isFilter ? "warning" : "light"}
                            size="sm"
                            className="me-1"
                            onClick={() => setShow(true)}
                          >
                            <i className="ri-filter-line fs-16" />
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="">Clear Filter</Tooltip>}
                        >
                          <Button variant="light" size="sm" className="me-1">
                            <i className="ri-filter-off-line fs-16" />
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="">List View</Tooltip>}
                        >
                          <Button variant="light" size="sm" className="me-1">
                            <i className="ri-bar-chart-horizontal-line fs-16" />
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="">Kanban View</Tooltip>}
                        >
                          <Button variant="light" size="sm" className="me-1">
                            <i className="ri-bar-chart-line fs-16" />
                          </Button>
                        </OverlayTrigger>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip id="">Export</Tooltip>}
                        >
                          <Button
                            variant="secondary"
                            size="sm"
                            className="me-1 d-felx align-items-center"
                          >
                            <i className="ri-download-2-line fs-16" />
                            <span className="ms-1">Export</span>
                          </Button>
                        </OverlayTrigger>
                      </>
                    )}
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="">Add New</Tooltip>}
                    >
                      <Button
                        variant="primary"
                        size="sm"
                        className="me-1 d-felx align-items-center"
                        onClick={() => navigate(`/data/crud/${moduleId}/0`)}
                      >
                        <i className="ri-add-line fs-16" />
                        <span className="ms-1">Add New</span>
                      </Button>
                    </OverlayTrigger>
                    {result.data.length > 0 && (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip id="">Action</Tooltip>}
                      >
                        <ButtonGroup as={Dropdown} size="sm">
                          <Dropdown.Toggle
                            variant="light"
                            className="arrow-none"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            <i className="ri-more-2-line fs-16" />
                            {selected.length > 0 && (
                              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger p-1">
                                {selected.length}
                              </span>
                            )}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item as="a" href="#">
                              <i className="me-1 ri-mail-line" />
                              Mass Email
                            </Dropdown.Item>
                            <Dropdown.Item as="a" href="#">
                              <i className="me-1 ri-upload-2-line" />
                              Mass Import
                            </Dropdown.Item>
                            <Dropdown.Item as="a" href="#">
                              <i className="me-1 ri-save-line" />
                              Mass Update
                            </Dropdown.Item>
                            <Dropdown.Item as="a" href="#">
                              <i className="me-1 ri-delete-bin-6-line" />
                              Mass Delete
                            </Dropdown.Item>
                            <Dropdown.Item as="a" href="#">
                              <i className="me-1 ri-file-transfer-line" />
                              Mass Transfer
                            </Dropdown.Item>
                            <Dropdown.Item as="a" href="#">
                              <i className="me-1 ri-folder-transfer-line" />
                              Mass Conversion
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </ButtonGroup>
                      </OverlayTrigger>
                    )}
                  </div>
                </div>
              </Card.Body>
              <Card.Body className="pt-1">
                {result.data.length == 0 ? (
                  <AgStatus
                    process={204}
                    message={{
                      100: "Loading data.Please wait...",
                      204: `No ${moduleInfo?.name} Found!`,
                      400: "You're trying to access invalid records.",
                    }}
                    button={{ 204: "Add New", 400: "Go to Modules" }}
                    size="l"
                    img={`${moduleInfo?.key}.svg`}
                    onAgCallBack={onAgCallBack}
                  />
                ) : (
                  <Fragment>
                    {isReload && (
                      <div className="list-table-spinner">
                        <Spinner />
                      </div>
                    )}
                    <Table
                      className="table-sm table-centered mb-0 table-fixed-col"
                      responsive
                    >
                      <thead>
                        <tr>
                          <th
                            style={{
                              display: "flex",
                              justifyContent: "flex-start",
                              alignItems: "center",
                              padding: "4px",
                            }}
                          >
                            <OverlayTrigger
                              placement="top"
                              overlay={<Tooltip id="">Select All</Tooltip>}
                            >
                              <Form.Check
                                checked={selectAllCheck()}
                                onChange={(e) => selectAll(e)}
                                // disabled={allow.noRecordAlert}
                              />
                            </OverlayTrigger>
                          </th>
                          {result.columns.map((item: any, key: any) => (
                            <th
                              key={key}
                              onClick={() => handleSortChange(item.key)}
                            >
                              {item.label}
                              {sort.field === item.key && (
                                <i
                                  className={`${
                                    sort.order === "ASC"
                                      ? "ri-arrow-down-line"
                                      : "ri-arrow-up-line"
                                  } ms-2`}
                                />
                              )}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {result.data.map((item: any, key: any) => (
                          <tr key={key}>
                            <td
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              <Form.Check
                                value={item.id}
                                checked={
                                  selected.includes(item.id) ? true : false
                                }
                                onChange={(e) => selectChange(e)}
                              />
                              <Dropdown>
                                <Dropdown.Toggle
                                  as={Link}
                                  to="#"
                                  className="arrow-none card-drop"
                                >
                                  <i className="ri-more-line" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu align="start">
                                  {moduleInfo.key === "leads" &&
                                    !item.conversion && (
                                      <Dropdown.Item
                                        className="dropdown"
                                        onClick={() => {
                                          navigate(
                                            `/data/conversion/${moduleId}/${item.id}`
                                          );
                                        }}
                                      >
                                        <i className="me-1 ri-edit-line" />
                                        Convert
                                      </Dropdown.Item>
                                    )}
                                  {((moduleInfo.key === "leads" &&
                                    !item.conversion) ||
                                    moduleInfo.key !== "leads") && (
                                    <Dropdown.Item
                                      className="dropdown"
                                      onClick={() => {
                                        navigate(
                                          `/data/crud/${moduleId}/${item.id}`
                                        );
                                      }}
                                    >
                                      <i className="me-1 ri-edit-line" />
                                      Edit
                                    </Dropdown.Item>
                                  )}
                                  <Dropdown.Item
                                    className="dropdown"
                                    onClick={() => deleteConfirm(item.id)}
                                  >
                                    <i className="me-1 ri-delete-bin-6-line" />
                                    Delete
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            {result.columns.map((cItem: any, cKey: any) => (
                              <td key={cKey}>
                                {cItem.key == "text_1" ? (
                                  <Link
                                    to={`/data/detail/${moduleId}/${item.id}`}
                                    className="td-plain-link"
                                  >
                                    {item[cItem.key]}
                                  </Link>
                                ) : cItem.type === "date" ? (
                                  item[cItem.key] ? (
                                    moment(item[cItem.key]).format(
                                      "DD/MMM/YYYY"
                                    )
                                  ) : (
                                    "-"
                                  )
                                ) : cItem.type === "date_time" ? (
                                  item[cItem.key] ? (
                                    moment(item[cItem.key]).format(
                                      "DD/MMM/YYYY hh:mm A"
                                    )
                                  ) : (
                                    "-"
                                  )
                                ) : cItem.type === "bool" ? (
                                  item[cItem.key] === "Y" ? (
                                    "Yes"
                                  ) : (
                                    "No"
                                  )
                                ) : ["pick_list", "lookup"].includes(
                                    cItem.type
                                  ) ? (
                                  item[cItem.key] ? (
                                    Object.values(item[cItem.key]).join(", ")
                                  ) : (
                                    "-"
                                  )
                                ) : (
                                  item[cItem.key] ?? "-"
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    {page.total > 0 && (
                      <div className="d-flex align-items-center justify-content-between mt-3">
                        {getPaging()}
                        <div className="d-flex align-items-center justify-content-end">
                          <Select
                            className="select2 z-5 me-1"
                            options={config.options.pages}
                            onChange={(e) => onPageChange(e?.value, 0)}
                            value={{
                              label: page.limit + " Rows",
                              value: page.limit,
                            }}
                          />
                          <nav>
                            <BootstrapPagination className="mb-0">
                              <BootstrapPagination.Prev
                                disabled={page.page === 0}
                                onClick={(e) =>
                                  onPageChange(page.limit, page.page - 1)
                                }
                              />
                              {[
                                ...Array(Math.ceil(page.total / page.limit)),
                              ].map((x, i) => (
                                <BootstrapPagination.Item
                                  key={i}
                                  active={i === page.page}
                                  onClick={(e) => onPageChange(page.limit, i)}
                                >
                                  {i + 1}
                                </BootstrapPagination.Item>
                              ))}
                              <BootstrapPagination.Next
                                disabled={
                                  page.page ===
                                  Math.ceil(page.total / page.limit) - 1
                                }
                                onClick={(e) =>
                                  onPageChange(page.limit, page.page + 1)
                                }
                              />
                            </BootstrapPagination>
                          </nav>
                        </div>
                      </div>
                    )}
                  </Fragment>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      )}

      {/* Column Filter */}
      <Modal
        show={columnFilerShow}
        size="sm"
        scrollable={true}
        onHide={() => setColumnFilterShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Manage Column</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group flex-nowrap mb-3">
            <span className="input-group-text">
              <i className="ri-refresh-line fs-16" />
            </span>
            <Form.Control type="text" placeholder="Search" />
          </div>
          <div className="mb-1">
            <Form.Check id="check1" label="Lead Name" />
          </div>
          <div className="mb-1">
            <Form.Check id="check2" label="Lead Status" />
          </div>
          <div className="mb-1">
            <Form.Check id="check1" label="Lead Name" />
          </div>
          <div className="mb-1">
            <Form.Check id="check2" label="Lead Status" />
          </div>
          <div className="mb-1">
            <Form.Check id="check1" label="Lead Name" />
          </div>
          <div className="mb-1">
            <Form.Check id="check2" label="Lead Status" />
          </div>
          <div className="mb-1">
            <Form.Check id="check1" label="Lead Name" />
          </div>
          <div className="mb-1">
            <Form.Check id="check2" label="Lead Status" />
          </div>
          <div className="mb-1">
            <Form.Check id="check1" label="Lead Name" />
          </div>
          <div className="mb-1">
            <Form.Check id="check2" label="Lead Status" />
          </div>
          <div className="mb-1">
            <Form.Check id="check1" label="Lead Name" />
          </div>
          <div className="mb-1">
            <Form.Check id="check2" label="Lead Status" />
          </div>
          <div className="mb-1">
            <Form.Check id="check1" label="Lead Name" />
          </div>
          <div className="mb-1">
            <Form.Check id="check2" label="Lead Status" />
          </div>
          <div className="mb-1">
            <Form.Check id="check1" label="Lead Name" />
          </div>
          <div className="mb-1">
            <Form.Check id="check2" label="Lead Status" />
          </div>
          <div className="mb-1">
            <Form.Check id="check1" label="Lead Name" />
          </div>
          <div className="mb-1">
            <Form.Check id="check2" label="Lead Status" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light">Show All</Button>
          <Button variant="light">Hide All</Button>
          <Button variant="primary">Save</Button>
        </Modal.Footer>
      </Modal>

      {/* Filter */}
      <Offcanvas show={show} onHide={() => setShow(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Filter</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          Some text as placeholder. In real life you can have the elements you
          have chosen. Like, text, images, lists, etc.
        </Offcanvas.Body>
      </Offcanvas>

      {delConfirm && <AgDelete type="delete" onAgDeleteCallBack={onDelete} />}
    </Row>
  );
}
