//package
import { useState } from "react";
import { Fragment, useEffect } from "react";
import { Button, Card, Col, Dropdown, Row, Form, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import AmlHashForm from "aml-hash-form";

// component
import BreadCrumb from "elements/System/BreadCrumb";
import AgDelete from "elements/System/AgDelete";
import AgStatus from "elements/System/AgStatus";
import FormSelect from "elements/System/FormSelect";
import { api, common } from "includes";

export default function ModuleList() {
  // const
  const navigate = useNavigate();
  // state
  const [process, setProcess] = useState<any>({ result: 100, edit: 100 });
  const [confirm, setConfirm] = useState<any>({ id: null, type: null });
  const [submit, setSubmit] = useState<String>("");
  const [popup, setPopup] = useState<String>("");
  const [resultList, setResultList] = useState<any>([]);
  const [utils, setUtils] = useState<any>({ moduleEdit: null });

  // form
  const formFields = {
    name: { validate: ["req#Module Name  is required!"] },
    permission: {
      validate: ["objValReq#Permission is required!"],
      obj: "value",
    },
  };
  const {
    fields,
    errors,
    setMultiValue,
    handleChange,
    handleSubmit,
    handleReset,
  } = AmlHashForm(formFields);

  // effect
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (popup === "module") {
      setMultiValue({
        name: "",
        permission: null,
      });
      handleReset();
    }
  }, [popup]);

  // api
  const getData = () => {
    let data: any = {
      url: "/module/read?type=mp&fields=id,name,is_system,key,sort&query=&join=&limit=30&offset=0&order=sort ASC",
      method: "GET",
    };

    api.call(data, (res: any) => {
      processState("result", res.status);
      if (res.status === 200) {
        let result: any = res.data;
        setResultList(result);
      }
    });
  };

  const deleteModule = () => {
    let data: any = {
      url: "/module/delete/",
      query: `${confirm.id}`,
      method: "DELETE",
    };

    api.call(data, (res: any) => {
      if (res.status === 200) {
        setConfirm({ id: null, type: "" });
        common.notify("S", res.message);
        getData();
      } else {
        common.notify("E", res.message);
      }
    });
  };

  const getModule = (id: any) => {
    utilsState("moduleEdit", id);
    setPopup("module");
    processState("edit", 100);
    let data: any = {
      url: `/module/read?type=sp&fields=id,name,is_system,key,sort,permission&query=id=${id}&join=&limit=30&offset=0&order=sort ASC`,
      method: "GET",
    };
    api.call(data, (res: any) => {
      if (res.status === 200) {
        let result: any = res.data;
        processState("edit", res.status);
        setMultiValue({
          name: result.name,
          description: result.description,
          permission: result.permission,
        });
      } else {
        processState("edit", res.status);
      }
    });
  };

  const updateModule = () => {
    setPopup("module");
    let payload = {
      name: fields.name,
      permission: fields.permission,
    };
    let data: any = {
      url: "/module/update/",
      query: `${utils.moduleEdit}`,
      method: "PUT",
      body: payload,
    };
    api.call(data, (res: any) => {
      if (res.status === 200) {
        common.notify("S", res.message);
        setPopup("");
        utilsState("moduleEdit", null);
        getData();
      } else {
        processState("result", res.status);
      }
      setSubmit("");
    });
  };

  const addModule = () => {
    let payload = {
      name: fields.name,
      permission: fields.permission,
    };
    let data: any = {
      url: "/module/create",
      method: "POST",
      body: payload,
    };

    api.call(data, (res: any) => {
      if (res.status === 200) {
        common.notify("S", res.message);
        navigate(`/module/settings/${res.data}`);
      } else {
        common.notify("E", res.message);
      }
      setSubmit("");
    });
  };

  // event
  const onSubmit = () => {
    let isValid = handleSubmit();
    if (isValid) {
      setSubmit("module");
      if (utils.moduleEdit) {
        updateModule();
      } else {
        addModule();
      }
    }
  };

  const deleteCallBack = (isDelete: boolean) => {
    if (isDelete) {
      deleteModule();
    } else {
      setConfirm({ id: null, type: "" });
    }
  };

  // support
  const onAgCallBack = (status: any) => {
    getData();
  };

  const processState = (label: any, value: any) => {
    setProcess((prev: any) => ({
      ...prev,
      [label]: value
    }));
  }

  const utilsState = (label: any, value: any) => {
    setUtils((prev: any) => ({
      ...prev,
      [label]: value
    }));
  }

  // render
  return (
    <Fragment>
      <BreadCrumb
        breadCrumbItems={[
          { label: "Settings", path: "/settings/home" },
          { label: "Module Customization", path: "/module/list", active: true },
        ]}
        title={"Module Customization"}
      />
      <Row>
        <Col xl={12} lg={12}>
          {process.result != 200 ? (
            <Card>
              <Card.Body className="py-1 px-3 border-bottom border-light">
                <AgStatus
                  process={process.result}
                  message={{ 100: "Loading Modules...Please wait." }}
                  size="l"
                  img="modules.svg"
                  onAgCallBack={onAgCallBack}
                />
              </Card.Body>
            </Card>
          ) : (
            <Card>
              <Card.Body className="py-2 px-3 border-bottom border-light">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="float-start">
                    <label className="form-check-label fs-16">Modules</label>
                  </div>
                  <Button
                    type="button"
                    className="btn-sm btn btn-outline-success btn btn-primary"
                    onClick={() => {
                      setMultiValue({
                        name: "",
                        permission: null,
                      });
                      handleReset();
                      setPopup("module");
                      processState("edit", 200);
                    }}
                  >
                    Add Module
                  </Button>
                </div>
              </Card.Body>

              <Card.Body>
                <p className="text-muted fs-14">
                  Design your own layouts to fit your business processes, then
                  assign them to your user accounts based on permission
                  profiles.
                </p>

                <ReactSortable
                  className="row"
                  easing="ease"
                  list={resultList}
                  setList={setResultList}
                >
                  {resultList?.length &&
                    resultList?.map((item: any, idx: any) => {
                      return (
                        <Col xl={3} lg={3} key={idx}>
                          <Card className="bg-secondary-subtle border border-secondary-subtle bg-opacity-50 shadow-sm rounded sortable-bg">
                            <Card.Body>
                              <Card.Header className="d-flex justify-content-between align-items-center p-0">
                                <h4
                                  className="header-title"
                                  onClick={(e: any) =>
                                    navigate(`/data/list/${item.id}`)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {item.name}
                                </h4>
                                <Dropdown align="end">
                                  <Dropdown.Toggle
                                    variant="light"
                                    className="dropdown-toggle arrow-none card-drop p-0"
                                  >
                                    <i className="ri-more-fill px-1" />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      as="a"
                                      href="#"
                                      onClick={() => {
                                        navigate(`/module/settings/${item.id}`);
                                      }}
                                    >
                                      <i className="ri-settings-3-line me-1" />
                                      Settings
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      as="a"
                                      href="#"
                                      onClick={() => {
                                        getModule(item.id);
                                      }}
                                    >
                                      <i className="ri-save-line me-1" />
                                      Edit
                                    </Dropdown.Item>
                                    {!item.is_system && <Dropdown.Item
                                      as="a"
                                      href="#"
                                      onClick={() => setConfirm({ id: item.id, type: "module" })}
                                    >
                                      <i className="ri-delete-bin-6-line me-1" />
                                      Delete
                                    </Dropdown.Item>}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </Card.Header>
                              <p className="mb-0 mt-1">
                                <span
                                  className={
                                    item.is_system
                                      ? "badge-outline-success badge rounded-pill"
                                      : "badge-outline-primary badge rounded-pill"
                                  }
                                >
                                  {item.is_system ? "System" : "Custom"} Module
                                </span>
                              </p>
                            </Card.Body>
                          </Card>
                        </Col>
                      );
                    })}
                </ReactSortable>
              </Card.Body>
            </Card>
          )}
        </Col>
      </Row>

      {/* Add module */}
      <Modal
        show={popup === "module"}
        size="lg"
        scrollable={true}
        onHide={() => (setPopup(""))}
      >
        <Modal.Header closeButton>
          <Modal.Title>{utils.moduleEdit ? "Edit Module" : "Add Module"}</Modal.Title>
        </Modal.Header>
        {process.edit != 200 ? (
          <Card>
            <Card.Body className="py-1 px-3 border-bottom border-light">
              <AgStatus
                process={process.edit}
                message={{ 100: "Loading module detail.Please wait..." }}
                img="modules.svg"
                onAgCallBack={onAgCallBack}
                size="m"
              />
            </Card.Body>
          </Card>
        ) : (
          process.edit == 200 && (
            <>
              <Modal.Body>
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Module Name</Form.Label>
                      <Form.Control
                        name="name"
                        type="text"
                        placeholder="Enter module name"
                        isInvalid={errors.name ? true : false}
                        value={fields.name}
                        onChange={handleChange}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label>Permissions</Form.Label>
                      <FormSelect
                        url="/permission/read"
                        fields="id,name"
                        query=""
                        order="name ASC"
                        fixed={false}
                        value={fields.permission}
                        limit={50}
                        multi={true}
                        render={"yes"}
                        async={false}
                        onSelect={(data: any) => {
                          setMultiValue({
                            permission: data,
                          })
                        }
                        }
                        error={errors.permission}
                      />
                      <p className="error-text">{errors.permission}</p>
                    </Form.Group>
                  </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="light"
                  onClick={() =>
                    setPopup("")
                  }
                >
                  <i className="ri-close-line" /> Close
                </Button>
                <Button
                  variant="success"
                  onClick={onSubmit}
                  disabled={submit === "module"}
                >
                  {submit === "module" ? (
                    <>
                      <Spinner
                        className="spinner-border-sm me-1"
                        color="white"
                      />
                      Processing...
                    </>
                  ) : (
                    <>
                      <i className="ri-save-line" /> Submit
                    </>
                  )}
                </Button>
              </Modal.Footer>
            </>
          )
        )}
      </Modal>

      {/* Delete confirmation */}
      {confirm.id && (
        <AgDelete
          type="delete"
          onAgDeleteCallBack={deleteCallBack}
        />
      )}
    </Fragment>
  );
}
