import { Fragment, useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Image,
  Form,
  Pagination as BootstrapPagination,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import Slider from "react-slick";
import { common } from "includes";

const DetailView: React.FC<any> = (props) => {
  // const dbModule: any = config.dbModule;
  const [showPreview, setShowPreview] = useState(false);
  const previewClose = () => setShowPreview(false);
  const previewShow = () => setShowPreview(true);
  const [expand, setExpand] = useState(false);
  const [showLeft, setShowLeft] = useState(false);
  const [readMore, setReadMore] = useState(false);

  const expandLeft = () => {
    setExpand(false);
    setShowLeft(false);
  };
  const collapseLeft = () => {
    setExpand(true);
    setShowLeft(true);
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Fragment>
      <>
        <Row>
          <Col xl={12} lg={12}>
            <Card>
              <Card.Body className="py-1 px-1 border-bottom border-light">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="float-start">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="">Back</Tooltip>}
                    >
                      <Link
                        to="#"
                        // to={"/poc/crm/" + props.module + "/list"}
                        className="text-reset fs-20 p-1 d-inline-block"
                      >
                        <i
                          className="ri-arrow-left-s-line pe-2"
                          data-bs-container="#tooltips-container"
                          data-bs-toggle="tooltip"
                        />
                      </Link>
                    </OverlayTrigger>
                    <label className="form-check-label fs-16">
                      Thunku Ousmand
                    </label>
                  </div>
                  <div id="tooltips-container d-flex align-items-center">
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="">Edit</Tooltip>}
                    >
                      <Link
                        to="#"
                        className="text-reset fs-20 p-1 d-inline-block"
                      >
                        <i
                          className="ri-edit-line"
                          data-bs-container="#tooltips-container"
                          data-bs-toggle="tooltip"
                        />
                      </Link>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="">Previous</Tooltip>}
                    >
                      <Link
                        to="#"
                        className="text-reset fs-20 p-1 d-inline-block"
                      >
                        <i
                          className="ri-arrow-left-s-line"
                          data-bs-container="#tooltips-container"
                          data-bs-toggle="tooltip"
                        />
                      </Link>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="">Next</Tooltip>}
                    >
                      <Link
                        to="#"
                        className="text-reset fs-20 p-1 d-inline-block"
                      >
                        <i
                          className="ri-arrow-right-s-line"
                          data-bs-container="#tooltips-container"
                          data-bs-toggle="tooltip"
                        />
                      </Link>
                    </OverlayTrigger>
                    <OverlayTrigger
                      placement="bottom"
                      overlay={<Tooltip id="">Action</Tooltip>}
                    >
                      <Link
                        to="#"
                        className="text-reset fs-20 p-1 d-inline-block"
                      >
                        <Dropdown>
                          <Dropdown.Toggle
                            as={Link}
                            to="#"
                            className="arrow-none card-drop"
                          >
                            <i className="ri-more-2-line" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu align="end">
                            <Dropdown.Item className="dropdown">
                              <i className="me-1 ri-delete-bin-3-line" />
                              Delete
                            </Dropdown.Item>
                            <Dropdown.Item className="dropdown">
                              <i className="me-1 ri-folder-transfer-line" />
                              Conversion
                            </Dropdown.Item>
                            <Dropdown.Item className="dropdown">
                              <i className="me-1 ri-file-copy-line" />
                              Clone
                            </Dropdown.Item>
                            <Dropdown.Item className="dropdown">
                              <i className="me-1 ri-mail-send-line" />
                              Send Email
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </Link>
                    </OverlayTrigger>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          {showLeft && (
            <Col xl={3} lg={3}>
              <Card>
                <Card.Body>
                  <div id="list-example" className="list-group">
                    <a
                      href="#note"
                      className="list-group-item list-group-item-action"
                    >
                      <i className="ri-sticky-note-line me-1" /> Notes
                    </a>
                    <a
                      href="#attach"
                      className="list-group-item list-group-item-action"
                    >
                      <i className="ri-attachment-line me-1" /> Attachments
                    </a>
                    <a
                      href="#open"
                      className="list-group-item list-group-item-action"
                    >
                      <i className="ri-door-open-line me-1" /> Open Activities
                    </a>
                    <a
                      href="#close"
                      className="list-group-item list-group-item-action"
                    >
                      <i className="ri-door-closed-line me-1" /> Closed
                      Activities
                    </a>
                    <a
                      href="#email"
                      className="list-group-item list-group-item-action"
                    >
                      {" "}
                      <i className="ri-mail-line me-1" /> Emails
                    </a>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          )}
          <Col
            xl={showLeft ? "9" : "12"}
            lg={showLeft ? "9" : "12"}
            className="detail-right-wrap"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id="">{expand ? "Collapse" : "Expand"}</Tooltip>
              }
            >
              <span className="expand-collapse-icon">
                {expand ? (
                  <i className="ri-skip-left-fill fs-22" onClick={expandLeft} />
                ) : (
                  <i
                    className="ri-skip-right-fill fs-22"
                    onClick={collapseLeft}
                  />
                )}
              </span>
            </OverlayTrigger>
            {/* Info */}
            <Card>
              <Card.Body>
                <div className="slider-container status-slider">
                  <Slider {...settings}>
                    <div className="status-card">
                      <span className="status-num">{1}</span> Submitted
                    </div>
                    <div className="status-card active">
                      <span className="status-num">{2}</span> Reviewed
                    </div>
                    <div className="status-card">
                      <span className="status-num">{3}</span> Approved
                    </div>
                    <div className="status-card">
                      <span className="status-num">{4}</span> Completed
                    </div>
                    <div className="status-card">
                      <span className="status-num">{5}</span> Rejected
                    </div>
                    <div className="status-card">
                      <span className="status-num">{6}</span> Hold
                    </div>
                    <div className="status-card">
                      <span className="status-num">{7}</span> Status1
                    </div>
                    <div className="status-card">
                      <span className="status-num">{8}</span> Status2
                    </div>
                    <div className="status-card">
                      <span className="status-num">{9}</span> Status3
                    </div>
                    <div className="status-card">
                      <span className="status-num">{10}</span> Status4
                    </div>
                    <div className="status-card">
                      <span className="status-num">{11}</span> Status5
                    </div>
                  </Slider>
                </div>
                <div>
                  <h5 className="mb-3 text-uppercase bg-light p-2">
                    <i className="ri-survey-line me-1" />
                    Detail
                  </h5>
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label>Label</Form.Label>
                        <p className="text-muted fs-14">Name</p>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="mb-2 pe-5 ps-4">
                      <Form.Group className="mb-3">
                        <Form.Label>Description</Form.Label>
                        <p
                          className={
                            readMore
                              ? "text-muted fs-14 full-description mb-0"
                              : "text-muted fs-14 short-description mb-0"
                          }
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>

                        <Link
                          to="#"
                          className="text-muted fw-bold fs-13"
                          onClick={() => setReadMore(!readMore)}
                        >
                          {!readMore ? "Read more" : "Show Less"}
                        </Link>
                      </Form.Group>
                    </Col>
                    {/* <Col md={6} className="mb-2 pe-5 ps-4">
                      <div className="text-dark-emphasis fs-14 pb-1">Label</div>
                      <p className="text-muted fs-14">Label</p>
                      <>
                        <p
                          className={
                            readMore
                              ? "text-muted fs-14 full-description mb-0"
                              : "text-muted fs-14 short-description mb-0"
                          }
                        >
                          Lorem Ipsum is simply dummy text of the printing and
                          typesetting industry. Lorem Ipsum has been the
                          industry's standard dummy text ever since the 1500s,
                          when an unknown printer took a galley of type and
                          scrambled it to make a type specimen book. It has
                          survived not only five centuries, but also the leap
                          into electronic typesetting, remaining essentially
                          unchanged. It was popularised in the 1960s with the
                          release of Letraset sheets containing Lorem Ipsum
                          passages, and more recently with desktop publishing
                          software like Aldus PageMaker including versions of
                          Lorem Ipsum.
                        </p>

                        <Link
                          to="#"
                          className="text-muted fw-bold fs-13"
                          onClick={() => setReadMore(!readMore)}
                        >
                          {!readMore ? "Read more" : "Show Less"}
                        </Link>
                      </>
                      <p className="text-muted fs-14">
                        <a href="#" className="text-muted fw-bold">
                          Label
                        </a>
                      </p>
                      <p className="text-muted fs-14">
                        <a href="#" className="text-muted fw-bold">
                          Phone
                        </a>
                      </p>

                      <p className="text-muted fs-14">Date</p>

                      <p className="text-muted fs-14">DateTime</p>

                      <p className="text-muted fs-14">Number</p>

                      <p className="text-muted fs-14">Checkboc</p>

                      <p className="text-muted fs-14">
                        <Link to="#" target="_blank" className="link-primary">
                          Url
                        </Link>
                      </p>

                      <>
                        <Card className="shadow-none mb-0">
                          <div className="p-1">
                            <Row className="align-items-center">
                              <div
                                className="col-auto cursor-pointer"
                                onClick={previewShow}
                              >
                                <Image
                                  src={common.loadImg(file.name)}
                                  className="avatar-xs rounded"
                                />
                                <div className="avatar-xs">
                                  <span className="avatar-title text-primary bg-primary-subtle rounded">
                                    .txt
                                  </span>
                                </div>
                              </div>
                              <Col className="ps-0">
                                <Link to="#" className="text-muted fs-13">
                                  File
                                </Link>
                                <p className="mb-0 fs-10">Size</p>
                              </Col>
                              <div className="col-auto">
                                <Link
                                  to="#"
                                  className="btn btn-link btn-lg text-muted"
                                >
                                  <i className=" ri-delete-bin-3-line" />
                                </Link>
                                <Link
                                  to="#"
                                  className="btn btn-link btn-lg text-muted"
                                >
                                  <i className="ri-download-line" />
                                </Link>
                              </div>
                            </Row>
                          </div>
                        </Card>
                      </>
                    </Col> */}
                  </Row>
                </div>
              </Card.Body>
            </Card>

            {/* Notes */}
            <Card id="note">
              <Card.Body>
                <h5 className="mb-3 text-uppercase bg-light p-2">
                  <i className="ri-sticky-note-line me-1" /> Notes
                </h5>
                <Row>
                  <Col md={6}>
                    <p className="text-muted fs-14">Coming Soon</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* Attachments */}
            <Card id="attach">
              <Card.Body>
                <h5 className="mb-3 text-uppercase bg-light p-2">
                  <i className="ri-attachment-line me-1" /> Attachments
                </h5>
                <Row>
                  <Col md={6}>
                    <p className="text-muted fs-14">Coming Soon</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* Open Activities */}
            <Card id="open">
              <Card.Body>
                <h5 className="mb-3 text-uppercase bg-light p-2">
                  <i className="ri-door-open-line me-1" /> Open Activities
                </h5>
                <Row>
                  <Col md={6}>
                    <p className="text-muted fs-14">Coming Soon</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* Closed Activities */}
            <Card id="close">
              <Card.Body>
                <h5 className="mb-3 text-uppercase bg-light p-2">
                  <i className="ri-door-closed-line me-1" /> Closed Activities
                </h5>
                <Row>
                  <Col md={6}>
                    <p className="text-muted fs-14">Coming Soon</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            {/* Emails */}
            <Card id="email">
              <Card.Body>
                <h5 className="mb-3 text-uppercase bg-light p-2">
                  <i className="ri-mail-line me-1" /> Emails
                </h5>
                <Row>
                  <Col md={6}>
                    <p className="text-muted fs-14">Coming Soon</p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>

      {/* Attachment Preview */}
      <Modal show={showPreview} onHide={previewClose} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          {(files || []).map((file, idx) => {
            return (
              <Card className="shadow-none mb-0" key={idx}>
                <div className="col-auto cursor-pointer" onClick={previewShow}>
                  {[".png", ".jpeg", ".gif", ".jpg"].includes(
                    file.extension
                  ) ? (
                    <Image src={common.loadImg(file.name)} className="w-100" />
                  ) : null}
                </div>
              </Card>
            );
          })}
        </Modal.Body>
      </Modal>
    </Fragment>
  );
};
export default DetailView;

export const files = [
  {
    name: "test-sketch-design.zip",
    extension: ".zip",
    size: "2.3MB",
  },
  {
    name: "users/avatar-1.jpg",
    extension: ".jpg",
    size: "1.3MB",
  },
  {
    name: "profile.pdf",
    extension: ".pdf",
    size: "5MB",
  },
];