import React, { Fragment, Suspense, useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";

// redux
import { AppDispatch, RootState } from "redux/store";

// hoooks
import { common } from "includes";

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52
const Topbar = React.lazy(() => import("./Topbar"));
const NavBar = React.lazy(() => import("./NavBar"));
const Footer = React.lazy(() => import("./Footer"));
const RightSideBar = React.lazy(() => import("./RightSideBar"));

const HorizontalLayout = () => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    // layoutType,
    layoutTheme,
    layoutWidth,
    topBarTheme,
    sideBarTheme,
    // sideBarType,
    layoutPosition,
    // showSideBarUserInfo,
    // isOpenRightSideBar,
  } = useSelector((state: RootState) => ({
    layoutType: state.Layout.layoutType,
    layoutTheme: state.Layout.layoutTheme,
    layoutWidth: state.Layout.layoutWidth,
    topBarTheme: state.Layout.topBarTheme,
    sideBarTheme: state.Layout.sideBarTheme,
    sideBarType: state.Layout.sideBarType,
    layoutPosition: state.Layout.layoutPosition,
    showSideBarUserInfo: state.Layout.showSideBarUserInfo,
    isOpenRightSideBar: state.Layout.isOpenRightSideBar,
  }));

  const [isAuth] = useState(common.checkAuth());

  // effect
  useEffect(() => {
    if (!isAuth) {
      common.redirect("login");
    }
  }, [isAuth]);

  /**
   * Layout defaults
   */

  useEffect(() => {
    common.changeHTMLAttribute("data-layout", "topnav");
  }, [dispatch]);

  useEffect(() => {
    common.changeHTMLAttribute("data-bs-theme", layoutTheme);
  }, [layoutTheme]);

  useEffect(() => {
    common.changeHTMLAttribute("data-layout-mode", layoutWidth);
  }, [layoutWidth]);

  useEffect(() => {
    common.changeHTMLAttribute("data-topbar-color", topBarTheme);
  }, [topBarTheme]);

  useEffect(() => {
    common.changeHTMLAttribute("data-menu-color", sideBarTheme);
  }, [sideBarTheme]);

  useEffect(() => {
    common.changeHTMLAttribute("data-layout-position", layoutPosition);
  }, [layoutPosition]);

  return (
    isAuth ? (
      <Suspense fallback={<div />}>
        <div className="wrapper">
          <Suspense fallback={<div />}>
            <Topbar />
          </Suspense>

          <Suspense fallback={<div />}>
            <NavBar />
          </Suspense>

          <div className="content-page">
            <div className="content">
              <Container fluid>
                <Suspense fallback={<div />}>
                  <Outlet />
                </Suspense>
              </Container>
            </div>
            <Footer />
          </div>
        </div>

        <Suspense fallback={<div />}>
          <RightSideBar />
        </Suspense>
      </Suspense>
    ) : (<Fragment></Fragment>)
  );
};

export default HorizontalLayout;
