export const config = {
  public_url: process.env.REACT_APP_PUBLIC_URL,
  // api
  api: {
    isExpired: 401,
    url: process.env.REACT_APP_API_URL,
    cloudImgPath: "https://osmo-crm.s3.amazonaws.com/",
  },
  cryptKey: "ABCD",
  paginationOptions: [
    { label: "10 Rows", value: 10 },
    { label: "25 Rows", value: 25 },
    { label: "50 Rows", value: 50 },
    { label: "100 Rows", value: 100 },
  ],
  // elements
  elements: {
    dp: "MMM DD, YYYY hh:mm A",
    singleDp: {
      singleDatePicker: true,
      showDropdowns: true,
      locale: {
        format: "MMMM DD, YYYY",
      },
      autoApply: true,
      autoUpdateInput: false,
      isClearable: true,
    },
    toast: {
      ToastPosition: "top-right",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    },
    modalStyle: {
      maxWidth: "450px",
      margin: "0 auto",
      Position: "absolute",
      top: "10%",
      left: "50%",
      transform: "translateX(-50%)",
    }
  },
  options: {
    permissions: [
      { value: "1", label: "All" },
      { value: "2", label: "Access Denied" },
      { value: "3", label: "Personal" },
      { value: "4", label: "Personal & Department" },
      { value: "5", label: "Personal, Department & Child Department" },
    ],
    accessNames: [
      { value: "r", label: "Read" },
      { value: "a", label: "Add" },
      { value: "u", label: "Update" },
      { value: "d", label: "Delete" },
      { value: "i", label: "Import" },
      { value: "e", label: "Export" },
    ],
    pages: [
      { label: "10 Rows", value: 10 },
      { label: "25 Rows", value: 25 },
      { label: "50 Rows", value: 50 },
      { label: "100 Rows", value: 100 },
    ]
  }
};
