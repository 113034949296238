// package
import { Fragment } from "react";
// component
import BreadCrumb from "elements/System/BreadCrumb";
import Company from "elements/System/Company";

export default function CompanyProfile() {
  // render
  return (
    <Fragment>
      <BreadCrumb
        breadCrumbItems={[
          { label: "Settings", path: "/settings/home" },
          { label: "Company Profile", path: "/company/home", active: true },
        ]}
        title={"Company Profile"}
      />
      <Company view="page" />
    </Fragment>
  );
}
