import { Button } from "react-bootstrap";
import { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import "swiper/css";
import { common } from "includes";
import { useNavigate } from "react-router-dom";
const logo = common.loadImg("logo.png");

export default function FrontHome() {
  const navigate = useNavigate();
  useEffect(() => {
    if (document.body) {
      document.body.classList.add("authentication-bg", "pb-0");
    }
    return () => {
      if (document.body) {
        document.body.classList.remove("authentication-bg", "pb-0");
      }
    };
  }, []);
  return (
    <div className="auth-fluid">
      <div className="auth-fluid-right text-center">
        <div>
          <img src={logo} alt="logo" />
        </div>
        <div className="mt-5">
          <Button
            variant="warning"
            className="rounded-pill"
            onClick={() => navigate("/login")}
          >
            Take me to Osmo
          </Button>
        </div>
        <div className="auth-user-testimonial">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            modules={[Autoplay]}
            autoplay={{ delay: 5000 }}
          >
            <SwiperSlide>
              <h2 className="mb-3">I love the color!</h2>
              <p className="lead">
                <i className="ri-double-quotes-l" /> Everything you need is in
                this platform. Love the overall look and feel. Not too flashy,
                and still very professional and smart.
              </p>
              <p>- Raj Krishna</p>
            </SwiperSlide>
            <SwiperSlide>
              <h2 className="mb-3">Flexibility !</h2>
              <p className="lead">
                <i className="ri-double-quotes-l" /> Pretty nice platform,
                hoping you guys could add more features to this. Keep up the
                good work.
              </p>
              <p>- Tunku Ousmand</p>
            </SwiperSlide>
            <SwiperSlide>
              <h2 className="mb-3">Feature Availability!</h2>
              <p className="lead">
                <i className="ri-double-quotes-l" /> This is a great product,
                helped us a lot and very quick to work with and implement.
              </p>
              <p>- Pradeep R</p>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}
