import { Fragment } from "react";
import BreadCrumb from "elements/System/BreadCrumb";
import {
  Row,
  Col,
  Card,
  Button,
  OverlayTrigger,
  Tooltip,
  Accordion,
  Form,
  Dropdown,
  ButtonGroup,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import Select from "react-select";

export default function FormSettings() {
  return (
    <Fragment>
      <BreadCrumb
        breadCrumbItems={[
          { label: "Form Setting", path: "/pages/Form Setting", active: true },
        ]}
        title={"Form Setting"}
      />
      <Col xl={12} lg={12}>
        <Card>
          <Card.Body className="py-1 px-2 border-bottom border-light">
            <div className="d-flex align-items-center justify-content-between">
              <div className="float-start">
                <OverlayTrigger
                  placement="bottom"
                  overlay={<Tooltip id="">Back</Tooltip>}
                >
                  <Link
                    to="/poc/form/list"
                    className="text-reset fs-20 p-1 d-inline-block"
                  >
                    <i
                      className="ri-arrow-left-s-line pe-2"
                      data-bs-container="#tooltips-container"
                      data-bs-toggle="tooltip"
                    />
                  </Link>
                </OverlayTrigger>
                <label className="form-check-label fs-16">Form Settings</label>
              </div>
              <div className="d-flex align-items-center">
                <Select className="select2 me-1" options={modules} />
                <Button variant="light" className="me-1">
                  Close
                </Button>
                <Button variant="primary">Save</Button>
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Row>
        <Col md={4}>
          <Card>
            <Card.Body>
              <h4 className="header-title">Form Assets</h4>
              <p className="text-muted">click and Drag a form asset to add</p>
              <Accordion defaultActiveKey="0" flush>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>New Fields</Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      {dynamicFieldInputs.map((item, index) => (
                        <Col md={6} key={index} draggable>
                          <div className="draggable-formfield-wrap">
                            <i className={item.icon} />
                            <span>{item.label}</span>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Unused Fields2</Accordion.Header>
                  <Accordion.Body>All fields are in use.</Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Card.Body>
          </Card>
        </Col>
        <Col md={8}>
          <Card>
            <Card.Body className="fs-right-wrap">
              <h5 className="mb-3 text-uppercase bg-light p-2">
                <i className="ri-survey-line me-1" /> Lead Information
              </h5>
              <Row>
                <Col md={6}>
                  <div className="dragged-formfield-wrap required-fs-input system-fs-input">
                    <div>
                      <Form.Label className="mb-0">Lead Name</Form.Label>
                    </div>
                    <div>
                      <p className="text-muted mb-0">Single Line</p>
                    </div>
                    <div>
                      <Dropdown>
                        <Dropdown.Toggle
                          as={ButtonGroup}
                          variant=""
                          className="arrow-none"
                          style={{ cursor: "pointer" }}
                        >
                          <i className="ri-more-line" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="start">
                          <Dropdown.Item className="dropdown">
                            <i className="me-1 ri-edit-line" />
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item className="dropdown">
                            <i className="me-1 ri-delete-bin-6-line" />
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="dragged-formfield-wrap">
                    <div>
                      <Form.Label className="mb-0">Company Name</Form.Label>
                    </div>
                    <div>
                      <p className="text-muted mb-0">Single Line</p>
                    </div>
                    <div>
                      <Dropdown>
                        <Dropdown.Toggle
                          as={ButtonGroup}
                          variant=""
                          className="arrow-none"
                          style={{ cursor: "pointer" }}
                        >
                          <i className="ri-more-line" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="start">
                          <Dropdown.Item className="dropdown">
                            <i className="me-1 ri-edit-line" />
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item className="dropdown">
                            <i className="me-1 ri-delete-bin-6-line" />
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="dragged-formfield-wrap add-dragged-field-wrap">
                    <i className="me-1 ri-add-line" />
                    <p className="text-muted mb-0">Drag & Drop Field here</p>
                  </div>
                </Col>
              </Row>
              <h5 className="mb-3 text-uppercase bg-light p-2">
                <i className="ri-survey-line me-1" /> Contact Details
              </h5>
              <Row>
                <Col md={6}>
                  <div className="dragged-formfield-wrap">
                    <div>
                      <Form.Label className="mb-0">Lead Name</Form.Label>
                    </div>
                    <div>
                      <p className="text-muted mb-0">Single Line</p>
                    </div>
                    <div>
                      <Dropdown>
                        <Dropdown.Toggle
                          as={ButtonGroup}
                          variant=""
                          className="arrow-none"
                          style={{ cursor: "pointer" }}
                        >
                          <i className="ri-more-line" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="start">
                          <Dropdown.Item className="dropdown">
                            <i className="me-1 ri-edit-line" />
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item className="dropdown">
                            <i className="me-1 ri-delete-bin-6-line" />
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </Col>
                <Col md={6}>
                  <div className="dragged-formfield-wrap">
                    <div>
                      <Form.Label className="mb-0">Company Name</Form.Label>
                    </div>
                    <div>
                      <p className="text-muted mb-0">Single Line</p>
                    </div>
                    <div>
                      <Dropdown>
                        <Dropdown.Toggle
                          as={ButtonGroup}
                          variant=""
                          className="arrow-none"
                          style={{ cursor: "pointer" }}
                        >
                          <i className="ri-more-line" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu align="start">
                          <Dropdown.Item className="dropdown">
                            <i className="me-1 ri-edit-line" />
                            Edit
                          </Dropdown.Item>
                          <Dropdown.Item className="dropdown">
                            <i className="me-1 ri-delete-bin-6-line" />
                            Delete
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="dragged-formfield-wrap add-dragged-field-wrap">
                    <i className="me-1 ri-add-line" />
                    <p className="text-muted mb-0">Drag & Drop Field here</p>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
}

export const modules = [
  { value: "", label: "Select" },
  { value: "lead", label: "Lead" },
  { value: "deal", label: "Deal" },
];

export const dynamicFieldInputs = [
  {
    id: 1,
    icon: "ri-subtract-line",
    label: "Single Line",
  },
  {
    id: 2,
    icon: "ri-menu-line",
    label: "Multi Line",
  },
  {
    id: 3,
    icon: "ri-mail-line",
    label: "Email",
  },
  {
    id: 4,
    icon: "ri-phone-line",
    label: "Phone",
  },
  {
    id: 5,
    icon: "ri-file-list-line",
    label: "Pick List",
  },
  {
    id: 6,
    icon: "ri-calendar-line",
    label: "Date",
  },
  {
    id: 7,
    icon: "ri-time-line",
    label: "Date/Time",
  },
  {
    id: 8,
    icon: "ri-hashtag",
    label: "Number",
  },
  {
    id: 9,
    icon: "ri-file-list-line",
    label: "Decimal",
  },
  {
    id: 10,
    icon: "ri-currency-line",
    label: "Currency",
  },
  {
    id: 11,
    icon: "ri-checkbox-line",
    label: "Checkbox",
  },
  {
    id: 12,
    icon: "ri-links-line",
    label: "Url",
  },
  {
    id: 13,
    icon: "ri-file-upload-line",
    label: "File Upload",
  },
  {
    id: 14,
    icon: "ri-file-list-line",
    label: "Lookup",
  },
];