// package
import { Fragment, useState } from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
// component
import { common } from "includes";
const AgDelete = (props: any) => {
    // const
    const [isDisabled, setIsDisabled] = useState(false);

    // render
    return (
        <Fragment>
            <Modal
                show={true}
                className="fade"
                onHide={() => props.onAgDeleteCallBack(false)}
            >
                <div className={`${props.type == "delete" ? "" : "modal-filled bg-danger"}`}>
                    <Modal.Header closeButton className={`${props.type == "delete" ? "modal-colored-header bg-danger" : ""}`}>
                        <h4 className="modal-title">Delete Confirmation</h4>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="text-center">
                            <img src={common.loadImg("svg/delete-confirm.svg")} style={{ width: 120, height: 120 }} alt="logo" />
                            <p className="fs-16 mt-2">Are you sure you want to delete the selected record?</p>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="light"
                            disabled={isDisabled}
                            onClick={() => props.onAgDeleteCallBack(false)}
                        >
                            <i className="ri-close-line me-1" />
                            No
                        </Button>
                        <Button
                            variant={`${props.type == "delete" ? "light" : "outline-light"}`}
                            type="button"
                            disabled={isDisabled}
                            onClick={() => {
                                setIsDisabled(true);
                                props.onAgDeleteCallBack(true);
                            }}
                        >
                            {isDisabled ? (
                                <>
                                    <Spinner
                                        className="spinner-border-sm me-1"
                                        color="white"
                                    />
                                    Processing...
                                </>
                            ) : (
                                <>
                                    <i className="ri-check-line me-1" />
                                    Yes
                                </>
                            )}
                        </Button>
                    </Modal.Footer>
                </div>
            </Modal>
        </Fragment>
    );
};
export default AgDelete;
